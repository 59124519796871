import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Draggable from 'react-draggable'
import './DraggableButton.scss' // Your custom CSS
import iconArrow from '../../assets/img/arrowIcon.svg'
import { postionOfElement } from '../../utils/helper'

const DraggableButton = ({ handleStopMasquerade }) => {
    const [positonPopover, setPositonPopover] = useState('')
    const [showDragButtonTop, setShowDragButtonTop] = useState(false)
    const [showDragButtonBottom, setShowDragButtonBottom] = useState(true)

    const onHover = (e) => {
        e.preventDefault()
        const offsets = postionOfElement('masqButton')
        if (offsets.left < 300 && offsets.right < 350) {
            setPositonPopover('left')
        } else if (offsets.left > 300 && offsets.right > 1000) {
            setPositonPopover('right')
        }

        if (offsets.y < 0) {
            setShowDragButtonTop(false)
            setShowDragButtonBottom(true)
        } else if (offsets.y > 700) {
            setShowDragButtonTop(true)
            setShowDragButtonBottom(false)
        }
    }

    return (
        <Draggable
            handle=".handle"
            defaultPosition={{ x: 0, y: 0 }}
            position={null}
            grid={[25, 25]}
            scale={1}
            defaultClassName="masquerade-quit"
        >
            <div className='text-center' style={{ bottom: '50px', left: '0px', width: '70px', position: 'fixed' }} >
                {(showDragButtonTop) && <button className='p-0 btn text-center text-primary handle' >
                    <img src={iconArrow} style={{ height: '30px' }} />
                </button>}
                <button
                    className='btn masqButton shadow btn-warning font-weight-bold p-3 rounded-5'
                    id='masqButton'
                    style={{ borderRadius: '50px' }}
                    onClick={handleStopMasquerade}
                    onMouseOver={onHover}
                >
                    <span className={`popoverContent bg-warning ${positonPopover}`}>
                        <p>You are temporarily logged in as another user. When you are done, switch back to your account</p>
                    </span>
                    <i className="fa-solid fa-masks-theater"></i>
                </button>
                {(showDragButtonBottom) && <button className='p-0 btn text-center text-primary handle'>
                    <img src={iconArrow} style={{ height: '30px' }} />
                </button>}
            </div>
        </Draggable>
    )
}

DraggableButton.propTypes = {
    handleStopMasquerade: PropTypes.func,
}

export default DraggableButton