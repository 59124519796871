import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { EMPTY_ARRAY } from '../../constants/globalConstants'

function ExtendDetails(props) {
    const { className, extendedDetails} = props
    // const [discountedTotal, setDiscountedTotal] = useState(0)
    

    useEffect(() => {
        
    },[EMPTY_ARRAY])


    const getFormattedTime = (totalhours, isConvert) => {

        const splittedTime = totalhours?.split('.')
        let minutes
        if(isConvert){
            minutes = (splittedTime?.[1]/100)*60
        }else{
            minutes = splittedTime?.[1]
        }
        const hours = splittedTime ? `${splittedTime?.[0] == 0 ? '00' : splittedTime?.[0] == 0 ? '00' : Number(splittedTime?.[0]) < 10 ? `0${splittedTime?.[0]} `: splittedTime?.[0]} : ${minutes != 0 ? minutes : '00'}` : ''
        return hours
    }

    

    return (
        <div>
            
            {extendedDetails?.length > 0 && <div className={`${className}`}>
                    <table className='table table-responsive small mt-2'>
                        {extendedDetails?.map((item, index) => {
                            return (
                                <tr className='border-bottom' key={index}>
                                    <td className='text-start'>
                                        Started
                                    </td>
                                    <td className='text-center'>
                                        {`${getFormattedTime(item?.length, false)} ${Number(item?.length) > 1 ? 'HRS' : Number(item?.length) == 1 ? 'HR' :'MM'}`}
                                    </td>
                                    <td className='text-end mb-1'>
                                        {index == 0 ? `$${item?.total}` : `$${Number(Number(item?.total) - Number(extendedDetails?.[index-1]?.total))?.toFixed(2)}`}
                                    </td>
                                </tr>
                            )
                        })}

                        {/* <tr className='border-bottom'>
                            <td className='text-start'>
                                Started
                            </td>
                            <td className='text-center'>
                                {`${getFormattedTime(extendedDetails[0]?.length, false)} ${extendedDetails[0]?.length > 1 ? 'Hrs' : 'Hr'}`}
                            </td>
                            <td className='text-end mb-1'>
                                {`$${extendedDetails[0]?.total}`}
                            </td>
                        </tr>
                        <tr className='border-bottom mb-1'>
                            <td className='text-start'>
                                Extends
                            </td>
                            <td className='text-center'>
                                {`${getFormattedTime(extendedDetails[1]?.length, true)} ${extendedDetails[1]?.length > 1 ? 'Hrs' : 'Hr'}`}
                            </td>
                            <td className='text-end'>
                                {`$${Number(Number(extendedDetails[1]?.total) - Number(extendedDetails[0]?.total))?.toFixed(2)}`}
                            </td>
                        </tr> */}
                    </table>
                    
                </div>}
        </div>
    )
}

ExtendDetails.propTypes = {
    dispatch: PropTypes.func,
    className: PropTypes.string,
    extendedDetails: PropTypes.array,
}

ExtendDetails.defaulProps = {
    dispatch: noop,
    className: '',
    extendedDetails: EMPTY_ARRAY,
}

function mapStateToProps() {
    return {}
}

export default connect(mapStateToProps)(ExtendDetails)