
import CONSTANTS from '../constants'
import {getRequest, postRequest } from '../../services'
import { APICONFIG } from '../config/apiConfig'


export const getRateDetailsData = (TicketNum) => (dispatch) => {
     dispatch({ type: CONSTANTS.EXTEND_CARD_DETAILS_REQUEST })
     let endpoint = `${APICONFIG.getExtendDetails}/${TicketNum}`
    return getRequest(endpoint).then((res) => {
      dispatch({ type: CONSTANTS.EXTEND_CARD_DETAILS_SUCCESS, data: res })
      return res
    }).catch((err) => {
      dispatch({ type: CONSTANTS.EXTEND_CARD_DETAILS_FAILURE })
      return err
    })
  }

  export const getRateHourData = (payload) => (dispatch) => {
    dispatch({ type: CONSTANTS.EXTEND_CARD_HOUR_REQUEST })
   return postRequest(APICONFIG.postExtendDays, payload).then((res) => {
     dispatch({ type: CONSTANTS.EXTEND_CARD_HOUR_SUCCESS, data: res })
     return res
   }).catch((err) => {
     dispatch({ type: CONSTANTS.EXTEND_CARD_HOUR_FAILURE })
     return err
   })
 }

 
 export const postExtendData = (payload) => (dispatch) => {
    dispatch({ type: CONSTANTS.EXTEND_CARD_HOUR_REQUEST })
   return postRequest(APICONFIG.postExtendSubmit, payload).then((res) => {
     dispatch({ type: CONSTANTS.EXTEND_CARD_HOUR_SUCCESS, data: res })
     return res
   }).catch((err) => {
     dispatch({ type: CONSTANTS.EXTEND_CARD_HOUR_FAILURE })
     return err
   })
 }

  // EXTEND LIST
export const getExtendList = (ticket) => (dispatch) => {
  // dispatch(addEventLog(ticket, CONSTANTS.EXTEND_TICKET_LIST_REQUEST))
  dispatch({ type: CONSTANTS.EXTEND_TICKET_LIST_REQUEST })
  // let tobject = { ticket: ticket }
  return getRequest(`${APICONFIG.getExtendList}/${ticket}`).then((res) => {
    // let resObject = { ...tobject, ...res }
    // dispatch(addEventLog(resObject, CONSTANTS.EXTEND_TICKET_LIST_SUCCESS))
    dispatch({ type: CONSTANTS.EXTEND_TICKET_LIST_SUCCESS, data: res })
    return res
  }).catch((err) => {
    // let errObject = { ...tobject, ...err }
    // dispatch(addEventLog(errObject, CONSTANTS.EXTEND_TICKET_LIST_FAILURE))
    dispatch({ type: CONSTANTS.EXTEND_TICKET_LIST_FAILURE })
    return err
  })
}