import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import ListingDataComp from './ListingDataComp'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
// import AmountPaid from './AmountPaid'
import ExtendDetails from './ExtendDetails'
import { getExtendList } from '../../redux/actions/ExtendFromAction'
// import { useParams } from 'react-router-dom'
import Loader from './Loader'

function PaymentBreakUpDetails(props) {
    // const { dispatch, className, sectionKey, paymentData, faciltyData, loader, isDisplayDetails, setIsDisplayDetails, isExtendDetails, ticketId } = props
    const { dispatch, className, paymentData, loader, isDisplayDetails, isExtendDetails, ticketId } = props

    const [extendedDetails, setExtendedDetails] = useState(EMPTY_ARRAY)
    const [isFetchingExtendDetails, setIsfetchingExtendDetails] = useState(false)
    const [isExtendDisplay, setIsExtendDisplay] = useState(false)
    // const {ticketId} = useParams()
    useEffect(() => {
        if (isExtendDetails) {
            setIsfetchingExtendDetails(true)
            dispatch(getExtendList(ticketId)).then((res) => {
                setIsfetchingExtendDetails(false)
                if (res.data && res.data.length > 0) {
                    setExtendedDetails(res?.data)
                } else {
                    setExtendedDetails(EMPTY_ARRAY)
                }
            }).catch(() => {
                setIsfetchingExtendDetails(false)
                setExtendedDetails(EMPTY_ARRAY)
            })
        }
    }, [EMPTY_ARRAY])

    const handleFetchingExtendDetails = () => {
        setIsExtendDisplay(!isExtendDisplay)
    }

    //    Uncomment when we need to display extend list

    // const handleCloseDetails = () => {
    //     setIsDisplayDetails(false)
    // }

    return (
        // isDisplayDetails && <div className='zIndexMid cover mt-4'>
        //     <div className='zIndexHigh opacity-100 bottom-0 datalist text-center pb-3'>
        //         <p className='text-primary mt-2 mb-4 fw-semibold'>Price Breakdown</p>
        isDisplayDetails && <div>
            <><ListingDataComp loader={loader} paymentData={paymentData} />
                {/* <AmountPaid 
                    className={className ? className : 'bg-white py-2'} 
                    faciltyData={faciltyData} 
                    paymentData={paymentData} 
                    sectionKey={sectionKey}
                    hideTaxIncluded={(paymentData?.tax_fee != '0.00') ? false : true}
                /> */}

                {(extendedDetails.length > 0) ? ((isExtendDetails && isFetchingExtendDetails) ? <Loader isMargin /> : <div className='mb-1 mt-2 text-end'>
                    <span className='fw-bold p-0 m-0 ticket-font text-primary' onClick={handleFetchingExtendDetails}>More Details {isExtendDisplay ? <i className='fa fa-circle-xmark' /> : <i className='fa fa-circle-arrow-right' />}</span>
                </div>) : null}
                {(isExtendDetails && isExtendDisplay) && <ExtendDetails
                    className={className ? className : 'bg-white py-2'}
                    extendedDetails={extendedDetails}
                />}
            </>
            {/* <span className='text-danger small mb-3' onClick={()=> handleCloseDetails()}>Close</span> */}
        </div>
    )
}

PaymentBreakUpDetails.propTypes = {
    dispatch: PropTypes.func,
    className: PropTypes.string,
    faciltyData: PropTypes.object,
    sectionKey: PropTypes.string,
    paymentData: PropTypes.object,
    loader: PropTypes.bool,
    listData: PropTypes.object,
    isDisplayDetails: PropTypes.bool,
    isExtendDetails: PropTypes.bool,
    setIsDisplayDetails: PropTypes.func,
    ticketId: PropTypes.string,
}

PaymentBreakUpDetails.defaulProps = {
    dispatch: noop,
    className: '',
    faciltyData: EMPTY_OBJECT,
    sectionKey: '',
    paymentData: EMPTY_OBJECT,
    loader: false,
    listData: EMPTY_OBJECT,
    isDisplayDetails: false,
    isExtendDetails: false,
    setIsDisplayDetails: noop,
    ticketId: null,
}

function mapStateToProps() {
    return {}
}

export default connect(mapStateToProps)(PaymentBreakUpDetails)