import React, { Component } from 'react'
import PropTypes from 'prop-types'

class LoadingComponent extends Component {
    render() {
        const { message } = this.props
        return (
            <div className="loaderOverLay" >
                <div className="loaderContainer">
                    <div className="spinner-border " role="status"></div>
                    <div className="spinner-grow" style={{ marginLeft: '-31px' }} role="status"></div>
                    <p className="m-0">{(message) ? message : 'Please Wait..'}</p>
                </div>
            </div>
        )
    }

   

    
}

LoadingComponent.propTypes = {
    message : PropTypes.string
}


LoadingComponent.defaultProps = {
    message  : ''
}
export default LoadingComponent

