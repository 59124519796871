import CONSTANTS from '../constants'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'

const INITIAL_STATE = {
    loader: false,
    hasError: false,
    events: EMPTY_OBJECT,
    passDetails: EMPTY_OBJECT,
    vehicleList: EMPTY_ARRAY,
    event_type: '',
    errorMessage: '',
    downloadLoader: false,
}

const permitListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        //RESPONSE HANDLING
        case CONSTANTS.GET_PERMIT_LIST_REQUEST:
            return {
                ...state,
                loader: true,
                errorMessage: ''
            }
        case CONSTANTS.GET_PERMIT_LIST_SUCCESS:
            return {
                ...state,
                loader: false,
                events: action.data,
                hasError: false,
                errorMessage: ''
            }
        case CONSTANTS.GET_PERMIT_LIST_FAILURE:
            return {
                ...state,
                loader: false,
                errorMessage: 'There is some Error'
            }

        //  GET VEHICLE LIST DETAILS
        case CONSTANTS.GET_VEHICLE_LIST_REQUEST:
            return {
                ...state,
                loader: true,
                errorMessage: ''
            }
        case CONSTANTS.GET_VEHICLE_LIST_SUCCESS:
            return {
                ...state,
                loader: false,
                vehicleList: action.data,
                hasError: false,
                errorMessage: ''
            }
        case CONSTANTS.GET_VEHICLE_LIST_FAILURE:
            return {
                ...state,
                loader: false,
                errorMessage: 'There is some Error'
            }

            //  GET VEHICLE LIST DETAILS
        case CONSTANTS.GET_PERMIT_QR_REQUEST:
            return {
                ...state,
                downloadLoader: true,
                errorMessage: ''
            }
        case CONSTANTS.GET_PERMIT_QR_SUCCESS:
            return {
                ...state,
                downloadLoader: false,
                hasError: false,
                errorMessage: ''
            }
        case CONSTANTS.GET_PERMIT_QR_FAILURE:
            return {
                ...state,
                downloadLoader: false,
                errorMessage: 'There is some Error'
            }
        default:
            return state
    }
}

export default permitListReducer
