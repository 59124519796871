import CONSTANTS from '../constants'
import { EMPTY_OBJECT } from '../../constants/globalConstants'

const INITIAL_STATE = {
	loader: false,
	hasError: false,
	data: EMPTY_OBJECT,
	passData: EMPTY_OBJECT,
	beforePaymentData: EMPTY_OBJECT,
	errorMessage: '',
	userDetails: {},
	proRateLoader: false,
	paymentSession: {},
}

const paymentReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {

		//RESPONSE HANDLING
		case CONSTANTS.PAYMENT_INITIAL_REQUEST:
			return {
				...state,
				loader: true,
				errorMessage: ''
			}
		case CONSTANTS.PAYMENT_INITIAL_SUCCESS:
			return {
				...state,
				loader: false,
				data: action.data,
				hasError: false,
				errorMessage: ''
			}
		case CONSTANTS.PAYMENT_INITIAL_FAILURE:
			return {
				...state,
				loader: false,
				errorMessage: 'There is some Error'
			}
		case CONSTANTS.STORE_USER_DETAILS_SUCCESS:
			return { ...state, userDetails: { ...state.userDetails, ...action.data } }
		case CONSTANTS.AFTER_PAYMENT_REQUEST:
			return {
				...state,
				loader: true,
				errorMessage: ''
			}
		case CONSTANTS.AFTER_PAYMENT_SUCCESS:
			return {
				...state,
				loader: false,
				data: action.data,
				hasError: false,
				errorMessage: ''
			}
		case CONSTANTS.AFTER_PAYMENT_FAILURE:
			return {
				...state,
				loader: false,
				errorMessage: 'There is some Error'
			}

		case CONSTANTS.PASS_PAYMENT_INITIAL_REQUEST:
			return {
				...state,
				loader: true,
				errorMessage: ''
			}
		case CONSTANTS.PASS_PAYMENT_INITIAL_SUCCESS:
			return {
				...state,
				loader: false,
				paymentSession: action.data,
				hasError: false,
				errorMessage: ''
			}
		case CONSTANTS.PASS_PAYMENT_INITIAL_FAILURE:
			return {
				...state,
				loader: false,
				errorMessage: 'There is some Error'
			}



		case CONSTANTS.QRCODE_REQUEST:
			return {
				...state,
				loader: true,
				errorMessage: ''
			}
		case CONSTANTS.QRCODE_REQUEST_SUCCESS:
			return {
				...state,
				loader: false,
				data: action.data,
				hasError: false,
				errorMessage: ''
			}
		case CONSTANTS.QRCODE_REQUEST_FAILURE:
			return {
				...state,
				loader: false,
				errorMessage: 'There is some Error'
			}
		case CONSTANTS.BEFORE_PAYMENT_INITIAL_REQUEST:
			return {
				...state,
				loader: true,
				errorMessage: ''
			}
		case CONSTANTS.BEFORE_PAYMENT_INITIAL_SUCCESS:
			return {
				...state,
				loader: false,
				beforePaymentData: action.data,
				hasError: false,
				errorMessage: ''
			}
		case CONSTANTS.BEFORE_PAYMENT_INITIAL_FAILURE:
			return {
				...state,
				loader: false,
				errorMessage: action.errorMessage
			}
		// Pro Rate List Constants
		case CONSTANTS.GET_PRO_RATE_LIST_REQUEST:
			return {
				...state,
				proRateLoader: true,
				errorMessage: ''
			}
		case CONSTANTS.GET_PRO_RATE_LIST_SUCCESS:
			return {
				...state,
				proRateLoader: false,
				proRateList: action.data,
				hasError: false,
				errorMessage: ''
			}
		case CONSTANTS.GET_PRO_RATE_LIST_FAILURE:
			return {
				...state,
				proRateLoader: false,
				errorMessage: 'There is some Error'
			}
		// Pro Rate List Constants
		case CONSTANTS.BOOKING_REQUEST:
			return {
				...state,
				loader: true,
				errorMessage: ''
			}
		case CONSTANTS.BOOKING_SUCCESS:
			return {
				...state,
				loader: false,
				data: action.data,
				hasError: false,
				errorMessage: ''
			}
		case CONSTANTS.BOOKING_FAILURE:
			return {
				...state,
				loader: false,
				errorMessage: 'There is some Error'
			}

		// Pro Rate List Constants
		case CONSTANTS.PASS_BOOKING_REQUEST:
			return {
				...state,
				loader: true,
				errorMessage: ''
			}
		case CONSTANTS.PASS_BOOKING_SUCCESS:
			return {
				...state,
				loader: false,
				// passData: action.data,
				data: action.data,
				hasError: false,
				errorMessage: ''
			}
		case CONSTANTS.PASS_BOOKING_FAILURE:
			return {
				...state,
				loader: false,
				errorMessage: 'There is some Error'
			}

		case CONSTANTS.GET_REQUEST_PERMIT_REQUEST:
			return {
				...state,
				loader: true,
				errorMessage: ''
			}
		case CONSTANTS.GET_REQUEST_PERMIT_SUCCESS:
			return {
				...state,
				loader: false,
				data: action.data,
				hasError: false,
				errorMessage: ''
			}
		case CONSTANTS.GET_REQUEST_PERMIT_FAILURE:
			return {
				...state,
				loader: false,
				errorMessage: 'There is some Error'
			}
		default:
			return state
	}
}

export default paymentReducer
