import CONSTANTS from '../constants'
import {getRequest , downloadFile } from '../../services'
import { APICONFIG } from '../config/apiConfig'
import moment from 'moment'


export const getInvoicePermitList = () => (dispatch) => {
     dispatch({ type: CONSTANTS.INVOICE_PERMIT_LIST_REQUEST })
     let endpoint = `${APICONFIG.getinvoicePermitList}`
    return getRequest(endpoint).then((res) => {
      dispatch({ type: CONSTANTS.INVOICE_PERMIT_LIST_SUCCESS, data: res?.data })
      return res?.data
    }).catch((err) => {
      dispatch({ type: CONSTANTS.INVOICE_PERMIT_LIST_FAILURE })
      return err
    })
  }



  export const downloadInvoice = (partner_id='', permit_no='' , date ) => (dispatch) => {
    dispatch({ type: CONSTANTS.DOWNLOAD_INVOICE_REQUEST })
    let url = (partner_id && partner_id !== '') ? `${APICONFIG.downloadInvoiceDoc}?partner_id=${partner_id}&` : `${APICONFIG.downloadInvoiceDoc}?`
    let endpoint = `${url}`
    if (permit_no != '') {
        endpoint = `${endpoint}permit_number=${permit_no}&`
    }

    if(date != ''){
        endpoint = `${endpoint}from_date=${`${moment(date?.from_date).year()}-${moment(date?.from_date).format('MM')}`}&to_date=${moment(date?.to_date).year()}-${moment(date?.to_date).format('MM')}`
    }

    // return downloadFile(`${APICONFIG.getTicketDownloadUrl}/${payload}/${UserPartner_id}`, payload, `${payload ? payload : 'ticket'}.pdf`).then((res) => {
     return downloadFile(endpoint , '' ,`Permit Invoice - ${permit_no}_${moment(date?.from_date).format('MM')}-${moment(date?.from_date).year()}.pdf`).then((res) => {
    dispatch({ type: CONSTANTS.DOWNLOAD_INVOICE_SUCCESS, data: res })
    return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.DOWNLOAD_INVOICE_FAILURE })
        return err
    })
}