
import { getConfigDetails } from '../../services/config'
// import { loggedInUserData } from '../../../../utils/helper'
import { loggedInUserData } from '../../utils/helper'
import { APICONFIG } from '../../redux/config/apiConfig'
import CONSTANTS from '../../redux/constants'
import { postRequest } from '../../services'


export const addEventLog = (data, title) => (dispatch) => {
    const payload = {
        'error_message': `${JSON.stringify(data)} ${loggedInUserData()?.user ? `userDetails:${JSON.stringify(loggedInUserData()?.user)}` : 'Guest User'}`,
        'facility_id': getConfigDetails().CLIENT_SECRET,
        'event_name': title,
        'user_id': loggedInUserData()?.user?.id,
        'user_ip': '',
        'device_type': 'web',
        'access_token': JSON.stringify(loggedInUserData()?.user),
    }
    dispatch({ type: CONSTANTS.ADD_EVENT_LOG_REQUEST })
    return postRequest(APICONFIG.storeEventLog, payload).then((res) => {
        dispatch({ type: CONSTANTS.ADD_EVENT_LOG_SUCCESS, data: res })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.ADD_EVENT_LOG_FAILURE })
        return err
    })
}