export const getSessionAttribute = (key) => {
    const sessionDetails = localStorage.getItem('paymentSession') ? JSON.parse(localStorage.getItem('paymentSession')) : {}
    return Object.values(sessionDetails).length > 0 ? sessionDetails?.[key] : ''
}

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export const restrictInputValues = (event) => {
    if (!`${event.target.value}${event.key}`.match(/^[0-9]{0,6}$/)) {
      // block the input if result does not match
      event.preventDefault()
      event.stopPropagation()
      return false
  }
} 