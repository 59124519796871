import React from 'react'
import PropTypes from 'prop-types'
import { EMPTY_OBJECT } from '../../constants/globalConstants'

function Steps(props) {

    const{page, selectedFaclity} = props
  return (
    <div className="row stpes-Design">
        <div className={page == 'home' ? 'col active' : 'col'}>
            <div className="box">
                <span>1</span>
            </div>
        </div>
        <div className={page == 'signup' ? 'col active' : 'col'}>
            <div className="box"><span>2</span> </div>
        </div>
        {(selectedFaclity && selectedFaclity?.is_permit_purchase_enabled != '1') ? null : <div className={page == 'pass' ? 'col active' : 'col'}>
            <div className="box"><span>3</span> </div>
        </div>}
        <div className={page == 'thankyou' ? 'col active' : 'col'}>
            <div className="box"><span>{(selectedFaclity && selectedFaclity?.is_permit_purchase_enabled != '1') ? 3 : 4}</span> </div>
        </div>
    </div>

  )
}
Steps.propTypes = {
    page: PropTypes.string,
    paymentStatus: PropTypes.bool,
    selectedFaclity: PropTypes.object,
}

Steps.defaulProps = {
	page:undefined,
  paymentStatus: false,
  selectedFaclity: EMPTY_OBJECT
}
export default Steps
