import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
// import moment from 'moment'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
import { permitRequestMessage, phoneMasker } from '../../utils/helper'
// import queryString from 'query-string'
// import { saveBookingAfterPayment } from '../../redux/actions/paymentActions'
import { useNavigate } from 'react-router-dom'
import URL from '../../constants/urls'
import { Circles } from 'react-loader-spinner'
import { systemError } from '../../Global/Constant'


function ThankYou(props) {
	const { response } = props
	const [isLoading, setIsLoading] = useState(true)
	// const [paymentStatus, setPaymentStatus] = useState(false)
	// const [paymentResponse, setPaymentResponse] = useState(EMPTY_OBJECT)
	const [bookingType, setBookingType] = useState('')
	// const [paymentSession, setPaymentSession] = useState(EMPTY_OBJECT)
	// const [queries, setQueries] = useState(EMPTY_OBJECT)
	const [vehicle, setVehicle] = useState([])
	const [displayVehicle, setDisplayVehicle] = useState(false)
	const [selectedFaclity, setSelectedFacilty] = useState(EMPTY_OBJECT)
	const navigate = useNavigate()
	// const partner = window.location.toString().split('/')[3]


	useEffect(() => {
		
		//setPage('thankyou')
		window.scrollTo(0, 0)
		// let queries = queryString.parse(location.search)
		const bookTypeData = localStorage.getItem('bookingType')
		const facility = JSON.parse(localStorage.getItem('facility') ? localStorage.getItem('facility') : '{}')
		setSelectedFacilty(facility)
		setBookingType(bookTypeData)
		// setQueries(queries)

		// if (queries?.AuthorisationCode && queries?.AuthorisationCode !== null && queries?.AuthorisationCode !== '') {
		//setPaymentStatus(true)
		// if(facility?.is_permit_purchase_enabled == '1'){
		// 	const beforePaymentPayload = JSON.parse(localStorage.getItem('beforePaymentPayloadValue'))
		// 	const paymentSession = JSON.parse(localStorage.getItem('paymentSession'))
		// 	setPaymentSession(paymentSession)
		// 	const payloadafterPayment = {
		// 		'facility_id': beforePaymentPayload?.facility_id,
		// 		'license_number': beforePaymentPayload?.license_number,
		// 		'first_name': beforePaymentPayload?.firstname,
		// 		'last_name': beforePaymentPayload?.lastname,
		// 		'password': beforePaymentPayload?.password ? beforePaymentPayload?.password : '',
		// 		'confirm_password': beforePaymentPayload?.cpassword ? beforePaymentPayload?.cpassword : '',
		// 		'email': beforePaymentPayload?.email,
		// 		'phone': beforePaymentPayload?.phone,
		// 		'city': beforePaymentPayload?.city,
		// 		'state': beforePaymentPayload?.state,
		// 		'zipcode': beforePaymentPayload?.zipcode,
		// 		'desired_start_date': moment().format('YYYY/MM/DD'),
		// 		'vehicleList': beforePaymentPayload?.vehicleList,
		// 		'is_admin': '0',
		// 		'user_id': beforePaymentPayload?.user_id ? beforePaymentPayload?.user_id : '',
		// 		'address1': beforePaymentPayload?.address1,
		// 		'address2': beforePaymentPayload?.address2 ? beforePaymentPayload?.address2 : '',
		// 		'permit_rate': beforePaymentPayload?.permit_rate,
		// 		'permit_rate_id': beforePaymentPayload?.permit_rate_id,
		// 		'payment_status': 'success',
		// 		'MerchantRef': queries?.MerchantRef ? queries?.MerchantRef : paymentSession?.reference ? paymentSession?.reference : '',
		// 		'TxID' : queries?.TxID ? queries?.TxID : '',
		// 		'CardType': queries?.CardType ? queries?.CardType : '',
		// 		'total' : paymentSession?.amount ? paymentSession?.amount : '',
		// 		'TokenNo':queries?.TokenNo ? queries?.TokenNo : '',
		// 		'AuthorisationCode': queries?.AuthorisationCode ? queries?.AuthorisationCode : '',

		// 	}
		// 	dispatch(saveBookingAfterPayment(payloadafterPayment)).then((res) => {
		// 		if (res?.data && res?.data?.message && res?.data?.message == 'Booking already done by the user.') {
		// 			navigate(URL?.HOME)
		// 		}
		// 		setPaymentResponse(res)
		// 		setIsLoading(false)
		// 		setPaymentStatus(true)
		// 		if (res?.data && res?.data?.ticketech_code != '') {
		// 			setPaymentStatus(true)
		// 		} else {
		// 			setPaymentStatus(false)
		// 			setIsLoading(false)
		// 		}
		// 		//localStorage.clear()
		// 	}).catch((err) => {
		// 		if (err.ticketech_code != '') {
		// 			//localStorage.clear()
		// 		} else {
		// 			setPaymentStatus(false)
		// 			setIsLoading(false)
		// 		}
		// 	})
		// }else{
			if(response?.vehicleList){
				const vehicles = JSON.parse(JSON.stringify(response?.vehicleList))
				setVehicle(vehicles)
			}
			setIsLoading(false)
		// }
		
		// }
		// else {
		// 	setPaymentStatus(false)
		// 	setIsLoading(false)
		// }
	}, EMPTY_ARRAY)



	const handleReBooking = () => {
		// (!paymentStatus) ? window.location.reload(true) : '';
		(bookingType == 'Pass') ? navigate(URL?.PASSCREATE) : window.location.reload(true)
	}

	const handleDisplayVehicleGrid = () => {
		setDisplayVehicle(!displayVehicle)
	}

	// const openDetails = (url) => {
	// 	window.open(url,'winname','directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=400,height=350')
	// }

	return (
		isLoading ? <span className='ms-2 d-flex justify-content-center'><Circles
			height="500"
			width="50"
			color="var(--bs-primary)"
			ariaLabel="circles-loading"
			visible={true}
		/></span> :
			// selectedFaclity?.is_permit_purchase_enabled == '1' ? 
			// <div>
			// 	{paymentStatus && <div className="bg-white p-4 mb-3"></div>}
			// 	<div className="flex justify-content-center">
			// 		<div className="col-sm-10">
			// 			{paymentStatus ? <div className="card my-card text-center">
			// 				<h1 className="text-center text-success fw-bold p-3">{paymentStatus ? 'Thank You!' : 'Error!'}</h1>
			// 				{!paymentStatus && <h4 className="text-center text-success fw-bold p-3">Booking Failed.</h4>}
			// 				<h5 className="fw-normal p-3">{paymentResponse?.data ? 'Your Parking ' + bookingType + ' #' + paymentResponse?.data?.account_number + ' has been created and issued for the period' : paymentResponse?.errors?.message ? paymentResponse?.errors?.message : systemError}
			// 				</h5>
			// 				<div className="text-success bg-opacity-10 p-3"><h3 >{paymentResponse?.data ? `${moment(paymentResponse?.data?.desired_start_date).format('MM/DD/YYYY')} - ${moment(paymentResponse?.data?.desired_end_date).format('MM/DD/YYYY')}` : ''}</h3></div>

			// 				{paymentSession ? <div className="text-center">
			// 					<p><b>Please note the following for your reference:</b></p>
			// 					<p><b>Amount:</b> ${paymentSession?.amount}</p>
			// 					{paymentResponse?.data ? <p><b>Permit ID:</b> {paymentResponse?.data?.account_number}</p>
			// 						:
			// 						<span><p><b>Reference ID:</b> {paymentSession?.reference}</p>
			// 							<p><b>Transaction ID:</b> {queries?.TxID}</p></span>
			// 					}
			// 				</div> : null}

			// 				<div className="text-success bg-opacity-25 p-3">
			// 					<p>
			// 						{paymentResponse?.data ? `Your e-${bookingType} details have been sent to your registered phone number ${phoneMasker(paymentResponse?.data?.user?.phone_number)} & email address ${paymentResponse?.data?.email}.` : paymentResponse?.errors?.message ? paymentResponse?.errors?.message : systemError}
			// 					</p>
			// 					<button type="button" className="btn btn-primary " onClick={(e) => handleReBooking(e)}>Book Another {bookingType}</button>
			// 				</div>
			// 			</div> :
			// 				<div className="card text-center mb-4 ">
			// 					<h1 className="text-center text-danger fw-bold p-3">Booking Failed!</h1>
			// 					<h5 className="fw-normal p-3 text-danger">{response ? response : paymentResponse?.errors?.message ? paymentResponse?.errors?.message : systemError}</h5>
			// 					{paymentSession ? <div className="text-center">
			// 						<p><b>Please note the following for your reference:</b></p>
			// 						<p><b>Amount:</b> ${paymentSession?.amount}</p>
			// 						<p><b>Reference ID:</b> {paymentSession?.reference}</p>
			// 						<p><b>Transaction ID:</b> {queries?.TxID}</p>
			// 					</div> : null}
			// 					<div className="text-success bg-opacity-25 p-3">
			// 						<button type="button" className="btn btn-primary " onClick={(e) => handleReBooking(e)}>Book Another {bookingType}</button>
			// 					</div>
			// 				</div>}
			// 		</div>
			// 	</div>
			// </div> : 
			<div className="flex justify-content-center">
				<div className="card text-center mb-2 w-100">
					<h1 className="text-center fw-bold p-3">{response?.name ? <span className='text-success'>Thank You</span> : <span className='text-danger'>Sorry</span>}</h1>
					<h4 className="fw-normal p-3">{response?.name ? permitRequestMessage() : response ? response : systemError}</h4>
					{response?.name && <div className='text-start ms-4 mb-3'>
						<h4>Request Details:</h4>
						<div className='row'>
							<div className='col-sm-6 row'>
								<div className='col-4'>
									<span className='fontsize85'>
										<b>Name:</b>
									</span>
								</div>
								<div className='col-8'>
									<span className='fontsize85 ms-0'>
										{response?.name}
									</span>
								</div>
							</div>
							<div className='col-sm-6 row'>
								<div className='col-4'>
									<span className='fontsize85'>
										<b>Email:</b>
									</span>
								</div>
								<div className='col-8'>
									<span className='fontsize85 ms-0'>
										{response?.email}
									</span>
								</div>
							</div>

						</div>
						<div className='row'>
							<div className='col-sm-6 row'>
								<div className='col-4'>
									<span className='fontsize85'>
										<b>Phone:</b>
									</span>
								</div>
								<div className='col-8'>
									<span className='fontsize85 ms-0'>
										{phoneMasker(response?.phone)}
									</span>
								</div>
							</div>
							<div className='col-sm-6 row'>
								<div className='col-4 me-0 pe-0 '>
									<span className='fontsize85'>
										<b>Reference ID:</b>
									</span>
								</div>
								<div className='col-8'>
									<span className='fontsize85 ms-0'>
										{response?.tracking_code}
									</span>
								</div>
							</div>

						</div>
						<div className='row'>
							<div className='col-sm-6 row'>
								<div className='col-4'>
									<span className='fontsize85'>
										<b>Facility:</b>
									</span>
								</div>
								<div className='col-8'>
									<span className='fontsize85 ms-0'>
										{selectedFaclity?.full_name}
									</span>
								</div>
							</div>
							<div className='col-sm-6 row'>
								<div className='col-4'>
									<span className='fontsize85'>
										<b>Permit Type:</b>
									</span>
								</div>
								<div className='col-8'>
									<span className='fontsize85 ms-0'>
										{response?.permit_type_name}
									</span>
								</div>
							</div>
						</div>
						<div className='row'>
						<div className='col-12 row'>
								<div className='col-12'>
									<span className='fontsize85'>
										<b>Vehicle:</b>
									</span>
								</div>
								<div className='col-12 table-responsive'>
									<span >
										{/* {`${vehicle[0]?.license_plate}/${vehicle[0]?.make}/${vehicle[0]?.model}`}  */}
										<table cellSpacing="0px" cellPadding="0" className='table'>
											<thead>
												<tr className='small'>
													<th className='text-muted small'>License Plate</th>
													<th className='text-muted small'>Color</th>
													<th className='text-muted small'>Make</th>
													<th className='text-muted small'>Model</th>
													<span className='text-decoration-underline fontsize85 text-primary cursor' onClick={() => handleDisplayVehicleGrid()}>{vehicle?.length > 1 && (displayVehicle ? 'Hide' : `+${vehicle?.length - 1} more`)}</span>

												</tr>
											</thead>
											<tbody>
											{displayVehicle ? 
										
											vehicle?.length > 0 && vehicle.map((vehicle, index) => {
												return <tr key={index} >
												
												<td>
													<p className="mb-0 ms-2 fontsize85">{vehicle?.license_plate_number ? vehicle?.license_plate_number : '-'}</p>
												</td>
												<td>
													<p className="mb-0 fontsize85">{vehicle?.color ? vehicle?.color : '-'}</p>
												</td>
												<td>
													<p className="mb-0 fontsize85">{vehicle?.make ? vehicle?.make : '-'}</p>
												</td>
												<td>
													<p className="mb-0 fontsize85">{vehicle?.model ? vehicle?.model : '-'}</p>
												</td>
												</tr>
											

											})
										
									: 
												vehicle?.length > 0 && <tr>
												
												<td>
													<p className="mb-0 ms-2 fontsize85">{vehicle[0]?.license_plate_number ? vehicle[0]?.license_plate_number : '-'}</p>
												</td>
												<td>
													<p className="mb-0 fontsize85">{vehicle[0]?.color ? vehicle[0]?.color : '-'}</p>
												</td>
												<td>
													<p className="mb-0 fontsize85">{vehicle[0]?.make ? vehicle[0]?.make : '-'}</p>
												</td>
												<td>
													<p className="mb-0 fontsize85">{vehicle[0]?.model ? vehicle[0]?.model : '-'}</p>
												</td>
												</tr>
											}
											</tbody>
									</table>
								</span>
								</div>
							</div>
						</div>

						<div className='row'>
						<div className='col-12 row'>
								<div className='col-12'>
									<span className='fontsize85'>
										<b>Uploaded Documents:</b>
									</span>
								</div>

								{response?.permit_doc?.length > 0 ? 
									response?.permit_doc?.map((item, index) => {
										return(
											<div className='col-6' key={index}>
												<span className='d-block'>{item?.doc_name}</span>
												<a href={`${item[item?.document_key]}`} target='_blank' rel="noreferrer" className='fw-bold'>View</a>
											</div>
										)
									})
								: <div className='col-12'>
									<span className='fontsize85'>
										N/A
									</span>
								</div>}
								
								
							</div>
						</div>
					</div>}
					{response?.name ? <div className="bg-success text-dark bg-opacity-25 p-3">
						<button type="button" className="btn btn-primary " onClick={(e) => handleReBooking(e)}>Go Back to Home</button>
					</div> : ''}
				</div>

			</div>
	)

}

ThankYou.propTypes = {
	dispatch: PropTypes.func,
	data: PropTypes.object,
	events: PropTypes.object,
	loader: PropTypes.bool,
	getCard: PropTypes.func,
	passData: EMPTY_ARRAY,
	setPage: PropTypes.func,
	response: PropTypes.object,
}

ThankYou.defaulProps = {
	dispatch: noop,
	data: EMPTY_OBJECT,
	events: EMPTY_OBJECT,
	loader: PropTypes.bool,
	getCard: PropTypes.func,
	passData: EMPTY_ARRAY,
	setPage: noop,
	response: EMPTY_OBJECT,
}

function mapStateToProps({ paymentReducer }) {
	return {
		data: paymentReducer,
	}
}
export default connect(mapStateToProps)(ThankYou)
