import CONSTANTS from '../constants'
import { getRequest } from '../../services'
import { APICONFIG } from '../config/apiConfig'
import { addEventLog } from './EventsLog'

// Calls the API to get Facility Initials
export const getFacilityList = () => (dispatch) => {
	dispatch({ type: CONSTANTS.GET_FACILITY_REQUEST })
	return getRequest(APICONFIG.getFacilityList).then((res) => {
		dispatch({ type: CONSTANTS.GET_FACILITY_SUCCESS, data: res.data })
		return res.data
	}).catch(() => {
		dispatch({ type: CONSTANTS.GET_FACILITY_FAILURE })
	})
}

//Permit rate list
// export const permitRateList = (data) => (dispatch) => {
// 	dispatch({ type: CONSTANTS.GET_PERMIT_RATE_LIST_REQUEST })
// 	return getRequest(`${APICONFIG.getPermitRateListUrl}?facility_id=${data?.facility_id}&&driving_license=${data?.driving_license}`).then((res) => {
// 		dispatch({ type: CONSTANTS.GET_PERMIT_RATE_LIST_SUCCESS, data: res })
// 		return res
// 	}).catch(() => {
// 		dispatch({ type: CONSTANTS.GET_PERMIT_RATE_LIST_FAILURE })
// 	})
// }

//Permit rate list
export const permitRateList = (data) => (dispatch) => {
	dispatch(addEventLog(data, CONSTANTS.GET_PERMIT_RATE_LIST_REQUEST))
	dispatch({ type: CONSTANTS.GET_PERMIT_RATE_LIST_REQUEST })
	return getRequest(`${APICONFIG.getArePermitRateListUrl}?facility_id=${data?.facility_id}&driving_license=${data?.driving_license}&is_permit_type=${data?.is_permit_type}${data?.business_id ? `&business_id=${data?.business_id}`: ''}`).then((res) => {
		let resObject = {...data, ...res} 
        dispatch(addEventLog(resObject, CONSTANTS.GET_PERMIT_RATE_LIST_SUCCESS))
		dispatch({ type: CONSTANTS.GET_PERMIT_RATE_LIST_SUCCESS, data: res })
		return res
	}).catch((err) => {
		let resObject = {...data, ...err} 
        dispatch(addEventLog(resObject, CONSTANTS.GET_PERMIT_RATE_LIST_FAILURE))
		dispatch({ type: CONSTANTS.GET_PERMIT_RATE_LIST_FAILURE })
	})
}

// OPEN MODAL ACTION
export const modalAction = (data) => (dispatch) => {
	dispatch({ type: CONSTANTS.OPEN_MODAL, isModalOpen: data })
	// return data
}

// Calls the API to get Document Types
export const getDocumentTypes = (facility_id = '') => (dispatch) => {
	const payload = {
		permit_id : facility_id
	}
	dispatch(addEventLog(payload, CONSTANTS.GET_DOCUMENT_TYPES_REQUEST))
	dispatch({ type: CONSTANTS.GET_DOCUMENT_TYPES_REQUEST })
	let api_url
	if(facility_id){
		api_url = `${APICONFIG.getDocumentTypeUrl}/${facility_id}`
	}else{
		api_url = `${APICONFIG.getDocumentTypeUrl}`
	}
	return getRequest(api_url).then((res) => {
		let resObject = {...payload, ...res} 
        dispatch(addEventLog(resObject, CONSTANTS.GET_DOCUMENT_TYPES_SUCCESS))
		dispatch({ type: CONSTANTS.GET_DOCUMENT_TYPES_SUCCESS, data: res?.data })
		return res?.data
	}).catch((err) => {
		let errObject = {...payload, ...err} 
        dispatch(addEventLog(errObject, CONSTANTS.GET_DOCUMENT_TYPES_FAILURE))
		dispatch({ type: CONSTANTS.GET_DOCUMENT_TYPES_FAILURE })
		return err
	})
}


//Permit Type List
export const getPermitTypeList = (data) => (dispatch) => {
	dispatch(addEventLog(data, CONSTANTS.GET_DOCUMENT_TYPES_REQUEST))
	dispatch({ type: CONSTANTS.GET_PERMIT_TYPE_LIST_REQUEST })
	return getRequest(`${APICONFIG.permitTypeList}/${data?.partner_id}/${data?.facility_id}`).then((res) => {
		let resObject = {...data, ...res} 
        dispatch(addEventLog(resObject, CONSTANTS.GET_DOCUMENT_TYPES_SUCCESS))
		dispatch({ type: CONSTANTS.GET_PERMIT_TYPE_LIST_SUCCESS, data: res })
		return res
	}).catch((err) => {
		let resObject = {...data, ...err} 
        dispatch(addEventLog(resObject, CONSTANTS.GET_DOCUMENT_TYPES_SUCCESS))
		dispatch({ type: CONSTANTS.GET_PERMIT_TYPE_LIST_FAILURE })
	})
}