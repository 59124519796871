import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { EMPTY_ARRAY, EMPTY_OBJECT, saveCard ,userProfilePayload } from '../../../constants/globalConstants'
import { getCardDataList, removeCardData, createCardData } from '../../../redux/actions/cardDetailsAction'
import { encryptionHandler } from '../../../utils/helper'
import { Circles } from 'react-loader-spinner'
import ConfermationModal from '../../../components/global/ConfermationModal'
import AddCardModal from '../../../components/global/addCardModal'
import AdminListTablePage from '../../../components/global/dataTable'
import {updateUserProfile,  getUserProfile } from '../../../redux/actions/login'
import debounce from 'lodash/debounce'

function PaymentSetting(props) {
    const { current_page, per_page, dispatch, total , partnerDetails , notify} = props
    const [cardList, setCardList] = useState(EMPTY_ARRAY)
    const [expiry, setExpiry] = useState()
    const [card, setCard] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [isEdit, setIsEdit] = useState(false)
    const [error, setError] = useState(false)
    const [payload, setPayload] = useState(saveCard)
    const [isModalOpen, setModal] = useState(false)
    const [confermationModal, setConfermationModal] = useState(false)
    const [deleteItem, setDeleteItem] = useState(EMPTY_ARRAY)
    const [isConsent, setIsConsent] = useState(true)
    const [userUpdatePayload, setUserUpdatePayload] = useState(userProfilePayload)
    const [userData, setUserData] = useState(EMPTY_OBJECT)
    const [images, setImages] = useState({})
    // const [search, setSearch] = useState('')

    const columns = [
        { header: 'Card Number', id: 'card_number', accessor: e => e?.card_last_four ? `XXXX XXXX XXXX ${e?.card_last_four}` : '-' },
        { header: 'Expiry Date', id: 'expiry_Date', accessor: e => (e?.expiry !== null && e?.expiry != '') ? `${e?.expiry?.slice(0, 2)}/${e?.expiry?.slice(-2)}` : '-' },
        { header: 'Actions', accessor: e => renderRowActions(e) }
    ]

    const renderRowActions = (data) => {
        return (
            <div className="d-flex action-btn">
                <a title="Delete" className="mt-0 ms-2" href="#" onClick={(event) => {
                    event.stopPropagation()
                    event.preventDefault()
                    handleRemoveCard(event, data?.id)
                }}>
                    <i className="fa  fa-trash"></i>
                </a>
            </div>
        )
    }


    useEffect(() => {
        paymentListData()
        dispatch(getUserProfile()).then((res) => {
            if (res) {
                const userProfileData = JSON.parse(localStorage.getItem('userData'))
                userProfileData.user = res
                setIsConsent(res?.user_consent == '1' ? true : false)
                if (userProfileData) {
                    localStorage.setItem('userData', JSON.stringify(userProfileData))
                } else {
                    localStorage.setItem('userData', '{}')
                }
                setUserData(res)
                images['front'] = res?.permit?.[0]?.idfront
                images['back'] = res?.permit?.[0]?.idback
                setImages({ ...images })
                if (res?.name !== '') {
                    const formdata = res
                    userUpdatePayload['name'] = formdata?.name ? formdata?.name?.trim() : ''
                    userUpdatePayload['license_number'] = formdata?.license_number ? formdata?.license_number?.trim() : ''
                    userUpdatePayload['email'] = formdata?.email ? formdata?.email?.trim() : ''
                    userUpdatePayload['phone'] = formdata?.phone_number ? formdata?.phone_number?.trim() : ''
                    userUpdatePayload['address'] = formdata?.address ? formdata?.address?.trim() : ''
                    userUpdatePayload['address2'] = formdata?.address2 ? formdata?.address2?.trim() : ''
                    userUpdatePayload['city'] = formdata?.city ? formdata?.city?.trim() : ''
                    userUpdatePayload['state'] = formdata?.state ? formdata?.state?.trim() : ''
                    userUpdatePayload['zipcode'] = formdata?.pincode ? formdata?.pincode?.trim() : ''
                    setUserUpdatePayload({ ...userUpdatePayload })
                }
            }
        })
    }, [EMPTY_ARRAY])

  

    const handleAutoPayChange = () => {

        setIsConsent(!isConsent)
        let formData = new FormData()     //formdata object
        formData.append('license_number', userUpdatePayload?.license_number ? userUpdatePayload?.license_number : '')
        formData.append('email', userUpdatePayload?.email ? userUpdatePayload?.email : '')
        formData.append('name', userUpdatePayload?.name ? userUpdatePayload?.name : '')
        formData.append('phone', userUpdatePayload?.phone ? userUpdatePayload?.phone : '')
        formData.append('address', userUpdatePayload?.address ? userUpdatePayload?.address : '')
        formData.append('address2', userUpdatePayload?.address2 ? userUpdatePayload?.address2 : '')
        formData.append('city', userUpdatePayload?.city ? userUpdatePayload?.city : '')
        formData.append('state', userUpdatePayload?.state ? userUpdatePayload?.state : '')
        formData.append('zipcode', userUpdatePayload?.zipcode ? userUpdatePayload?.zipcode : '')
        formData.append('image_front', images?.front ? images?.front : '')
        formData.append('image_back', images?.back ? images?.back : '')
        formData.append('permit_id', userData?.permit?.[0]?.id ? userData?.permit?.[0]?.id : '')
        formData.append('user_consent', !isConsent ? 1 : 0)

        deb(formData)


    }

    const deb = useCallback(
        debounce((payload) => {
            dispatch(updateUserProfile(payload)).then((res) => {
                notify('success', 'Autopay consent is updated successfully')
                // setIsUpdatingProfile(false)
                // setDisclaimerNote('')
                const userProfileData = JSON.parse(localStorage.getItem('userData'))
                userProfileData.user = res?.data?.user
                userProfileData.permit = res?.data?.permit
                if (userProfileData) {
                    if (res?.data?.permit?.idfront || res?.data?.permit?.idback) {
                        images['front'] = res?.data?.permit?.idfront ? res?.data?.permit?.idfront : ''
                        images['back'] = res?.data?.permit?.idback ? res?.data?.permit?.idback : ''
                        setImages({ ...images })
                    }
                    localStorage.setItem('userData', JSON.stringify(userProfileData))
                } else {
                    localStorage.setItem('userData', '{}')
                }
                // setEdit(false)
            }).catch(() => {
                notify('success', 'Something went wrong, Please try again!')
                // setDisclaimerNote('')
                // setIsUpdatingProfile(false)
                setErrorMessage({ message: 'Something went wrong, Please try again!' })
            })
        }, 300
        ), [])



    const paymentListData = () => {
        setIsLoading(true)
        const facility_id = partnerDetails?.facilities?.[0]?.id
        dispatch(getCardDataList(facility_id)).then((res) => {
            setCardList(res?.data?.payments)
            setIsLoading(false)
        }).catch(() => {
            setIsLoading(false)
        })
    }

    const handleInputFieldsData = (e) => {
        const { name, value } = e.target
        setPayload({ ...payload, [name]: value })
    }

    const handleCardChange = (value) => {
        setCard(value?.trim())
        payload['card_number'] = value?.trim()
        setPayload({ ...payload })
    }

    const handleExpiryChange = (value) => {
        setExpiry(value?.trim())
        payload['expiration_date'] = value?.trim()
        setPayload({ ...payload })
    }

    const handleRemoveCard = (e, item) => {
        e.preventDefault()
        setDeleteItem(item)
        setConfermationModal(true)
    }

    // Delete Vehicle item
     // Delete Vehicle item
     const handleDelete = () => {
        if (deleteItem) {
            setIsLoading(true)
            const deleteCardPayload = {
                facility_id: partnerDetails?.facilities?.[0]?.id,
                id: deleteItem
            }
            dispatch(removeCardData(deleteCardPayload)).then((res) => {
                setIsLoading(false)
                setConfermationModal(false)
                if (res?.status === 201 && res?.data !== null) {
                notify('success', 'Card Deleted')   
                paymentListData()
                }else {
                    notify('error', res?.errors?.message ? res?.errors?.message : 'Something went wrong, Please try again!')
                }
            }).catch((errors) => {
                setIsLoading(false)
                setConfermationModal(false)
                notify('error', errors?.message ? errors?.message : 'Something went wrong, Please try again!')
            })
        }
    }

    const handleSubmit = () => {
        setError(true)

        if(Object.values(payload)?.length > 0 && payload?.card_number && ((payload?.card_number?.length === 16 && payload?.security_code?.length != 3) || (payload?.card_number?.length === 15 && payload?.security_code?.length != 4))){
            setIsLoading(false)
            notify('error', 'Please Enter Valid Card Details')
            return 
        } 

        if (Object.values(payload)?.length > 0 && payload?.card_number && (payload?.card_number?.length === 16 || payload?.card_number?.length === 15) && payload?.expiration_date && payload?.security_code &&  payload?.cvvErrorMessage == '' &&  payload?.zip_code_on_card) {
            const nonce = encryptionHandler(payload)

            // let formData = new FormData()    //formdata object
            // formData.append('nonce', nonce)   //append the values with key, value pair
            // formData.append('payment_type', 'credit_card')   //append the values with key, value pair
            // formData.append('is_auto_deduction', 0)   //append the values with key, value pair

            setIsLoading(true)
            setErrorMessage('')

            const finalPayload = { 
                'nonce': nonce,
                 'payment_type': 'credit_card',
                  'savePaymentMethod': true,
                  'facility_id': partnerDetails?.facilities?.[0]?.id,
                 }
            dispatch(createCardData(finalPayload)).then((res) => {
                if (res?.data !== null) {
                    setPayload({ card_number: '', expiration_date: '' })
                    setCard('')
                    setExpiry('')
                    setIsEdit(false)
                    setModal(false)
                    setError(false)
                    paymentListData()
                } else if (res?.data === null && res?.errors?.message) {
                    setErrorMessage(res?.errors?.message)
                    setIsLoading(false)
                }
            })
        } else {
            setIsLoading(false)
            setErrorMessage('')
        }
    }

    const handleModalOpen = () => {
        setModal(true)
    }

    return (
        <div>
            <div className="d-flex mb-4">
                <h4 className="fw-bold">Payment Settings </h4>
                <button className="btn btn-primary ms-auto" onClick={handleModalOpen}><i className="fa fa-plus"></i> Add</button>
            </div>
            {isLoading ? <span className='ms-2 d-flex justify-content-center'><Circles
                height="50"
                width="50"
                color="var(--bs-primary)"
                ariaLabel="circles-loading"
                visible={true}
            /></span> :
                <div className="card mb-3">
                    <div className="card-header">  <b>Card List</b> </div>
                    <AdminListTablePage
                        data={cardList}
                        columns={columns}
                        getNewRecords={paymentListData}
                        curPage={current_page}
                        perPage={per_page}
                        total={total}
                        showSearch={false}
                    />
                     <div className="container p-0">
                        <div className="card">
                            <div className="card-header">
                                <div className="d-flex">
                                    <h5 className="mb-0">AutoPay Settings</h5>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row"></div>
                                <span className="switch-wrap">
                                    <label className="switch">
                                        <input type="checkbox" id='autopayconsent' name='autopayconsent' checked={isConsent} onChange={() => handleAutoPayChange()} />
                                        <span className="slider round"></span>
                                    </label>
                                    <label htmlFor="autopayconsent" className='ps-2' > AutoPay Consent </label>
                                    {/* <i className='ps-2 fa-sm fa fa-info-circle' onClick={() => setDisplayModal(true)}></i> */}
                                </span>
                                <i className='d-block small'>By clicking this button, you are providing your consent for auto renewal.</i>

                            </div>
                        </div>
                    </div>
                </div>}

            <AddCardModal isModalOpen={isModalOpen} setModal={setModal} setPayload={setPayload} payload={payload} modalName='Add New Card' isLoading={isLoading} handleCardChange={handleCardChange} handleExpiryChange={handleExpiryChange} handleSubmit={handleSubmit} expiry={expiry} card={card} errorMessage={errorMessage} isEdit={isEdit} error={error} setErrorMessage={setErrorMessage} setError={setError} setCard={setCard} setExpiry={setExpiry} handleInputFieldsData={handleInputFieldsData} />
            <ConfermationModal setConfermationModal={setConfermationModal} confermationModal={confermationModal} confermationHeading='Confirm Delete' confermationMessage='Are you sure you want to delete?' buttonLable='Delete' handleDelete={handleDelete} />
        </div>
    )
}

PaymentSetting.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    current_page: PropTypes.number,
    per_page: PropTypes.number,
    total: PropTypes.number,
    partnerDetails: PropTypes.object,
    notify: PropTypes.func,
}

PaymentSetting.defaulProps = {
    dispatch: noop,
    partnerDetails: {},
    loader: PropTypes.bool,
    notify: noop,
}
function mapStateToProps({ cardDetailsReducer , PartnerReducer}) {
    const { current_page, per_page, total, loader } = cardDetailsReducer
    return {
        loader,
        current_page,
        per_page,
        total,
        partnerDetails: PartnerReducer?.partnerDetails?.data,
    }
}
export default connect(mapStateToProps)(PaymentSetting)