import CONSTANTS from '../constants'
import { EMPTY_ARRAY } from '../../constants/globalConstants'

const INITIAL_STATE = {
    loader: false,
    hasError: false,
    permitRequests: EMPTY_ARRAY,
    errorMessage: '',
}

const permitRequestReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        //RESPONSE HANDLING
        case CONSTANTS.GET_PERMIT_REQUEST_LIST_REQUEST:
            return {
                ...state,
                loader: true,
                errorMessage: ''
            }
        case CONSTANTS.GET_PERMIT_REQUEST_LIST_SUCCESS:
            return {
                ...state,
                loader: false,
                permitRequests: action.data,
                hasError: false,
                errorMessage: ''
            }
        case CONSTANTS.GET_PERMIT_REQUEST_LIST_FAILURE:
            return {
                ...state,
                loader: false,
                errorMessage: 'There is some Error'
            }
        default:
            return state
    }
}

export default permitRequestReducer
