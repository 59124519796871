import CONSTANTS from '../constants'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'

const INITIAL_STATE = {
	loader: false,
	loaderSms: false,
	loaderDownload: false,
	hasError: false,
	data: EMPTY_ARRAY,
	faclityData: EMPTY_OBJECT,
	errorMessage: '',
	smsResponse: '',
	downloadResponse: '',
	ticketDetails: EMPTY_OBJECT,
	isFetching: false
}

const widgetReservationReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CONSTANTS.USER_DETAILS_REQUEST:
			return {
				...state,
				loader: true,
				errorMessage: ''
			}
		case CONSTANTS.USER_DETAILS_SUCCESS:
			return {
				...state,
				loader: false,
				data: action?.data?.data,
				hasError: false,
				errorMessage: ''
			}
		case CONSTANTS.USER_DETAILS_FAILURE:
			return {
				...state,
				loader: false,
				data: EMPTY_ARRAY,
				errorMessage: 'There is some Error'
			}

		// get facilty reducer 
		case CONSTANTS.GET_FACILTY_REQUEST:
			return {
				...state,
				errorMessage: ''
			}
		case CONSTANTS.GET_FACILTY_SUCCESS:
			return {
				...state,
				faclityData: action.data,
				hasError: false,
				errorMessage: ''
			}
		case CONSTANTS.GET_FACILTY_FAILURE:
			return {
				...state,
				errorMessage: 'There is some Error'
			}
		case CONSTANTS.SMS_EMAIL_REQUEST:
			return {
				...state,
				loaderSms: true,
				errorMessage: ''
			}

		case CONSTANTS.SMS_EMAIL_SUCCESS:
			return {
				...state,
				loaderSms: false,
				smsResponse: action?.data,
				hasError: false,
				errorMessage: ''
			}
		case CONSTANTS.SMS_EMAIL_FAILURE:
			return {
				...state,
				loaderSms: false,
				smsResponse: '',
				errorMessage: 'There is some Error'
			}
		case CONSTANTS.DOWNLOAD_TICKET_REQUEST:
			return {
				...state,
				loaderDownload: true,
				errorMessage: ''
			}

		case CONSTANTS.DOWNLOAD_TICKET_SUCCESS:
			return {
				...state,
				loaderDownload: false,
				downloadResponse: action?.data,
				hasError: false,
				errorMessage: ''
			}
		case CONSTANTS.DOWNLOAD_TICKET_FAILURE:
			return {
				...state,
				loaderDownload: false,
				downloadResponse: '',
				errorMessage: 'There is some Error'
			}
		case CONSTANTS.GET_TICKET_DETAILS_REQUEST:
			return {
				...state,
				loader: true,
				errorMessage: null
			}

		case CONSTANTS.GET_TICKET_DETAILS_SUCCESS:
			return {
				...state,
				loader: false,
				ticketDetails: action?.data,
				hasError: false,
				errorMessage: null
			}
		case CONSTANTS.GET_TICKET_DETAILS_FAILURE:
			return {
				...state,
				loader: false,
				ticketDetails: null,
				hasError: true,
				errorMessage: action?.errorMessage
			}	
		case CONSTANTS.GET_TICKET_LIST_REQUEST:
			return {
				...state,
				loader: true,
				errorMessage: null
			}

		case CONSTANTS.GET_TICKET_LIST_SUCCESS:
			return {
				...state,
				loader: false,
				ticketDetails: action?.data,
				hasError: false,
				errorMessage: null
			}
		case CONSTANTS.GET_TICKET_LIST_FAILURE:
			return {
				...state,
				loader: false,
				ticketDetails: null,
				hasError: true,
				errorMessage: action?.errorMessage
			}	
		case CONSTANTS.GET_PARTNER_DETAILS_BY_SLUG_REQUEST:
			return {
				...state,
				isFetching: true,
				errorMessage: null
			}

		case CONSTANTS.GET_PARTNER_DETAILS_BY_SLUG_SUCCESS:
			return {
				...state,
				isFetching: false,
				partnerDetails: action?.data,
				hasError: false,
				errorMessage: null
			}
		case CONSTANTS.GET_PARTNER_DETAILS_BY_SLUG_FAILURE:
			return {
				...state,
				isFetching: false,
				partnerDetails: null,
				hasError: true,
				errorMessage: action?.errorMessage
			}				
		default:
			return state
	}
}

export default widgetReservationReducer
