import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask'
import { connect } from 'react-redux'
import { Circles } from 'react-loader-spinner'

import { useEffect } from 'react'
import { EMPTY_ARRAY } from '../../constants/globalConstants'
const cardnumbermask = createDefaultMaskGenerator('9999 9999 9999 9999')
const expirymask = createDefaultMaskGenerator('99/99')

function AddCardPaymentModal(props) {
     const { isModalOpen, hanndleCancel, modalName, isLoading, error, handleCardChange, handleExpiryChange, handleSubmit, expiry,cardExpiryMessage, card, payload, errorMessage, setErrorMessage, setError, setCard, setExpiry, handleInputFieldsData  ,isCardFormVisible,cardList, setCardFormVisible , handleCardSelectChange , setPayload } = props

    useEffect(() => {
        setErrorMessage('')
        setError(false)
        setCard('')
        setExpiry('')
    }, [isModalOpen])

 

    const showCardForm = () => {
        setCardFormVisible(true)
        setPayload({ ...payload, ['payment_profile_id']: '' })
    }

    const hideCardForm = () => {
        setCardFormVisible(false)
        setPayload({ ...payload, ['payment_profile_id']: '' })
    }
    return (
        <>
            <Modal size="lg" show={isModalOpen} onHide={() => hanndleCancel()} aria-labelledby="example-modal-sizes-title-sm" >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm"> {modalName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                    <div className='row '>
                                {cardList?.length > 0 && <div className="col-sm-6">
                                    <div className="mb-3">
                                        <select name="vehicle" id="vehicle" disabled={isCardFormVisible} className="form-select" onChange={handleCardSelectChange} >
                                            <option>Select Card</option>
                                            {cardList?.map((card, index) => {
                                                return (
                                                    <option value={card?.payment_profile_id} selected={card?.payment_profile_id == payload?.payment_profile_id} key={index}>{`XXXX XXXX XXXX ${card?.card_last_four?.slice(0, 4) ? card?.card_last_four?.slice(0, 4) : 'XXXX'} (${card?.expiry?.slice(0, 2) ? card?.expiry?.slice(0, 2) : '-'}/${card?.expiry?.slice(2, 4) ? card?.expiry?.slice(2, 4) : '-'})`}</option>
                                                )
                                            })}
                                        </select>
                                        <span className="form-text text-danger h-40">{error && (!payload?.payment_profile_id && !payload?.card_number) ? 'Please select or add new card' : ''}</span>
                                    </div>
                                </div>}

                                {cardList?.length > 0 && !isCardFormVisible ? <div className='col-sm-6 mb-3'>{cardList?.length > 0 && <span className='badge badge-primary text-dark fw-bold me-2'>OR</span>}<button className='btn btn-primary' type="button" onClick={showCardForm}><i className='fa fa-plus'></i> Add New Card</button></div> : cardList?.length > 0 && <div className='col-sm-6 mb-3'><span className='badge badge-primary text-dark fw-bold me-2'>OR</span>  <button className='btn btn-danger' type="button" onClick={hideCardForm}>Select Existing Card</button></div>}
                            </div>
                       {(cardList?.length < 1 || isCardFormVisible) && <div className="row">
                            <div className="col-sm-6  mb-3">
                                <label className="form-label">Name on Card</label>
                                <input
                                    type="text"
                                    autoComplete="off"
                                    maxLength={20}
                                    minLength={1}
                                    className="form-control"
                                    name="name_on_card"
                                    value={payload.name_on_card}
                                    placeholder="Name on Card"
                                    onChange={handleInputFieldsData}
                                />
                                {/* <span className="form-text text-danger h-40">{error && !payload?.name ? 'This field is required' : ''}</span> */}
                            </div>
                            <div className="col-sm-6  mb-3">
                                <label className="form-label">Card Number</label>
                                <MaskedInput
                                    maskGenerator={cardnumbermask}
                                    value={card}
                                    type="text"
                                    autoComplete="off"
                                    name="card_number"
                                    id="card_number"
                                    required={true}
                                    pattern="[0-9 ]{18,19}"
                                    maxLength='19'
                                    minLength='18'
                                    title="Card no. should be 15-16 digit numberic"
                                    className="form-control"
                                    onChange={handleCardChange}
                                    onInvalid={e => e.target.setCustomValidity('Please enter a valid card number')}
                                    onInput={e => e.target.setCustomValidity('')}
                                    placeholder="Card Number"
                                />
                                <span className="form-text text-danger h-40">{error && !payload?.card_number ? 'This field is required' : ''}</span>
                            </div>
                            <div className="col-sm-4  mb-3">
                                <label className="form-label">Expiration Date</label>
                                <MaskedInput
                                    maskGenerator={expirymask}
                                    value={expiry}
                                    type="text"
                                    autoComplete="off"
                                    name="expiration_date"
                                    id="expiration_date"
                                    required={true}
                                    pattern="[0-9 ]{4}"
                                    maxLength='5'
                                    minLength='4'
                                    title="Enter a valid expiry date"
                                    className="form-control"
                                    onChange={handleExpiryChange}
                                    onInvalid={e => e.target.setCustomValidity('Please enter a valid expiry date')}
                                    onInput={e => e.target.setCustomValidity('')}
                                    placeholder="mm/yy"
                                />
                                <span className="form-text text-danger h-40">{error && !payload?.expiration_date ? 'This field is required' : ''}</span>
                                <span className='form-text text-danger h-40'>{cardExpiryMessage}</span>
                            </div>
                            <div className="col-sm-4  mb-3">
                                <label className="form-label">CVV</label>
                                <input
                                    onChange={handleInputFieldsData}
                                    autoComplete="off"
                                    className="form-control"
                                    id="security_code"
                                    maxLength="4"
                                    name="security_code"
                                    value={payload.security_code}
                                    placeholder="CVV / Security Code"
                                    type="password"
                                    required=""
                                />
                                <span className="form-text text-danger h-40">{error && !payload?.security_code ? 'This field is required' : ''}</span>
                            </div>
                            <div className="col-sm-4 ">
                                <div className="mb-3">
                                    <label htmlFor="" className="form-label">Zip Code</label>
                                    <input
                                        onChange={handleInputFieldsData}
                                        className="form-control "
                                        maxLength="6"
                                        autoComplete="new password"
                                        minLength="5"
                                        name="zip_code_on_card"
                                        value={payload?.zip_code_on_card}
                                        pattern="[0-9]{5,6}"
                                        placeholder="Billing Address Zip Code"
                                        type="text"
                                        required=""
                                    />
                                    <span className="form-text text-danger h-40">{error && !payload?.zip_code_on_card ? 'This field is required' : ''}</span>
                                </div>
                            </div>
                            <span className="form-text text-danger h-40">{(errorMessage) ? errorMessage : ''}</span>

                            {/* <div className="col-sm-4 ">
                                <div className="mb-3">
                                    <div className='switch-wrap'>
                                        <input type={'checkbox'} id='defaultbtn' name='defaultbtn'/>
                                        <label htmlFor="defaultbtn" className='fw-normal ps-2' > Mark as default</label>
                                    </div>
                                </div>
                            </div> */}
                        </div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-primary d-flex" type="submit" data-bs-dismiss="modal" onClick={handleSubmit}>{'Pay'}{isLoading && error ? <span className=' ms-2'><Circles
                        height="20"
                        width="20"
                        color="#ffffff"
                        ariaLabel="circles-loading"
                        visible={true}
                    /></span> : null}</Button>
                    {/* <Button  className="btn btn-primary" onClick={handleSubmit} style={{ float: 'right' }}>Submit</Button> */}
                </Modal.Footer>
            </Modal>
        </>
    )
}

AddCardPaymentModal.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    modalName: PropTypes.string,
    isModalOpen: PropTypes.bool,
    setModal: PropTypes.func,
    isLoading: PropTypes.bool,
    isEdit: PropTypes.bool,
    error: PropTypes.bool,
    handleCardChange: PropTypes.func,
    handleExpiryChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    expiry: PropTypes.string,
    card: PropTypes.string,
    payload: PropTypes.object,
    errorMessage: PropTypes.string,
    setErrorMessage: PropTypes.func,
    setError: PropTypes.func,
    setCard: PropTypes.func,
    setExpiry: PropTypes.func,
    handleInputFieldsData: PropTypes.func,
    setCardFormVisible:PropTypes.func,
    cardList: PropTypes.array,
    isCardFormVisible:PropTypes.bool,
    handleCardSelectChange : PropTypes.func,
    setPayload : PropTypes.func,
    hanndleCancel : PropTypes.func,
    cardExpiryMessage :  PropTypes.string,
}

AddCardPaymentModal.defaulProps = {
    dispatch: noop,
    loader: PropTypes.bool,
    modalName: PropTypes.string,
    isModalOpen: PropTypes.bool,
    setModal: PropTypes.func,
    isLoading: false,
    isEdit: false,
    error: false,
    handleCardChange: noop,
    handleExpiryChange: noop,
    handleSubmit: noop,
    expiry: '',
    card: '',
    payload: {},
    errorMessage: '',
    setErrorMessage: noop,
    setError: noop,
    setCard: noop,
    setExpiry: noop,
    handleInputFieldsData: noop,
    setCardFormVisible:noop,
    cardList: EMPTY_ARRAY,
    isCardFormVisible:PropTypes.bool,
    handleCardSelectChange : PropTypes.func,
    setPayload : noop,
    hanndleCancel : PropTypes.func,
    cardExpiryMessage :  PropTypes.string,

}

function mapStateToProps({ state }) {
    return { state }
}
export default connect(mapStateToProps)(AddCardPaymentModal)
