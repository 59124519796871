import CONSTANTS from '../constants'
import { EMPTY_ARRAY, EMPTY_OBJECT, } from '../../constants/globalConstants'

const INITIAL_STATE = {
    loader: false,
    hasError: false,
    facilty: EMPTY_ARRAY,
    rateList: EMPTY_OBJECT,
    proRateList: EMPTY_OBJECT,
    errorMessage: '',
    isModalOpen: false,
    documentList: EMPTY_ARRAY,
    permitTypeList: EMPTY_ARRAY,
}

const basicInfoReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        //RESPONSE HANDLING
        case CONSTANTS.GET_FACILITY_REQUEST:
            return {
                ...state,
                loader: true,
                errorMessage: ''
            }
        case CONSTANTS.GET_FACILITY_SUCCESS:
            return {
                ...state,
                loader: false,
                facilty: action.data,
                hasError: false,
                errorMessage: ''
            }
        case CONSTANTS.GET_FACILITY_FAILURE:
            return {
                ...state,
                loader: false,
                errorMessage: 'There is some Error'
            }

        // Rate List Constants
        case CONSTANTS.GET_PERMIT_RATE_LIST_REQUEST:
            return {
                ...state,
                loader: true,
                errorMessage: ''
            }
        case CONSTANTS.GET_PERMIT_RATE_LIST_SUCCESS:
            return {
                ...state,
                loader: false,
                rateList: action.data,
                hasError: false,
                errorMessage: ''
            }
        case CONSTANTS.GET_PERMIT_RATE_LIST_FAILURE:
            return {
                ...state,
                loader: false,
                errorMessage: 'There is some Error'
            }

        case CONSTANTS.OPEN_MODAL:
            return {
                ...state,
                isModalOpen: action.isModalOpen
            }
        // GET DOCUMENT TYPES
        case CONSTANTS.GET_DOCUMENT_TYPES_REQUEST:
            return {
                ...state,
                loader: true,
                errorMessage: ''
            }
        case CONSTANTS.GET_DOCUMENT_TYPES_SUCCESS:
            return {
                ...state,
                loader: false,
                documentList: action.data,
                hasError: false,
                errorMessage: ''
            }
        case CONSTANTS.GET_DOCUMENT_TYPES_FAILURE:
            return {
                ...state,
                loader: false,
                errorMessage: 'There is some Error'
            }

        // Rate List Constants
        case CONSTANTS.GET_PERMIT_TYPE_LIST_REQUEST:
            return {
                ...state,
                loader: true,
                errorMessage: ''
            }
        case CONSTANTS.GET_PERMIT_TYPE_LIST_SUCCESS:
            return {
                ...state,
                loader: false,
                permitTypeList: action.data,
                hasError: false,
                errorMessage: ''
            }
        case CONSTANTS.GET_PERMIT_TYPE_LIST_FAILURE:
            return {
                ...state,
                loader: false,
                errorMessage: 'There is some Error'
            }
        default:
            return state
    }
}

export default basicInfoReducer
