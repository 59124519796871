import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import noop from 'lodash/noop'
import './navigating.css'
import { getUserData, getUserProfile } from '../../../redux/actions/login'
import Loader from '../../../components/global/Loader'
import URL from '../../../constants/urls'
import { getPartnerDetails } from '../../../redux/actions'
import { useEffect } from 'react'

function Navigating(props) {
    const { acc, ref, isreceipt, secret, masquerade } = useParams()
    const { dispatch, isFetching } = props
    const userData = {}
    userData.access_token = acc
    userData.refresh_token = ref

    localStorage.setItem('userData', JSON.stringify(userData))

    const navigate = useNavigate()
    if (isreceipt && isreceipt != '' && isreceipt == '1') {
        localStorage.setItem('isreceipt', true)
    }
    if (masquerade && masquerade != '') {
        localStorage.setItem('masquerade', masquerade)
    }

    useEffect(() => {
        if (secret) {
            localStorage.setItem('partnerSecret', secret)
            dispatch(getPartnerDetails(secret)).then((res) => {
                localStorage.setItem('partnerData', JSON.stringify(res?.data))
                const partnerData = res?.data
                const color = partnerData?.brand_setting?.color
                if (partnerData?.brand_setting && partnerData?.brand_setting?.color) {
                    document.documentElement.style.setProperty('--bs-primary', color)
                    document.documentElement.style.setProperty('base-color', color)
                    document.documentElement.style.setProperty('primary', color)
                    document.documentElement.style.setProperty('textcolor', color)
                    document.documentElement.style.setProperty('--bs-btn-bg', color)
                    document.documentElement.style.setProperty('--bs-primary-rgb', '34, 32, 93')
                    document.documentElement.style.setProperty('--bs-list-group-active-border-color', color)
                    document.documentElement.style.setProperty('--bs-list-group-active-color', color)
                    document.documentElement.style.setProperty('--bs-list-group-active-bg', color)
                }

                dispatch(getUserData()).then((res) => {
                    if (res?.email) {
                        dispatch(getUserProfile()).then((res) => {
                            if (res) {
                                const userProfileData = JSON.parse(localStorage.getItem('userData'))
                                userProfileData.user = res
                                if (isreceipt && isreceipt != '' && isreceipt == '2') {
                                    localStorage.setItem('userData', JSON.stringify(userProfileData))
                                    navigate(`${URL?.USER_PROFILE}`)
                                } else if (userProfileData) {
                                    localStorage.setItem('userData', JSON.stringify(userProfileData))
                                    navigate(`${URL?.USER}`)
                                } else {
                                    localStorage.setItem('userData', '{}')
                                }
                            } else {
                                navigate(`${URL?.HOME}`)
                                localStorage.removeItem('userData')
                            }
                        }).catch(() => {
                            navigate(`${URL?.HOME}`)
                            localStorage.removeItem('userData')
                        })

                    } else {
                        navigate(`${URL?.HOME}`)
                        localStorage.removeItem('userData')
                    }
                }).catch(() => {
                    navigate(`${URL?.HOME}`)
                    localStorage.removeItem('userData')
                })
            }).catch((err) => {
                console.log(err)
            })
        } else {
            localStorage.removeItem('partnerSecret')
        }



    }, [])

    return (
        <div className="d-flex justify-content-center align-items-center loaderDivHeight">
            {isFetching && <Loader
                eventsLoader={false}
            />}
        </div>
    )
}

Navigating.propTypes = {
    dispatch: PropTypes.func,
    isFetching: PropTypes.bool,
}

Navigating.defaulProps = {
    dispatch: noop,
    isFetching: true,

}

function mapStateToProps({ PartnerReducer }) {
    return {
        isFetching: PartnerReducer?.isFetching
    }
}
export default connect(mapStateToProps)(Navigating)