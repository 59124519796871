import React, { useState , useEffect} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
// import { Table } from 'react-bootstrap'
import { restrictSpecialChar } from '../../utils/helper'
import { Col, Row } from 'react-bootstrap'
import { EMPTY_ARRAY } from '../../constants/globalConstants'
import { getVehicleModelList } from '../../redux/actions/VehicleListAction'

function VehicleModal(props) {
    const { isModalOpen, setModal, modalName, vehicleData, setVehicleData, handleSubmitVehicleData, submitButtonDisable, formError, setFormError, getVehicleList , dispatch } = props
    const [error, setError] = useState(false)
	const [vehicleMakeList, setVehicleMakeList] = useState(EMPTY_ARRAY)
	const [vehicleModelList, setvehicleModelList] = useState(EMPTY_ARRAY)
    const [vehicleInputDataLists , setVehicleInputDataLists] = useState(EMPTY_ARRAY)
   
    useEffect(() => {
        if(formError){
            setError('this field is required.')
        }else{
            setError('') 
        }
    },[formError])

    useEffect(() =>{
        getVehical()
    } , EMPTY_ARRAY)

    
	const getVehical = () => {
		dispatch(getVehicleModelList()).then((res) => {
			let makeList = res.make
			makeList.unshift({
				'id': 'Others',
				'name': 'Others',
				'mst_model': [{
					'id': 'Others',
					'make_id': 'Others',
					'name': 'Others'
				}]
			})

			setVehicleMakeList(makeList)
            setVehicleInputDataLists(res)
			// setValue('country', res.country[0].name)
			// if (BookingEdit) {
			// 	let model = (res?.make?.length > 0) && res?.make?.filter((v) => v?.name == BookingEditData?.vehical_details?.make)
			// 	setvehicleModelList(model[0]?.mst_model)
			// }

		})

	}

    const handleModalFieldsData = (e, i) => {
      const { name, value } = e.target
        if (name == 'make') {
            let modelData = vehicleInputDataLists?.make?.filter((v) => v.name == value)
            let makeData = vehicleMakeList?.filter((v) => v.name == value)
            let modelList=modelData[0].mst_model
            let check = modelList?.some((value) => value?.name == 'Others')
            if(!check){
			modelList.unshift({
				'id': 'Others',
				'make_id': 'Others',
				'name': 'Others'
		})}
        const newState = [...vehicleModelList]
            if(i < vehicleModelList.length) {
                newState[i] = modelData[0]?.mst_model
            } else {
                newState.push(modelData[0]?.mst_model)
            }
            setvehicleModelList(newState)
            vehicleData[i][name] = value
            vehicleData[i]['make_id']= makeData?.[0]?.id != 'Others' ? makeData?.[0]?.id : 0
            vehicleData[i]['maketext'] = value == 'Others' ? '' : value
			vehicleData[i]['othermake'] = (value == 'Others') ? true : false
            vehicleData[i]['othermodel'] = (value == 'Others') ? true : false
            vehicleData[i]['vehicleList'] = modelList
            // console.log('vehicleData' ,  vehicleData)
            setVehicleData([...vehicleData])
            setFormError(false)
        } else if( name == 'model'){
            vehicleData[i]['othermodel'] = (value == 'Others') ? true : false
            vehicleData[i][name] = value
            setVehicleData([...vehicleData])  
        }
        else {
            vehicleData[i][name] = value
            setVehicleData([...vehicleData])
            setFormError(false)
        }
    }

    // const addVehicleMake = () => {
    //     const payloadData = [...vehicleData]
    //     payloadData?.push({ 'license_plate': '', 'make_model': '' })
    //     setVehicleData([...payloadData])
    // }


    const addVehicleMake = () => {
        const payloadData = [...vehicleData]
        // checking the every object item is empty or not 
        const testToSeeAllErrorsAreEmpty = payloadData?.every((item) => item?.license_plate !== '')
        if (payloadData?.length <= (4 - getVehicleList?.length) && testToSeeAllErrorsAreEmpty === true) {
            payloadData?.push({ 'license_plate': '', 'make': '', 'model': '', 'color': '', 'state_id': '' , 'othermake' : false , 'othermodel' : false , 'vehicleList' :  [] })
            setVehicleData([...payloadData])
            setError('')
        } else if (testToSeeAllErrorsAreEmpty === false) {
            setError('License Plate is required!')
        }else{
            setError('Maximum 5 vehicles are allowed.')

        }
    }



    const removeVehicleMake = (e, i) => {
        const payloadData = [...vehicleData]
        payloadData?.splice(i, 1)
        setVehicleData([...payloadData])
        setError('')
    }

    return (
        <>
            {/* <Button onClick={() => setSmShow(true)} className="me-2">
                Small modal
            </Button> */}
            {/* <Button onClick={() => setLgShow(true)}>Large modal</Button> */}
            <Modal
                size="xl"
                show={isModalOpen}
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        {modalName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        {/* <div className='d-flex justify-content-end'>
                        <div className='col-2 ps-1'><button type="button" className="btn btn-primary w-100" 
                         onClick={addVehicleMake}><i className="fa fa-plus"></i> </button></div>
                        </div> */}
                   {vehicleData?.map((vehicleListItem, vehicleListIndex) => {
                        return (
                           <div className='bg-light border p-2 mb-2 position-relative' key={vehicleListIndex}>
                            <Row  className='row g-2 row-cols-2  row-cols-sm-3 row-cols-md-4' >
                                <Col className='mt-1'>
                                    <label className='form-label' >License Plate <span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        name='license_plate'
                                        required={true}
                                        className="form-control"
                                        placeholder="License Plate"
                                        maxLength={10}
                                        minLength={2}
                                        onKeyDown={restrictSpecialChar}
                                        value={vehicleListItem?.license_plate}
                                        onChange={(e) => handleModalFieldsData(e, vehicleListIndex)}
                                        aria-describedby="emailHelp" />
                                        <small className='text-danger'>{(error && vehicleListItem?.license_plate == '') ? error : (error && vehicleListItem?.license_plate?.length < 2) ? 'Minimum 2 characters allowed'  : ''}</small>
        
                                </Col>

                                
                                <Col className='mt-1'>
                                    <label className='form-label' >Vehicle Make </label>
                                    <select autoComplete="off" name="make" placeholder="Make" onKeyDown={restrictSpecialChar} className="form-select form-control" value={vehicleListItem?.make} id="make" onChange={(e) => handleModalFieldsData(e, vehicleListIndex)}>
                                        <option value="">Select an option</option>
                                        {(vehicleMakeList?.length > 0) && vehicleMakeList?.map((val, index) => {
                                            return (
                                                <option value={val?.name} key={index} >{val?.name}</option>
                                            )
                                        })}
                                    </select>
                                    {/* <input
                                        type="text"
                                        name='make'
                                        className="form-control"
                                        placeholder="Make"
                                        onChange={(e) => handleModalFieldsData(e, vehicleListIndex)}
                                        maxLength={15}
                                        minLength={4}
                                        onKeyDown={restrictSpecialChar}
                                        value={vehicleListItem?.make}
                                        aria-describedby="emailHelp" /> */}
                                </Col>
                               {vehicleListItem?.othermake  ? <Col className='mt-1'>
                                    <label className='form-label' >Make </label>
                                    <input
                                        type="text"
                                        name='maketext'
                                        className="form-control"
                                        placeholder="Make"
                                        onChange={(e) => handleModalFieldsData(e, vehicleListIndex)}
                                        maxLength={15}
                                        minLength={4}
                                        onKeyDown={restrictSpecialChar}
                                        value={vehicleListItem?.maketext}
                                        aria-describedby="emailHelp" />
                                </Col> : ''}
                                {!vehicleListItem?.othermake ? <Col className='mt-1'>
                                    <label className='form-label' >Vehicle Model</label>
                                    <select className='form-select form-control' value={vehicleListItem?.model} placeholder="Model" name="model" id='state' onKeyDown={restrictSpecialChar} onChange={(e) => handleModalFieldsData(e, vehicleListIndex)} disabled={vehicleListItem?.make ? false : true}>
                                        <option value="0">Select an option</option>
                                        {(vehicleModelList?.length > 0) && vehicleModelList?.[vehicleListIndex]?.map((item, itemIndex) => <option key={(itemIndex + 1)} value={item?.name}>{item?.name}</option>)}
                                    </select>
                                    {/* <input
                                        type="text"
                                        name='modeltext'
                                        className="form-control"
                                        placeholder="Model"
                                        onChange={(e) => handleModalFieldsData(e, vehicleListIndex)}
                                        maxLength={15}
                                        minLength={4}
                                        onKeyDown={restrictSpecialChar}
                                        value={vehicleListItem?.modeltext}
                                        aria-describedby="emailHelp" /> */}
                                </Col> : ''}
                               {(vehicleListItem?.othermake || vehicleListItem?.othermodel) ? <Col className='mt-1'>
                                    <label className='form-label' >Model</label>
                                    {/* <select className='form-select form-control' value={vehicleListItem?.model} placeholder="Model" name="model" id='state' onKeyDown={restrictSpecialChar} onChange={(e) => handleModalFieldsData(e, vehicleListIndex)}>
                                        <option value="0">Select an option</option>
                                        {(vehicleModelList?.length > 0) && vehicleModelList?.map((item, itemIndex) => <option key={(itemIndex + 1)} value={item?.name}>{item?.name}</option>)}
                                    </select> */}
                                    <input
                                        type="text"
                                        name='modeltext'
                                        className="form-control"
                                        placeholder="Model"
                                        onChange={(e) => handleModalFieldsData(e, vehicleListIndex)}
                                        maxLength={15}
                                        minLength={4}
                                        onKeyDown={restrictSpecialChar}
                                        value={vehicleListItem?.modeltext}
                                        aria-describedby="emailHelp" />
                                </Col> : ''}
                                <Col className='mt-1'>
                                    <label className='form-label' >Color </label>
                                    <select name='color' autoComplete="off" className='form-select form-control' value={vehicleListItem?.color} placeholder="Color" id='color' onChange={(e) => handleModalFieldsData(e, vehicleListIndex)} onKeyDown={restrictSpecialChar}>
                                        <option value="0">Select an option</option>
                                        {(vehicleInputDataLists?.color?.length > 0) && vehicleInputDataLists?.color?.map((item, itemIndex) => <option key={(itemIndex + 1)} value={item?.name}>{item?.name}</option>)}
                                    </select>
                                    {/* <input
                                        type="text"
                                        name='color'
                                        className="form-control"
                                        placeholder="Color"
                                        onChange={(e) => handleModalFieldsData(e, vehicleListIndex)}
                                        maxLength={15}
                                        minLength={4}
                                        onKeyDown={restrictSpecialChar}
                                        value={vehicleListItem?.color}
                                        aria-describedby="emailHelp" /> */}
                                </Col>
                                <Col className='mt-1'>
                                    <label className='form-label' >State/Province</label>
                                    <select className='form-select form-control' value={vehicleListItem?.state_id} placeholder="Model" name="state_id" id='state_id' onKeyDown={restrictSpecialChar} onChange={(e) => handleModalFieldsData(e, vehicleListIndex)}>
                                        <option value="0">Select an option</option>
                                        {(vehicleInputDataLists?.state?.length > 0) && vehicleInputDataLists?.state?.map((item, itemIndex) => <option key={(itemIndex + 1)} value={item?.id}>{item?.state_code}</option>)}
                                    </select>
                                    
                                </Col>
                        
                            </Row>
                    
                            <Col xl="auto" className='top-0 right-0 position-absolute '>
                               {(vehicleData && getVehicleList) ? vehicleData?.length - 1 === vehicleListIndex ?
                                   <button type="button" className="btn btn-primary btn-sm" disabled={vehicleData?.length > (4 - getVehicleList.length)} onClick={addVehicleMake}><i className="fa fa-plus"></i> </button> :
                                   vehicleListIndex !== -1 ? <button type="button" className="btn btn-danger btn-sm w-100" onClick={(e) => removeVehicleMake(e, vehicleListIndex)}><i className="fa fa-minus"></i></button> :
                                       null
                                : null}
                           </Col>
                            </div>
                        
                        )
                    })}
                </Modal.Body>
                <Modal.Footer>
                <div className='row d-block w-100'>
                        <div className='d-flex align-items-end justify-content-end'>
                            <Button type="submit" data-bs-dismiss="modal" disabled={submitButtonDisable} onClick={handleSubmitVehicleData} style={{ float: 'right' , marginTop:'5px'}}>Submit</Button>
                        </div>
                        <div  style={{ float: 'right' }} className='text-end'>
                            <small className='small'>License plate is required and should have 2 to 10 characters.</small>
                        </div>
                     
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

VehicleModal.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    modalName: PropTypes.string,
    vehicleData: PropTypes.array,
    setVehicleData: PropTypes.func,
    handleSubmitVehicleData: PropTypes.func,
    isModalOpen: PropTypes.bool,
    setModal: PropTypes.func,
    submitButtonDisable: PropTypes.bool,
    getVehicleList: PropTypes.array,
    formError: PropTypes.bool,
    setFormError: PropTypes.func,
}

VehicleModal.defaulProps = {
    dispatch: noop,
    loader: false,
    modalName: undefined,
    vehicleData: EMPTY_ARRAY,
    setVehicleData: noop,
    handleSubmitVehicleData: noop,
    isModalOpen: false,
    setModal: noop,
    formError: false,
    setFormError: noop,
    submitButtonDisable: false,
    getVehicleList: EMPTY_ARRAY,
}

function mapStateToProps({ state }) {
    return { state }
}
export default connect(mapStateToProps)(VehicleModal)
