import React from 'react'
import pickBy from 'lodash/pickBy'
import { STORAGE_KEY } from '../constants'
import {
  DAYS,
  EVENT_TYPES,
  EVENT_TYPES_VALUES,
  EVENT_TYPES_VALUES_LOGS,
  MONTHNAME,
  MONTH_NAMES,
  validCard,
  validPassword,
  validPhone,
} from '../constants/globalConstants'
import moment from 'moment'
import CryptoJS from 'crypto-js'
import { getConfigDetails } from '../services/config'
import Resizer from 'react-image-file-resizer'
import master from '../assets/img/mastercard.png'
import visa from '../assets/img/visa_credit.png'
import discover from '../assets/img/discover_card.png'
import diner from '../assets/img/diners_card.png'
import amex from '../assets/img/amax_card.png'
import jcb from '../assets/img/jcb_hr.png'
import blank from '../assets/img/blank_credit_card.png'
import masterimg from '../assets/img/master_img.png'
import parkingpayments from '../assets/img/parkingpayments.png'
import townsend from '../assets/img/townsend.png'
import jordanImage from '../assets/img/intrapark_blue1bg.png'
import logos from '../assets/img/17.png'
import $ from 'jquery'
import { useLocation } from 'react-router-dom'
import MyURLs from '../constants/urls'


let userAgent = navigator.userAgent || navigator.vendor
const iOS = (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) || /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  ? true : false

//   Sat Apr 01 2023 17:05:00 GMT+0530 (India Standard Time)
export const formatDate = (date) => {
  let date_final
  if (iOS) {
    let arr = date.split(/[- :]/)
    const newDate = new Date(
      arr[0],
      arr[1] - 1,
      arr[2],
      arr[3],
      arr[4],
      arr[5]
    )
    date_final = MONTH_NAMES[newDate.getMonth()] + ' ' + newDate.getDate()
  } else {
    let format_date = new Date(date)
    date_final =
      MONTH_NAMES[format_date.getMonth()] + ' ' + format_date.getDate()
  }
  return date_final
}

export const formatDateDays = (date) => {
  let date_final
  if (iOS) {
    let arr = date.split(/[- :]/)
    const newDate = new Date(
      arr[0],
      arr[1] - 1,
      arr[2],
      arr[3],
      arr[4],
      arr[5]
    )
    date_final = DAYS[newDate.getDay()]
  } else {
    let format_date = new Date(date)
    date_final = DAYS[format_date.getDay()]
  }
  return date_final
}

export const formatAMPM = (date) => {
  let strTime
  if (iOS) {
    let arr = date.split(/[- :]/)
    const newDate = new Date(
      arr[0],
      arr[1] - 1,
      arr[2],
      arr[3],
      arr[4],
      arr[5]
    )
    let hours = newDate.getHours()
    let minutes = newDate.getMinutes()
    let ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes ? (minutes < 10 ? '0' + minutes : minutes) : '00'
    strTime = hours + ':' + minutes + ' ' + ampm
  } else {
    let hours = new Date(date).getHours()
    let minutes = new Date(date).getMinutes()
    let ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes ? (minutes < 10 ? '0' + minutes : minutes) : '00'
    strTime = hours + ':' + minutes + ' ' + ampm
  }
  return strTime
}

export const dateFormatter = (date, format) => moment(date).format(format)
// export const dateFormatterMonth = (date, format) => moment(date).format(format)

export function formatMonthYear(date) {
  let date_final
  if (iOS) {
    let arr = date.split(/[- :]/)
    const newDate = new Date(
      arr[0],
      arr[1] - 1,
      arr[2],
      arr[3],
      arr[4],
      arr[5]
    )
    date_final =
      MONTHNAME[newDate.getMonth()] + ' ' + newDate.getFullYear()
  } else {
    let format_date = new Date(date)
    date_final =
      MONTHNAME[format_date.getMonth()] + ' ' + format_date.getFullYear()
  }

  return date_final
}



export const getEventType = (event) => {
  switch (event) {
    case EVENT_TYPES.EVENT:
      return EVENT_TYPES_VALUES.EVENT
    case EVENT_TYPES.BUNDLE_PASS:
      return EVENT_TYPES_VALUES.BUNDLE_PASS
    case EVENT_TYPES.PARTY_PASS:
      return EVENT_TYPES_VALUES.PARTY_PASS
    case EVENT_TYPES.SIMPLE:
      return EVENT_TYPES_VALUES.SIMPLE
    default:
      return EVENT_TYPES_VALUES.EVENT
  }
}
// export const getEventTypeById = (event_id, log) => {
//   switch (event_id) {
//     case `${getConfigDetails().FULL_SEASON}`:
//       return log ? EVENT_TYPES_VALUES_LOGS.BUNDLE_PASS : EVENT_TYPES_VALUES.BUNDLE_PASS
//     case `${getConfigDetails().HALF_SEASON}`:
//       return  log ? EVENT_TYPES_VALUES_LOGS.PARTY_PASS : EVENT_TYPES_VALUES.PARTY_PASS
//     case `${getConfigDetails().SIX_PACK}`:
//       return log ? EVENT_TYPES_VALUES_LOGS.SIMPLE : EVENT_TYPES_VALUES.SIMPLE
//     default:
//       return log ? EVENT_TYPES_VALUES_LOGS.EVENT : EVENT_TYPES_VALUES.EVENT
//   }
// }

export const getEventTypeByPassType = (pass_type, is_full_season, log) => {
  // switch (event_id) {
  if (pass_type == EVENT_TYPES?.BUNDLE_PASS && is_full_season == 1) {
    return log ? EVENT_TYPES_VALUES_LOGS.BUNDLE_PASS : EVENT_TYPES_VALUES.BUNDLE_PASS
  } else if (pass_type == EVENT_TYPES?.BUNDLE_PASS && is_full_season != 1) {
    return log ? EVENT_TYPES_VALUES_LOGS.PARTY_PASS : EVENT_TYPES_VALUES.PARTY_PASS
  } else if (pass_type == EVENT_TYPES?.PARTY_PASS) {
    return log ? EVENT_TYPES_VALUES_LOGS.SIMPLE : EVENT_TYPES_VALUES.SIMPLE
  } else {
    return log ? EVENT_TYPES_VALUES_LOGS.EVENT : EVENT_TYPES_VALUES.EVENT
  }
}

export const getEventCount = (eventId) => {
  // const halfSeasonEvents = localStorage.getItem('userChooseEvents')
  const eventDetails = JSON.parse(localStorage.getItem(EVENT_TYPES?.EVENT))

  switch (eventId) {
    case getConfigDetails().HALF_SEASON:
      return eventDetails?.all_event?.length / 2
    case getConfigDetails().FULL_SEASON:
      return eventDetails?.all_event?.length
    case getConfigDetails().SIX_PACK:
      return 6
    default:
      return 1
  }
}

export const getSanitizedValues = (values) =>
  pickBy(values, (value) => {
    const expression = !(
      value === 0 ||
      value === '' ||
      value === undefined ||
      value === null ||
      Number.isNaN(value) ||
      value?.length === 0
    )
    return expression
  })

const setLocalStorage = (userData) => {
  userData.map((data) => {
    localStorage.setItem(data.key, data.value)
  })
}

export const getLoginDetailFromSession = () =>
  localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN)

export const getBearerToken = () => localStorage.getItem(STORAGE_KEY.ID_TOKEN)

export const setLoginDetailInSession = (loggedInUserData) => {
  const userData = [
    {
      key: STORAGE_KEY.ACCESS_TOKEN,
      value: loggedInUserData?.AccessToken,
    },
    {
      key: STORAGE_KEY.REFRESH_TOKEN,
      value: loggedInUserData?.RefreshToken,
    },
    {
      key: STORAGE_KEY.ID_TOKEN,
      value: loggedInUserData?.IdToken,
    },
    {
      key: STORAGE_KEY.TOKEN_TYPE,
      value: loggedInUserData?.TokenType,
    },
  ]
  setLocalStorage(userData)
}

export function isLoggedIn() {
  const userDataFromStorage = localStorage.getItem('userData') ? localStorage.getItem('userData') : '{}'
  const userData = JSON.parse(userDataFromStorage)
  const access_token = userData?.access_token
  if (access_token && access_token != '') {
    return true
  } else {
    return false
  }
}

export function isArrayWithLength(arr) {
  return Array.isArray(arr) && arr.length
}

export function getAllowedRoutes(routes) {
  const role = localStorage.getItem('userRole')
  return routes.filter(({ permission }) => {
    if (!permission) return true
    else if (!isArrayWithLength(permission)) return true
    else return permission.indexOf(role) !== -1
  })
}

export function classes() {
  return [...arguments].join(' ')
}

export const clearSessionDetails = () => {
  localStorage.clear()
}

export const restrictInputValues = (event) => {
  if (!`${event.target.value}${event.key}`.match(/^[0-9]{0,6}$/)) {
    // block the input if result does not match
    event.preventDefault()
    event.stopPropagation()
    return false
  }
}

export const restrictSpecialChar = (e) => {
  const result = (e.key == '!' || e.key == '@' || e.key == '#' || e.key == '$' || e.key == '%' || e.key == '^'
    || e.key == '&' || e.key == '~' || e.key == '`' || e.key == '*' || e.key == '(' || e.key == ')' || e.key == '_'
    || e.key == '=' || e.key == '+' || e.key == '[' || e.key == ']' || e.key == '{' || e.key == '}' || e.keyCode == 220
    || e.key == '|' || e.key == ':' || e.key == ';' || e.keyCode == 222 || e.key == ' ' || e.key == '?' || e.key == '.' || e.key == ',' || e.key == '>' || e.key == '<' || e.key == '-')
  if (result) {
    e.preventDefault()
  } else {
    return result
  }
}

export const getEventLogName = (pass_type, is_full_season) => `MAPCO - BOOK NOW - ${getEventTypeByPassType(pass_type, is_full_season, 'log')}`


export const validateEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
  return pattern.test(email)
}

export const validatePhone = (phone) => {
  // eslint-disable-next-line no-useless-escape
  return validPhone.test(phone)
}

export const validatePassword = (pwd) => {
  // eslint-disable-next-line no-useless-escape
  return validPassword.test(pwd)
}
export const matchPassword = (pwd, cpwd) => {
  // eslint-disable-next-line no-useless-escape
  return pwd == cpwd
}

export const validateCardNumber = (card) => {
  // eslint-disable-next-line no-useless-escape
  return validCard.test(card)
}

export const fileChangedHandler = (event, fileId, setIdProof, idProof, fileWidth, fileHeight, notify, fileSizeLimit) => {
  let fileInput = false
  let file = document.getElementById(fileId).files[0]
  const selectedIdProof = idProof?.filter((item) => item?.type == fileId)
  const filterIdProof = idProof?.filter((item) => item?.type != fileId)

  let flag = false
  idProof.map((item) => {
    if (item?.file?.name.toLowerCase() == file?.name.toLowerCase()) {
      flag = true
    }
  })

  if (flag) {
    notify('error', 'Please select a different document.')
    $('.custom-file-label-logo').html('')
    document.getElementById(fileId).value = ''
    return
  }

  if (file?.size <= fileSizeLimit * (1048576)) {
    fileInput = file && (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png')
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          file,
          fileWidth,
          fileHeight,
          file.type == 'image/jpeg' || file.type == 'image/jpg' ? 'JPG' : 'PNG', // fileType(JPEG, PNG,WEBP)
          0,  // quality compression(for jpeg)
          0, // rotation(0-100)
          (uri) => {
            new Promise((resolve, reject) => {
              const img = new Image()
              // the following handler will fire after a successful loading of the image
              img.onload = () => {
                const { naturalWidth: width, naturalHeight: height } = img
                resolve({ width, height })
                if (fileId) {
                  const newFile = {
                    type: fileId,
                    file: uri
                  }
                  if (selectedIdProof?.length < 1 && uri) {
                    setIdProof([...idProof, newFile])
                  } else {
                    if (uri) {
                      setIdProof([...filterIdProof, newFile])
                    }
                  }
                  $('.custom-file-label-logo').html('')
                  document.getElementById(fileId).value = ''
                }
                // if (fileId == 'front') {
                //   setIdProof({ ...idProof, ...{ ['front']: uri } })
                //   setIdErrProof({ ...idProof, ...{ ['front']: uri } })
                // } else {
                //   setIdProof({ ...idProof, ...{ ['back']: uri } })
                //   setIdErrProof({ ...idProof, ...{ ['back']: uri } })
                // }
              }
              // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
              img.onerror = () => {
                $('.custom-file-label-logo').html('')
                document.getElementById(fileId).value = ''
                reject('There was some problem with the image.')
              }
              img.src = URL?.createObjectURL(uri)
            })
          },
          'file' // outputType( base64, blob or file)
        )
      } catch (err) {
        // setIdProof({fileId : ''})
      }
    } else if (file && (file.type === 'application/vnd.ms-excel' || file.type === 'text/csv' || file.type === 'application/pdf')) {
      const newFile = {
        type: fileId,
        file: file
      }
      if (selectedIdProof?.length < 1 && file) {
        setIdProof([...idProof, newFile])
      } else {
        if (file) {
          setIdProof([...filterIdProof, newFile])
        }
      }
      $('.custom-file-label-logo').html('')
      document.getElementById(fileId).value = ''
    } else {
      $('.custom-file-label-logo').html('')
      document.getElementById(fileId).value = ''
      notify('error', 'Selected Document is not Valid')
    }
  } else {
    $('.custom-file-label-logo').html('')
    document.getElementById(fileId).value = ''
    notify('error', 'Maximum File Size Exceeded')
  }

}

export const encryptionHandler = (d) => {
  // console.log(' dstring' , d)
  // var nonceString = d.name + ':' + d.card_pan + ':' + d.expiration_date + ':' + d.security_code + ':' + d.zip_code
  var nonceString = d.name_on_card + ':' + d.card_number + ':' + d.expiration_date + ':' + d.security_code + ':' + d.zip_code_on_card
  var CryptoJSAesJson = {
    stringify: function (cipherParams) {
      var j = {
        ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
        iv: '',
        s: ''
      }
      if (cipherParams.iv)
        j.iv = cipherParams.iv.toString()
      if (cipherParams.salt)
        j.s = cipherParams.salt.toString()
      return JSON.stringify(j)
    },
    parse: function (jsonStr) {
      var j = JSON.parse(jsonStr)
      var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) })
      if (j.iv)
        cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
      if (j.s)
        cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
      return cipherParams
    }
  }
  var encrypted = CryptoJS.AES.encrypt(JSON.stringify(nonceString), 'Tw5qNOJLe5GFe7rjFsentNNQBsRHstbNzTP/18lkjnY=', { format: CryptoJSAesJson }).toString()
  return encrypted
}

export const phoneMasker = (phone) => {

  if (phone && phone.length >= 10) {
    var USNumber = phone.match(/(\d{3})(\d{3})(\d{4})/)
    return USNumber = '(' + USNumber[1] + ') ' + USNumber[2] + '-' + USNumber[3]
  } else {
    return '-'
  }

}
export const formattedExpiry = (expiry) => {
  return expiry.slice(0, 2) + '/' + expiry.slice(2)
}

export const getCardIcon = (type) => {

  const cardType = type?.toLowerCase()

  switch (cardType) {
    case 'visa':
      return visa
    case 'vs':
      return visa
    case 'mastercard':
      return master
    case 'mastercardpng':
      return masterimg
    case 'mc':
      return master
    case 'm/c':
      return master
    case 'discover':
      return discover
    case 'dinersclub':
      return diner
    case 'americanexpress':
      return amex
    case 'amex':
      return amex
    case 'jcb':
      return jcb
    default:
      return blank
  }
}

export const loggedInUserData = () => {
  const userData = JSON.parse(localStorage.getItem('userData') ? localStorage.getItem('userData') : '{}')
  return userData
}

export const isPartnerTownsend = (partnerSlug) => {
  if (partnerSlug == 'townsend') return true
  return false
}

export const isPartnerWorldPort = (partnerSlug) => {
  if (partnerSlug == 'worldport-la') return true
  return false
}

export const getLocation = (faciltyData) => {
  const location = faciltyData?.geolocations
  return `https://www.google.com/maps?daddr=${location?.latitude},${location?.longitude}+(${faciltyData?.full_name?.replace(/ /g, '+')})`
}

export const getPartnerDetails = () => {
  const partner = (localStorage.getItem('partnerData') && localStorage.getItem('partnerData') != 'undefined') ? JSON.parse(localStorage.getItem('partnerData')) : (sessionStorage.getItem('partnerData') && sessionStorage.getItem('partnerData') != 'undefined') ? JSON.parse(sessionStorage.getItem('partnerData')) : {}
  return partner
}

export const getBreakUpDetails = (paymentData) => {
  let listItem = []

  if (paymentData?.parking_amount && paymentData?.parking_amount != '0.00') {
    listItem = [...listItem, { 'headerName': 'Parking Amount', 'val': `$${paymentData?.parking_amount ? Number(paymentData?.parking_amount).toFixed(2) : '0.00'}` }]
  }
  if (paymentData?.processing_fee && paymentData?.processing_fee != '0.00' && paymentData?.parking_amount != '0.00' && Number(paymentData?.parking_amount) > 0) {
    listItem = [...listItem, { 'headerName': 'Processing Fee', 'val': `$${paymentData?.processing_fee ? paymentData?.processing_fee : '0.00'}` }]
  }

  if (((paymentData?.tax_rate && paymentData?.tax_rate != '0.00') || (paymentData?.tax_fee && paymentData?.tax_fee != '0.00')) && paymentData?.parking_amount != '0.00' && Number(paymentData?.parking_amount) > 0) {
    listItem = [...listItem, { 'headerName': 'Tax', 'val': `$${paymentData?.tax_rate ? Number(paymentData?.tax_rate).toFixed(2) : paymentData?.tax_fee ? Number(paymentData?.tax_fee).toFixed(2) : '0.00'}` }]
  }

  if (paymentData?.paid_amount && paymentData?.paid_amount !== '' && Number(paymentData?.paid_amount) > 0) {
    listItem = [...listItem, { 'headerName': 'Validated Amount', 'val': `- $${paymentData?.paid_amount ? Number(paymentData?.paid_amount)?.toFixed(2) : 0}` }]
  }

  if (paymentData?.discount_hours && paymentData?.discount_hours != '') {
    listItem = [...listItem, { 'headerName': paymentData?.discount_hours ? `Validated ${paymentData?.discount_hours} Hrs Free` : '-', 'val': `- $${paymentData?.discount_hours ? paymentData?.discount_hours : '-'}` }]
  }

  if (paymentData?.amount_paid && paymentData?.amount_paid !== '' && Number(paymentData?.amount_paid) > 0 && Number(paymentData?.payable_amount) > 0) {
    listItem = [...listItem, { 'headerName': 'Amount Paid', 'val': `- $${paymentData?.amount_paid ? Number(paymentData?.amount_paid)?.toFixed(2) : 0}` }]
  }

  if (paymentData?.overstay_amount && paymentData?.overstay_amount !== '' && paymentData?.overstay_amount != '0.00' && Number(paymentData?.overstay_amount) > 0) {
    listItem = [...listItem, { 'headerName': 'Overstay Amount', 'val': `$${paymentData?.overstay_amount ? Number(paymentData?.overstay_amount).toFixed(2) : 0}` }]
  }

  if (paymentData?.discount_amount && paymentData?.discount_amount != '' && Number(paymentData?.discount_amount) > 0) {
    listItem = [...listItem, { 'headerName': paymentData?.discount_hours ? `Validated ${paymentData?.discount_hours} Hrs Free` : 'Discount Amount', 'val': `- $${paymentData?.discount_amount ? Number(paymentData?.discount_amount)?.toFixed(2) : 0}` }]
  }



  return listItem

}
export const priceGreaterOrNot = (ticketDetails) => {
  const paymentDataKeys = ['parking_amount', 'amount_paid', 'processing_fee', 'tax_rate', 'payable_amount', 'grand_total', 'discount_amount', 'paid_amount']
  let isGreaterOrNot = paymentDataKeys.map((key) => {
    if ((ticketDetails?.[key] && Number(ticketDetails?.[key]) > 0)) {
      return true
    } else {
      return false
    }
  })
  if (isGreaterOrNot.includes(true)) {
    return true
  }
}

export const getErrorMessage = (response) => {
  let errorMsg = 'Sorry! something went wrong, please try again.'
  const errorsList = response.errors
  if (errorsList.message !== 'Validation Errors') {
    errorMsg = errorsList.message
  } else {
    for (const key in errorsList.detail) {
      if (Object.prototype.hasOwnProperty.call(errorsList.detail, key)) {
        if (typeof errorsList.detail === 'undefined') {
          errorMsg = errorsList.message
        } else {
          errorMsg = Array.isArray(errorsList.detail[key]) ? errorsList.detail[key][0] : errorsList.detail[key]
        }
      }
    }
  }

  return errorMsg
}
export const EncryptionHandler = (d) => {
  var nonceString = d.name_on_card + ':' + d.card_number + ':' + d.expiration_date + ':' + d.security_code + ':' + d.zip_code
  var CryptoJSAesJson = {
    stringify: function (cipherParams) {
      var j = {
        ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
        iv: '',
        s: ''
      }
      if (cipherParams.iv)
        j.iv = cipherParams.iv.toString()
      if (cipherParams.salt)
        j.s = cipherParams.salt.toString()
      return JSON.stringify(j)
    },
    parse: function (jsonStr) {
      var j = JSON.parse(jsonStr)
      var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) })
      if (j.iv)
        cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
      if (j.s)
        cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
      return cipherParams
    }
  }
  var encrypted = CryptoJS.AES.encrypt(JSON.stringify(nonceString), 'Tw5qNOJLe5GFe7rjFsentNNQBsRHstbNzTP/18lkjnY=', { format: CryptoJSAesJson }).toString()
  return encrypted
}


export const isNotUrl = (partnerslug) => {
  if (partnerslug != 'book-reservation' && partnerslug != 'pass-create' && partnerslug != 'booking') {
    return true
  } else {
    return false
  }
}

export const getPartnerLogo = (name) => {

  const patnerName = name?.toLowerCase()
  switch (patnerName) {

    case 'parkingpayments':
      return parkingpayments
    case 'townsend':
      return townsend
    case 'demoparkengage':
      return logos
    case 'intrapark':
      return jordanImage

    default:
      return logos
  }
}

export const getPartnerTagline = (name) => {

  const patnerName = name?.toLowerCase()
  switch (patnerName) {

    case 'parkingpayments':
      return 'Parking Payments'
    case 'townsend':
      return 'PCI Townsend Parking'
    case 'demoparkengage':
      return 'Parkengage Parking'
    case 'intrapark':
      return 'IntraPark Parking'
    default:
      return 'Customer Portal'
  }
}

export const formatKeyInDocumentName = (string) => {
  const textWithoutUnderscore = string?.replace(/_/g, ' ')
  return textWithoutUnderscore?.charAt(0)?.toUpperCase() + textWithoutUnderscore?.slice(1)
}
export const getAcceptedDocument = (documentType) => {
  switch (documentType) {
    case 'image':
      return 'image/*'
    case 'pdf':
      return '.pdf'
    case 'csv':
      return '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
    case 'image,pdf':
      return 'image/*, .pdf'
    case 'all':
      return 'image/*, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .pdf'
    default:
      return 'image/*, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .pdf'
  }
}

export const getAcceptedFiles = (documentType) => {
  switch (documentType) {
    case 'image':
      return 'jpeg, jpg, png, svg'
    case 'pdf':
      return 'pdf'
    case 'csv':
      return 'csv/excel'
    case 'image,pdf':
      return 'jpeg, jpg, png, pdf'
    case 'all':
      return 'jpeg, jpg, png, pdf, excel, csv'
    default:
      return 'jpeg, jpg, png, pdf, excel, csv'
  }
}

// export const permitRequestMessage = (facility) => `Thank you for showing interest in ${facility} Parking Permits. You will receive your Permit details on your registered email once it is approved by the Admin.`
// export const permitRequestMessage = () => 'Your Parking Permit Request has been submitted and is currently being reviewed.'
export const permitRequestMessage = () => 'Your Parking Permit Request has been submitted and is currently being reviewed. You can check your request under the Permit Request section from My Account.'

export const ifDocumentIsImage = (document) => {
  const split = document.split('.')
  const lastElem = split[split?.length - 1]?.toLowerCase()
  const acceptedArray = ['jpeg', 'jpg', 'png', 'svg', 'webp']

  return acceptedArray?.includes(lastElem)
}

export const getExtension = (document) => {
  const split = document.split('.')
  return split[split?.length - 1]?.toLowerCase()
}

export const isValuePresentInArray = (array, item, key) => {
  for (const obj of array) {
    if (obj[key] === item) {
      return true
    }
  }
  return false
}

export const getFacilityOpenDays = (data) => {
  let result
  const daysToCheck = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
  const MondayToFriday = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
  const keysWithValueOne = daysToCheck.filter(day => data[day] === '1')
  if (keysWithValueOne?.length == 7) {
    result = null
  } else if (areAllElementsPresent(MondayToFriday, keysWithValueOne)) {
    result = 'Monday-Friday'
  } else if (keysWithValueOne?.length > 0) {
    result = keysWithValueOne?.map((item, index) => {
      if (index != (keysWithValueOne?.length - 1)) {
        return nameUpperCase(item) + ', '
      } else {
        return nameUpperCase(item)
      }
    })
  } else {
    result = null
  }
  return result
}

export const areAllElementsPresent = (arr1, arr2) => {
  return arr1.every(function (element) {
    return arr2.includes(element)
  })
}

export const nameUpperCase = (nameString) => {
  const arr = nameString.split('_')
  for (var i = 0; i < arr.length; i++) { arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1) }
  let finalName = arr.join(' ')
  return finalName
}

export const nextDayCheck = (endTime) => {
  if (endTime == undefined || endTime == '')
    return ''

  let timestamp = endTime.split(':')
  return (timestamp[0] > 24 ? '(next day)' : '')

}

export const HorurCheck = (endTime) => {
  if (endTime == undefined || endTime == '')
    return ''

  let timestamp = endTime.split(':')
  timestamp[0] = timestamp[0] > 24 ? (timestamp[0] - 24).toString() > 9 ? (timestamp[0] - 24).toString() : '0' + (timestamp[0] - 24).toString() : timestamp[0]
  let result = timestamp.join(':')
  return result
}

export const MultiSelectWeekDays = [
  {
    'value': '1',
    'label': 'Sunday',
  },
  {
    'value': '2',
    'label': 'Monday',
  },
  {
    'value': '3',
    'label': 'Tuesday',
  },
  {
    'value': '4',
    'label': 'Wednesday',
  },
  {
    'value': '5',
    'label': 'Thursday',
  },
  {
    'value': '6',
    'label': 'Friday',
  },
  {
    'value': '7',
    'label': 'Saturday',
  }
]

export const commaSeperatedDays = (days) => {
  const dayValues = days.split(',').map(day => parseInt(day))
  let sortedDays = []

  for (let i = 2; i <= MultiSelectWeekDays.length + 1; i++) {
    let index = i % MultiSelectWeekDays.length
    if (index === 0) index = MultiSelectWeekDays.length
    if (dayValues.includes(index)) {
      sortedDays.push(MultiSelectWeekDays[index - 1].label.substring(0, 3))
    }
  }
  return sortedDays.join(', ')
}

export const calculateNextDayTimeIndication = (currentTime) => {
  // Parse the current time string into hours, minutes, and seconds
  const [hours, minutes, seconds] = currentTime.split(':').map(Number)

  // Calculate total seconds
  let totalSeconds = (hours * 3600) + (minutes * 60) + seconds

  // Determine if it's today or next day
  const indication = totalSeconds >= 86400 ? 'next day' : 'today'
  // Check if the total seconds exceed 24 hours
  if (totalSeconds >= 86400) {
    totalSeconds -= 86400 // Subtract 24 hours (86400 seconds) to wrap around to the next day
  }

  // Calculate new time components
  let nextDayHours = Math.floor(totalSeconds / 3600)
  let nextDayMinutes = Math.floor((totalSeconds % 3600) / 60)
  let nextDaySeconds = totalSeconds % 60

  // Format the next day time
  const nextDayTime = `${String(nextDayHours).padStart(2, '0')}:${String(nextDayMinutes).padStart(2, '0')}:${String(nextDaySeconds).padStart(2, '0')}`


  return { indication, nextDayTime }
}

export const mergeSubsequentDays = (commaSeparatedDays) => {
  const daysArray = commaSeparatedDays.split(', ')
  let mergedDays = []
  let startDay = ''
  let endDay = ''
  if (daysArray.length == 7) {
    return 'All Days'
  } else {
    for (let i = 0; i < daysArray.length; i++) {
      let currentDay = daysArray[i]
      let currentIndex = getDayIndex(currentDay)

      if (startDay === '') {
        startDay = currentDay
        endDay = currentDay
      } else {
        let endDayIndex = getDayIndex(endDay)
        if ((currentIndex === endDayIndex + 1) || (endDayIndex === 6 && currentIndex === 0)) {
          endDay = currentDay
        } else {
          if (startDay !== endDay) {
            if (getDayIndex(startDay) === getDayIndex(endDay) - 1) {
              mergedDays.push(startDay)
              mergedDays.push(endDay)
            } else {
              mergedDays.push(`${startDay} - ${endDay}`)
            }
          } else {
            mergedDays.push(startDay)
          }
          startDay = currentDay
          endDay = currentDay
        }
      }
    }

    if (startDay !== endDay) {
      if (getDayIndex(startDay) === getDayIndex(endDay) - 1) {
        mergedDays.push(startDay)
        mergedDays.push(endDay)
      } else {
        mergedDays.push(`${startDay} - ${endDay}`)
      }
    } else {
      mergedDays.push(startDay)
    }

    return mergedDays.join(', ')
  }
}


export const getDayIndex = (day) => {
  return MultiSelectWeekDays.findIndex(item => item.label.substring(0, 3) === day)
}

export const fullDayTimeSlot = [
  { name: '12:00 AM', val: '00:00:00' }, { name: '1:00 AM', val: '01:00:00' }, { name: '2:00 AM', val: '02:00:00' }, { name: '3:00 AM', val: '03:00:00' },
  { name: '4:00 AM', val: '04:00:00' }, { name: '5:00 AM', val: '05:00:00' }, { name: '6:00 AM', val: '06:00:00' }, { name: '7:00 AM', val: '07:00:00' },
  { name: '8:00 AM', val: '08:00:00' }, { name: '9:00 AM', val: '09:00:00' }, { name: '10:00 AM', val: '10:00:00' }, { name: '11:00 AM', val: '11:00:00' },
  { name: '12:00 PM', val: '12:00:00' }, { name: '1:00 PM', val: '13:00:00' }, { name: '2:00 PM', val: '14:00:00' }, { name: '3:00 PM', val: '15:00:00' },
  { name: '4:00 PM', val: '16:00:00' }, { name: '5:00 PM', val: '17:00:00' }, { name: '6:00 PM', val: '18:00:00' }, { name: '7:00 PM', val: '19:00:00' },
  { name: '8:00 PM', val: '20:00:00' }, { name: '9:00 PM', val: '21:00:00' }, { name: '10:00 PM', val: '22:00:00' }, { name: '11:00 PM', val: '23:00:00' }
]

export const AllowededTime = (permit_rate_criteria) => {
  const { indication, nextDayTime } = calculateNextDayTimeIndication(permit_rate_criteria.entry_time_end)
  let commaSepDays = commaSeperatedDays(permit_rate_criteria.days)
  let subSequent = mergeSubsequentDays(commaSepDays)
  let entryBeginToExitEnd = `${fullDayTimeSlot.find((car) => car.val == permit_rate_criteria.entry_time_begin).name} - ${(nextDayTime == '23:59:59') ? '11:59 PM' : (fullDayTimeSlot.find((car) => car.val == nextDayTime).name)} ${(indication == 'today') ? '' : `(${indication})`}`
  let tooltipText = `${subSequent} - ${entryBeginToExitEnd}`
  return tooltipText
}

// for the Permit Parking Time fucntion

export const perkingParkingTime = (selectedPermit, isBorderHide) => {
  return (<div className='d-flex flex-sm-row flex-column my-2 align-items-center  gap-2 '>
    <label>Permit Parking Time : </label> 
    {(selectedPermit?.permit_rate_criteria_mapping?.length > 0) && selectedPermit?.permit_rate_criteria_mapping?.map((item, index) =>
      <span className={`${(isBorderHide) ? 'd-block' : 'block '}`} key={index} > {AllowededTime(item?.permit_rate_criteria)}  </span>
    )
    }
  </div >)
}

export const getDiffFromTwoTime = (endTime, screen) => {
  let facilityTimeZone = 'America/New_York'
  if (screen == 'extend') {
    const data = JSON?.parse(localStorage?.getItem('ExtendData'))
    facilityTimeZone = data?.facility?.timezone || 'America/New_York'
  } else {
    const data = JSON.parse(localStorage?.getItem('paymentRecipt'))
    facilityTimeZone = data?.facility?.timezone || 'America/New_York'
  }
  let now = new Date(moment(new Date()).tz(facilityTimeZone).format('YYYY-MM-DD HH:mm:ss'))
  const timeDiff = moment(endTime).diff(now, 'minutes')
  return timeDiff
}

export const validExpiryDate = (value) => {
  let expiryDateCheck = value.length == 4 && value.substring(0, 2) < 13 && (value.substring(0, 2) >= moment(new Date()).format('MM') && value.substring(2) >= moment(new Date()).format('YY')
    || value.substring(0, 2) <= moment(new Date()).format('MM') && value.substring(2) > moment(new Date()).format('YY')
  )
  return expiryDateCheck
}

export const restrictInValidCharPassword = (value = '') => {
  // List of restricted characters
  const restrictedChars = /[^!@#$%^&*?\w\s-]/g

  // Remove restricted characters from the input value
  let filteredValue = value.replace(restrictedChars, '')
  filteredValue = filteredValue.replace('_', '')

  // Restrict the length to a maximum of 15 characters
  if (filteredValue.length > 15) {
    filteredValue = filteredValue.substring(0, 15)
  }
  return filteredValue
}


export const getQuerySearchParams = (queryName) => {
  const location = useLocation()
  let urlParams = new URLSearchParams(location?.search)
  const myParam = urlParams?.get(queryName)
  return myParam
}

export function sortPortalPermissionByKey(partnerDetails, sectionName) {
  return partnerDetails?.customer_portal_permission?.[sectionName]?.map((item) => item)
}

export const isPermissionExist = (data, itemName) => {
  const filterObj = (data?.length > 0) && data?.filter((item) => item?.display_name == itemName)
  if (filterObj[0]?.is_default == '1') {
    return true
  } else {
    return false
  }
}

export const postionOfElement = (id) => {
  const offsets = document.getElementById(id).getBoundingClientRect()
  return offsets
}

export const isNotaValidSlug = (path) => {
  
  for (let key in MyURLs) {
    // eslint-disable-next-line no-prototype-builtins
		if (MyURLs?.hasOwnProperty(key)) {
      // eslint-disable-next-line no-prototype-builtins
			// Check if the MyURLsString matches the entire value or is part of it
			if (MyURLs[key] === path || MyURLs[key]?.includes(path)) {
				return true
			}
		}
	}
	return false
}