import CONSTANTS from '../constants'
import { getRequest } from '../../services'
import { APICONFIG } from '../config/apiConfig'
import { getConfigDetails } from '../../services/config'

// Calls the API to get Facility Initials
export const getPermitRequestList = () => (dispatch) => {
    dispatch({ type: CONSTANTS.GET_PERMIT_REQUEST_LIST_REQUEST })
    return getRequest(`${APICONFIG.getPermitRequestUrl}?partner_id=${getConfigDetails()?.PARTNER_ID}`).then((res) => {
        // return getRequest(`${APICONFIG.getPermitRequestUrl}?partner_id=${getConfigDetails()?.PARTNER_ID}`).then((res) => {
        dispatch({ type: CONSTANTS.GET_PERMIT_REQUEST_LIST_SUCCESS, data: res.data })
        return res.data
    }).catch(() => {
        dispatch({ type: CONSTANTS.GET_PERMIT_REQUEST_LIST_FAILURE })
    })
}


export const CancelPermit = (id) => (dispatch) => {
    dispatch({ type: CONSTANTS.CANCEL_PERMIT_REQUEST })
    return getRequest(`${APICONFIG.cancelPermitUrl}/${id}`).then((res) => {
        dispatch({ type: CONSTANTS.CANCEL_PERMIT_SUCCESS })
        return res.data
    }).catch(() => {
        dispatch({ type: CONSTANTS.CANCEL_PERMIT_FAILURE })
    })
}