import React, { Component } from 'react'
import Aux from './../Wrapper/Auxiliary'
import moment from '../../../components/global/Time/Moment'
import CarouselItems from './CarouselItems'
import { Link } from 'react-router-dom'
import { BottomLogo, systemError } from '../Constant'
import { connect } from 'react-redux'
import { APICONFIG } from '../../../redux/config/apiConfig'
import LoadingComponent from '../../../common/UI/loadingComponent'
import NotificationSystem from 'react-notification-system'
import eventCallRequest from '../../../../src/utils/HelperEvent'
import * as actionCreators from '../../../redux/actions'
import { makeAfterPayment } from '../../../redux/actions/passesAction'
import PropTypes from 'prop-types'
import { EMPTY_OBJECT } from '../../../constants/globalConstants'
import noop from 'lodash/noop'
import { getPartnerDetails } from '../../../utils/helper'

class failedComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userData: '',
            ticket: '',
            booking_date: '',
            endTime: '',
            phone: '',
            phonePre: '+1',
            email: '',
            pass_code: '',
            ticketech_code: '',
            isPass: false,
            paymentRes: {},
            valid_till: '',
            bookingId: '',
            passDetails:{},
            reservationDetails:{},
            data:{},
            errorMessage:'',
            systemError: false,
            cardDetails:{}
        }
        this.notificationSystem = React.createRef()
        this.dateDisplayFormat = 'MM/DD/YYYY'
        this.dateFormat = 'YYYY-MM-DD 00:00:00'
        this.existingDateFormat = 'll'
        this.timeDisplayFormat = 'h:mm A'
        this.phoneNumberMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    }

    carouselOptions = {
        nav: false,
        rewind: false,
        autoplay: false,
        margin: 30,
        loop: false,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 4,
            }
        }
    }

    

    componentDidMount = () => {
        const {dispatch} = this.props
        let queries = localStorage.getItem('queries') ? JSON.parse(localStorage.getItem('queries')) : ''
        const cardLastFour = localStorage.getItem('card_pan')
        const card_date_expiry_month = localStorage.getItem('card_date_expiry_month')
        const card_date_expiry_year = localStorage.getItem('card_date_expiry_year')
        const card_card_security_cvx_2 = localStorage.getItem('card_card_security_cvx_2')
        const cardDetails = {
            card: cardLastFour,
            expiry_month: card_date_expiry_month,
            expiry_year: card_date_expiry_year,
            cvv: card_card_security_cvx_2
        }

        this.setState({cardDetails : cardDetails})
        const userData = JSON.parse(localStorage.getItem('userData'))
        const isDirectBooking = localStorage.getItem('directBooking')
        const directBookingData = JSON.parse(localStorage.getItem('directBookingData'))
        if(!userData || userData === ''){
            window.location.replace(`${getPartnerDetails()?.partner_slug}/booking`)
        }

        const entry_date = localStorage.getItem('entry_date')
        const paymentDetails = JSON.parse(localStorage.getItem('paymentSession'))
        const passDetails = localStorage.getItem('passDetails') ? JSON.parse(localStorage.getItem('passDetails')) : ''
        const paaswithBooking = localStorage.getItem('isBooking')
        const requestPayload = {
            
                'length': isDirectBooking ? directBookingData?.length : passDetails?.length ? passDetails?.length : APICONFIG.defaultBookingLength,
                'facility_id': userData?.garage_id ? userData?.garage_id : this.props.partnerDetails.facilities[0].id ,
                'member_id': userData?.member_id,
                'pass_id': isDirectBooking ? directBookingData?.pass_id : passDetails?.is_book_parking ? passDetails?.pass_rate_id : '',
                'pass_rate_id': passDetails?.is_book_parking ? '' : passDetails?.pass_rate_id,
                'is_pass_purchase': isDirectBooking ? 1 : passDetails !== '' ? passDetails?.is_book_parking ? 0 : 1 : 0,
                'is_booking_also': parseInt(paaswithBooking),
                'is_book_parking': isDirectBooking ? 1 : parseInt(paaswithBooking) === 0 ? passDetails?.is_book_parking ? 1 : 0 : 1,
                'description': `Reservation for Parkengage - A beginning at ${entry_date ? moment(entry_date, this.dateDisplayFormat).format(this.dateFormat) : moment(new Date(), this.dateDisplayFormat).format(this.dateFormat)}`,
                'total': isDirectBooking ? directBookingData?.price : parseInt(paymentDetails?.amount).toFixed(2),
                'session': paymentDetails?.ipgSession,
                'email': userData?.email,
                'phone': userData?.phone,
                'name': userData?.user_name,
                'companyName': '',
                'is_opt_out': '',
                'use_bonus': 0,
                'coupon_threshold_price': 0,
                'warning_on_reservation': 0,
                'warning_on_reservation_msg_email': '',
                'is_card_req': 0,
                'arrival': entry_date ? moment(entry_date, this.dateDisplayFormat).format(this.dateFormat) : moment(new Date(), this.dateDisplayFormat).format(this.dateFormat),
                'MerchantRef':queries.MerchantRef, 
                'TxID':queries.TxID,
                'AuthorisationCode':queries.AuthorisationCode,
                'CardType':queries.CardType,
                'CardNumberLast4': queries.CardNumberLast4 ? queries.CardNumberLast4 : this.state.cardDetails.card,
                'expiryMonth': this.state.cardDetails.expiry_month,
                'expiryYear': this.state.cardDetails.expiry_year,
                'cvv': this.state.cardDetails.cvv,
                'TokenNo':queries.TokenNo,
                'DSIndicator':queries.DSIndicator,
                'SCATransRef': queries.SCATransRef,
				'payment_status':'fail',
            
        }
        let logData = userData
        logData = {...logData, directBookingData}
        logData = {...logData, paymentDetails}
        logData = {...logData, requestPayload}
        logData = { ...logData, cardDetails }

        let setUserLog = eventCallRequest('After Payment Failed, Booking API Call', JSON.stringify(logData))
        this.props.eventRequest(setUserLog).then(() => { })


        dispatch(makeAfterPayment(requestPayload)).then((paymentRes) => {
            this.setState({paymentRes: paymentRes, data: paymentRes?.data, reservationDetails: paymentRes?.data?.reservation, passDetails: paymentRes?.data?.pass, bookingId: paymentRes?.data?.reservation?.anet_transaction_id})
            const reservationData = paymentRes?.data?.reservation
            if(!paymentRes?.errorMessage){

                let logData = userData
                logData = {...logData, directBookingData}
                logData = {...logData, paymentDetails}
                logData = {...logData, requestPayload}
                logData = {...logData, paymentRes}
                logData = { ...logData, cardDetails }

                let setUserLog = eventCallRequest('After Payment Failed, Booking API Call Success', JSON.stringify(logData))
                this.props.eventRequest(setUserLog).then(() => { })



                if (Object.values(reservationData).length > 0) {
                    let confirmationData = {
                        end_date: reservationData.formatted_start_date,
                        phone: reservationData.user.phone,
                        email: reservationData.user.email,
                        is_pass: 0,
                        ticketech_code: reservationData.ticketech_code,
                    }
                    if (confirmationData) {
                        let phoneNum = confirmationData.phone.slice(-10)
                        let phonePre = confirmationData.phone.substring(0, (confirmationData.phone.length - 10))
                        this.setState({
                            endDate: confirmationData.end_date,
                            ticketech_code: confirmationData.ticketech_code,
                            pass_code: confirmationData.pass_code,
                            phone: phoneNum,
                            phonePre: phonePre,
                            email: confirmationData.email,
                            isPass: (confirmationData.is_pass === 1) ? true : false
                        })
                        // localStorage.removeItem('userData')
                        // localStorage.clear()
                    } else {
                        // window.location.replace("/");
                    }
                } else {
                    const passData = paymentRes?.data?.pass

                    let confirmationData = {
                        end_date: passData.end_date,
                        phone: passData.user.phone,
                        email: passData.user.email,
                        is_pass: 1,
                        pass_code: passData.pass_code
                    }
                    if (confirmationData) {
                        let phoneNum = confirmationData.phone.slice(-10)
                        let phonePre = confirmationData.phone.substring(0, (confirmationData.phone.length - 10))
                        this.setState({
                            endDate: confirmationData.end_date,
                            ticketech_code: confirmationData.ticketech_code,
                            pass_code: confirmationData.pass_code,
                            phone: phoneNum,
                            phonePre: phonePre,
                            email: confirmationData.email,
                            isPass: (confirmationData.is_pass === 1) ? true : false
                        })
                        // localStorage.removeItem('userData')
                        // localStorage.clear()
                    } else {
                        // window.location.replace("/");
                    }
                    
                }
            }else{
                let logData = userData
                logData = {...logData, directBookingData}
                logData = {...logData, paymentDetails}
                logData = {...logData, requestPayload}
                logData = {...logData, paymentRes}
                logData = { ...logData, cardDetails }

                let setUserLog = eventCallRequest('After Payment Failed, Booking API Call Failed', JSON.stringify(logData))
                this.props.eventRequest(setUserLog).then(() => { })
                
                this.setState({errorMessage:paymentRes?.errorMessage, systemError: true})                
                // localStorage.clear()

            }
        }).catch((err) => {
            let logData = userData
                logData = {...logData, directBookingData}
                logData = {...logData, paymentDetails}
                logData = {...logData, requestPayload}
                logData = {...logData, err}
                logData = { ...logData, cardDetails }

                let setUserLog = eventCallRequest('After Payment Failed, Booking API Call Failed', JSON.stringify(logData))
                this.props.eventRequest(setUserLog).then(() => { })
                
            this.setState({errorMessage: systemError, systemError: true})
            // localStorage.clear()

        })

        
        
    }

    render() {
    const {isFetching} = this.props
    
        return (
            <Aux>
                <NotificationSystem ref={this.notificationSystem} />
                {isFetching && <LoadingComponent/>}
                <div className="container-fluid">
                    <div className="row no-gutters">
                        <div className="col-sm-12 col-md-6 col-12">
                            <div className="col my-4 text-center">
                                <Link to="/" className='text-decoration-none'>
                                    <h5 className="fw-bold text-primary">Parking at Main Street Parking</h5>
                                </Link>
                            </div>
                            <CarouselItems />
                            <BottomLogo
                                className="my-2 text-center bg-opacity-10 d-none d-sm-block"
                            />
                        </div>
                        {!isFetching && <div className="col-sm-12 col-md-6 col-12 bg-white p-0 border-start ">
                            <div className="ContentWrapper p-sm-5 p-2 mt-sm-5 mt-2 thankyou">
                                <div className="card shadow">
                                    <div className="card-header bg-primary">
                                        <h2 className='card-title text-white fw-bolder text-center'>
                                            {this.state.data ? 'Thank You!!' : this.state.systemError ? 'Payment Failed!!' : 'Warning!!'}

                                        </h2>
                                    </div>                                
                                   <div><p className='m-2'>{this.state.errorMessage ? this.state.errorMessage : systemError }</p></div>
                                </div>
                            </div>
                        </div>}
                        <BottomLogo
                            className="my-2 text-center bg-opacity-10 d-block d-sm-none"
                        />
                    </div>
                </div>
            </Aux>
        )
    }
}

failedComponent.propTypes = {
    errorMessage: PropTypes.string,
    hasError: PropTypes.bool,
    eventRequest: PropTypes.func,
    dispatch: PropTypes.func,
    reqGetPartnerDetails: PropTypes.func, 
    hasErrorPartner: PropTypes.bool, 
    partnerDetails: PropTypes.object, 
    hasErrorMessage: PropTypes.bool,
    history: PropTypes.object, 
    reqGetPasses: PropTypes.func,
    passes: PropTypes.object,
    reqGetUserPasses : PropTypes.func,
    getGaragePrice  : PropTypes.func,
    reqMakePayment : PropTypes.func,
    paymentRes : PropTypes.object,
    userPasses : PropTypes.array,
    getGaragePriceRes  : PropTypes.object,
    reqGetPrice : PropTypes.func,
    isFetching  : PropTypes.bool
    
}

failedComponent.defaultProps = {
    errorMessage: '',
    hasError: false,
    eventRequest: noop,
    dispatch: noop,
    reqGetPartnerDetails: noop,
    hasErrorPartner: false, 
    partnerDetails: EMPTY_OBJECT, 
    hasErrorMessage: false,
    history: EMPTY_OBJECT,
    reqGetPasses: noop,
    passes:EMPTY_OBJECT,
    reqGetUserPasses : noop,
    getGaragePrice : noop,
    reqMakePayment : noop,
    paymentRes : EMPTY_OBJECT,
    userPasses : noop,
    getGaragePriceRes : EMPTY_OBJECT,
    reqGetPrice : noop,
    isFetching : false,
}

const mapStateToProps = ({ PassesReducer, PartnerReducer }) => {
    const { passes, isFetching, paymentRes, getGaragePriceRes, hasError, errorMessage, userPasses } = PassesReducer
    const { memberCheck, partnerDetails, eventRes } = PartnerReducer
    return {
        passes,
        userPasses,
        isFetching,
        paymentRes,
        getGaragePriceRes,
        hasError,
        errorMessage,
        memberCheck,
        eventRes,
        partnerDetails
    }
}
const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        eventRequest: (data) => dispatch(actionCreators.logEvent(data)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(failedComponent)
