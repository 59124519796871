import React from 'react'

function Contact() {
	return (
		<div className="empty-page">
			<h1 className='text-center mt-3'>Contact</h1>
		</div>
	)
}

export default Contact
