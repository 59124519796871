import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import './ForgotPassword.scss'

function PasswordSuccess(props) {
    const { isCreate, errorMessage } = props
    return (
        <div className='container  mt-5 pt-5 ' style={{ minHeight: 'calc(100vh - 130px)' }} >

            <div className={`p-4 shadow-sm rounded-2 bg-white text-center bg-opacity-10 border ${errorMessage ? 'bg-danger border-danger' : 'bg-success border-success'}`}>

                <i className={`fa fa-4x  mb-3 fa-${errorMessage ? 'times-circle' : 'check-circle'} text-${errorMessage ? 'danger' : 'success'}  `}></i>
                <h3 className={`fw-bold text-captalized ${errorMessage ? 'text-danger' : 'text-success'}  `}>{errorMessage ? 'Error!' : 'Success'}</h3>
                {errorMessage != '' ? <p>{errorMessage}</p> :
                    <p>{`Your password has been ${(isCreate == '1') ? 'created' : 'updated'} successfully. You can now log in with your new password.`}</p>
                }
            </div>

        </div>
    )
}
PasswordSuccess.propTypes = {
    dispatch: PropTypes.func,
    notify: PropTypes.func,
    loader: PropTypes.func,
    isCreate: PropTypes.string,
    errorMessage: PropTypes.string
}

PasswordSuccess.defaulProps = {
    dispatch: noop,
    notify: noop,
    loader: noop,
    isCreate: '0',
    errorMessage: ''
}

function mapStateToProps({ resetPasswordReducer }) {
    return {
        loader: resetPasswordReducer?.loader
    }
}
export default connect(mapStateToProps)(PasswordSuccess)