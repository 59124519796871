const CONSTANTS = {
	// Login request 
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_FAILURE: 'LOGIN_FAILURE',

	// Diamond Parking basic informatio page (facility)
	GET_FACILITY_REQUEST: 'GET_FACILITY_REQUEST',
	GET_FACILITY_SUCCESS: 'GET_FACILITY__SUCCESS',
	GET_FACILITY_FAILURE: 'GET_FACILITY_FAILURE',

	// Diamond Parking basic informatio page (facility)
	BOOKING_REQUEST: 'BOOKING_REQUEST',
	BOOKING_SUCCESS: 'BOOKING__SUCCESS',
	BOOKING_FAILURE: 'BOOKING_FAILURE',

	// RATE LIST
	GET_PERMIT_RATE_LIST_REQUEST: 'GET_PERMIT_RATE_LIST_REQUEST',
	GET_PERMIT_RATE_LIST_SUCCESS: 'GET_PERMIT_RATE_LIST_SUCCESS',
	GET_PERMIT_RATE_LIST_FAILURE: 'GET_PERMIT_RATE_LIST_FAILURE',

	// PRO RATE LIST
	GET_PRO_RATE_LIST_REQUEST: 'GET_PRO_RATE_LIST_REQUEST',
	GET_PRO_RATE_LIST_SUCCESS: 'GET_PRO_RATE_LIST_SUCCESS',
	GET_PRO_RATE_LIST_FAILURE: 'GET_PRO_RATE_LIST_FAILURE',


	// permit list constant 
	GET_PERMIT_LIST_REQUEST: 'GET_PERMIT_LIST_REQUEST',
	GET_PERMIT_LIST_SUCCESS: 'GET_PERMIT_LIST_SUCCESS',
	GET_PERMIT_LIST_FAILURE: 'GET_PERMIT_LIST_FAILURE',

	// permit request list constant 
	GET_PERMIT_REQUEST_LIST_REQUEST: 'GET_PERMIT_REQUEST_LIST_REQUEST',
	GET_PERMIT_REQUEST_LIST_SUCCESS: 'GET_PERMIT_REQUEST_LIST_SUCCESS',
	GET_PERMIT_REQUEST_LIST_FAILURE: 'GET_PERMIT_REQUEST_LIST_FAILURE',

	// get vehicle list constant
	GET_VEHICLE_LIST_REQUEST: 'GET_VEHICLE_LIST_REQUEST',
	GET_VEHICLE_LIST_SUCCESS: 'GET_VEHICLE_LIST_SUCCESS',
	GET_VEHICLE_LIST_FAILURE: 'GET_VEHICLE_LIST_FAILURE',

	// get check in list constant
	GET_CHECK_IN_LIST_REQUEST: 'GET_CHECK_IN_LIST_REQUEST',
	GET_CHECK_IN_LIST_SUCCESS: 'GET_CHECK_IN_LIST_SUCCESS',
	GET_CHECK_IN_LIST_FAILURE: 'GET_CHECK_IN_LIST_FAILURE',

	//get vehicle Model list
	GET_VEHICLE_MODEL_REQUEST : 'GET_VEHICLE_MODEL_REQUEST',
	GET_VEHICLE_MODEL_SUCCESS : 'GET_VEHICLE_MODEL_SUCCESS',
	GET_VEHICLE_MODEL_FAILURE : 'GET_VEHICLE_MODEL_FAILURE',

	// post vehicle list constant
	POST_VEHICLE_LIST_REQUEST: 'POST_VEHICLE_LIST_REQUEST',
	POST_VEHICLE_LIST_SUCCESS: 'POST_VEHICLE_LIST_SUCCESS',
	POST_VEHICLE_LIST_FAILURE: 'POST_VEHICLE_LIST_FAILURE',

	// Delete vehicle list constant
	DELETE_VEHICLE_ITEM_REQUEST: 'DELETE_VEHICLE_ITEM_REQUEST',
	DELETE_VEHICLE_ITEM_SUCCESS: 'DELETE_VEHICLE_ITEM_SUCCESS',
	DELETE_VEHICLE_ITEM_FAILURE: 'DELETE_VEHICLE_ITEM_FAILURE',
	// UPDATE WAITING LIST
	UPDATE_WAITING_LIST_REQUEST: 'UPDATE_WAITING_LIST_REQUEST',
	UPDATE_WAITING_LIST_SUCCESS: 'UPDATE_WAITING_LIST_SUCCESS',
	UPDATE_WAITING_LIST_FAILURE: 'UPDATE_WAITING_LIST_FAILURE',


	// card list constant 
	GET_CARD_LIST_REQUEST: 'GET_CARD_LIST_REQUEST',
	GET_CARD_LIST_SUCCESS: 'GET_CARD_LIST_SUCCESS',
	GET_CARD_LIST_FAILURE: 'GET_CARD_LIST_FAILURE',

	CREATE_CARD_REQUEST: 'CREATE_CARD_REQUEST',
	CREATE_CARD_SUCCESS: 'CREATE_CARD_SUCCESS',
	CREATE_CARD_FAILURE: 'CREATE_CARD_FAILURE',

	UPDATE_CARD_REQUEST: 'UPDATE_CARD_REQUEST',
	UPDATE_CARD_SUCCESS: 'UPDATE_CARD_SUCCESS',
	UPDATE_CARD_FAILURE: 'UPDATE_CARD_FAILURE',

	CARD_DELETE_REQUEST: 'CARD_DELETE_REQUEST',
	CARD_DELETE_SUCCESS: 'CARD_DELETE_SUCCESS',
	CARD_DELETE_FAILED: 'CARD_DELETE_FAILED',
	//  update user profile 
	UPDATE_USER_PROFILE_REQUEST: 'UPDATE_USER_PROFILE_REQUEST',
	UPDATE_USER_PROFILE_SUCCESS: 'UPDATE_USER_PROFILE_SUCCESS',
	UPDATE_USER_PROFILE_FAILURE: 'UPDATE_USER_PROFILE_FAILURE',

	//  get user profile 
	GET_USER_PROFILE_REQUEST: 'GET_USER_PROFILE_REQUEST',
	GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
	GET_USER_PROFILE_FAILURE: 'GET_USER_PROFILE_FAILURE',

	// MODAL CONSTANTS
	OPEN_MODAL: 'OPEN_MODAL',

	//  get Pass List
	GET_PASS_LIST_REQUEST: 'GET_PASS_LIST_REQUEST',
	GET_PASS_LIST_SUCCESS: 'GET_PASS_LIST_SUCCESS',
	GET_PASS_LIST_FAILURE: 'GET_PASS_LIST_FAILURE',

	// Reset Password
	POST_RESET_PASSWORD_REQUEST: 'POST_RESET_PASSWORD_REQUEST',
	POST_RESET_PASSWORD_SUCCESS: 'POST_RESET_PASSWORD_SUCCESS',
	POST_RESET_PASSWORD_FAILURE: 'POST_RESET_PASSWORD_FAILURE',

	//passList constants
	FACILITY_PASS_LIST_REQUEST: 'FACILITY_PASS_LIST_REQUEST',
	FACILITY_PASS_LIST_SUCCESS: 'FACILITY_PASS_LIST_SUCCESS',
	FACILITY_PASS_LIST_FAILED: 'FACILITY_PASS_LIST_FAILED',

	// Diamond Parking basic informatio page (facility)
	PASS_BOOKING_REQUEST: 'PASS_BOOKING_REQUEST',
	PASS_BOOKING_SUCCESS: 'PASS_BOOKING__SUCCESS',
	PASS_BOOKING_FAILURE: 'PASS_BOOKING_FAILURE',

	//PAYMENT ACTION CONSTANTS
	PAYMENT_INITIAL_REQUEST: 'PAYMENT_INITIAL_REQUEST',
	PAYMENT_INITIAL_SUCCESS: 'PAYMENT_INITIAL_SUCCESS',
	PAYMENT_INITIAL_FAILURE: 'PAYMENT_INITIAL_FAILURE',


	//booking before payment ACTION CONSTANTS
	BEFORE_PAYMENT_INITIAL_REQUEST: 'BEFORE_PAYMENT_INITIAL_REQUEST',
	BEFORE_PAYMENT_INITIAL_SUCCESS: 'BEFORE_PAYMENT_INITIAL_SUCCESS',
	BEFORE_PAYMENT_INITIAL_FAILURE: 'BEFORE_PAYMENT_INITIAL_FAILURE',

	//booking after payment ACTION CONSTANTS
	AFTER_PAYMENT_INITIAL_REQUEST: 'AFTER_PAYMENT_INITIAL_REQUEST',
	AFTER_PAYMENT_INITIAL_SUCCESS: 'AFTER_PAYMENT_INITIAL_SUCCESS',
	AFTER_PAYMENT_INITIAL_FAILURE: 'AFTER_PAYMENT_INITIAL_FAILURE',
	// Pass purchage

	//PAYMENT ACTION CONSTANTS
	PASS_PAYMENT_INITIAL_REQUEST: 'PASS_PAYMENT_INITIAL_REQUEST',
	PASS_PAYMENT_INITIAL_SUCCESS: 'PASS_PAYMENT_INITIAL_SUCCESS',
	PASS_PAYMENT_INITIAL_FAILURE: 'PASS_PAYMENT_INITIAL_FAILURE',


	//booking before payment ACTION CONSTANTS
	BEFORE_PASS_PAYMENT_INITIAL_REQUEST: 'BEFORE_PASS_PAYMENT_INITIAL_REQUEST',
	BEFORE_PASS_PAYMENT_INITIAL_SUCCESS: 'BEFORE_PASS_PAYMENT_INITIAL_SUCCESS',
	BEFORE_PASS_PAYMENT_INITIAL_FAILURE: 'BEFORE_PASS_PAYMENT_INITIAL_FAILURE',

	//booking after payment ACTION CONSTANTS
	AFTER_PASS_PAYMENT_INITIAL_REQUEST: 'AFTER_PASS_PAYMENT_INITIAL_REQUEST',
	AFTER_PASS_PAYMENT_INITIAL_SUCCESS: 'AFTER_PASS_PAYMENT_INITIAL_SUCCESS',
	AFTER_PASS_PAYMENT_INITIAL_FAILURE: 'AFTER_PAYMENT_INITIAL_FAILURE',


	//  get user Data 
	GET_USER_DATA_REQUEST: 'GET_USER_DATA_REQUEST',
	GET_USER_DATA_SUCCESS: 'GET_USER_DATA_SUCCESS',
	GET_USER_DATA_FAILURE: 'GET_USER_DATA_FAILURE',


	// booking history

	GET_BOOKING_HISTORY_REQUEST: 'GET_BOOKING_HISTORY_REQUEST',
	GET_BOOKING_HISTORY_SUCCESS: 'GET_BOOKING_HISTORY_SUCCESS',
	GET_BOOKING_HISTORY_FAILURE: 'GET_BOOKING_HISTORY_FAILURE',

	// get ticket details
	GET_TICKET_LIST_REQUEST: 'GET_TICKET_LIST_REQUEST',
	GET_TICKET_LIST_SUCCESS: 'GET_TICKET_LIST_SUCCESS',
	GET_TICKET_LIST_FAILURE: 'GET_TICKET_LIST_FAILURE',

	// get ticket details
	GET_TICKET_DETAILS_REQUEST: 'GET_TICKET_DETAILS_REQUEST',
	GET_TICKET_DETAILS_SUCCESS: 'GET_TICKET_DETAILS_SUCCESS',
	GET_TICKET_DETAILS_FAILURE: 'GET_TICKET_DETAILS_FAILURE',

	// download ticket request 
	DOWNLOAD_TICKET_REQUEST: 'DOWNLOAD_TICKET_REQUEST',
	DOWNLOAD_TICKET_SUCCESS: 'DOWNLOAD_TICKET_SUCCESS',
	DOWNLOAD_TICKET_FAILURE: 'DOWNLOAD_TICKET_FAILURE',

	// get facity
	GET_FACILTY_REQUEST: 'GET_FACILTY_REQUEST',
	GET_FACILTY_SUCCESS: 'GET_FACILTY_SUCCESS',
	GET_FACILTY_FAILURE: 'GET_FACILTY_FAILURE',

	// payment Card Post
	POST_CARD_PAYMENT_REQUEST: 'POST_CARD_PAYMENT_REQUEST',
	POST_CARD_PAYMENT_SUCCESS: 'POST_CARD_PAYMENT_SUCCESS',
	POST_CARD_PAYMENT_FAILURE: 'POST_CARD_PAYMENT_FAILURE',

	END_PARKING_REQUEST: 'END_PARKING_REQUEST',
	END_PARKING_SUCCESS: 'END_PARKING_SUCCESS',
	END_PARKING_FAILURE: 'END_PARKING_FAILURE',


	EXTEND_CARD_DETAILS_REQUEST: 'EXTEND_CARD_DETAILS_REQUEST',
	EXTEND_CARD_DETAILS_SUCCESS: 'EXTEND_CARD_DETAILS_SUCCESS',
	EXTEND_CARD_DETAILS_FAILURE: 'EXTEND_CARD_DETAILS_FAILURE',

	EXTEND_CARD_HOUR_REQUEST: 'EXTEND_CARD_HOUR_REQUEST',
	EXTEND_CARD_HOUR_SUCCESS: 'EXTEND_CARD_HOUR_SUCCESS',
	EXTEND_CARD_HOUR_FAILURE: 'EXTEND_CARD_HOUR_FAILURE',

	// for sing-up
	SINGUP_REQUEST: 'SINGUP_REQUEST',
	SINGUP_SUCCESS: 'SINGUP_SUCCESS',
	SINGUP_FAILURE: 'SINGUP_FAILURE',

	// PASS DETAILS
	PASS_DETAILS_REQUEST: 'PASS_DETAILS_REQUEST',
	PASS_DETAILS_SUCCESS: 'PASS_DETAILS_SUCCESS',
	PASS_DETAILS_FAILURE: 'PASS_DETAILS_FAILURE',

	// PERMIT DETAILS
	PERMIT_DETAILS_REQUEST: 'PERMIT_DETAILS_REQUEST',
	PERMIT_DETAILS_SUCCESS: 'PERMIT_DETAILS_SUCCESS',
	PERMIT_DETAILS_FAILURE: 'PERMIT_DETAILS_FAILURE',

	// promoCode request 
	EXTEND_TICKET_LIST_REQUEST: 'EXTEND_TICKET_LIST_REQUEST',
	EXTEND_TICKET_LIST_SUCCESS: 'EXTEND_TICKET_LIST_SUCCESS',
	EXTEND_TICKET_LIST_FAILURE: 'EXTEND_TICKET_LIST_FAILURE',

	// send email for forgot password request
	SEND_EMAIL_RESET_PASSWORD_REQUEST: 'SEND_EMAIL_RESET_PASSWORD_REQUEST',
	SEND_EMAIL_RESET_PASSWORD_SUCCESS: 'SEND_EMAIL_RESET_PASSWORD_SUCCESS',
	SEND_EMAIL_RESET_PASSWORD_FAILURE: 'SEND_EMAIL_RESET_PASSWORD_FAILURE',

	//booking permit token payment ACTION CONSTANTS
	PERMIT_TOKEN_PAYMENT_REQUEST: 'PERMIT_TOKEN_PAYMENT_REQUEST',
	PERMIT_TOKEN_PAYMENT_SUCCESS: 'PERMIT_TOKEN_PAYMENT_SUCCESS',
	PERMIT_TOKEN_PAYMENT_FAILURE: 'PERMIT_TOKEN_PAYMENT_FAILURE',


	//booking permit token payment ACTION CONSTANTS
	PASS_TOKEN_PAYMENT_REQUEST: 'PASS_TOKEN_PAYMENT_REQUEST',
	PASS_TOKEN_PAYMENT_SUCCESS: 'PASS_TOKEN_PAYMENT_SUCCESS',
	PASS_TOKEN_PAYMENT_FAILURE: 'PASS_TOKEN_PAYMENT_FAILURE',
	//book reservation 
	// user details request 
	USER_DETAILS_REQUEST: 'USER_DETAILS_REQUEST',
	USER_DETAILS_SUCCESS: 'USER_DETAILS_SUCCESS',
	USER_DETAILS_FAILURE: 'USER_DETAILS_FAILURE',
	// GET PARTNER DETAILS
	GET_PARTNER_DETAILS_BY_SLUG_REQUEST: 'GET_PARTNER_DETAILS_BY_SLUG_REQUEST',
	GET_PARTNER_DETAILS_BY_SLUG_SUCCESS: 'GET_PARTNER_DETAILS_BY_SLUG_SUCCESS',
	GET_PARTNER_DETAILS_BY_SLUG_FAILURE: 'GET_PARTNER_DETAILS_BY_SLUG_FAILURE',

	// GET PRICE RESERVATION
	GET_RESERVATION_PRICE_REQUEST: 'GET_RESERVATION_PRICE_REQUEST',
	GET_RESERVATION_PRICE_SUCCESS: 'GET_RESERVATION_PRICE_SUCCESS',
	GET_RESERVATION_PRICE_FAILURE: 'GET_RESERVATION_PRICE_FAILURE',

	// APPLY PROCODE
	GET_RESERVATION_PROMO_CODE_REQUEST: 'GET_RESERVATION_PROMO_CODE_REQUEST',
	GET_RESERVATION_PROMO_CODE_SUCCESS: 'GET_RESERVATION_PROMO_CODE_SUCCESS',
	GET_RESERVATION_PROMO_CODE_FAILURE: 'GET_RESERVATION_PROMO_CODE_FAILURE',

	// APPLY PROCODE
	SAVE_RESERVATION_REQUEST: 'SAVE_RESERVATION_REQUEST',
	SAVE_RESERVATION_SUCCESS: 'SAVE_RESERVATION_SUCCESS',
	SAVE_RESERVATION_FAILURE: 'SAVE_RESERVATION_FAILURE',

	// GET DOCUMENT TYPES
	GET_DOCUMENT_TYPES_REQUEST: 'GET_DOCUMENT_TYPES_REQUEST',
	GET_DOCUMENT_TYPES_SUCCESS: 'GET_DOCUMENT_TYPES_SUCCESS',
	GET_DOCUMENT_TYPES_FAILURE: 'GET_DOCUMENT_TYPES_FAILURE',


	// PERMIT REQUEST
	GET_REQUEST_PERMIT_REQUEST: 'GET_REQUEST_PERMIT_REQUEST',
	GET_REQUEST_PERMIT_SUCCESS: 'GET_REQUEST_PERMIT_SUCCESS',
	GET_REQUEST_PERMIT_FAILURE: 'GET_REQUEST_PERMIT_FAILURE',

	// PERMIT TYPE LIST
	GET_PERMIT_TYPE_LIST_REQUEST: 'GET_PERMIT_TYPE_LIST_REQUEST',
	GET_PERMIT_TYPE_LIST_SUCCESS: 'GET_PERMIT_TYPE_LIST_SUCCESS',
	GET_PERMIT_TYPE_LIST_FAILURE: 'GET_PERMIT_TYPE_LIST_FAILURE',

	// PERMIT TYPE LIST
	GET_PERMIT_QR_REQUEST: 'GET_PERMIT_QR_REQUEST',
	GET_PERMIT_QR_SUCCESS: 'GET_PERMIT_QR_SUCCESS',
	GET_PERMIT_QR_FAILURE: 'GET_PERMIT_QR_FAILURE',

	// RESET PASSWORD
	RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
	RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
	RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

	// RESET PASSWORD
	CANCEL_PERMIT_REQUEST: 'CANCEL_PERMIT_REQUEST',
	CANCEL_PERMIT_SUCCESS: 'CANCEL_PERMIT_SUCCESS',
	CANCEL_PERMIT_FAILURE: 'CANCEL_PERMIT_FAILURE',

	// EVENT LOG

	ADD_EVENT_LOG_REQUEST: 'ADD_EVENT_LOG_REQUEST',
	ADD_EVENT_LOG_SUCCESS: 'ADD_EVENT_LOG_SUCCESS',
	ADD_EVENT_LOG_FAILURE: 'ADD_EVENT_LOG_FAILURE',

	// Permit Renew api const
	PERMIT_RENEW_REQUEST : 'PERMIT_RENEW_REQUEST',
	PERMIT_RENEW_SUCCESS : 'PERMIT_RENEW_SUCCESS',
	PERMIT_RENEW_FAILURE : 'PERMIT_RENEW_FAILURE',

	INVOICE_PERMIT_LIST_REQUEST : 'INVOICE_PERMIT_LIST_REQUEST',
	INVOICE_PERMIT_LIST_SUCCESS : 'INVOICE_PERMIT_LIST_SUCCESS',
	INVOICE_PERMIT_LIST_FAILURE : 'INVOICE_PERMIT_LIST_FAILURE',

	DOWNLOAD_INVOICE_REQUEST : 'DOWNLOAD_INVOICE_REQUEST',
	DOWNLOAD_INVOICE_SUCCESS : 'DOWNLOAD_INVOICE_SUCCESS',
	DOWNLOAD_INVOICE_FAILURE : 'DOWNLOAD_INVOICE_FAILURE',

}
export default CONSTANTS
