export const GET_PASSES_REQUEST = 'GET_PASSES_REQUEST'
export const GET_PASSES_REQUEST_SUCCESS = 'GET_PASSES_REQUEST_SUCCESS'
export const GET_PASSES_REQUEST_FAIL = 'GET_PASSES_REQUEST_FAIL'
export const GET_USER_PASSES_REQUEST = 'GET_USER_PASSES_REQUEST'
export const GET_USER_PASSES_REQUEST_SUCCESS = 'GET_USER_PASSES_REQUEST_SUCCESS'
export const GET_USER_PASSES_REQUEST_FAIL = 'GET_USER_PASSES_REQUEST_FAIL'
export const GET_PAYMENT_REQUEST = 'GET_PAYMENT_REQUEST'
export const GET_PAYMENT_REQUEST_SUCCESS = 'GET_PAYMENT_REQUEST_SUCCESS'
export const GET_PAYMENT_REQUEST_FAIL = 'GET_PAYMENT_REQUEST_FAIL'
export const GET_PARTNER_DETAILS_REQUEST = 'GET_PARTNER_DETAILS_REQUEST'
export const GET_PARTNER_DETAILS_REQUEST_SUCCESS = 'GET_PARTNER_DETAILS_REQUEST_SUCCESS'
export const GET_PARTNER_DETAILS_REQUEST_FAIL = 'GET_PARTNER_DETAILS_REQUEST_FAIL'
export const GET_MEMBER_CHECK_REQUEST = 'GET_MEMBER_CHECK_REQUEST'
export const GET_MEMBER_CHECK_REQUEST_SUCCESS = 'GET_MEMBER_CHECK_REQUEST_SUCCESS'
export const GET_MEMBER_CHECK_REQUEST_FAIL = 'GET_MEMBER_CHECK_REQUEST_FAIL'
export const GET_FACILITY_PRICE_REQUEST = 'GET_FACILITY_PRICE_REQUEST'
export const GET_FACILITY_PRICE_REQUEST_SUCCESS = 'GET_FACILITY_PRICE_REQUEST_SUCCESS'
export const GET_FACILITY_PRICE_REQUEST_FAIL = 'GET_FACILITY_PRICE_REQUEST_FAIL'
export const POST_EVENT_LOG_REQUEST = 'POST_EVENT_LOG_REQUEST'
export const POST_EVENT_LOG_REQUEST_SUCCESS = 'POST_EVENT_LOG_REQUEST_SUCCESS'
export const POST_EVENT_LOG_REQUEST_FAIL = 'POST_EVENT_LOG_REQUEST_FAIL'

//PAYMENT ACTION CONSTANTS
export const PAYMENT_INITIAL_REQUEST = 'PAYMENT_INITIAL_REQUEST'
export const PAYMENT_INITIAL_SUCCESS = 'PAYMENT_INITIAL_SUCCESS'
export const PAYMENT_INITIAL_FAILURE = 'PAYMENT_INITIAL_FAILURE'
//PAYMENT ACTION CONSTANTS

//VALIDATION CHECK
export const CHECK_VALIDATION_REQUEST = 'CHECK_VALIDATION_REQUEST'
export const CHECK_VALIDATION_SUCCESS = 'CHECK_VALIDATION_SUCCESS'
export const CHECK_VALIDATION_FAILURE = 'CHECK_VALIDATION_FAILURE'
//VALIDATION CHECK