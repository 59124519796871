import React from 'react'

export const renderNoResults = (error) => {

    const message = error ? error : 'No data available'
    return (
      <div className='no-results text-center my-4'>
        <p className='mb-0 alert alert-danger'>{message}</p>
      </div>
    )
  }