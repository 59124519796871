import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import banner from '../../assets/img/hero-img.jpg'
import { EMPTY_OBJECT } from '../../constants/globalConstants'
import { errorMessage } from '../../constants/errorMessage'

function Header(props) {
    const { loggedInError, partnerDetails} = props
  return (
    <section className="hero position-relative py-5" style={{backgroundImage: `url(${partnerDetails  ? (partnerDetails?.brand_setting && partnerDetails?.brand_setting?.customerbannerurl && partnerDetails?.brand_setting?.customer_banner) ? partnerDetails?.brand_setting?.customerbannerurl : (partnerDetails?.partner_details?.brand_setting?.customer_banner && partnerDetails?.partner_details?.brand_setting?.customerbannerurl) ? partnerDetails?.partner_details?.brand_setting?.customerbannerurl : banner : banner})`}}>
        
            <div className="container ">
                <div className="row justify-content-md-end">
                {loggedInError && <div className="alert alert-danger">
                    <strong>Danger!</strong> {loggedInError}.
                </div>}
                    <div className="col-sm-6 me-4">
                    <div className="p-3 topBox text-white pt-5">
                        <h1>{partnerDetails  ? (partnerDetails?.brand_setting && partnerDetails?.brand_setting?.customer_banner_heading) ? partnerDetails?.brand_setting?.customer_banner_heading : partnerDetails?.partner_details?.brand_setting?.customer_banner_heading ? partnerDetails?.partner_details?.brand_setting?.customer_banner_heading : errorMessage?.customer_banner_heading : errorMessage?.customer_banner_heading}</h1>
                        <p>{partnerDetails  ? (partnerDetails?.brand_setting && partnerDetails?.brand_setting?.customer_banner_text) ? partnerDetails?.brand_setting?.customer_banner_text : partnerDetails?.partner_details?.brand_setting?.customer_banner_text ? partnerDetails?.partner_details?.brand_setting?.customer_banner_text : errorMessage?.customer_banner_text : errorMessage?.customer_banner_text}</p>
                    </div>
                        

                    </div>
                </div>
            </div>
        </section>
  )
}

Header.propTypes = {
	page: PropTypes.string,
	loggedInError: PropTypes.string,
    partnerDetails: PropTypes.object,
}

Header.defaulProps = {
	page: undefined,
	loggedInError: undefined,
    partnerDetails: EMPTY_OBJECT,
}

function mapStateToProps({ login, paymentReducer }) {
	return {
		isAuthenticated: login?.isAuthenticated,
		userData: login?.data,
		permitData: paymentReducer?.data
	}
}
export default connect(mapStateToProps)(Header)
