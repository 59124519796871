import CONSTANTS from '../constants'
import { getRequest } from '../../services'
import { APICONFIG } from '../config/apiConfig'


// Calls the API to get Booking History Data Initials
export const getDataBookingHistory = (page = '', search = '', sortBy = '', sortByOrder = '') => (dispatch) => {
    dispatch({ type: CONSTANTS.GET_BOOKING_HISTORY_REQUEST })
    let url = `${APICONFIG.getBookingHistroyDataUrl}/page/${page}?`
    let endpoint = `${url}`
    if ((sortBy && sortBy !== '') && (search && search !== '')) {
        endpoint = `${url}search=${search}&sort=${sortBy}&sortBy=${sortByOrder}`
    } else if ((sortBy && sortBy !== '')) {
        endpoint = `${url}sort=${sortBy}&sortBy=${sortByOrder}`
    } else if ((search && search !== '')) {
        endpoint = `${url}search=${search}`
    } else {
        endpoint = `${url}`
    }
    return getRequest(endpoint).then((res) => {
        dispatch({ type: CONSTANTS.GET_BOOKING_HISTORY_SUCCESS, data: res.data })
        return res.data
    }).catch(() => {
        dispatch({ type: CONSTANTS.GET_BOOKING_HISTORY_FAILURE })
    })
}