import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { EMPTY_ARRAY, EMPTY_OBJECT, vehicleArray } from '../../../constants/globalConstants'
import { DeleteVehicleList, getVehicleDataList, postVehicleList , getVehicleModelList} from '../../../redux/actions/VehicleListAction'
import { getUserProfile } from '../../../redux/actions/login'
import VehicleModal from '../../../components/global/VehicleModal'
import { Circles } from 'react-loader-spinner'
import ConfermationModal from '../../../components/global/ConfermationModal'
import { renderNoResults } from '../../../components/global/NoDataFound'
import { errorMessage } from '../../../constants/errorMessage'

function VehicleDetails(props) {
    const { dispatch, loader, notify, setVehicleCount } = props

    const [vehicleData, setVehicleData] = useState(vehicleArray)
    const [getVehicleList, setGetVehicleList] = useState(EMPTY_ARRAY)
    const [isModalOpen, setModal] = useState(false)
    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)
    const [vehicleDetailsList , setVehicleDetailsList] = useState({})
    const [formError, setFormError] = useState(false)

    // for confermation pop-up

    const [confermationModal, setConfermationModal] = useState(false)
    const [deleteItem, setDeleteItem] = useState(EMPTY_ARRAY)
 
    useEffect(() => {
        dispatch(getVehicleDataList()).then((res) => setGetVehicleList(res?.data))
        dispatch(getVehicleModelList()).then((res) =>{
            setVehicleDetailsList(res)
        })
    }, [EMPTY_ARRAY])


    const handleSubmitVehicle = (e) => {
        e.preventDefault()
        let newPyalod = []
        vehicleData.map((item) =>{
           newPyalod.push({'license_plate' : item?.license_plate , 'make' :  item?.othermake ? item?.maketext  : item?.make , 'make_id' : item?.make_id, 'model' : (item?.othermake || item?.othermodel) ? item?.modeltext :  item?.model, 'color' : item?.color ,'state_id' : item.state_id})
        })
        // const newPyalod = vehicleData?.filter((item) => (item?.license_plate === '') ? null : item)
        const finalPayload = {
            vehicleList: newPyalod
        }
        if ((newPyalod?.length > 0 && newPyalod?.[0]?.license_plate != '' && newPyalod?.[0]?.license_plate?.length >= 2 && newPyalod?.[0]?.license_plate?.length <= 10) && !submitButtonDisable) {
            setSubmitButtonDisable(true)
            dispatch(postVehicleList(finalPayload)).then((res) => {
                if (res?.status === 201 && res.data != null) {
                    notify('success', 'Vehicle Added Successfully')
                    vehicleArray?.map((item) => {
                        if (item) {
                            item['license_plate'] = ''
                            item['make'] = ''
                            item['make_id']= ''
                            item['model'] = ''
                            item['color'] = ''
                            item['state_id'] = ''
                            item['maketext'] = ''
                            item['modeltext'] = ''
                            item['othermake'] = false
                            item['othermodel']=false
                            
                        }
                    })
                    setModal(false)
                    setVehicleData(vehicleArray)
                    dispatch(getVehicleDataList()).then((res) => {
                        setGetVehicleList(res?.data)
                        setVehicleCount(res?.data)
                    })
                    dispatch(getUserProfile())
                    setSubmitButtonDisable(false)
                } else {
                    notify('error', res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
                    setSubmitButtonDisable(false)
                }
            }).catch((errors) => {
                notify('error', errors?.message ? errors?.message : errorMessage?.somethingWentWrong)
                setSubmitButtonDisable(false)
            })
        }else{
            setFormError(true)
        }
    }

    const handleVehicleDelete = (e, item) => {
        e.preventDefault()
        setDeleteItem(item)
        setConfermationModal(true)
    }

    // Delete Vehicle item
    const handleDelete = () => {
        if (deleteItem?.id) {
            dispatch(DeleteVehicleList(deleteItem?.id)).then((res) => {
                if (res?.status === 202 && res?.data !== null) {
                    notify('success', 'Vehicle Deleted')
                    dispatch(getUserProfile())
                    return dispatch(getVehicleDataList()).then((res) => {
                        setGetVehicleList(res?.data)
                        setVehicleCount(res?.data)
                    })
                } else {
                    notify('error', res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
                }
            }).catch((errors) => {
                notify('error', errors?.message ? errors?.message : errorMessage?.somethingWentWrong)
            })
            setConfermationModal(false)
        }
    }

    const handleModalOpen = () => {
        if (getVehicleList?.length < 5) {
            setModal(true)
        }
    }

    const setModalOpen  = (isopen) =>{
        setFormError(false)
         vehicleArray?.map((item) => {
             if (item) {
                 item['license_plate'] = ''
                 item['make'] = ''
                 item['make_id'] = ''
                 item['model'] = ''
                 item['color'] = ''
                 item['state_id'] = ''
                 item['maketext'] = ''
                 item['modeltext'] = ''
                 item['othermake'] = false
                 item['othermodel']=false

             }
         })
         setModal(isopen)
         setVehicleData(vehicleArray)
}

    return (
        <div>
            <div className="d-flex justify-content-between mb-4">
                {/* <h4 className="fw-bold">Vehicle Details </h4> <button href='#' className="btn btn-success ms-auto" data-bs-toggle="modal" data-bs-target="#vehicleModal"><i className="fa fa-plus"></i> Add</button> */}
                <h4 className="fw-bold">Vehicle Details </h4>
                <button className="btn btn-primary ms-auto" onClick={handleModalOpen}
                    disabled={(getVehicleList?.length >= 5 || loader) ? true : false}
                ><i className="fa fa-plus"></i> Add</button>
            </div>
            {loader ? <span className='ms-2 d-flex justify-content-center'><Circles
                height="50"
                width="50"
                color="var(--bs-primary)"
                ariaLabel="circles-loading"
                visible={true}
            /></span> : <div className="card mb-3">
                <div className="card-header">
                    <b>Vehicle List</b>
                </div>
                {/* <div className="card-body">
                    {getVehicleList?.length > 0 ?
                        <div className='row g-0 align-items-center'>
                            <div className="col-sm-4 mb-3">
                                <p className="mb-0 ms-3"><b>License Plate</b></p>
                            </div>
                            <div className="col-sm-2 mb-3 text-start">
                                <p className="mb-0"><b>Color</b></p>
                            </div>
                            <div className="col-sm-2 mb-3 text-start">
                                <p className="mb-0"><b>Make</b></p>
                            </div>
                            <div className="col-sm-2 mb-3 text-start">
                                <p className="mb-0"><b>Model</b></p>
                            </div>
                            <div className="col-sm-2 text-end mb-3">
                                <b>Action</b>
                            </div>

                            {getVehicleList?.length > 0 ? getVehicleList?.map((getListDataItem, getListDataIndex) => {
                                return <div key={getListDataIndex} className="row g-0 mb-1 align-items-center">
                                    <div className="col-sm-4">
                                        <p className="mb-0 ms-3">{getListDataItem?.license_plate_number}</p>
                                    </div>
                                    <div className="col-sm-2 text-start">
                                        <p className="mb-0">{getListDataItem?.color}</p>
                                    </div>
                                    <div className="col-sm-2 text-start">
                                        <p className="mb-0">{getListDataItem?.make}</p>
                                    </div>
                                    <div className="col-sm-2 text-start">
                                        <p className="mb-0">{getListDataItem?.model}</p>
                                    </div>
                                    <div className="col-sm-2 text-end">
                                        <button className="btn btn-outline-danger px-3 btn-sm" onClick={(e) => handleVehicleDelete(e, getListDataItem)}><i className="fa  fa-trash"></i></button>
                                    </div>
                                </div>
                            }) : renderNoResults()}
                        </div> : renderNoResults()}

                </div> */}

                <div className="card-body table-responsive">
                    {getVehicleList?.length > 0 ?
                        <table className="table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col" className='tableLicensePlate'>License Plate</th>
                                    <th scope="col">Color</th>
                                    <th scope="col">Make</th>
                                    <th scope="col">Model</th>
                                    <th scope='col'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getVehicleList?.length > 0 ? getVehicleList?.map((getListDataItem, getListDataIndex) => {
                                    return <tr key={getListDataIndex}>
                                        <td>{getListDataItem?.license_plate_number}</td>
                                        <td>{getListDataItem?.color}</td>
                                        <td>{getListDataItem?.make}</td>
                                        <td>{getListDataItem?.model}</td>
                                        {getVehicleList.length > 1 ? <td><button className="btn btn-outline-danger px-3 btn-sm" onClick={(e) => handleVehicleDelete(e, getListDataItem)}><i className="fa  fa-trash"></i></button></td> : null}
                                    </tr>
                                }) : renderNoResults()}
                            </tbody>
                        </table> : renderNoResults()}
                </div>
            </div>}

            <VehicleModal isModalOpen={isModalOpen} setModal={setModalOpen} modalName='Add Vehicle' submitButtonDisable={submitButtonDisable} formError={formError} setFormError={setFormError} vehicleData={vehicleData} setVehicleData={setVehicleData} handleSubmitVehicleData={handleSubmitVehicle} getVehicleList={getVehicleList} vehicleInputDataLists={vehicleDetailsList} dispatch={dispatch} />
            <ConfermationModal setConfermationModal={setConfermationModal} confermationModal={confermationModal} confermationHeading='Confirm Delete' confermationMessage='Are you sure you want to delete?' buttonLable='Delete' handleDelete={handleDelete} />
        </div>
    )
}

VehicleDetails.propTypes = {
    dispatch: PropTypes.func,
    data: PropTypes.object,
    loader: PropTypes.bool,
    setUserData: PropTypes.func,
    notify: PropTypes.func,
    setVehicleCount: PropTypes.func,
    vehicleCount: PropTypes.array,
}

VehicleDetails.defaulProps = {
    dispatch: noop,
    data: EMPTY_OBJECT,
    loader: PropTypes.bool,
    setUserData: PropTypes.func,
    notify: noop,
    setVehicleCount: noop,
    vehicleCount: EMPTY_ARRAY,

}

function mapStateToProps({ vehicleListReducer }) {
    return {
        loader: vehicleListReducer?.loader,
    }
}
export default connect(mapStateToProps)(VehicleDetails)