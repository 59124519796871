import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { useEffect, useState } from 'react'
import { Circles } from 'react-loader-spinner'
import { Form, Image, InputGroup } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { getErrorMessage, validateEmail } from '../../utils/helper'
import emailImage from '../../assets/img/email.png'
import { getConfigDetails } from '../../services/config'
import { sendEmailResetPassword } from '../../redux/actions/login'

function SendEmailResetPassword(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const dispatch = useDispatch()
    const partner = window.location.toString().split('/')

    const { notify } = props
    useEffect(() => {
        setIsLoading(false)
    }, [])

    const handleInputChange = (e) => {
        const { value } = e.target
        setEmail(value)
        setErrorMessage('')
    }

    const handleSubmit = () => {
        partner.pop()
        if (email == '') {
            setErrorMessage('Please enter Email Id')
            return
        } else if (!validateEmail(email)) {
            setErrorMessage('Please enter correct Email Id')
            return
        } else {
            setIsLoading(true)
            let formData = {
                email: email,
                client_secret: getConfigDetails().CLIENT_SECRET,
                base_url: partner.join('/'),
            }
            dispatch(sendEmailResetPassword(formData)).then((res) => {
                setIsLoading(false)
                if (res?.status == '201') {
                    notify('success', res?.data?.message)
                } else {
                    notify('error', getErrorMessage(res))
                }
            })
        }
    }

    return (
        <div className='container mt-5 pt-5 usersActive min-height-600'>
            <div className="row g-0 justify-content-center align-items-center">
                <div className='col-sm-6'>
                    <div className='p-3 bg-white mt-3 border shadow-sm '>
                        <div className='border-bottom'>
                            <h3>Forgot Password</h3>
                        </div>
                        <div className="text-center text-center m-3">
                            Enter the email address associated with your account below and we will send you a link to reset your password.
                        </div>
                        <form>
                            <InputGroup className='imageStyle border rounded'>
                                <InputGroup.Text><Image src={emailImage} width={23} height={23} /></InputGroup.Text>
                                <Form.Control
                                    type="email"
                                    name='email'
                                    aria-describedby="basic-addon1"
                                    className='form-control input-field border-0'
                                    id='email'
                                    maxLength='40'
                                    minLength='1'
                                    placeholder="Enter Email Address"
                                    value={email}
                                    autoComplete='false'
                                    onChange={handleInputChange}
                                />
                            </InputGroup>
                            <span className='text-danger'>{errorMessage}</span>
                            <div className="form-group mt-4 col-3" >
                                <button
                                    type="button"
                                    className="btn btn-primary text-white w-100 "
                                    onClick={handleSubmit}
                                    disabled={isLoading}
                                ><div className='d-flex justify-content-center align-items-center'>
                                        <span className='me-2'>{'Submit'}</span> {
                                            isLoading ? <Circles
                                                height="20"
                                                width="20"
                                                color="#ffffff"
                                                ariaLabel="circles-loading"
                                                visible={true}
                                            /> : null}
                                    </div></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    )
}
SendEmailResetPassword.propTypes = {
    dispatch: PropTypes.func,
    notify: PropTypes.func,
}

SendEmailResetPassword.defaulProps = {
    dispatch: noop,
    notify: noop,
}

export default SendEmailResetPassword