import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { EMPTY_ARRAY,EMPTY_OBJECT ,saveCard} from '../../../constants/globalConstants'
import { getPermitDataList, getPermitQR ,PermitRenewData} from '../../../redux/actions/PermitListAction'
import { Circles } from 'react-loader-spinner'
import { renderNoResults } from '../../../components/global/NoDataFound'
// import { staticStrings } from '../../../constants/staticStrings'
import { errorMessage } from '../../../constants/errorMessage'
import moment from 'moment'
import { perkingParkingTime,isPermissionExist ,sortPortalPermissionByKey,encryptionHandler,isLoggedIn,validExpiryDate} from '../../../utils/helper'
import { CancelPermit } from '../../../redux/actions/PermitRequestAction'
import AddCardPaymentModal from '../../../components/global/addCardPaymentModal'
import { getCardDataList } from '../../../redux/actions/cardDetailsAction'
// import { errorMessages } from '../../../constants/errorMessages'
// import { Link } from 'react-router-dom'

// let moment = require('moment')

function PermitAccount(props) {
    const { dispatch, loader, notify,partnerDetails } = props
    const [permitListData, setPermitListData] = useState(EMPTY_ARRAY)
    // const [userData, setUserData] = useState(EMPTY_OBJECT)
    const [displayVehicle, setDisplayVehicle] = useState(false)
    const [downloadQR, setDownloadQR] = useState(false)
    const [displayError, setDisplayError] = useState('')
    const [accessPermissions, setAccessPermissions] = useState('')
    const [permit_no, setPermitNo] = useState(EMPTY_OBJECT)
    const [isModalOpen, setModal] = useState(false)
    const [cardExpiryMessage, setCardExpiryMessage] = useState('')

    //new

    const [expiry, setExpiry] = useState()

    const [card, setCard] = useState()

    const [payload, setPayload] = useState(saveCard)

    const [isLoading, setIsLoading] = useState(false)

    const [errorMessagePay, setErrorMessagePay] = useState('')

    const [cardList, setCardList] = useState(EMPTY_ARRAY)

    const [isCardFormVisible, setCardFormVisible] = useState(false)

    const [error, setError] = useState(false)

    const [isConsent, setIsConsent] = useState(true)

    useEffect(() => {
        const getAccessPermissionData = sortPortalPermissionByKey(partnerDetails, 'Restricted Permissions')
        setAccessPermissions(getAccessPermissionData)
    }, [EMPTY_ARRAY, partnerDetails])


    useEffect(() => {
        // setTimeout(
        dispatch(getPermitDataList()).then((res) => {
            if (res?.data && res?.data != null) {
                // const userData = localStorage.getItem('userData')
                // const userDetails = JSON.parse(userData)
                // setUserData(userDetails)
                setPermitListData(res?.data)
                setIsConsent(res?.data?.user_consent == '1' ? true : false)
            }else{
                setDisplayError(res?.errors && res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
            }
        }).catch((err) => {
            setDisplayError(err?.errors && err?.errors?.message ? err?.errors?.message : errorMessage?.somethingWentWrong)
        })

        //     if (res) {
        //         // const userData = localStorage.getItem('userData')
        //         // const userDetails = JSON.parse(userData)
        //         // setUserData(userDetails)
        //         setPermitListData(res)
        //     }
        // })
        // , 500)

        if (isLoggedIn()) {
            setCardFormVisible(false)
            const facility_id = partnerDetails?.facilities?.[0]?.id
            dispatch(getCardDataList(facility_id)).then((res) => {
                if (res?.data) {
                    setCardList(res?.data?.payments)
                    // setIsLoading(false)
                }
            }).catch(() => {
                // setIsLoading(false)
            })
        } else {
            setCardFormVisible(true)
        }

    }, [EMPTY_ARRAY])

    const handleshowVehicle = () => {
        setDisplayVehicle(!displayVehicle)
    }

    const handlePaymentModalOpen = (item) => {
        setPermitNo(item)
        setModal(true)
    }

    const handleCardSelectChange = (e) => {

        const { value } = e.target

        const selectedCard = cardList?.filter((card) => card?.payment_profile_id == value)

        payload['payment_profile_id'] = selectedCard[0]?.payment_profile_id

        setPayload({ ...payload })

    }

    const handleExpiryChange = (value) => {

        let expiryDateCheck= validExpiryDate(value)
            if(value.length == 4  && !expiryDateCheck){
                setCardExpiryMessage('Enter a valid expire date')
            }else{
                setCardExpiryMessage('')
                setExpiry(value?.trim())
                payload['expiration_date'] = value?.trim()
                setPayload({ ...payload })
            }
         
        }



    // const handleExpiryChange = (value) => {

    //     setExpiry(value?.trim())

    //     payload['expiration_date'] = value?.trim()

    //     setPayload({ ...payload })

    // }



    const handleCardChange = (value) => {

        setCard(value?.trim())

        payload['card_number'] = value?.trim()

        setPayload({ ...payload })

    }

    const hanndleCancel = () => {
        setPayload({ card_number: '', expiration_date: '', payment_profile_id: '' })
        setCard('')
        setExpiry('')
        setModal(false)
    }

    const handleInputFieldsData = (e) => {

        const { name, value } = e.target

        setPayload({ ...payload, [name]: value })

    }

    


    const handleSubmit = () => {

        setError(true)

        setIsLoading(true)

        if (Object.values(payload)?.length > 0 && payload?.payment_profile_id || (payload?.card_number && payload?.card_number?.length === 16 && payload?.expiration_date && payload?.security_code && payload?.zip_code_on_card)) {

            payload.zip_code = payload?.zip_code_on_card

            let finalPayload

            setErrorMessagePay('')

            if (!payload?.payment_profile_id || payload?.payment_profile_id == '') {

                finalPayload = {

                    'nonce': encryptionHandler(payload),

                    'permit_number': permit_no?.account_number,

                    'user_consent': 1,

                }

            } else {

                finalPayload = {

                    'payment_profile_id': payload?.payment_profile_id,

                    'permit_number': permit_no?.account_number,

                    'user_consent': 0,

                }

            }

            dispatch(PermitRenewData(finalPayload)).then((res) => {

                if (res && res?.data !== null) {

                    notify('success', 'Payment is made successfully')

                    setPayload({ card_number: '', expiration_date: '', payment_profile_id: '' })

                    setCard('')

                    setExpiry('')

                    setModal(false)

                    setError(false)

                    setIsLoading(false)

                    dispatch(getPermitDataList()).then((res) => {

                        if (res?.data && res?.data != null) {

                            // const userData = localStorage.getItem('userData')

                            // const userDetails = JSON.parse(userData)

                            // setUserData(userDetails)

                            setPermitListData(res?.data)

                        }else{

                            setDisplayError(res?.errors && res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)

                        }

                    }).catch((err) => {

                        setDisplayError(err?.errors && err?.errors?.message ? err?.errors?.message : errorMessage?.somethingWentWrong)

                    })

                } else if (res?.data === null && res?.errors?.message) {

                    notify('error', res?.errors?.message ? res?.errors?.message : 'Something went wrong, Please try again!')

                    setIsLoading(false)

                }

            }).catch((err) => {

                // setErrorMessage(err?.errors?.message)

                notify('error', err?.errors?.message ? err?.errors?.message : 'Something went wrong, Please try again!')

            })

        } else {

            setIsLoading(false)

            setErrorMessagePay('')

        }

    }

    const downloadPermitQr = (id, permitAccount) => {
        setDownloadQR(true)
        if (!downloadQR) {
            dispatch(getPermitQR(id, permitAccount)).then((res) => {
                setDownloadQR(false)
                if (res?.status && res?.status == 500) {
                    notify('error', errorMessage?.somethingWentWrong)
                } else {
                    notify('success', 'File Downloaded')
                }
            }).catch((err) => {
                setDownloadQR(false)
                notify('error', err ? err?.message ? err?.message : errorMessage?.somethingWentWrong : errorMessage?.somethingWentWrong)
            })
        }

    }


    const cancelPermit = (data) => {
        setIsConsent(!isConsent)
        dispatch(CancelPermit(data.id)).then((res) => {
            notify('success', res.msg)
            dispatch(getPermitDataList()).then((res) => {
                if (res?.data && res?.data != null) {
                    // const userData = localStorage.getItem('userData')
                    // const userDetails = JSON.parse(userData)
                    // setUserData(userDetails)
                    setPermitListData(res?.data)
                }else{
                    setDisplayError(res?.errors && res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
                }
            }).catch((err) => {
                setDisplayError(err?.errors && err?.errors?.message ? err?.errors?.message : errorMessage?.somethingWentWrong)
            })
        }).catch((err) => {
            console.log(err)
        })

    }




    return (
        loader ? <span className='ms-2 d-flex justify-content-center'><Circles
            height="50"
            width="50"
            color="var(--bs-primary)"
            ariaLabel="circles-loading"
            visible={true}
        /></span> :
            <div>
                <div className="d-flex justify-content-between mb-4">
                    <h4 className="fw-bold">Permit Account </h4>
                    {/* <Link to='/'>
                    <button className="btn btn-success ms-auto"><i className="fa fa-plus"></i> Add</button>
                </Link> */}
                </div>
                {permitListData?.length > 0 ? <ul className="permitList">
                    {permitListData?.map((item, index) => {
                        if (item == null) return null
                        // const is_active = moment(item?.desired_end_date).format('MM/DD/YYYY') > moment().format('MM/DD/YYYY')
                        return (
                                                        
                            <li className={` listItem card rounded-3  border-opacity-25 ${item?.is_expired == 0 && item?.cancelled_at == null ? 'active' : 'inactive'}`} key={index}>

                                <div className={`card-header p-3  border-0   ${item?.is_expired == 0 && item?.cancelled_at == null ? 'bg-success ' : 'bg-danger '}`} >
                                    <div className='d-flex justify-content-between'>
                                        <h5 className='mb-0 fw-normal text-white '>{item?.permit_rate?.name} Plan</h5>
                                        <small className={item?.is_expired == 0 && item?.cancelled_at == null ? 'text-success' : 'text-white'}><i className='fa fa-circle '></i> {item?.is_expired == 0 && item?.cancelled_at == null ? 'Active' : item?.cancelled_at == null ? 'In-active' : 'Canceled'}</small>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-sm-12 mb-sm-0 mb-3'>
                                            {/* <h5 className='mb-0 fw-bold text-muted '>{item?.facility?.full_name ? `${item?.facility?.full_name} -` : ''} <span className='h-6 small'>{item?.permit_type_name}</span></h5> */}
                                            <div className='d-flex mb-3'>
                                                <div className='leftBlock'>
                                                <h4 className='mb-0 fw-bold '>{item?.facility?.full_name ? `${item?.facility?.full_name} -` : ''} <span className='h-6 small fw-light '>{item?.permit_type_name}</span></h4>

                                                <b className='text-muted'>Permit No: </b><span className='text-success fw-bold'>{item?.account_number}</span>
                                                </div>
                                                {(isPermissionExist(accessPermissions, 'Display Permit Pay Now') && item?.permit_renew_pay == '1' && item?.cancelled_at == null) ? <button className="btn btn-success ms-auto" onClick={() => handlePaymentModalOpen(item)}>Pay Now</button> : ''}

                                            </div>
                                         
                                            {/* {(isPermissionExist(accessPermissions, 'Display Permit Pay Now') && item?.permit_renew_pay == '0' && item?.cancelled_at == null) ? <button className="btn btn-success ms-auto" onClick={() => handlePaymentModalOpen(item)}>Pay Now</button> : ''} */}
                                          
                                            <div className="bg-light rounded-2 border p-3 mb-3">
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <b className='text-muted'>Start Date: </b><span> <i className='fa fa-calendar-alt text-secondary ms-3'></i> {moment(item?.desired_start_date).format('MM/DD/YYYY')} </span>
                                                    </div>
                                                    <div className='col-6'>
                                                        <b className='text-muted'>End Date: </b><span> <i className='fa fa-calendar-alt text-secondary ms-3'></i> {moment(item?.desired_end_date).format('MM/DD/YYYY')} </span>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                                {item?.permit_rate ? perkingParkingTime(item?.permit_rate, true) : null}
                                        


                                        </div>
                                        <div className='col-sm mb-sm-0 mb-3'>

                                            <table cellSpacing="0px" cellPadding="0">
                                                <tr>
                                                    <th className='text-muted d-none'>Driver License No.:</th><td className='px-2'></td> <td className='text-end'>{item?.license_number}</td>
                                                </tr>
                                                {item?.user?.resident_status ? <tr>
                                                    <th colSpan="3" className='text-muted d-none'><span className="badge bg-success"> {item?.user?.resident_status}</span></th>
                                                </tr> : <span className='text-muted d-none'>NA</span>}
                                            </table>
                                            {/* <p className='text-muted mb-0  '>{item?.user?.license_number}<br/>  <b>Driving License No.</b></p> */}
                                        </div>
                                        {item?.permit_vehicle && <div className='col-12 col-sm-12 order-sm-1 order-4 mt-sm-0'>
                                            <table className='table border  mt-3' >
                                                <thead className='border-bottom bg-light' >
                                                    {item?.permit_vehicle[0]?.vehicle?.license_plate_number && <th className='p-2 border-e' >License Plate:</th>
                                                    }
                                                    {item?.permit_vehicle[0]?.vehicle?.color && <th className='p-2 border-e' >Color:</th>
                                                    }
                                                    {item?.permit_vehicle[0]?.vehicle?.make && <th className='p-2 border-e' >Make:</th>
                                                    }
                                                    {item?.permit_vehicle[0]?.vehicle?.model && <th className='p-2 ' >Model:</th>
                                                    }
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    {item?.permit_vehicle[0]?.vehicle?.license_plate_number && <td className='p-2 border-e' >{item?.permit_vehicle[0]?.vehicle?.license_plate_number}</td>
                                                    }
                                                    {item?.permit_vehicle[0]?.vehicle?.color && <td className='p-2 border-e' >{item?.permit_vehicle[0]?.vehicle?.color}</td>
                                                    }
                                                    {item?.permit_vehicle[0]?.vehicle?.make && <td className='p-2 border-e' >{item?.permit_vehicle[0]?.vehicle?.make}</td>
                                                    }
                                                    {item?.permit_vehicle[0]?.vehicle?.model && <td className='p-2 ' >{item?.permit_vehicle[0]?.vehicle?.model}</td>
                                                    }
                                                </tr>
                                                </tbody>
                                            </table>
                                            {item?.permit_vehicle?.length > 1 ? <span className='fw-bold text-primary text-decoration-underline cursor' onClick={() => handleshowVehicle()}>{displayVehicle ? 'Hide All' : 'View All'}</span> : null}

                                        </div>
                                        }

                                    </div>


                                    {displayVehicle && <span><hr />
                                        <b className='text-muted fw-bold'>Vehicles:</b>
                                        <div className='row align-items-center justify-content-between  '>
                                            <div className='col-sm-12 '>
                                                {item?.permit_vehicle && <div className='col-12 col-sm-auto order-sm-1 order-4 mt-sm-0  '>
                                                    <table cellSpacing="0px" cellPadding="0" className='table'>
                                                        <thead>
                                                            <tr>
                                                                <th className='text-muted'>License Plate</th>
                                                                <th className='text-muted'>Color</th>
                                                                <th className='text-muted'>Make</th>
                                                                <th className='text-muted'>Model</th>
                                                            </tr>
                                                        </thead>
                                                        {item?.permit_vehicle?.length > 0 && item?.permit_vehicle?.map((item, index) => {
                                                            return <tr key={index}>
                                                                <td className='text-start ps-2'>{item?.vehicle?.license_plate_number ? item?.vehicle?.license_plate_number : '-'}</td>
                                                                <td className='text-start ps-2'>{item?.vehicle?.color ? item?.vehicle?.color : '-'}</td>
                                                                <td className='text-start ps-2'>{item?.vehicle?.make ? item?.vehicle?.make : '-'}</td>
                                                                <td className='text-start ps-2'>{item?.vehicle?.model ? item?.vehicle?.model : '-'}</td>
                                                            </tr>
                                                        })}
                                                    </table>

                                                </div>
                                                }
                                            </div>
                                        </div></span>}

                                  
                                    <div className='mb-3 w-100 mt-3'>
                                        <h5 className='fw-bold mb-1' > Uploaded Documents:</h5>
                                      
                                        <div className='row'>        
                                        {item?.permit_doc?.length > 0 ?
                                            item?.permit_doc?.map((item, index) => {
                                                return (
                                                    <div className='col-6 d-flex align-items-center gap-3' key={index}>
                                                        <span className='d-block'>{item?.doc_name}</span>
                                                        - <a href={`${item[item?.document_key]}`} target='_blank' rel="noreferrer" className='fw-bold'>View</a>
                                                    </div>
                                                )
                                            })
                                            : <div className='col-12'>
                                                <span className='fontsize85'>
                                                    N/A
                                                </span>
                                            </div>}
                                            </div>


                                    </div>
                                    </div>
                                    <div className='card-footer p-3'>
                                    <div className='row align-items-center justify-content-between  '>
                                        <div className='col-sm-4 text-end order-sm-1 order-4 '>
                                            {item?.transaction?.total && <p className='mb-0'><b className=' fw-bold'>Paid: </b> ${item?.transaction?.total}</p>}
                                        </div>
                                      {item?.permit_renew_pay == '1' && item?.renewal_due_amount > 0  ?  <div className='col-sm-4 '>
                                        <small><b className='fw-bold'>Amount Due:</b>  <br className='d-block d-sm-none' />{item?.renewal_due_amount}</small>
                                        </div>:''}
                                        {/* <div className='col-sm-4 '> */}
                                            {/* <small><b className='text-muted fw-bold'>Valid Till:</b>  <br className='d-block d-sm-none' />{item?.formatted_desired_end_date}</small> */}
                                            {/* <small><b className='text-muted fw-bold'>Disclaimer:</b>  <br className='d-block d-sm-none' />{staticStrings?.rates24}</small> */}
                                        {/* </div> */}
                                        <div className='col-sm-4 '>
                                            <small><b className='t fw-bold'>Autopay Enabled</b> - <br className='d-block d-sm-none' />{item?.user_consent ? item?.user_consent == '1' ? 'Yes' : 'No' : 'Yes'}</small>
                                        </div>
                                    </div>
                                  
                                    {((item?.is_expired == 0 && item?.cancelled_at == null) || (item?.user_consent === '1')) && <div className='d-flex justify-content-between'>
                                        {(item?.is_expired == 0 && item?.cancelled_at == null) ? <span>
                                            <button
                                                className='mt-3 btn btn-primary btn-sm'
                                                type='button'
                                                disabled={downloadQR}
                                                onClick={() => downloadPermitQr(item?.id, item?.account_number)}>
                                                Download QR
                                            </button>
                                        </span> : <span></span>}
                                        {item?.user_consent === '1' && <>
                                            <button className='mt-3 btn btn-danger btn-sm' onClick={() => cancelPermit(item)}>Cancel Auto Renew</button>
                                        </>}
                                      {/* <div className="w-auto">
       
                                        <div className="form-check form-switch">
  <input className="form-check-input" type="checkbox" role="switch" id='autopayconsent' name='autopayconsent' checked={isConsent} onChange={() => cancelPermit(item)} />
  <label className="form-check-label" htmlFor="autopayconsent">AutoPay Consent</label>
</div>
                                <i className='d-block small'>By clicking this button, you are providing<br/> your consent for auto renewal.</i>

                            </div> */}

                                    </div>}
                                    {/* <div>
                                        <small className='text-danger'>{userData?.user?.license_number != item?.license_number ? errorMessages?.licenseAlertMessage : ''}</small>
                                    </div> */}
                                    </div>
                              
                            </li>
                        )
                    })}
                </ul> : renderNoResults(displayError)}
                <AddCardPaymentModal isModalOpen={isModalOpen} hanndleCancel={hanndleCancel} cardList={cardList} setPayload={setPayload} setCardList={setCardList} setCardFormVisible={setCardFormVisible} isCardFormVisible={isCardFormVisible} setModal={setModal} handleSubmit={handleSubmit} payload={payload} modalName='Add Payment' isLoading={isLoading} handleCardChange={handleCardChange} handleExpiryChange={handleExpiryChange} handleCardSelectChange={handleCardSelectChange} expiry={expiry} cardExpiryMessage={cardExpiryMessage} card={card} errorMessage={errorMessagePay} error={error} setErrorMessage={setErrorMessagePay} setError={setError} setCard={setCard} setExpiry={setExpiry} handleInputFieldsData={handleInputFieldsData} />

            </div>
    )
}

PermitAccount.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    partnerDetails: PropTypes.object,
    notify: PropTypes.func,

}

PermitAccount.defaulProps = {
    dispatch: noop,
    loader: false,
    partnerDetails: EMPTY_OBJECT,
    notify: noop,

}

function mapStateToProps({ permitListReducer,PartnerReducer }) {
    return {
        loader: permitListReducer?.loader,
        partnerDetails: PartnerReducer?.partnerDetails?.data,
    }
}
export default connect(mapStateToProps)(PermitAccount)