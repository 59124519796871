import React from 'react'
import { Circles } from  'react-loader-spinner'
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import PropTypes from 'prop-types'
function Loader(props) {  
    const {eventsLoader, isMargin} = props

	return (
        <div className= {eventsLoader ? `${isMargin ? 'mb-2 eventsloader d-flex justify-content-center align-item-center' : 'm-5 p-5 eventsloader d-flex justify-content-center align-item-center'}` : `${isMargin ? 'mb-2 loader d-flex justify-content-center align-item-center' : 'm-5 p-5 loader d-flex justify-content-center align-item-center'}`}>
            <Circles
                height={isMargin ? '20' : '40'}
                width={isMargin ? '20' : '40'}
                color="var(--bs-primary)"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
        
        )
}
Loader.propTypes = {
	
	eventsLoader: PropTypes.bool,
    isMargin: PropTypes.bool,
}

Loader.defaulProps = {
	
	eventsLoader: false,
    isMargin: false
	
}
export default Loader
