import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import moment from 'moment-timezone'
import { connect } from 'react-redux'
import { EMPTY_ARRAY, EMPTY_OBJECT, validPassword, validPhone, vehicleArray } from '../../constants/globalConstants'
import Header from '../../components/global/Header'
import Steps from '../../components/global/Steps'
import Home from '../home'
import SignUp from '../signup'
import Pass from '../pass'
import ThankYou from '../thankyou'
import { Circles } from 'react-loader-spinner'
import { encryptionHandler, validateEmail } from '../../utils/helper'
import { updateWaitingList } from '../../redux/actions/waitingListActions'
//import { bookPermit ,getPaymentInitials,saveBookingBeforePayment,saveBookingAfterPayment} from '../../redux/actions/paymentActions'
import { getPaymentInitials, requestPermit} from '../../redux/actions/paymentActions'

import { permitRateList } from '../../redux/actions/BasicInfoAction'

function Main(props) {
    const { dispatch, isAuthenticated, partnerDetails, notify ,setPageActive } = props
    const [page, setPage] = useState()
    const [active, setActive] = useState('home')
    const [idProof, setIdProof] = useState(EMPTY_ARRAY)
    const [formData, setFormData] = useState({ vehicleList: vehicleArray, userConsent: true })
    const [vehicleListArray , setVehicleListArray] = useState(vehicleArray)
    const [facilityId , setFacilityId] = useState('')
    const [formError, setFormError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [previousActive, setPreviousActive] = useState(false)
    const [response, setResponse] = useState({})
    const [errorMessage, setErrorMessage] = useState()
    const [res, setRes] = useState('')
    const [url, setUrl] = useState('')
    const [guid, setGuid] = useState(EMPTY_OBJECT)
	const [selectedFaclity, setSelectedFacilty] = useState(EMPTY_OBJECT)
	const [documentTypes, setDocumentTypes] = useState(EMPTY_ARRAY)
    const [vehicleCountList ,  setvehicleCountList] = useState(EMPTY_ARRAY)
    const [VehiclePrev , setVehiclePrev] = useState(true)
    const [vehicleCount, setvehicleCount] = useState(0)

    //const navigate = useNavigate()
    const formRef = useRef()
    useEffect(() => {
        if (page == 'thankyou') {
            setActive('thankyou')
        }
        if (page == 'pass') {
            paymentInitials(formData)
        }
    }, [page])

    useEffect(() => {
        setPageActive(active)
    },[active])
    
    

    useEffect(() => {
        if (isAuthenticated) {
            setIsLoggedIn(true)
            // setFormData({ vehicleList: vehicleArray, userConsent: true })
        } else {    
            const getuserDatafromLocal = JSON.parse(localStorage.getItem('userData'))
            if (getuserDatafromLocal?.access_token && getuserDatafromLocal?.access_token != '') {
                setIsLoggedIn(true)
                // setFormData({ vehicleList: vehicleArray, userConsent: true })
            } else {
                setIsLoggedIn(false)
                // setFormData({ vehicleList: vehicleArray, userConsent: true })
            }
        }


    }, [EMPTY_ARRAY, isAuthenticated])



    const paymentInitials = (formData) => {
        const paymentSessionPayload = {
            'permit_rate_id': formData?.permit_rate_id,
            'license_number': formData?.license_number,
            'facility_id': formData?.facility_id,
            'promocode': '',
        }
        dispatch(getPaymentInitials(paymentSessionPayload)).then((response) => {
            if(response?.data){
                setUrl(response?.data?.api_url)
                setRes(response?.data?.ipgSession)
                setGuid(response?.data?.guid)
                localStorage.setItem('paymentSession', JSON.stringify(response?.data))
            }else{
                notify('error', response?.message ? response?.message : 'Something went wrong, Please try again!')
                setIsLoading(false)
            }           
        }).catch((err) => {
            notify('error', err ? err : 'Something went wrong, Please try again!')
            setIsLoading(false)
        })
    }


    const checkPermitUsage = () => {
        const permitPayload = {
            'facility_id': formData?.facility_id,
            'driving_license': formData?.license_number ? formData?.license_number : ''
        }
        dispatch(permitRateList(permitPayload)).then((res) => {
            const permit = res?.data[0]
            const permitRate = permit?.permit_rate[0]
            // console.log('permitRate' ,  permitRate)
            if (permitRate?.remaining_usage == 0) {
                const userData = JSON.parse(localStorage.getItem('userData'))
                const payload = {
                    facility_id: formData?.facility_id,
                    // partner_id: PARTNER_ID,
                    name: `${formData?.firstname ? formData?.firstname : ''} ${formData?.lastname ? formData?.lastname : ''}`,
                    email: formData?.email ? formData?.email : '',
                    phone: formData?.phone ? formData?.phone : '',
                    license_number: formData?.license_number ? formData?.license_number : '',
                    first_name: formData?.firstname ? formData?.firstname : '',
                    confirm_password: formData?.cpassword,
                    password: formData?.password,
                    last_name: formData?.lastname ? formData?.lastname : '',
                    user_id: isLoggedIn ? userData?.user?.id : '',
                    address1: formData?.address1 ? formData?.address1 : '',
                    address2: formData?.address2 ? formData?.address2 : '',
                    city: formData?.city ? formData?.city : '',
                    state: formData?.state ? formData?.state : '',
                    zipcode: formData?.zipcode ? formData?.zipcode : '',
                    permit_rate: formData?.permit_rate,
                    permit_rate_id: formData?.permit_rate_id,
                    desired_start_date: moment().format('YYYY/MM/DD'),
                    month: 1,
                    type_id: 3
                }
                dispatch(updateWaitingList(payload)).then((res) => {
                    if (res?.data) {
                        const userData = res?.data?.userDetails
                        if(userData){
                            if (userData?.access_token && userData?.access_token != '') {
                                localStorage.setItem('userData', JSON.stringify(userData))
                            } else {
                                const userDetailsFromLocal = JSON.parse(localStorage.getItem('userData'))
                                userDetailsFromLocal.user = userData
                                localStorage.setItem('userData', JSON.stringify(userDetailsFromLocal))
                            }
                        }
                    }
                    setIsLoading(false)
                    setActive('thankyou')
                    setResponse(res?.data?.message ? res?.data?.message : 'Maximum passes have been sold for this permit, You are added in the waiting list, and will be notified when a pass is available.')
                    if (res?.data?.message == 'Invalid password.') {
                        setPreviousActive(true)
                    } else {
                        setPreviousActive(false)
                    }
                }).catch((err) => {
                    const response = err?.errors
                    setResponse(response?.message ? response?.message : 'Something went wrong, Please try again!')
                    if (response?.message == 'Invalid password.') {
                        setPreviousActive(true)
                    } else {
                        setPreviousActive(false)
                    }
                    setIsLoading(false)
                    setActive('thankyou')
                })
            } else {
                setIsLoading(false)
                setErrorMessage('')
                setActive('pass')
            }
        }).catch((err) => {
            const response = err?.errors
            setResponse(response?.message ? response?.message : 'Something went wrong, Please try again!')
            if (response?.message == 'Invalid password.') {
                setPreviousActive(true)
            } else {
                setPreviousActive(false)
            }
            setIsLoading(false)
            setActive('thankyou')
        })
    }
    const handleNext = () => {
        if (page == 'home') {
            setFormError('home')
            setErrorMessage('')
            // if (Object.values(formData).length > 0 && formData?.facility_id && formData?.license_number && formData?.vehicleList?.length > 0) {
            if (Object.values(formData).length > 0 && facilityId && vehicleListArray?.length > 0 && formData?.permit_id && idProof?.length == documentTypes?.length) {
                // if (formData?.license_number.length < 7 || formData?.license_number.length > 13 || !validLicenceNumber.test(formData?.license_number)) {
                //     setErrorMessage('')
                // } else 
                // if (formData?.permit_rate == '' || formData?.permit_rate == undefined || formData?.permit_rate == null) {
                //     setErrorMessage('')
                // } else {
                    const vehicles = vehicleListArray
                    const emptyLicenseVehicle = vehicles?.filter((item) => item?.license_plate == '' && item?.license_plate?.length < 2)
                    if (emptyLicenseVehicle?.length > 0) {
                        // setErrorMessage('Please add a vehicle')
                    } 
                    // else if(idProof?.front == undefined || idProof?.back == undefined){
                    //     // no message
                    // }
                    else {
                        const payloadData = {...formData}
                        payloadData['vehicleList'] = vehicleListArray
                        payloadData['facility_id'] = facilityId
                        setFormData({ ...payloadData })
                        setErrorMessage('')
                        setActive('signup')
                        setVehiclePrev(false)
                    }

                // }

            }
        } else if (page == 'signup') {
            // paymentInitials(formData)
            setFormError('signup')
            setIsLoading(true)
            setErrorMessage('')



            if (Object.values(formData).length > 0 && formData?.firstname && formData?.email && formData?.phone && formData?.address1 && formData?.city && formData?.state && formData?.zipcode) {
                // if (Object.values(formData).length > 0 && formData?.firstname && formData?.email && formData?.phone && formData?.address1)  {
                if (isLoggedIn) {
                    if (formData?.email) {
                        if (validateEmail(formData?.email)) {
                            if (validPhone.test(formData?.phone) && formData?.phone?.length == 10) {
                                checkPermitUsage()
                            } else {
                                setIsLoading(false)
                            }
                        } else {
                            setIsLoading(false)
                        }
                    }
                } else {
                    if (formData?.email) {
                        if (validateEmail(formData?.email)) {
                            setErrorMessage('')
                            if (formData?.password) {
                                if (formData?.password == formData?.cpassword) {
                                    if (validPassword.test(formData?.password)) {
                                        if (validPhone.test(formData?.phone) && formData?.phone?.length == 10) {
                                            checkPermitUsage()
                                        } else {
                                            setIsLoading(false)
                                        }
                                    } else {
                                        setIsLoading(false)
                                    }

                                } else {
                                    setIsLoading(false)
                                }
                            } else {
                                setIsLoading(false)
                            }

                        } else {
                            setIsLoading(false)
                        }
                    }
                }
            } else {
                setIsLoading(false)
            }

        } else if (page == 'pass') {
            paymentInitials(formData)
            setErrorMessage('')
            setActive('thankyou')
        }
    }
    const handlePrevious = () => {
        if (page == 'signup') {
            setErrorMessage('')
            setActive('home')
        } else if (page == 'pass') {
            setErrorMessage('')
            setActive('signup')
        } else if (page == 'thankyou') {
            setErrorMessage('')
            if(selectedFaclity?.is_permit_purchase_enabled == '1'){
                setActive('pass')
            }else{
                setActive('signup')
            }
        }
    }





    const handleSubmit = (e) => {
        // const event = e
        // const form = formRef.current
        console.log(e)
        setFormError('pass')
        // paymentInitials(formData)
        // if (Object.values(formData).length > 0 && ((formData?.card_pan && formData?.name && formData?.card_date_expiry_year && formData?.card_card_security_cvx_2 && formData?.zip_code))) {
        if(!isLoading){
            if(formData?.userAcknowledge || selectedFaclity?.is_permit_purchase_enabled == '0'){
                if(selectedFaclity?.is_permit_purchase_enabled == '0' || partnerDetails?.attendent_type == 'business_account'){
                    if (Object.values(formData).length > 0 && (formData?.firstname)) {
                        setErrorMessage('')
                        setIsLoading(true)
                        const userData = JSON.parse(localStorage.getItem('userData'))
                        let finalVehicleList = []
                        formData?.vehicleList?.map((value) =>{
                            if(value?.license_plate){
                                finalVehicleList.push({'license_plate' : value?.license_plate , 'make' : value?.othermake ? value?.maketext : value?.make , 'make_id' : value?.make_id , 'model' : value?.othermodel ? value?.modeltext : value?.model , 'color' : value?.color  , 'state_id' : value?.state_id})
                            }
                        })
                       // const finalVehicleList = formData?.vehicleList?.filter((item) => (item?.license_plate === '' && item?.make === '' && item?.model === '') ? null : item)
                        const facilityData = partnerDetails?.facilities?.filter((item) => (item?.id == formData?.facility_id))
                        let formSubmitData = new FormData() 
                            formSubmitData.append('facility_id',formData?.facility_id ? formData?.facility_id : '')
                            formSubmitData.append('license_number',formData?.license_number ? formData?.license_number : '')
                            formSubmitData.append('first_name',formData?.firstname ? formData?.firstname : '')
                            formSubmitData.append('last_name',formData?.lastname ? formData?.lastname : '')
                            formSubmitData.append('name',`${formData?.firstname ? formData?.firstname : ''} ${formData?.lastname ? formData?.lastname : ''}`)
                            formSubmitData.append('email', formData?.email ? formData?.email : '')
                            formSubmitData.append('phone', formData?.phone ? formData?.phone : '')
                            formSubmitData.append('city',formData?.city ? formData?.city : '')
                            formSubmitData.append('state',formData?.state ? formData?.state : '')
                            formSubmitData.append('zipcode',formData?.zipcode ? formData?.zipcode : '')
                            formSubmitData.append('confirm_password', formData?.cpassword ? formData?.cpassword : '')
                            formSubmitData.append('password', formData?.password ? formData?.password : '')
                            formSubmitData.append('desired_start_date',moment(new Date()).tz(facilityData?.timezone ? facilityData?.timezone : 'America/New_York').format('YYYY/MM/DD'))
                            formSubmitData.append('vehicleList',JSON.stringify(finalVehicleList))
                            formSubmitData.append('is_admin','0')
                            formSubmitData.append('user_id',isLoggedIn ? userData?.user?.id : '')
                            formSubmitData.append('address1',formData?.address1 ? formData?.address1 : '')
                            formSubmitData.append('address2',formData?.address2 ? formData?.address2 : '')
                            formSubmitData.append('permit_rate',formData?.permit_rate ? formData?.permit_rate : '')
                            formSubmitData.append('permit_rate_id',formData?.permit_rate_id ? formData?.permit_rate_id : '')
                            formSubmitData.append('month','1')
                            formSubmitData.append('permit_type' , formData?.permit_id ? formData?.permit_id : '')
                            formSubmitData.append('permit_type_name', formData?.permit_type_name ? formData?.permit_type_name : '')
                            formSubmitData.append('acknowledge', formData?.userAcknowledge ? 1 : 0)
                            formSubmitData.append('type', 'formData')
                            formSubmitData.append('business_id', partnerDetails?.attendent_type == 'business_account' ? partnerDetails?.id : '')
                             
        
                        const requestPayload = {
                            facility_id: formData?.facility_id ? formData?.facility_id : '',
                            license_number: formData?.license_number ? formData?.license_number : '',
                            first_name: formData?.firstname ? formData?.firstname : '',
                            last_name: formData?.lastname ? formData?.lastname : '',
                            name: `${formData?.firstname ? formData?.firstname : ''} ${formData?.lastname ? formData?.lastname : ''}`,
                            email:  formData?.email ? formData?.email : '',
                            phone:  formData?.phone ? formData?.phone : '',
                            city: formData?.city ? formData?.city : '',
                            state: formData?.state ? formData?.state : '',
                            zipcode: formData?.zipcode ? formData?.zipcode : '',
                            confirm_password:  formData?.cpassword ? formData?.cpassword : '',
                            password:  formData?.password ? formData?.password : '',
                            desired_start_date: moment(new Date()).tz(facilityData?.timezone ? facilityData?.timezone : 'America/New_York').format('YYYY/MM/DD'),
                            vehicleList: JSON.stringify(finalVehicleList),
                            is_admin: '0',
                            user_id: isLoggedIn ? userData?.user?.id : '',
                            address1: formData?.address1 ? formData?.address1 : '',
                            address2: formData?.address2 ? formData?.address2 : '',
                            permit_rate: formData?.permit_rate ? formData?.permit_rate : '',
                            permit_rate_id: formData?.permit_rate_id ? formData?.permit_rate_id : '',
                            month: '1',
                            permit_type_name: formData?.permit_type_name ? formData?.permit_type_name : '',
                            acknowledge: formData?.userAcknowledge ? 1 : 0,
                            business_id: partnerDetails?.attendent_type == 'business_account' ? partnerDetails?.id : '',
                        }
        
                        if(idProof?.length > 0){
                            idProof?.map((item) => {
                                formSubmitData.append(item?.type, item?.file)
                            })
                        }
                        if(idProof?.length > 0){
                            idProof?.map((item) => {
                                requestPayload[item?.type] = item?.file
                            })
                        }
        
                        localStorage.setItem('beforePaymentPayloadValue', JSON.stringify(requestPayload))
                        localStorage.setItem('bookingType', 'Permit')
            
                        dispatch(requestPermit(formSubmitData)).then((response) => {
            
                            const responseData = response?.data
                            if(responseData && responseData?.name != ''){
                                setPreviousActive(false)                    
                                if(responseData?.error_msg && responseData?.error_msg != ''){
                                    setResponse(responseData?.error_msg)
                                    setPage('thankyou')
                                }else{
                                    setResponse(responseData)
                                    setPage('thankyou')
                                }
                            }else{ 
                                const errors = response?.errors
                                setResponse(errors ? errors?.message : 'Something went wrong, Please try again!')                   
                                //old                  
                                if(errors?.message == 'Invalid password.' || !response?.message || response?.message == '' || response?.message == undefined || response?.message == null){
                                    setPreviousActive(true)
                                }else{
                                    setPreviousActive(false)
                                }
                                setIsLoading(false)
                                setPage('thankyou')
                            }
                        }).catch((error) => { 
                            const ErrorMsg = error?.errors  
                            setIsLoading(false)
                            setResponse(ErrorMsg?.message ? ErrorMsg?.message : 'Something went wrong, Please try again!')               
                            setPage('thankyou')
                        })                       
                     
                    } else { 
                        setIsLoading(false)
                    }
                }else{
                    // if (Object.values(formData).length > 0 && (formData?.payment_profile_id || (formData?.card_pan && formData?.name && formData?.card_date_expiry_month && formData?.card_date_expiry_year && formData?.card_card_security_cvx_2 && formData?.zip_code && formData?.cvvErrorMessage =='' && formData?.userConsent == true))) {
                    if (Object.values(formData).length > 0 && (Number(formData?.permit_rate == 0) || formData?.payment_profile_id || (formData?.name_on_card && formData?.card_number && formData?.expiration_date && formData?.card_card_security_cvx_2 && formData?.zip_code_on_card))) {
                        setErrorMessage('')
                        setIsLoading(true)
                        const userData = JSON.parse(localStorage.getItem('userData'))
                        const paymentSessionValue = JSON.parse(localStorage.getItem('paymentSession'))
                        let nonce = null

                        if (!formData?.payment_profile_id && (formData?.name_on_card && formData?.card_number && formData?.expiration_date && formData?.card_card_security_cvx_2 && formData?.zip_code_on_card)
                        ) {
                           const card_expiration_date = formData?.expiration_date
            
                            let cardData = {
                                name_on_card : formData?.name_on_card,
                                card_number : formData?.card_number ? formData?.card_number.replace(/\D/g, '') : '',
                                expiration_date: card_expiration_date ? card_expiration_date.replace(/\D/g, '') : '',
                                security_code: formData?.card_card_security_cvx_2,
                                zip_code_on_card : formData?.zip_code_on_card
                            }
                            nonce = encryptionHandler(cardData)
                        }

                        let finalVehicleList = []
                        formData?.vehicleList?.map((value) =>{
                            if(value?.license_plate){
                            finalVehicleList.push({'license_plate' : value?.license_plate , 'make' : value?.othermake ? value?.maketext : value?.make , 'make_id' : value?.make_id ,  'model' : value?.othermodel ? value?.modeltext : value?.model , 'color' : value?.color  , 'state_id' : value?.state_id})
                            }
                        })
                        // to filter the data of vehicle Array object should not be empty with both values
                        // const finalVehicleList = formData?.vehicleList?.filter((item) => (item?.license_plate === '' && item?.make === '' && item?.model === '') ? null : item)
                        //  
                        const facilityData = partnerDetails?.facilities?.filter((item) => (item?.id == formData?.facility_id))

                        let formPostData = new FormData()    //formdata object
                        formPostData.append('license_number', formData?.license_number ? formData?.license_number : '')   //append the values with key, value pair
                        formPostData.append('first_name', formData?.firstname ? formData?.firstname : '')
                        formPostData.append('email', formData?.email ? formData?.email : '')
                        formPostData.append('phone', formData?.phone ? formData?.phone : '')
                        formPostData.append('vehicleList', JSON.stringify(finalVehicleList))
                        formPostData.append('confirm_password', formData?.cpassword ? formData?.cpassword : '')
                        formPostData.append('password', formData?.password ? formData?.password : '')
                        formPostData.append('facility_id', formData?.facility_id ? formData?.facility_id : '')
                        formPostData.append('last_name', formData?.lastname ? formData?.lastname : '')
                        formPostData.append('user_id', isLoggedIn ? userData?.user?.id : '')
                        formPostData.append('address1', formData?.address1 ? formData?.address1 : '')
                        formPostData.append('address2', formData?.address2 ? formData?.address2 : '')
                        formPostData.append('city', formData?.city ? formData?.city : '')
                        formPostData.append('state', formData?.state ? formData?.state : '')
                        formPostData.append('zipcode', formData?.zipcode ? formData?.zipcode : '')
                        formPostData.append('name', formData?.name ? formData?.name : '')
                        formPostData.append('permit_rate', formData?.permit_rate ? formData?.permit_rate : '')
                        formPostData.append('permit_rate_id', formData?.permit_rate_id ? formData?.permit_rate_id : '')
                        formPostData.append('desired_start_date', moment(new Date()).tz(facilityData?.timezone ? facilityData?.timezone : 'America/New_York').format('YYYY/MM/DD'))
                        formPostData.append('month', 1)
                        formPostData.append('user_consent', (formData?.userConsent && formData?.userConsent == true) ? 1 : 0)
                        // formPostData.append('is_card_req', 1)
                        formPostData.append('type_id', 3)
                        formPostData.append('nonce', nonce ? nonce : '')
                        formPostData.append('payment_profile_id', formData?.payment_profile_id ? formData?.payment_profile_id : '')
                        formPostData.append('promocode', formData?.coupon_applied == true ? formData?.promocode ? formData?.promocode : '' : '')
                        formPostData.append('is_admin', '0')
                        formPostData.append('reference', paymentSessionValue.reference ? paymentSessionValue.reference : '')
                        formPostData.append('is_payment_authrize', (formData?.payment_profile_id || nonce) ? 1 : 0)
                        formPostData.append('permit_type_name', formData?.permit_type_name ? formData?.permit_type_name : '')
                        formPostData.append('acknowledge', formData?.userAcknowledge ? 1 : 0)
                        formPostData.append('type', 'formData'),
                        formPostData.append('business_id', partnerDetails?.attendent_type == 'business_account' ? partnerDetails?.id : '')
        
        
                        if(idProof?.length > 0){
                            idProof?.map((item) => {
                                formPostData.append(item?.type, item?.file)
                            })
                        }
        
                        if(!formData?.payment_profile_id && nonce){
                            formPostData.append('is_card_req' , 1)
                        }
                        
                        
                        
                        let formDataInJson = {    //formdata object
                            license_number : formData?.license_number ? formData?.license_number : '',   //append the values with key, value pai,
                            first_name : formData?.firstname ? formData?.firstname : '',
                            email : formData?.email ? formData?.email : '',
                            phone : formData?.phone ? formData?.phone : '',
                            vehicleList : JSON.stringify(finalVehicleList),
                            confirm_password : formData?.cpassword ? formData?.cpassword : '',
                            password : formData?.password ? formData?.password : '',
                            facility_id : formData?.facility_id ? formData?.facility_id : '',
                            last_name : formData?.lastname ? formData?.lastname : '',
                            user_id : isLoggedIn ? userData?.user?.id : '',
                            address1 : formData?.address1 ? formData?.address1 : '',
                            address2 : formData?.address2 ? formData?.address2 : '',
                            city : formData?.city ? formData?.city : '',
                            state : formData?.state ? formData?.state : '',
                            zipcode : formData?.zipcode ? formData?.zipcode : '',
                            name : formData?.name ? formData?.name : '',
                            permit_rate : formData?.permit_rate ? formData?.permit_rate : '',
                            permit_rate_id : formData?.permit_rate_id ? formData?.permit_rate_id : '',
                            desired_start_date : moment(new Date()).tz(facilityData?.timezone ? facilityData?.timezone : 'America/New_York').format('YYYY/MM/DD'),
                            month : 1,
                            user_consent : formData?.userConsent ? formData?.userConsent == true ? 1 : 0 : 1,
                            is_card_req : 1,
                            type_id : 3,
                            nonce : nonce ? nonce : '',
                            payment_profile_id : formData?.payment_profile_id ? formData?.payment_profile_id : '',
                            promocode : formData?.coupon_applied == true ? formData?.promocode ? formData?.promocode : '' : '',
                            is_admin : '0',
                            reference : paymentSessionValue.reference ? paymentSessionValue.reference : '',
                            is_payment_authrize: (formData?.payment_profile_id || nonce) ? 1 : 0,
                            permit_type_name: formData?.permit_type_name ? formData?.permit_type_name : '',
                            acknowledge: formData?.userAcknowledge ? 1 : 0,
                            business_id: partnerDetails?.attendent_type == 'business_account' ? partnerDetails?.id : ''
    
                        }
        
                        if(idProof?.length > 0){
                            idProof?.map((item) => {
                                formDataInJson[item?.type] = item?.file
                            })
                        }
        
                        localStorage.setItem('beforePaymentPayloadValue', JSON.stringify(formDataInJson))
                        localStorage.setItem('bookingType', 'Permit')
            
                        // const permitTokenPaymentPayload = {
                        //     'TokenNo': formData?.payment_profile_id,
                        //     'facility_id': formData?.facility_id,
                        //     'license_number': formData?.license_number,
                        //     'ref': paymentSessionValue?.reference,
                        //     'permit_rate_id': formData?.permit_rate_id,
                        // }
                        
        
                        // OLD FLOW WITHOUT LOGIN
        
                        // dispatch(requestPermit(formPostData)).then((response) => {
            
                        //     const responseData = response?.data
                        //     if(responseData && responseData?.name != ''){
                        //         setPreviousActive(false)                    
                        //         if(responseData?.error_msg && responseData?.error_msg != ''){
                        //             setResponse(responseData?.error_msg)
                        //             setPage('thankyou')
                        //         }else{
                        //             setResponse(responseData)
                        //             setPage('thankyou')
                        //         }
                        //     }else{ 
                        //         const errors = response?.errors
                        //         setResponse(errors ? errors?.message : 'Something went wrong, Please try again!')                   
                        //         //old                  
                        //         if(errors?.message == 'Invalid password.' || !response?.message || response?.message == '' || response?.message == undefined || response?.message == null){
                        //             setPreviousActive(true)
                        //         }else{
                        //             setPreviousActive(false)
                        //         }
                        //         setIsLoading(false)
                        //         setPage('thankyou')
                        //     }
                        // }).catch((error) => { 
                        //     const ErrorMsg = error?.errors                   
                        //     setResponse(ErrorMsg?.message ? ErrorMsg?.message : 'Something went wrong, Please try again!')               
                        //     setPage('thankyou')
                        //     setIsLoading(false)
        
                        // })  
        
                        // OLD FLOW WITHOUT LOGIN
        
                        // USER LOGIN AFTER PERMIT REQUEST
        
                        dispatch(requestPermit(formPostData,'formData')).then((response) => {
                            setIsLoading(false)
                            const responseData = response?.data
                            // localStorage.setItem('responseData-before-api', JSON.stringify(responseData))               
                            if(responseData && responseData?.name != ''){
                                setPreviousActive(false)
                                const userDetailsFromLocal = JSON.parse(localStorage.getItem('userData'))
                                if (userDetailsFromLocal?.access_token) {
                                    if (responseData?.error_msg && responseData?.error_msg != '') {
                                        setResponse(responseData?.error_msg)
                                        const userDetailsFromLocal = JSON.parse(localStorage.getItem('userData'))
                                        userDetailsFromLocal.user = responseData?.userDetails
                                        // const userData = responseData?.userDetails
                                        if (userDetailsFromLocal) {
                                            localStorage.setItem('userData', JSON.stringify(userDetailsFromLocal))
                                        } else {
                                            localStorage.setItem('userData', '{}')
                                        }
                                        setPage('thankyou')
                                        setIsLoading(false)
                                        
                                    } else {
                                        setResponse(responseData)
                                        const userData = responseData?.userDetails
                                        if (userData) {
                                            const userDetailsFromLocal = JSON.parse(localStorage.getItem('userData'))
                                            userDetailsFromLocal.user = userData
                                            localStorage.setItem('userData', JSON.stringify(userDetailsFromLocal))
                                            setPage('thankyou')
        
                                        } else {
                                            const userDetailsFromLocal = JSON.parse(localStorage.getItem('userData'))
                                            userDetailsFromLocal.user = responseData?.userDetails
                                            // const userData = responseData?.userDetails
                                            if (userDetailsFromLocal) {
                                                localStorage.setItem('userData', JSON.stringify(userDetailsFromLocal))
                                            } else {
                                                localStorage.setItem('userData', '{}')
                                            }
                                            setPage('thankyou')
        
                                        }
                                        setIsLoading(false)
                                        
                                    }
                                } else {
                                    if (responseData?.error_msg && responseData?.error_msg != '') {
                                        setResponse(responseData?.error_msg)
                                        setPage('thankyou')
                                        const userData = responseData?.userDetails
                                        if (userData) {
                                            localStorage.setItem('userData', JSON.stringify(userData))
                                        } else {
                                            localStorage.setItem('userData', '{}')
                                        }
                                        setIsLoading(false)
        
                                    } else {
                                        setResponse(responseData)
            
                                        const userData = responseData?.userDetails
            
                                        if (userData) {
            
                                            localStorage.setItem('userData', JSON.stringify(userData))
                                            setPage('thankyou')
                                        } else {
                                            localStorage.setItem('userData', '{}')
                                            setPage('thankyou')
                                        }
                                        setIsLoading(false)
            
                                    }
                                }
            
                            } else {
            
                                const errors = response?.errors
                                setResponse(errors ? errors?.message : 'Something went wrong, Please try again!')
                                //old                  
                                if (errors?.message == 'Invalid password.' || !response?.message || response?.message == '' || response?.message == undefined || response?.message == null) {
                                    setPreviousActive(true)
                                } else {
                                    setPreviousActive(false)
                                }
                                setIsLoading(false)
                                setPage('thankyou')
                            }
                            // formRef.current = false
                        }).catch((err) => {
                                setResponse(err?.message ? err?.message : 'Something went wrong, Please try again!')               
                                //old                  
                                setIsLoading(false)
                                setPage('thankyou')
                        })
                        .catch((error) => { 
                            const ErrorMsg = error?.errors                   
                            setResponse(ErrorMsg?.message ? ErrorMsg?.message : error)               
                            setPage('thankyou')
                            setIsLoading(false)
        
                        })                       
        
                        // USER LOGIN AFTER PERMIT REQUEST
        
        
                        // dispatch(saveBookingBeforePayment(formPostData)).then((response) => {
                        //     const responseData = response?.data
                        //     // localStorage.setItem('responseData-before-api', JSON.stringify(responseData))               
                        //     if (responseData && (response?.data?.mer_reference != '' || response?.data?.mer_reference != null || response?.data?.mer_reference != undefined)) {
                        //         setPreviousActive(false)
                        //         const userDetailsFromLocal = JSON.parse(localStorage.getItem('userData'))
                        //         if (userDetailsFromLocal?.access_token) {
                        //             if (responseData?.error_msg && responseData?.error_msg != '') {
                        //                 setResponse(responseData?.error_msg)
                        //                 const userDetailsFromLocal = JSON.parse(localStorage.getItem('userData'))
                        //                 userDetailsFromLocal.user = responseData?.userDetails
                        //                 // const userData = responseData?.userDetails
                        //                 if (userDetailsFromLocal) {
                        //                     localStorage.setItem('userData', JSON.stringify(userDetailsFromLocal))
                        //                 } else {
                        //                     localStorage.setItem('userData', '{}')
                        //                 }
                        //                 setPage('thankyou')
            
                        //             } else {
                        //                 const userData = responseData?.userDetails
            
                        //                 if (userData) {
                        //                     const userDetailsFromLocal = JSON.parse(localStorage.getItem('userData'))
                        //                     userDetailsFromLocal.user = userData
                        //                     localStorage.setItem('userData', JSON.stringify(userDetailsFromLocal))
                        //                     if (formData?.payment_profile_id) {
                        //                         dispatch(permitTokenPayment(permitTokenPaymentPayload)).then((res) => {
                        //                             localStorage.setItem('transactionState', res?.Params?.TxState)
                        //                             setIsLoading(false)
                        //                             if (res?.Params?.TxState === 'CQ' || res?.Params?.TxState === 'AA') {
                        //                                 setPage('thankyou')
                        //                                 setActive('thankyou')
                        //                             } else {
                        //                                 setPage('failPayment')
                        //                                 setActive('failPayment')
                        //                             }
                        //                         })
                        //                     } else {
                        //                         if (event) {
                        //                             setPage('thankyou')
                        //                             setActive('thankyou')
                        //                             // form.submit()
                        //                             console.log(form, 'formref')
                        //                         }
            
                        //                         else {
                        //                             console.log('formref eventnot found')
                        //                         }
                        //                     }
            
                        //                 } else {
                        //                     localStorage.setItem('userData', '{}')
                        //                 }
            
                        //                 setResponse(response)
            
                        //             }
                        //         } else {
                        //             if (responseData?.error_msg && responseData?.error_msg != '') {
                        //                 setResponse(responseData?.error_msg)
                        //                 const userData = responseData?.userDetails
                        //                 if (userData) {
                        //                     localStorage.setItem('userData', JSON.stringify(userData))
                        //                 } else {
                        //                     localStorage.setItem('userData', '{}')
                        //                 }
                        //             } else {
            
                        //                 const userData = responseData?.userDetails
            
                        //                 if (userData) {
            
                        //                     localStorage.setItem('userData', JSON.stringify(userData))
                        //                     if (formData?.payment_profile_id) {
                        //                         dispatch(permitTokenPayment(permitTokenPaymentPayload)).then((res) => {
                        //                             localStorage.setItem('transactionState', res?.Params?.TxState)
                        //                             setIsLoading(false)
                        //                             if (res?.Params?.TxState === 'CQ' || res?.Params?.TxState === 'AA') {
                        //                                 setPage('thankyou')
                        //                                 setActive('thankyou')
                        //                             } else {
                        //                                 setPage('failPayment')
                        //                                 setActive('failPayment')
                        //                             }
                        //                         })
                        //                     } else {
                        //                     if (event) {
                        //                         //console.log('form',form)
                        //                         // form.submit()
                        //                         setPage('thankyou')
                        //                         setActive('thankyou')
                        //                         console.log(form, 'formref')
                        //                     } else {
                        //                         console.log('formref eventnot found')
                        //                     }
                        //                 }
                        //                 } else {
                        //                     localStorage.setItem('userData', '{}')
                        //                 }
            
                        //                 setResponse(response)
                        //             }
                        //         }
            
                        //     } else {
            
                        //         const errors = response?.errors
                        //         setResponse(errors ? errors?.message : 'Something went wrong, Please try again!')
                        //         //old                  
                        //         if (errors?.message == 'Invalid password.' || !response?.message || response?.message == '' || response?.message == undefined || response?.message == null) {
                        //             setPreviousActive(true)
                        //         } else {
                        //             setPreviousActive(false)
                        //         }
                        //         setIsLoading(false)
                        //         setPage('thankyou')
                        //     }
                        //     // formRef.current = false
                        // }).catch((err) => {
                        //         setResponse(err?.message ? err?.message : 'Something went wrong, Please try again!')               
                        //         //old                  
                        //         setIsLoading(false)
                        //         setPage('thankyou')
                        // })
                        // .catch((error) => { 
                        //     formRef.current = false
                        //     const ErrorMsg = error?.errors                   
                        //     setResponse(ErrorMsg?.message ? ErrorMsg?.message : error)               
                        //     setPage('thankyou')
                        // })                       
            
                    } else {
                        setIsLoading(false)
                    }
                }
            }else{
                console.log('')
            }
        }
        
        

    }

    

    return (
        <div>
            {/* loggedInError={loggedInError} */}
            <Header page={page} partnerDetails={partnerDetails}/>
            <main>

                <section id="forms" className="formDesign p-sm-3">
                    <div className="container mt-2 " id="qbox-container">
                        <div className='row justify-content-center'>
                            <div className="col-md-10 text-center mb-3">
                                <h1>Permit Request</h1>
                            </div>
                        </div>
                    </div>
                    <Steps
                        page={page}
                        selectedFaclity={selectedFaclity}
                    />
                    <div className="container mt-2 " id="qbox-container">
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                {/* <form className="needs-validation" id="form-wrapper" method="post" name="form-wrapper"
                                    noValidate=""> */}
                                <form action={url} ref={formRef} id='ipg_form' target="_top" name='ipg_form' method='post' onSubmit={(e) => handleSubmit(e)}>
                                    <div id="steps-container">
                                        {active == 'home' && <Home setPage={setPage} setIdProof={setIdProof} idProof={idProof} vehicleListArray={vehicleListArray} setVehicleListArray={setVehicleListArray} vehicleCountList={vehicleCountList} setvehicleCountList={setvehicleCountList} vehicleCount={vehicleCount} setvehicleCount={setvehicleCount}  VehiclePrev={VehiclePrev} formData={formData} setFormData={setFormData} formError={formError} facilityId={facilityId} setFacilityId={setFacilityId} selectedFaclity={selectedFaclity} setSelectedFacilty={setSelectedFacilty} 
                                            setDocumentTypes={setDocumentTypes}
                                            documentTypes={documentTypes}
                                            partnerDetails={partnerDetails}
                                            notify={notify}
                                        />}
                                        {active == 'signup' && <SignUp setPage={setPage} isLoggedIn={isLoggedIn} formData={formData} setFormData={setFormData} formError={formError} 
                                            notify={notify}
                                            />}
                                        {active == 'pass' && <Pass guidValue={guid} resValue={res} setPage={setPage} idProof={idProof} formData={formData} setFormData={setFormData} formError={formError} 
                                            notify={notify}
                                            loader={isLoading}
                                            setActive={setActive}
                                            />}
                                        {active == 'thankyou' && <ThankYou setPage={setPage} response={response} selectedFaclity={selectedFaclity}
                                            notify={notify}
                                            />}
                                    </div>
                                    {errorMessage != '' ? <span className='text-danger'>{errorMessage}</span> : ''}

                                    <div id="q-box__buttons" className="text-center mt-4 d-flex justify-content-center">
                                        {(active != 'home' && (previousActive || active == 'signup' || active == 'pass')) &&
                                            <button id="next-btn" type="button" className="btn btn-primary nextprevButton" onClick={(e) => handlePrevious(e)} disabled={isLoading}>Previous</button>}
                                        &nbsp;
                                        {((active == 'home' && active != 'thankyou') || (active == 'signup' && selectedFaclity?.is_permit_purchase_enabled == '1')) &&
                                            <button id="next-btn" type="button" className="btn btn-primary nextprevButton" onClick={(e) => handleNext(e)} disabled={isLoading}>Next {page == 'signup' && isLoading ? <span className=' ms-2'><Circles
                                                height="20"
                                                width="20"
                                                color="#ffffff"
                                                ariaLabel="circles-loading"
                                                visible={true}
                                            /></span> : null}
                                            </button>}&nbsp;
                                        {(active == 'pass' || (active == 'signup' && selectedFaclity?.is_permit_purchase_enabled == '0')) &&
                                            <button id="next-btn" type="button" className="btn btn-primary d-flex nextprevButton" onClick={(e) => handleSubmit(e)} disabled={isLoading}>Submit {isLoading ? <span className=' ms-2'><Circles
                                                height="20"
                                                width="20"
                                                color="#ffffff"
                                                ariaLabel="circles-loading"
                                                visible={true}
                                            /></span> : null}</button>}
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )

}

Main.propTypes = {
    dispatch: PropTypes.func,
    data: PropTypes.object,
    events: PropTypes.object,
    loader: PropTypes.bool,
    getCard: PropTypes.func,
    passData: PropTypes.array,
    isAuthenticated: PropTypes.bool,
    isModeOpen: PropTypes.bool,
    partnerDetails: PropTypes?.object,
    notify: PropTypes.func,
    pageActive: PropTypes.string,
    setPageActive: PropTypes.func,
}

Main.defaulProps = {
    dispatch: noop,
    data: EMPTY_OBJECT,
    events: EMPTY_OBJECT,
    loader: false,
    getCard: noop,
    passData: EMPTY_ARRAY,
    isAuthenticated:false,
    isModeOpen: false,
    partnerDetails: EMPTY_OBJECT,
    notify: noop,
    pageActive: undefined,
    setPageActive: noop,
}

function mapStateToProps({ paymentReducer, login }) {
    return {
        data: paymentReducer,
        isAuthenticated: login?.isAuthenticated,
    }
}
export default connect(mapStateToProps)(Main)
