import React, { useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import './dataTable.scss'
import { EMPTY_OBJECT } from '../../constants/globalConstants'

function AdminListTablePage(props) {
  const { data, columns, getNewRecords, curPage, total, perPage, showSearch, search } = props
  const [PageChanged, setPageChanged] = useState(false)
  const [statePage, setStatePage] = useState(1)
  const [stateSearch, setStateSearch] = useState('')
  const [isSearched, setIsSearched] = useState(false)
  // const [sortByIndex, setSortByIndex] = useState(false)
  // const [sortBy, setSortBy] = useState('')
  // const [sortByOrder, setSortByOrder] = useState('asc')

  const renderNoResults = () => {
    const message = 'No data available'
    return (
      <div className='no-results text-center my-4'>
        <p className='mb-0 alert alert-danger'>{message}</p>
      </div>
    )
  }

  const handlePageInput = (page) => {
    if (page > Math.ceil(total / perPage)) return
    if (page < 0) return
    setPageChanged(true)
    setStatePage(page)
  }

  const handleEnterInput = (e) => {
    let searchVal = (isSearched) ? stateSearch : search
    if (e.key === 'Enter') {
      if (statePage == '' || statePage < 1) {
        setPageChanged(true)
        setStatePage(1)
        return getNewRecords(1, searchVal)
      } else {
        return getNewRecords(statePage, searchVal)
      }
    }
  }

  const handlePageRender = (page, searchVal) => {
    setPageChanged(false)
    setStatePage(page)
    setStateSearch(searchVal)
    return getNewRecords(page, searchVal)
  }

  /**
   * Update the current search term
   */
  const updateSearchTerm = (event) => {
    setStateSearch(event.target.value)
    setIsSearched(true)
  }

  /**
   * Handle a keydown event while we are in the search term box
   */
  const handleSearchKeyDown = (event) => {
    if (event.keyCode !== 13) return
    this.updateSearchTerm(event)
    return getNewRecords(statePage, stateSearch)
  }

  const clearSearch = () => {
    setStateSearch('')
    setIsSearched(true)
    return getNewRecords(statePage, '')
  }

  const renderSearch = () => {
    if (!showSearch) return null
    let searchVal = (isSearched) ? stateSearch : search
    let curPageVal = (PageChanged) ? statePage : curPage
    return (
      <form>
        <div className='admin-list-table-search search_input_box'>
          <input
            type='text'
            className='form-control'
            placeholder='Search here'
            value={searchVal}
            onChange={(e) => updateSearchTerm(e)}
            onKeyDown={(e) => handleSearchKeyDown(e)}
          />
          {(searchVal != '') ?
            <span className='crossbtn' onClick={() => clearSearch()}>✕</span>
            : ''}
          <button
            type='button'
            className='btn btn-primary mt-0 search-form myformchange searchforms'
            style={{ height: 'auto' }}
            onClick={() => handlePageRender(curPageVal, searchVal)}
          >
            <i className='fa fa-search'></i>
          </button>
        </div>
      </form>
    )
  }

  const renderPagination = () => {
    if (!getNewRecords) return null
    const maxPages = (total === undefined || perPage === undefined) ? 1 : Math.ceil(total / perPage)
    // Hide if we only have one page
    if (maxPages <= 1) return null
    let searchVal = (isSearched) ? stateSearch : search
    return (
      <div className='admin-table-pagination mb-2'>
        {curPage < maxPages && <button className='fa fa-chevron-right next-page' aria-hidden='true' onClick={() => handlePageRender(curPage + 1, searchVal)}></button>}
        {curPage === maxPages && <button className='fa fa-chevron-right next-page disabled' aria-hidden='true' disabled onClick={() => handlePageRender(curPage + 1, searchVal)}></button>}
        {curPage !== 1 && <button className='fa fa-chevron-left prev-page' aria-hidden='true' onClick={() => handlePageRender(curPage - 1, searchVal)}></button>}
        {curPage === 1 && <button className='fa fa-chevron-left prev-page disabled' aria-hidden='true' disabled onClick={() => handlePageRender(curPage - 1, searchVal)}></button>}
        <span className='current-page'>
          On page
          <input
            className='enter-page'
            type='number'
            min={1}
            value={(PageChanged) ? statePage : curPage}
            onKeyDown={(event) => handleEnterInput(event)}
            onChange={(event) => handlePageInput(event.target.value)}
          />
          of {maxPages}
        </span>
      </div>
    )
  }


  // const onSortArrowClick = (colName, index, order, id) => {
  //   setSortBy(colName)
  //   setSortByIndex(index)
  //   setSortByOrder(order)
  //   onSortClick(id, curPage, order)
  // }

  // const transformDataForSorting = (data) => {
  //   if (!data || typeof data !== 'string') return data
  //   return data
  // }

  // const sort = (data) => {
  //   if (!sortBy) return data
  //   const accessor = columns[sortByIndex].accessor
  //   return data.sort((a, b) => {
  //     const sortA = transformDataForSorting(accessor(a))
  //     const sortB = transformDataForSorting(accessor(b))
  //     if (sortByOrder === ASCENDING) return sortA < sortB ? -1 : 1
  //     return sortA > sortB ? -1 : 1
  //   })
  // }


  return (
    <div className='card-body p-2 table-responsive'>
      {(showSearch || getNewRecords) &&
        <div className='table-header card-body clearfix mb-2'>
          {(data?.length !== 0 || search !== '') && renderSearch()}
          {renderPagination()}
        </div>
      }
      {data && data?.length === 0 && renderNoResults()}
      <table className='table table-border datatable'>
        {data && data.length !== 0 && <thead>
          <th className='p-2'>Sr. No.</th>
          {columns.map((col, i) => {
            return (
              <th key={i} style={col.style ? col.style : {}} className='p-2'>
                {col.header}
                {/* {col.sortable && <SortByArrow
                  colName={col.header}
                  index={i}
                  order={props.sortOrder}
                  id={col.id}
                  selected={props.sortBy}
                  onClick={onSortArrowClick}
                />} */}
              </th>
            )
          })}
        </thead>}
        <tbody>
          {data?.length > 0 ?
            data?.map((currentEle, index) => {
              // let currentPage = (PageChanged) ? statePage : curPage
              // let serialNumber = ((currentPage - 1) * 10) + (index + 1)
              return (
                <tr key={index}>
                  <td>{index+1}</td>
                  {columns.map((col, j) => {
                    return <td className='col-auto ' data-label={col.header} key={j} style={col.style ? col.style : {}}>
                      <div className=''>{col.accessor(currentEle)}</div>
                    </td>
                  })}
                </tr>
              )
            }) : ''}
        </tbody>
      </table>
      <div className='table-header clearfix'>
        {renderPagination()}
      </div>
    </div>
  )
}

AdminListTablePage.propTypes = {
  data: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  userData: PropTypes.object,
  permitData: PropTypes.object,
  columns: PropTypes.array,
  getNewRecords: PropTypes.array,
  curPage: PropTypes.number,
  total: PropTypes.number,
  perPage: PropTypes.number,
  showSearch: PropTypes.bool,
  search: PropTypes.string,
  onSortClick: PropTypes.func
}

AdminListTablePage.defaulProps = {
  isAuthenticated: false,
  userData: EMPTY_OBJECT,
  permitData: EMPTY_OBJECT,
  columns: [],
  getNewRecords: [],
  curPage: 1,
  total: 0,
  perPage: 10,
  showSearch: false,
  search: '',
  onSortClick: noop
}


export default AdminListTablePage
