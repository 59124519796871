import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { EMPTY_ARRAY, EMPTY_OBJECT, validPassword, validPhone, vehicleArray } from '../../.././constants/globalConstants'
import Header from '../../.././components/global/Header'
import PassForm from '../home/PassForm'
import { Circles } from 'react-loader-spinner'
import { encryptionHandler, validateEmail } from '../../../utils/helper'
import { savePassBookingBeforePayment, getPassPaymentInitials , passTokenPayment } from '../../../redux/actions/paymentActions'
import PassSteps from '../../../components/global/PassSteps'
import Confirmation from '../confirmation/Confirmation'
import PaymentPage from '../payment/PaymentPage'
import moment from 'moment'

function CreatePass(props) {
    const { dispatch, isAuthenticated, partnerDetails } = props
    const [page, setPage] = useState()
    const [active, setActive] = useState('home')
    const [formData, setFormData] = useState({ vehicleList: vehicleArray, userConsent: true })
    const [formError, setFormError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [previousActive, setPreviousActive] = useState(false)
    const [response, setResponse] = useState({})
    const [errorMessage, setErrorMessage] = useState()
    const [res, setRes] = useState('')
    const [url, setUrl] = useState('')
    const [guid, setGuid] = useState(EMPTY_OBJECT)
    //const navigate = useNavigate()
    const formRef = useRef()

    useEffect(() => {
        if (page == 'confirmation') {
            setActive('confirmation')
        }
        if (page == 'payment') {
            paymentInitials(formData)
        }
    }, [page])


    useEffect(() => {
        if (isAuthenticated) {
            setIsLoggedIn(true)
        } else {
            const getuserDatafromLocal = JSON.parse(localStorage.getItem('userData'))

            if (getuserDatafromLocal?.access_token && getuserDatafromLocal?.access_token != '') {
                setIsLoggedIn(true)
                // let userNameData=getuserDatafromLocal?.user?.name.split(' ')
                // console.log(userNameData,'userNameData')
                // formData.firstname=userNameData[0]
                // formData.lastname=userNameData[1]
                formData.phone = getuserDatafromLocal?.user?.phone
                formData.address = getuserDatafromLocal?.user?.address
                formData.zipcode = getuserDatafromLocal?.user?.pincode
            } else {
                setIsLoggedIn(false)
            }
        }

        formData.vehicleList[0].license_plate = ''
        formData.vehicleList[0].make = ''
        formData.vehicleList[0].model = ''
        formData.vehicleList[0].color = ''

    }, [EMPTY_ARRAY, isAuthenticated])

    const handleNext = () => {
        if (page == 'home') {
            setFormError('home')
            setIsLoading(true)
            setErrorMessage('')
            if (Object.values(formData).length > 0 && formData?.license_number && formData?.vehicleList?.length > 0 && formData?.vehicleList[0]?.license_plate?.length >= 3 && formData?.vehicleList[0]?.license_plate?.length <= 10 && formData?.firstname && formData?.email && formData?.phone && formData?.facility_id && formData?.pass_id && formData?.vehicleList) {
                // formData?.address && formData?.city && formData?.state && formData?.zipcode
                const vehicles = formData?.vehicleList
                const firstVehicle = vehicles[0]
                if (firstVehicle?.license_plate == '' || firstVehicle?.make == '' || firstVehicle?.model == '' || firstVehicle?.color == '') {
                    setErrorMessage('')
                } else {

                    if (isLoggedIn) {
                        if (formData?.email) {
                            if (validateEmail(formData?.email)) {
                                if (validPhone.test(formData?.phone) && formData?.phone?.length == 10) {
                                    setErrorMessage('')
                                    setIsLoading(false)
                                    setActive('payment')
                                } else {
                                    setIsLoading(false)
                                }
                            } else {
                                setIsLoading(false)
                            }
                        }
                    } else {
                        if (formData?.email) {
                            if (validateEmail(formData?.email)) {
                                setErrorMessage('')
                                if (formData?.password) {
                                    if (formData?.password == formData?.cpassword) {
                                        if (validPassword.test(formData?.password)) {
                                            if (validPhone.test(formData?.phone) && formData?.phone?.length == 10) {
                                                setErrorMessage('')
                                                setIsLoading(false)
                                                setActive('payment')
                                            } else {
                                                setIsLoading(false)
                                            }
                                        } else {
                                            setIsLoading(false)
                                        }
                                    } else {
                                        setIsLoading(false)
                                    }
                                } else {
                                    setIsLoading(false)
                                }
                            } else {
                                setIsLoading(false)
                            }
                        }
                    }
                }
            } else {
                setIsLoading(false)
            }

            // paymentInitials(formData)
        } else if (page == 'payment') {

            setErrorMessage('')
            setActive('confirmation')
        }
    }
    const paymentInitials = (formData) => {
        const paymentSessionPayload = {
            'pass_id': formData?.pass_id,
            'facility_id': formData?.facility_id
        }
        dispatch(getPassPaymentInitials(paymentSessionPayload)).then((response) => {
            setUrl(response?.data?.api_url)
            setRes(response?.data?.ipgSession)
            setGuid(response?.data?.guid)
            localStorage.setItem('paymentSession', JSON.stringify(response?.data))
        }).catch((err) => {
            console.log(err)
        })
    }
    const handlePrevious = () => {
        if (page == 'payment') {
            setErrorMessage('')
            setActive('home')
        } else if (page == 'confirmation') {
            setErrorMessage('')
            setActive('payment')
        }
    }

    const handleSubmit = (e) => {
        const event = e
        const form = formRef.current
        setFormError('pass')
        if (Object.values(formData).length > 0 && (formData?.payment_profile_id || (formData?.card_pan && formData?.name && formData?.card_date_expiry_month && formData?.card_date_expiry_year && formData?.card_card_security_cvx_2 && formData?.zip_code))) {
            setErrorMessage('')
            setIsLoading(true)
            const userData = JSON.parse(localStorage.getItem('userData'))
            const paymentSessionValue = JSON.parse(localStorage.getItem('paymentSession'))
            let nonce
            if (!formData?.payment_profile_id && (formData?.payment_profile_id == '' || formData?.payment_profile_id == undefined || formData?.payment_profile_id == null)) {
                const card_expiration_date = formData?.card_date_expiry_month + formData?.card_date_expiry_year
                let cardData = {
                    name_on_card: formData?.name,
                    card_number: formData?.card_pan.replace(/\D/g, ''),
                    expiration_date: card_expiration_date.replace(/\D/g, ''),
                    security_code: formData?.card_card_security_cvx_2,
                    zip_code: formData?.zip_code
                }
                nonce = encryptionHandler(cardData)
            }
            // to filter the data of vehicle Array object should not be empty with both values
            // const finalVehicleList = formData?.vehicleList?.filter((item) => (item?.license_plate === '' && item?.make_model === '') ? null : item)

            let formPostData = new FormData()    //formdata object
            formPostData.append('license_plate', formData?.vehicleList?.[0]?.license_plate ? formData?.vehicleList?.[0]?.license_plate : '')
            formPostData.append('make', formData?.vehicleList?.[0]?.make ? formData?.vehicleList?.[0]?.make : '')   //append the values with key, value pair
            formPostData.append('model', formData?.vehicleList?.[0]?.model ? formData?.vehicleList?.[0]?.model : '')   //append the values with key, value pair
            formPostData.append('color', formData?.vehicleList?.[0]?.color ? formData?.vehicleList?.[0]?.color : '')   //append the values with key, value pair
            formPostData.append('license_number', formData?.license_number ? formData?.license_number : '')
            formPostData.append('first_name', formData?.firstname ? formData?.firstname : '')
            formPostData.append('last_name', formData?.lastname ? formData?.lastname : '')
            formPostData.append('email', formData?.email ? formData?.email : '')
            formPostData.append('mobile', formData?.phone ? formData?.phone : '')
            // formPostData.append('vehicleList', JSON.stringify(finalVehicleList))
            formPostData.append('facility_id', formData?.facility_id ? formData?.facility_id : '')
            formPostData.append('pass_id', formData?.pass_id ? formData?.pass_id : '')
            formPostData.append('user_id', isLoggedIn ? userData?.user?.id : '')
            formPostData.append('address', formData?.address ? formData?.address : '')
            formPostData.append('address2', formData?.address2 ? formData?.address2 : '')
            formPostData.append('city', formData?.city ? formData?.city : '')
            formPostData.append('state', formData?.state ? formData?.state : '')
            formPostData.append('zipcode', formData?.zipcode ? formData?.zipcode : '')
            formPostData.append('confirm_password', formData?.cpassword ? formData?.cpassword : '')
            formPostData.append('password', formData?.password ? formData?.password : '')
            formPostData.append('is_card_req', '1')
            formPostData.append('payment_profile_id', formData?.payment_profile_id ? formData?.payment_profile_id : '')
            formPostData.append('name_on_card', formData?.name ? formData?.name : '')
            formPostData.append('is_admin', '0')
            formPostData.append('nonce', nonce ? nonce : '')
            // payment

            const passDetails = JSON.parse(localStorage.getItem('passDetails') ? localStorage.getItem('passDetails') : '{}')
            const beforePaymentPayload = {
                'email': formData?.email ? formData?.email : '',
                'end_date': passDetails?.booking_end_date ? moment(passDetails?.booking_end_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
                'end_time': '23:59:59',
                'facility_id': formData?.facility_id ? formData?.facility_id : '',
                'first_name': formData?.firstname ? formData?.firstname : '',
                'last_name': formData?.lastname ? formData?.lastname : '',
                'address': formData?.address ? formData?.address : '',
                'address2': formData?.address2 ? formData?.address2 : '',
                'city': formData?.city ? formData?.city : '',
                'state': formData?.state ? formData?.state : '',
                'zipcode': formData?.zipcode ? formData?.zipcode : '',
                'password': formData?.password ? formData?.password : '',
                'confirm_password': formData?.cpassword ? formData?.cpassword : '',
                'license_number': formData?.license_number ? formData?.license_number : '',
                'license_plate': formData?.vehicleList?.[0]?.license_plate ? formData?.vehicleList?.[0]?.license_plate : '',
                'make': formData?.vehicleList?.[0]?.make ? formData?.vehicleList?.[0]?.make : '',
                'model': formData?.vehicleList?.[0]?.model ? formData?.vehicleList?.[0]?.model : '',
                'color': formData?.vehicleList?.[0]?.color ? formData?.vehicleList?.[0]?.color : '',
                'mobile': formData?.phone ? formData?.phone : '',
                'pass_id': formData?.pass_id ? formData?.pass_id : '',
                'start_date': moment().format('YYYY-MM-DD'),
                'start_time': moment().format('h:mm:ss'),
                //'start_time': '01:14:34',
                'reference': paymentSessionValue.reference,
                'user_id': isLoggedIn ? userData?.user?.id : '',
                'payment_profile_id': formData?.payment_profile_id ? formData?.payment_profile_id : ''
            }
            localStorage.setItem('beforePaymentPayloadValue', JSON.stringify(beforePaymentPayload))
            localStorage.setItem('bookingType', 'Pass')

            const passTokenPaymentPayload = {
                'TokenNo': formData?.payment_profile_id,
                'ref': paymentSessionValue?.reference,
                'facility_id': formData?.facility_id,
                'pass_id': formData?.pass_id,
            }

            dispatch(savePassBookingBeforePayment(beforePaymentPayload)).then((response) => {
                const responseData = response?.data
                if (responseData && response?.data?.mer_reference && (response?.data?.mer_reference != '' || response?.data?.mer_reference != null || response?.data?.mer_reference != undefined)) {

                    const userDetailsFromLocal = JSON.parse(localStorage.getItem('userData'))
                    if (userDetailsFromLocal?.access_token) {
                        if (responseData?.error_msg && responseData?.error_msg != '') {
                            setResponse(responseData?.error_msg)
                            const userDetailsFromLocal = JSON.parse(localStorage.getItem('userData'))
                            userDetailsFromLocal.user = responseData?.userDetails
                            // const userData = responseData?.userDetails
                            if (userDetailsFromLocal) {
                                localStorage.setItem('userData', JSON.stringify(userDetailsFromLocal))
                            } else {
                                localStorage.setItem('userData', '{}')
                            }
                        } else {

                            const userData = responseData?.userDetails

                            if (userData) {
                                const userDetailsFromLocal = JSON.parse(localStorage.getItem('userData'))
                                userDetailsFromLocal.user = userData
                                localStorage.setItem('userData', JSON.stringify(userDetailsFromLocal))
                                if (formData?.payment_profile_id) {
                                    dispatch(passTokenPayment(passTokenPaymentPayload)).then((res) => {
                                        localStorage.setItem('transactionState', res?.Params?.TxState)
                                        setIsLoading(false)
                                    })
                                    setPage('confirmation')
                                } else {
                                    if (event) {
                                        //console.log('form',form)
                                        form.submit()
                                    }
                                }
                            } else {
                                localStorage.setItem('userData', '{}')
                            }

                            setResponse(response)

                        }
                    } else {

                        if (responseData?.error_msg && responseData?.error_msg != '') {
                            setResponse(responseData?.error_msg)
                            const userData = responseData?.userDetails
                            if (userData) {
                                localStorage.setItem('userData', JSON.stringify(userData))
                            } else {
                                localStorage.setItem('userData', '{}')
                            }
                        } else {
                            const userData = responseData?.userDetails

                            if (userData) {
                                localStorage.setItem('userData', JSON.stringify(userData))
                                if (formData?.payment_profile_id) {
                                    dispatch(passTokenPayment(passTokenPaymentPayload)).then((res) => {
                                        localStorage.setItem('transactionState', res?.Params?.TxState)
                                        setIsLoading(false)
                                    })
                                    setPage('confirmation')
                                } else {
                                if (event) {
                                    // console.log('form',form)
                                    form.submit()
                                }
                            }
                            } else {
                                localStorage.setItem('userData', '{}')
                            }

                            setResponse(response)
                        }
                    }
                    setPreviousActive(false)

                } else {
                    const errors = response?.errors
                    setResponse(errors ? errors?.message : 'Something went wrong, Please try again!')
                    setPage('confirmation')
                    setPreviousActive(true)
                }
                formRef.current = false
            }).catch((error) => {
                formRef.current = false
                const ErrorMsg = error?.errors
                setResponse(ErrorMsg?.message ? ErrorMsg?.message : 'Something went wrong, Please try again!')
                setPage('confirmation')
                setPreviousActive(true)
            })
            // end
        } else {
            setIsLoading(false)
            // setErrorMessage('Please enter credit card details')
        }
    }

    return (
        <div>
            <Header page={page} partnerDetails={partnerDetails}/>
            <main>
                <section id="forms" className="formDesign p-sm-3">
                    <div className="container mt-2 " id="qbox-container">
                        <div className='row justify-content-center'>
                            <div className="col-md-10 text-center mb-3">
                                <h1>Pass Creation</h1>
                            </div>
                        </div>
                    </div>
                    <PassSteps page={page} />
                    <div className="container mt-2 " id="qbox-container">
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                {/* <form className="needs-validation" id="form-wrapper" method="post" name="form-wrapper" noValidate=""> */}
                                <form action={url} ref={formRef} id='ipg_form' target="_top" name='ipg_form' method='post' >

                                    <div id="steps-container">
                                        {active == 'home' && <PassForm setPage={setPage} formData={formData} setFormData={setFormData} formError={formError} />}
                                        {active == 'payment' && <PaymentPage guidValue={guid} resValue={res} setPage={setPage} formData={formData} setFormData={setFormData} formError={formError} />}
                                        {active == 'confirmation' && <Confirmation setPage={setPage} response={response} />}
                                    </div>
                                    {errorMessage != '' ? <span className='text-danger'>{errorMessage}</span> : ''}
                                    <div id="q-box__buttons" className="text-center mt-4 d-flex justify-content-center">
                                        {(active != 'home' && (previousActive || active == 'payment')) && <button id="next-btn" type="button" className="btn btn-primary" onClick={(e) => handlePrevious(e)}>Previous</button>}&nbsp;
                                        {(active != 'payment' && active != 'confirmation') && <button id="next-btn" type="button" className="btn btn-primary d-flex" onClick={(e) => handleNext(e)}>Next {page == 'payment' && isLoading ? <span className=' ms-2'><Circles
                                            height="20"
                                            width="20"
                                            color="#ffffff"
                                            ariaLabel="circles-loading"
                                            visible={true}
                                        /></span> : null}
                                        </button>}&nbsp;
                                        {active == 'payment' && <button id="next-btn" type="button" className="btn btn-primary d-flex" onClick={(e) => handleSubmit(e)}>Submit {isLoading ? <span className=' ms-2'><Circles
                                            height="20"
                                            width="20"
                                            color="#ffffff"
                                            ariaLabel="circles-loading"
                                            visible={true}
                                        /></span> : null}</button>}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

CreatePass.propTypes = {
    dispatch: PropTypes.func,
    data: PropTypes.object,
    events: PropTypes.object,
    loader: PropTypes.bool,
    getCard: PropTypes.func,
    passData: EMPTY_ARRAY,
    isAuthenticated: PropTypes.bool,
    isModeOpen: PropTypes.bool,
    partnerDetails: PropTypes.object,
}

CreatePass.defaulProps = {
    dispatch: noop,
    data: EMPTY_OBJECT,
    events: EMPTY_OBJECT,
    loader: false,
    getCard: noop,
    passData: EMPTY_ARRAY,
    isAuthenticated: false,
    isModeOpen: false,
    partnerDetails: EMPTY_OBJECT,
}

function mapStateToProps({ paymentReducer, login }) {
    return {
        data: paymentReducer,
        isAuthenticated: login?.isAuthenticated,
    }
}
export default connect(mapStateToProps)(CreatePass)
