import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
import { userLogin, userSingUp } from '../../redux/actions/login'
import { useNavigate } from 'react-router-dom'
import URL from '../../constants/urls'
import { Circles } from 'react-loader-spinner'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
// import Header from '../../components/global/Header'
import { useForm, Controller } from 'react-hook-form'
import { createDefaultMaskGenerator, MaskedInput } from 'react-hook-mask'
import './login.css'
import { getConfigDetails } from '../../services/config'
const phoneMaskGenerator = createDefaultMaskGenerator('(999) 999-99999')

function Login(props) {

    const { dispatch, caller, notify } = props
    const [isLoggingIn, setIsLoggingIn] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')
    const navigate = useNavigate()
	const partner = window.location.toString().split('/')[3]
    const [passwordShown, setPasswordShown] = useState(false)
    const [cpasswordShown, setCPasswordShown] = useState(false)
    const [mpasswordShown, setMPasswordShown] = useState(false)
    const { register, handleSubmit, formState: { errors }, control, reset } = useForm()



    const [loginPayload, setLoginPayload] = useState({ 'username': '', 'password': '', 'client_id': getConfigDetails()?.CLIENT_ID, 'client_secret': getConfigDetails()?.CLIENT_SECRET, 'grant_type': 'password' })
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
        const getuserDatafromLocal = JSON.parse(localStorage.getItem('userData'))
        if (getuserDatafromLocal?.access_token && getuserDatafromLocal?.access_token != '' && getuserDatafromLocal?.user?.email != '') {
            navigate(`/${partner}/user`)
        }
    }, [])

    const togglePasswordVisiblity = (e, key) => {
        if (key === 'password') {
            setPasswordShown(passwordShown ? false : true)
        }
        if (key === 'mpassword') {
            setMPasswordShown(mpasswordShown ? false : true)
        }
        if (key === 'cpassword') {
            setCPasswordShown(cpasswordShown ? false : true)
        }
    }

    const onSignUp = (data) => {
        setIsLoading(true)
        if (data?.cpassword === data?.password) {
            const payload = {
                'first_name': '',
                'last_name': '',
                'email': '',
                'phone': '',
                'confirm_password': '',
                'client_secret': getConfigDetails()?.clientSecret
            }
            const finalPayload = { ...payload, ...data }
            finalPayload['confirm_password'] = finalPayload?.cpassword
            delete finalPayload?.password
            delete finalPayload?.cpassword
            delete finalPayload?.username
            dispatch(userSingUp(finalPayload)).then((res) => {
                if (res?.data) {
                    loginPayload.username = data?.email
                    loginPayload.password = data?.password
                    localStorage?.setItem('userData', JSON.stringify(res?.data))
                    localStorage?.setItem('access_token', JSON.stringify(res?.data?.access_token))
                    notify('success', 'Registration Successfully Completed')
                    dispatch(userLogin(loginPayload)).then((res) => {
                        setIsLoading(false)
                        const userData = res?.data

                        if (userData) {
                            setIsLoading(false)
                            localStorage.setItem('isLoggedIn', true)
                            localStorage.setItem('userData', JSON.stringify(userData))
                            navigate(`/${partner}${URL?.BASE_USER}`)

                        } else {
                            setIsLoading(false)
                            setErrorMessage(res?.errors?.message ? res?.errors?.message : 'Something went wrong, Please try again!')
                        }

                    }).catch((err) => {
                        const response = err?.errors
                        setIsLoading(false)

                        setErrorMessage(response?.message ? response?.message : 'Something went wrong, Please try again!')

                    })
                    // setIsLogin(true)
                    // setShow(false)
                    reset(formValues => ({
                        ...formValues,
                        'first_name': '',
                        'last_name': '',
                        'email': '',
                        'phone': '',
                        'confirm_password': '',
                        'client_secret': getConfigDetails()?.clientSecret
                    }))
                    navigate(`/${partner}${URL?.BASE_HOME}`)
                    setIsLoggingIn(true)
                    setIsLoading(false)
                    // navigate(0)
                } else {
                    // setIsLogin(false)
                    notify('error', res?.errors?.message)
                    setIsLoading(false)
                }
            }).catch(() => {
                notify('error', 'Something went wrong!')
                // setSignInModal(true)
                setIsLoading(false)
            })
        } else {
            notify('error', 'Password not match')
            setIsLoading(false)
        }
    }

    const handleLogin = (e) => {
        const { name, value } = e.target
        setLoginPayload({ ...loginPayload, [name]: value })
    }

    const handleLoginSubmit = (event) => {
        setIsLoading(true)
        event.preventDefault()
        if (loginPayload?.username != '' && loginPayload?.password != '') {
            dispatch(userLogin(loginPayload)).then((res) => {
                setIsLoading(false)
                const userData = res?.data

                if (userData) {
                    setIsLoading(false)
                    if (caller == 'signup') {
                        localStorage.setItem('isLoggedIn', true)
                        localStorage.setItem('userData', JSON.stringify(userData))
                        navigate(`/${partner}/user`)
                    } else {
                        localStorage.setItem('isLoggedIn', true)
                        localStorage.setItem('userData', JSON.stringify(userData))
                        navigate(`/${partner}${URL?.BASE_USER}`)
                        // navigate(0)
                    }

                } else {
                    setErrorMessage(res?.errors?.message ? res?.errors?.message : 'Something went wrong, Please try again!')
                }

            }).catch((err) => {
                const response = err?.errors
                setIsLoading(false)

                setErrorMessage(response?.message ? response?.message : 'Something went wrong, Please try again!')

            })
        } else {
            setIsLoading(false)
            setErrorMessage('Please enter user name & password')
        }
    }
    const handleLoginModal = () => {
        setIsLoggingIn(!isLoggingIn)
    }

    // const handleForgetPassword = () =>{
    //     navigate(URL.FORGOT_PASSWORD)

    // }

    return (

        <div className='container'>
            <div className='row g-0 justify-content-center'>
                {/* loggedInError={loggedInError} */}
                {/* <div className='col-sm-6 p-0'><Header page={'page'} /></div> */}
                <div className='col-sm-6 p-0'>
                    <main className='p-3 bg-white mt-3 mt-sm-5 border shadow-sm '>
                        <section id="forms" className="p-sm-2">
                            <div className='row justify-content-center mt-5'>
                                {isLoggingIn ? <div className='col-10'>
                                    <Modal.Header className='mb-3'>
                                        <Modal.Title id="example-modal-sizes-title-lg">
                                            Login
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">User Name</label>
                                            <input type="email" name='username' className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={handleLogin} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                            <input type="password" name='password' className="form-control" id="exampleInputPassword1" onChange={handleLogin} />
                                        </div>
                                        <span className='text-danger'>{errorMessage}</span>
                                    </Modal.Body>
                                    <Modal.Footer className='d-block'>
                                        <Button type="submit" className="btn btn-primary d-flex" onClick={(e) => handleLoginSubmit(e)}>Submit {isLoading ? <span className=' ms-2'><Circles
                                            height="20"
                                            width="20"
                                            color="#ffffff"
                                            ariaLabel="circles-loading"
                                            visible={true}
                                        /></span> : null}
                                        </Button>

                                        {/* <div className="mb-3 d-block mt-3">
                                <a type='button' className='text-primary fw-bold text-decoration-none' role='button' onClick={handleForgetPassword}>Forgot Password?</a>
                                </div> */}

                                        <div className="mb-3 d-block mt-3">
                                            New on our platform? <a type='button' className='text-primary fw-bold text-decoration-none' role='button' onClick={handleLoginModal}>Create an account</a>
                                        </div>
                                    </Modal.Footer>
                                </div> :
                                    <div className='col-10'>
                                        <form onSubmit={handleSubmit(onSignUp)}>
                                            <Modal.Header className='mb-3'> <Modal.Title>Create your Account</Modal.Title></Modal.Header>
                                            <Modal.Body>
                                                <div className="checkinForm">
                                                    <div className='row'>
                                                        <div className='col-sm-6'>
                                                            <div className="mt-2">
                                                                <label htmlFor="" className="form-label">First Name <span style={{ color: 'red' }}>*</span></label>
                                                                <input type="text" className="form-control" name='first_name' id="first_name" placeholder="Enter First Name" {...register('first_name', { required: true })} />
                                                            </div>
                                                            {errors?.first_name?.type === 'required' && <span style={{ color: 'red' }}>This field is required</span>}
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <div className="mt-2">
                                                                <label htmlFor="" className="form-label">Last Name</label>
                                                                <input type="text" name='last_name' className="form-control" id="last_name" placeholder="Enter Last Name" {...register('last_name')} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='row'>
                                                        <div className='col-sm-6'>
                                                            <div className="mt-2">
                                                                <label htmlFor="" className="form-label">Email <span style={{ color: 'red' }}>*</span></label>
                                                                <input type="email" name='email' className="form-control" maxLength='40' minLength='1' placeholder="Enter your email" {...register('email', {
                                                                    required: true,
                                                                    pattern: {
                                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                        message: 'invalid email address'
                                                                    }
                                                                })} />
                                                            </div>
                                                            {errors?.email?.type === 'required' && <span style={{ color: 'red' }}>This field is required</span>}
                                                            {errors?.email?.type === 'pattern' && <span style={{ color: 'red' }}>{errors?.email?.message}</span>}
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <div className="mt-2">
                                                                <label htmlFor="" className="form-label">Phone <span style={{ color: 'red' }}>*</span></label>
                                                                <Controller
                                                                    name="phone"
                                                                    type="text"
                                                                    id="phone"
                                                                    control={control}
                                                                    rules={{ required: true, maxLength: 14 }}
                                                                    render={({ field }) => <MaskedInput
                                                                        maskGenerator={phoneMaskGenerator}
                                                                        className="form-control"
                                                                        {...field}
                                                                        maxLength={14}
                                                                        title="Please enter a valid phone number"
                                                                        onInvalid={e => e.target.setCustomValidity('Please enter phone number')}
                                                                        onInput={e => e.target.setCustomValidity('')}
                                                                        placeholder="Phone Number"
                                                                    />}
                                                                />
                                                            </div>
                                                            {errors?.phone?.type === 'required' && <span style={{ color: 'red' }}>This field is required</span>}
                                                            {errors?.phone?.type === 'minLength' && <span style={{ color: 'red' }}>{errors?.phone?.message}</span>}
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-6 myiconset">
                                                            <div className="mt-2">
                                                                <label htmlFor="" className="form-label">Password <span style={{ color: 'red' }}>*</span></label>
                                                                <input className="form-control ng-untouched ng-pristine ng-invalid passwordInput"
                                                                    name="password" placeholder="Create Password"
                                                                    {...register('password', { required: true })}
                                                                    // reverse="true" 
                                                                    type={mpasswordShown ? 'text' : 'password'} 
                                                                    // validateequal="cpassword"
                                                                     />
                                                            </div>
                                                            <i className={mpasswordShown ? 'fas fa-eye-slash iconset' : 'fas fa-eye iconset'} onClick={(e) => togglePasswordVisiblity(e, 'mpassword')}></i>
                                                            {errors?.password?.type === 'required' && <span style={{ color: 'red' }}>This field is required</span>}
                                                        </div>
                                                        <div className="col-sm-6 myiconset">
                                                            <div className="mt-2">
                                                                <label htmlFor="" className="form-label">Confirm Password <span style={{ color: 'red' }}>*</span></label>
                                                                <input className="form-control ng-untouched ng-pristine ng-invalid passwordInput"
                                                                    {...register('cpassword', { required: true })}
                                                                    name="cpassword" placeholder="Confirm Password"
                                                                    type={cpasswordShown ? 'text' : 'password'} 
                                                                    // validateequal="password" 
                                                                    />
                                                            </div>
                                                            <i className={cpasswordShown ? 'fas fa-eye-slash iconset' : 'fas fa-eye iconset'} onClick={(e) => togglePasswordVisiblity(e, 'cpassword')}></i>
                                                            {errors?.cpassword?.type === 'required' && <span style={{ color: 'red' }}>This field is required</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer className='d-block'>
                                                <Button type="submit" className="btn btn-primary mt-3" disabled={isLoading ? true : false}>
                                                    <div className='d-flex aling-items-center'>
                                                        <span>Submit</span> <span className='ms-2'>
                                                            {isLoading ? <Circles
                                                                height="20"
                                                                width="20"
                                                                style={{ display: 'inline-block' }}
                                                                color="#ffffff"
                                                                ariaLabel="circles-loading"
                                                                visible={true}
                                                            /> : null}
                                                        </span>
                                                    </div>
                                                </Button>
                                                <div className="mb-3 d-block mt-3">
                                                    Already have an Account? <a type='button' className='text-primary text-decoration-none fw-bold' role='button' onClick={handleLoginModal}>Login</a>
                                                </div>
                                            </Modal.Footer>
                                        </form>
                                    </div>}
                            </div>
                        </section>
                    </main>
                </div>
            </div>
        </div>
    )
}


Login.propTypes = {
    dispatch: PropTypes.func,
    data: PropTypes.object,
    events: PropTypes.object,
    loader: PropTypes.bool,
    getCard: PropTypes.func,
    passData: EMPTY_ARRAY,
    setPage: PropTypes.func,
    setFront: PropTypes.func,
    setBack: PropTypes.func,
    setIsLoggedIn: PropTypes.func,
    homePayload: PropTypes.object,
    // 
    isLoginModal: PropTypes.bool,
    setLoginModal: PropTypes.func,
    caller: PropTypes.string,
    loaderSing: PropTypes.bool,
    notify: PropTypes.func,

}

Login.defaulProps = {
    dispatch: noop,
    data: EMPTY_OBJECT,
    events: EMPTY_OBJECT,
    loader: PropTypes.bool,
    getCard: PropTypes.func,
    passData: EMPTY_ARRAY,
    setPage: noop,
    setFront: noop,
    setBack: noop,
    setIsLoggedIn: noop,
    homePayload: EMPTY_OBJECT,
    // 
    isLoginModal: PropTypes.bool,
    setLoginModal: PropTypes.func,
    caller: undefined,
    loaderSing: false,
    notify: noop,

}

function mapStateToProps({ login }) {
    return {
        loader: login?.isAuthenticated,
    }
}
export default connect(mapStateToProps)(Login)