import React, { useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import moment from 'moment'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
// import { Circles } from 'react-loader-spinner'
import { EMPTY_ARRAY, EMPTY_OBJECT , saveCard} from '../../../constants/globalConstants'
import { getInvoicePermitList, downloadInvoice } from '../../../redux/actions/InvoiceAction'
import AddCardPaymentModal from '../../../components/global/addCardPaymentModal'
import { encryptionHandler , isLoggedIn, sortPortalPermissionByKey } from '../../../utils/helper'
import { PermitRenewData } from '../../../redux/actions/PermitListAction'
import { getCardDataList } from '../../../redux/actions/cardDetailsAction'
import { isPermissionExist } from '../../../utils/helper'
import { renderNoResults } from '../../../components/global/NoDataFound'
import { errorMessage } from '../../../constants/errorMessage'

// import { downloadTicket } from '../../../redux/actions/eTicketRecipt'


function Invoice(props) {
    const { dispatch, key, partnerDetails, notify } = props
    // const [entryDateTime, setEntryDateTime] = useState(new Date())
    // const [startDateTime, setStartDateTime] = useState(new Date())
    const [month, setMonth] = useState(EMPTY_OBJECT)
    const [selectedPermit, SetSelectedPermit] = useState('')
    const [permit, setPermit] = useState(EMPTY_OBJECT)
    const [monthEnable, setMonthenable] = useState(true)
    const [monthList, setMonthList] = useState(EMPTY_ARRAY)
    const [billing_invoices , setbilling_invoices] = useState(EMPTY_ARRAY)
    const [loader, setLoader] = useState(true)
    // const [isDownloaded , setIsDownloaded] = useState(false)
    // card payment
    const [expiry, setExpiry] = useState()
    const [card, setCard] = useState()
    const [isModalOpen, setModal] = useState(false)
    const [payload, setPayload] = useState(saveCard)
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessagePay, setErrorMessagePay] = useState('')
    const [cardList, setCardList] = useState(EMPTY_ARRAY)
    const [isCardFormVisible, setCardFormVisible] = useState(false)
    const [error, setError] = useState(false)
    const [paynowenable , setPayNowenable] = useState(true)
    const [accessPermissions, setAccessPermissions] = useState('')
    const [displayError, setDisplayError] = useState('')
    let dateDisplayFormat = 'DD MMM, YYYY'
    

    useEffect(() => {
        dispatch(getInvoicePermitList()).then((res) => {
            console.log(res,'res')
            if (res) {
                setPermit(res)
                if(res?.permit_list?.length == 1){
                    SetSelectedPermit(res?.permit_list[0].account_number)
                    setMonthList(res?.permit_list[0]?.billing_details)
                    setMonth(res?.permit_list[0]?.billing_details[0])
                    setbilling_invoices([res?.permit_list[0]?.billing_details[0]])
                    setMonthenable(false)
                    setLoader(false)
                }else{
                setDisplayError(res?.errors && res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)

                }
            }else{                
                setDisplayError(res?.errors && res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
            }
        }).catch((err) => {
            setDisplayError(err?.errors && err?.errors?.message ? err?.errors?.message : errorMessage?.somethingWentWrong)
        })
        if (isLoggedIn()) {
           
            setCardFormVisible(false)
            const facilityIDS = JSON.parse(localStorage.getItem('facility'))
            const facility_id = partnerDetails?.facilities?.[0]?.id ? partnerDetails?.facilities?.[0]?.id : facilityIDS?.id
            dispatch(getCardDataList(facility_id)).then((res) => {
                if (res?.data) {
                    setCardList(res?.data?.payments)
                    // setIsLoading(false)
                }
            }).catch(() => {
                // setIsLoading(false)
            })
        } else {
            setCardFormVisible(true)
        }
        const getAccessPermissionData = sortPortalPermissionByKey(partnerDetails, 'Restricted Permissions')
        setAccessPermissions(getAccessPermissionData)
    }, [EMPTY_ARRAY])

    
    const handleSubmit = () => {
        setError(true)
        setIsLoading(true)
        if (Object.values(payload)?.length > 0 && payload?.payment_profile_id ||  (payload?.card_number && payload?.card_number?.length === 16 && payload?.expiration_date && payload?.security_code && payload?.zip_code_on_card)) {
            payload.zip_code = payload?.zip_code_on_card
            let finalPayload
            setErrorMessagePay('')
            if (!payload?.payment_profile_id || payload?.payment_profile_id == '') {
                finalPayload = {
                    'nonce': encryptionHandler(payload),
                    'permit_number': month?.account_number,
                    'user_consent': 1,
                }
            } else {
                finalPayload = {
                    'payment_profile_id': payload?.payment_profile_id,
                    'permit_number': month?.account_number,
                    'user_consent': 0,
                }
            }
            dispatch(PermitRenewData(finalPayload)).then((res) => {
                if (res && res?.data !== null) {
                    notify('success' , 'Payment is made successfully')
                    setPayload({ card_number: '', expiration_date: '' , payment_profile_id : ''})
                    setCard('')
                    setExpiry('')
                    setModal(false)
                    setError(false)
                    setIsLoading(false)
                    setPayNowenable(false)
                    dispatch(getInvoicePermitList()).then((res) => {
                        if (res) {
                            setPermit(res)
                            if(res?.permit_list?.length == 1){
                                SetSelectedPermit(res?.permit_list[0].account_number)
                                setMonthList(res?.permit_list[0]?.billing_details)
                                setMonth(res?.permit_list[0]?.billing_details[0])
                                setbilling_invoices([res?.permit_list[0]?.billing_details[0]])
                                setMonthenable(false)
                                setLoader(false)
                            }
                        }
                    })
                } else if (res?.data === null && res?.errors?.message) {
                    notify('error', res?.errors?.message ? res?.errors?.message : 'Something went wrong, Please try again!')
                    setIsLoading(false)
                    setPayNowenable(true)
                }else{
                    notify('error', res?.errors?.message ? res?.errors?.message : 'Something went wrong, Please try again!')
                    setIsLoading(false)
                }
            }).catch((err) => {
                // setErrorMessage(err?.errors?.message)
                notify('error' , err?.errors?.message ? err?.errors?.message : 'Something went wrong, Please try again!')
                setIsLoading(false)
            })
        } else {
            setIsLoading(false)
            setErrorMessagePay('')
        }
    }

    const handleInputFieldsData = (event) => {
        const { name, value } = event.target

        if (name == 'permit') {
            SetSelectedPermit(value)
            setPayNowenable(true)
            if (value != '') {
                let mapindex = permit?.permit_list?.findIndex((res) => res?.account_number == value)
                setMonthList(permit?.permit_list?.[mapindex]?.billing_details)
                // if(permit?.permit_list?.[mapindex]?.billing_details?.length==1){
                    setMonth(permit?.permit_list?.[mapindex]?.billing_details[0])
                    setbilling_invoices(permit?.permit_list?.[mapindex]?.billing_details)
                    setLoader(false)
                // }
                    setMonthenable(false)
            } else {
                setMonthenable(true)
                setMonth(EMPTY_OBJECT)
                setLoader(true)
            }
        } else if(name == 'month') {
            setPayNowenable(true)
            let mapindex = monthList?.findIndex((res) => res?.from_date == value)
            setMonth(monthList?.[mapindex])
            setbilling_invoices([monthList?.[mapindex]])
            if (value == '') {
                setLoader(true)
            } else {
                setLoader(false)
            }
        }
        else{
            setPayload({ ...payload, [name]: value })
        }
    }

//   const  handleDownloadClick = (invoiceYear, invoiceMonth, index) => {
//         // for Dyanamic Spinner start
//         billing_invoices[index]['SpinnerKey'] = true
//         setIsDownloaded(true)
    
//         // this.setState({ checkin_invoice_month: `${invoiceYear}-${invoiceMonth}`, isDownloaded: true }, () => {
//         //   {
//         //     this.state.partnerDetails.is_autogate == 1 ?
//         //       this.props.dispatch(downloadAutogateInvoice(this.state.checkin_invoice_month, this.state.user_id)).then(() => {
//         //         const { downloadInvoiceDetails } = this.props;
//         //         // for Dyanamic Spinner Close
//         //         billing_invoices[index]["SpinnerKey"] = false;
//         //         this.setState({ isSubmitted: false, isDownloaded: false });
//         //       })
//         //       :
//         //       //Send email Id to check if user is Mapco or other
//         //       this.props.dispatch(downloadInvoice(this.state.checkin_invoice_month, this.state.user_id, partnerDetails.email)).then(() => {
//         //         const { downloadInvoiceDetails } = this.props;
//         //         // for Dyanamic Spinner Close
//         //         billing_invoices[index]["SpinnerKey"] = false;
//         //         this.setState({ isSubmitted: false, isDownloaded: false });
//         //       })
//         //   }
//         // })
//       }

    const handleDownload = () => {
        let partnerDetailsId=partnerDetails?.user_type !='3'  ? partnerDetails?.partner_name?.id : partnerDetails?.id 
        setLoader(true)
        dispatch(downloadInvoice(partnerDetailsId, selectedPermit, month, notify)).then((res) => {
            setLoader(false)
            if (res?.status !== 201 || res?.status !== 200) {
                notify('error', res?.errors?.message)
            }
        }).catch((errors) => {
            notify('error', errors?.message)
            setLoader(false)
        })

    }

    const handlePaymentModalOpen = () => {
        setModal(true)
    }

  

    const hanndleCancel = () =>{
        setPayload({ card_number: '', expiration_date: '' , payment_profile_id : ''})
        setCard('')
        setExpiry('')
        setModal(false)
    }

    const handleCardSelectChange = (e) => {
        const { value } = e.target
        const selectedCard = cardList?.filter((card) => card?.payment_profile_id == value)
        payload['payment_profile_id'] = selectedCard[0]?.payment_profile_id
        setPayload({ ...payload })
    }

    const handleExpiryChange = (value) => {
        if(value.length == 4 && value.substring(2) < moment(new Date()).format('YY')){
            notify('error', 'Please Enter a valid expire date')
            setExpiry('')
            payload['expiration_date'] = ''
            setPayload({ ...payload })
        }else if(value.length == 4 && value.substring(0 , 2) < moment(new Date()).format('MM')){
            notify('error', 'Please Enter a valid expire date')
            setExpiry('')
            payload['expiration_date'] = ''
            setPayload({ ...payload })
        } else{
        setExpiry(value?.trim())
        payload['expiration_date'] = value?.trim()
        setPayload({ ...payload })
        } 
    }

    const handleCardChange = (value) => {
        setCard(value?.trim())
        payload['card_number'] = value?.trim()
        setPayload({ ...payload })
    }
    return (
        <div>
            <div className="d-flex justify-content-between mb-4">
                <h4 className="fw-bold">Invoice </h4>
                {/* <button className="btn btn-success ms-auto" onClick={handlePaymentModalOpen}><i className="fa fa-plus"></i> Add</button> */}
            </div>
       
          {permit && permit?.permit_list?.length > 0 ? <Row key={key} className='row align-items-end'>

                <div className="col-sm-4">
                    <label htmlFor="Permit" className="form-label">Permit<span className='text-danger'> *</span></label>
                    <select
                        name='permit'
                        id='permit'
                        required=""
                        value={selectedPermit}
                        className="form-select"
                        onChange={handleInputFieldsData}
                    >
                        <option value="">Select Permit</option>
                        {permit?.permit_list?.map((item, key) => <option key={key} value={item?.account_number}>{item?.account_number}</option>)}
                    </select>
                </div>

                <div className="col-sm-4">
                    <label htmlFor="Month" className="form-label">Month<span className='text-danger'> *</span></label>
                    <select
                        name='month'
                        id='month'
                        required=""
                        value={month?.from_date ? month?.from_date : ''}
                        disabled={monthEnable}
                        className="form-select"
                        onChange={handleInputFieldsData}
                    >
                        <option value="">Select Month</option>
                        {monthList?.map((item, key) => <option key={key} value={item?.from_date}>{`${moment(item?.from_date).format('MMM')}, ${moment(item?.from_date).year()}`}</option>)}
                    </select>
                </div>

                <div className="col-sm-4">
                    <button
                        className="btn btn-primary "
                        onClick={handleDownload}
                        disabled={(loader) ? true : false}
                    > Download</button>
                </div>

                {billing_invoices && <div className='bg-light border-top p-1'>
            {(billing_invoices.length > 0) ? billing_invoices.map((invoice, i) => {
              if(i== 0){  
              return (<div key={i} className="card shadow card-body  mt-4  border">
                <div className="row align-items-center">
                  <div className="col-4">
                    <p>
                      <b>Permit Number</b><br></br>
                      {invoice?.account_number ? invoice?.account_number : '-'}
                     </p>
                     

                   {(invoice?.permit_renew_pay == '1' || (parseFloat(invoice?.bill_amount) > 0 &&  paynowenable)) ? <p >
                      <b>Amount Due</b><br></br>
                      ${invoice?.renewal_due_amount ? invoice?.renewal_due_amount : 0.00}
                    </p> :
                    <p className="m-0">
                    <b>Amount Paid</b><br></br>
                    ${invoice?.paid_amount ? invoice?.paid_amount : 0.00}
                  </p>
                    }
                    
                  </div>
                  <div className="col-4">
                  <p>
                      <b>Bill Cycle</b><br></br>
                      {moment(invoice?.from_date).format(dateDisplayFormat)} - {moment(invoice?.to_date).format(dateDisplayFormat)}
                    </p>
                    <p className="m-0">
                      <b>Bill Amount</b><br></br>
                      ${invoice?.bill_amount ? invoice?.bill_amount : 0.00}
                    </p>
                  </div>

                 
                  <div className="col-2 text-end text-right">
                    {/* {(parseFloat(invoice?.bill_amount) > 0) && <button type="button" onClick={() => handleDownloadClick(moment(invoice?.from_date, 'YYYY-MM-DD').year(), moment(invoice?.from_date, 'YYYY-MM-DD').subtract(0, 'months').format('MM'), i)} className="btn btn btn-outline-primary invoice-download-link"> {isDownloaded ? 'Please wait...' : 'Download Bill'} {isDownloaded  ? <Circles /> : null} </button>} */}
                    {(isPermissionExist(accessPermissions, 'Invoice Permit Pay Now') && (invoice?.permit_renew_pay == '1' || (parseFloat(invoice?.bill_amount) > 0 &&  paynowenable))) && <button type="button" className="btn btn-outline-primary invoice-download-link ms-1"  data-toggle="modal" data-target="#updateInvoice" onClick={() => handlePaymentModalOpen()}>Pay Now</button>}
                  </div>
                </div>
              </div>)
              }
            }) : ''

            }
          </div>}
            </Row> : renderNoResults(displayError)}
            <AddCardPaymentModal isModalOpen={isModalOpen} hanndleCancel={hanndleCancel} cardList={cardList}  setPayload={setPayload} setCardList={setCardList} setCardFormVisible={setCardFormVisible} isCardFormVisible={isCardFormVisible} setModal={setModal} handleSubmit={handleSubmit} payload={payload} modalName='Add Payment' isLoading={isLoading} handleCardChange={handleCardChange} handleExpiryChange={handleExpiryChange} handleCardSelectChange={handleCardSelectChange} expiry={expiry} card={card} errorMessage={errorMessagePay} error={error} setErrorMessage={setErrorMessagePay} setError={setError} setCard={setCard} setExpiry={setExpiry} handleInputFieldsData={handleInputFieldsData} />

        </div>
    )
}

Invoice.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    handleDetails: PropTypes.func,
    receipt: PropTypes.object,
    key: PropTypes.string,
    notify: PropTypes.func,
    partnerDetails: PropTypes.array

}

Invoice.defaulProps = {
    dispatch: noop,
    loader: true,
    handleDetails: noop,
    receipt: EMPTY_OBJECT,
    key: '',
    notify: noop,
    partnerDetails: EMPTY_ARRAY
}

function mapStateToProps({ userDetailsReducer }) {
    return {
        loader: userDetailsReducer?.loaderDownload
    }
}
export default connect(mapStateToProps)(Invoice)