import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import {  EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
require('./FileUpload.scss')

function FileUpload(props) {
    const { name, accept, onChange, id, required, disabled } = props
    // const hiddenFileInput = useRef(null)

    // const handleClick = () => {
    //     hiddenFileInput.current.click()
    //   }
    
        return (
                <div className="position-relative border">
                    
                    {/* <div className='input-group'> */}
                    {/* <div type='button' onClick={handleClick} className='btn btn-primary' disabled={disabled}>Upload</div>
                        <input type="text" onClick={handleClick} className='form-control'  disabled={disabled} value={id == 'front' ? idProof?.front?.name ? idProof?.front?.name : 'Select file' : idProof?.back?.name ? idProof?.back?.name : 'Select file'}  readOnly /> */}
                    {/* </div> */}
                    <div 
                        // onClick={handleClick}
                        disabled={disabled}
                        className={ disabled ? 'border border-primary btn btn-primary disabled' : 'border border-primary btn btn-primary'}>Upload
                        <input                             
                            aria-describedby="emailHelp"
                            type="file"
                            // ref={hiddenFileInput}
                            accept={accept}
                            onChange={onChange}
                            name={name}
                            className='form-control inputFile'
                            id={id}
                            hidden={disabled}
                            disabled={disabled}
                            required={required}
                        />
                        
                    </div><span className='ps-2'>Select File</span>
                    <span 
                        className={'position-absolute end-0 top-100 custom-file-label-logo'}
                        htmlFor={id}
                    ></span>
                </div>
        )
    }

FileUpload.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    errors: PropTypes.objectOf(
        PropTypes.arrayOf(PropTypes.string)
    ),
    id: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    accept: PropTypes.string,
    idProof:PropTypes.array,
}
FileUpload.defaulProps = {
	name: undefined,
    onChange: noop,
    errors: EMPTY_OBJECT,
    id: undefined,
    required: false,
    disabled: false,
    accept: undefined,
    idProof:EMPTY_ARRAY,
}

const mapStateToProps = () => {
    return {} // Return props object
}

export default connect(mapStateToProps)(FileUpload)
