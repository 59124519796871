import CONSTANTS from '../constants'
import { getRequest, putRequest, postRequest } from '../../services'
import { APICONFIG } from '../config/apiConfig'


// Calls the API to get Facility Initials
// export const getCardDataList = (page = '', search = '', sortBy = '', sortByOrder = '') => (dispatch) => {
//     dispatch({ type: CONSTANTS.GET_CARD_LIST_REQUEST })
//     console.log('pagepage' , page)
//     let url=''
//     if(page)
//      url = `${APICONFIG.getCardDataUrl}/page/${page}`
//     else
//     url = `${APICONFIG.getCardDataUrl}`

//     let endpoint = `${url}`
//     if ((sortBy && sortBy !== '') && (search && search !== '')) {
//         endpoint = `${url}?search=${search}&sort=${sortBy}&sortBy=${sortByOrder}`
//     } else if ((sortBy && sortBy !== '')) {
//         endpoint = `${url}?sort=${sortBy}&sortBy=${sortByOrder}`
//     } else if ((search && search !== '')) {
//         endpoint = `${url}?search=${search}`
//     } else {
//         endpoint = `${url}`
//     }
//     return getRequest(endpoint).then((res) => {
//         dispatch({ type: CONSTANTS.GET_CARD_LIST_SUCCESS, data: res.data })
//         return res
//     }).catch(() => {
//         dispatch({ type: CONSTANTS.GET_CARD_LIST_FAILURE })
//     })
// }
// Calls the API to get Facility Initials
export const getCardDataList = (facility_id) => (dispatch) => {
    // dispatch(addEventLog(facility_id, CONSTANTS.GET_PRO_RATE_LIST_REQUEST))
    dispatch({ type: CONSTANTS.GET_CARD_LIST_REQUEST })
    let endpoint = `${APICONFIG.getCardDataUrl}/${facility_id}`    

    return getRequest(endpoint).then((res) => {
        // let resObject = {  ...res, facility_id: facility_id }
		// dispatch(addEventLog(resObject, CONSTANTS.PERMIT_PURCHASE_SUCCESS))
		
        dispatch({ type: CONSTANTS.GET_CARD_LIST_SUCCESS, data: res.data })
        return res
    }).catch(() => {
        // let errObject = { ...error, facility_id: facility_id }
		// dispatch(addEventLog(errObject, CONSTANTS.GET_PRO_RATE_LIST_FAILURE))
		
        dispatch({ type: CONSTANTS.GET_CARD_LIST_FAILURE })
    })
}


export const createCardData = (payload) => dispatch => {
    dispatch({ type: CONSTANTS.CREATE_CARD_REQUEST })
    return postRequest(APICONFIG.createCardUrl, payload).then((res) => {
        dispatch({ type: CONSTANTS.CREATE_CARD_SUCCESS, data: res.data })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.CREATE_CARD_FAILURE })
        return err
    })
}

export const updateCardData = (payload, cardId) => (dispatch) => {
    dispatch({ type: CONSTANTS.UPDATE_CARD_REQUEST })
    return putRequest(`${APICONFIG.updateCardDataUrl}/${cardId}`, payload).then((res) => {
        dispatch({ type: CONSTANTS.UPDATE_CARD_SUCCESS, data: res.data })
        return res.data
    }).catch(() => {
        dispatch({ type: CONSTANTS.UPDATE_CARD_FAILURE })
    })
}

export const removeCardData = (cardData) => dispatch => {
    dispatch({ type: CONSTANTS.CARD_DELETE_REQUEST })
    return postRequest(`${APICONFIG.removeCardUrl}`, cardData).then((res) => {
        dispatch({ type: CONSTANTS.CARD_DELETE_SUCCESS })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.CARD_DELETE_FAILED })
        return err
    })
}

