import CONSTANTS from '../constants'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'

const INITIAL_STATE = {
    loader: false,
    hasError: false,
    events: EMPTY_OBJECT,
    passDetails: EMPTY_OBJECT,
    vehicleList: EMPTY_ARRAY,
    facilityPass: EMPTY_ARRAY,
    event_type: '',
    errorMessage: '',
}

const passListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        //RESPONSE HANDLING
        case CONSTANTS.GET_PASS_LIST_REQUEST:
            return {
                ...state,
                loader: true,
                errorMessage: ''
            }
        case CONSTANTS.GET_PASS_LIST_SUCCESS:
            return {
                ...state,
                loader: false,
                events: action.data,
                hasError: false,
                errorMessage: ''
            }
        case CONSTANTS.GET_PASS_LIST_FAILURE:
            return {
                ...state,
                loader: false,
                errorMessage: 'There is some Error'
            }

        //  GET VEHICLE LIST DETAILS
        case CONSTANTS.GET_VEHICLE_LIST_REQUEST:
            return {
                ...state,
                loader: true,
                errorMessage: ''
            }
        case CONSTANTS.GET_VEHICLE_LIST_SUCCESS:
            return {
                ...state,
                loader: false,
                vehicleList: action.data,
                hasError: false,
                errorMessage: ''
            }
        case CONSTANTS.GET_VEHICLE_LIST_FAILURE:
            return {
                ...state,
                loader: false,
                errorMessage: 'There is some Error'
            }
        //  GET facility Pass List
        case CONSTANTS.FACILITY_PASS_LIST_REQUEST:
            return {
                ...state,
                loader: true,
                errorMessage: ''
            }
        case CONSTANTS.FACILITY_PASS_LIST_SUCCESS:
            return {
                ...state,
                loader: false,
                facilityPass: action.data,
                hasError: false,
                errorMessage: ''
            }
        case CONSTANTS.FACILITY_PASS_LIST_FAILED:
            return {
                ...state,
                loader: false,
                errorMessage: 'There is some Error'
            }

        default:
            return state
    }
}

export default passListReducer
