import {useState, useEffect} from 'react'

export default function usePathDetector() {

    const [isPathChanged, setIsPathChanged] = useState(window.location.href)
  
    const updatePath = () => {
        setIsPathChanged(window.location.href)
    }
    
    useEffect(() => {
        window.addEventListener('hashchange', updatePath)
      return () => {
        window.addEventListener('hashchange', updatePath)
      }
    },[window.location.href])
  
    return isPathChanged
  }