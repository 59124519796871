import {
    GET_PASSES_REQUEST,
    GET_PASSES_REQUEST_SUCCESS,
    GET_PASSES_REQUEST_FAIL,
    GET_USER_PASSES_REQUEST,
    GET_USER_PASSES_REQUEST_SUCCESS,
    GET_USER_PASSES_REQUEST_FAIL,
    GET_PAYMENT_REQUEST,
    GET_PAYMENT_REQUEST_SUCCESS,
    GET_PAYMENT_REQUEST_FAIL,
    GET_FACILITY_PRICE_REQUEST,
    GET_FACILITY_PRICE_REQUEST_SUCCESS,
    GET_FACILITY_PRICE_REQUEST_FAIL,
    CHECK_VALIDATION_SUCCESS,
    CHECK_VALIDATION_REQUEST,
    CHECK_VALIDATION_FAILURE
} from '../config/ActionTypes'
import axios from '../config/axios'
import { APICONFIG } from '../config/apiConfig'
import { postRequest } from '../../services'
import { getConfigDetails } from '../../services/config'
import {loggedInUserData} from '../../../src/utils/helper'

const token = loggedInUserData()?.access_token
const config = {
    headers: { 
        Authorization: `Bearer ${token}`,
        'X-ClientSecret':getConfigDetails()?.CLIENT_SECRET
    }
}

export const getPasses = (requestData) => {
    return dispatch => {
        dispatch({ type: GET_PASSES_REQUEST })
        let endpoint = `${APICONFIG.getPassesReqUrl}/${requestData.garage_id}?arrival_time=${requestData.arrival}&is_member=${requestData.is_member}`
        return axios.get(endpoint, config)
            .then(res => {
                return dispatch({ type: GET_PASSES_REQUEST_SUCCESS, data: res.data.data, isFetching: false })
            })
            .catch(() => {
                return dispatch({ type: GET_PASSES_REQUEST_FAIL, isFetching: false })
            })
    }
}

export const getUserPasses = (requestData) => {
    return dispatch => {
        dispatch({ type: GET_USER_PASSES_REQUEST })
        let endpoint = `${APICONFIG.getUserPassesReqUrl}?phone=${requestData.phone}`
        return axios.get(endpoint, config)
            .then(res => {
                return dispatch({ type: GET_USER_PASSES_REQUEST_SUCCESS, data: res.data.data, isFetching: false })
            })
            .catch(() => {
                return dispatch({ type: GET_USER_PASSES_REQUEST_FAIL, isFetching: false })
            })
    }
}



// Calls the API to get Payment Initials
export const checkValidations = (data) => (dispatch) => {
	dispatch({ type: CHECK_VALIDATION_REQUEST })
	return postRequest(APICONFIG.getChecksUrl, data).then((res) => {
		dispatch({ type: CHECK_VALIDATION_SUCCESS, data: res })
		return res
	}).catch((err) => {
		dispatch({ type: CHECK_VALIDATION_FAILURE, errorMessage: err?.errors?.message })
        return err?.errors?.message
	})
}

export const makePayment = (requestData) => {
    return dispatch => {
        dispatch({ type: GET_PAYMENT_REQUEST })
        let endpoint = `${APICONFIG.getReservationUrl}`
        return axios.post(endpoint, requestData, config)
            .then(res => {
                return dispatch({ type: GET_PAYMENT_REQUEST_SUCCESS, data: res.data.data, isFetching: false })
            })
            .catch(error => {
                let errorMsg = 'Sorry! something went wrong, please try again.'
                const errorsList = error.response.data.errors
                if (errorsList.message !== 'Validation Errors') {
                    errorMsg = errorsList.message
                } else {
                    for (const key in errorsList.detail) {
                        if (Object.prototype.hasOwnProperty.call(errorsList.detail, key)) {
                            if (typeof errorsList.detail === 'undefined') {
                                errorMsg = errorsList.message
                            } else {
                                errorMsg = errorsList.detail[key]
                            }
                        }
                    }
                }
                return dispatch({ type: GET_PAYMENT_REQUEST_FAIL, hasError:true, errorMessage:errorMsg, isFetching: false })
            })
    }
}

export const getGaragePrice = (requestData) => {
    return dispatch => {
        dispatch({ type: GET_FACILITY_PRICE_REQUEST })
        let endpoint = `${APICONFIG.getGaragePriceUrl}/${requestData.garage_id}?arrival_time=${requestData.arrival_time}&length_of_stay=${requestData.length_of_stay}&use_bonus=${requestData.use_bonus}&is_member=${requestData.is_member}`
        return axios.get(endpoint, config)
            .then(res => {
                return dispatch({ type: GET_FACILITY_PRICE_REQUEST_SUCCESS, data: res.data.data, isFetching: false })
            })
            .catch(error => {
                let errorMsg = 'Sorry! something went wrong, please try again.'
                const errorsList = error.response.data.errors
                if (errorsList.message !== 'Validation Errors') {
                    errorMsg = errorsList.message
                } else {
                    for (const key in errorsList.detail) {
                        if (Object.prototype.hasOwnProperty.call(errorsList.detail, key)) {
                            if (typeof errorsList.detail === 'undefined') {
                                errorMsg = errorsList.message
                            } else {
                                errorMsg = errorsList.detail[key]
                            }
                        }
                    }
                }
                return dispatch({ type: GET_FACILITY_PRICE_REQUEST_FAIL, hasError:true, errorMessage:errorMsg, isFetching: false })
            })
    }
}


export const makeAfterPayment = (requestData) => {
    return dispatch => {
        dispatch({ type: GET_PAYMENT_REQUEST })
        let endpoint = `${APICONFIG.getAfterPayment}`
        return axios.post(endpoint, requestData, config)
            .then(res => {
                return dispatch({ type: GET_PAYMENT_REQUEST_SUCCESS, data: res.data.data, isFetching: false })
            })
            .catch(error => {
                let errorMsg = 'Sorry! something went wrong, please try again.'
                const errorsList = error.response.data.errors
                if (errorsList.message !== 'Validation Errors') {
                    errorMsg = errorsList.message
                } else {
                    for (const key in errorsList.detail) {
                        if (Object.prototype.hasOwnProperty.call(errorsList.detail, key)) {
                            if (typeof errorsList.detail === 'undefined') {
                                errorMsg = errorsList.message
                            } else {
                                errorMsg = errorsList.detail[key]
                            }
                        }
                    }
                }
                return dispatch({ type: GET_PAYMENT_REQUEST_FAIL, hasError:true, errorMessage:errorMsg, isFetching: false })
            })
    }
}


export const failedPayment = (requestData) => {
    return dispatch => {
        dispatch({ type: GET_PAYMENT_REQUEST })
        let endpoint = `${APICONFIG.getReservationUrl}`
        return axios.post(endpoint, requestData, config)
            .then(res => {
                return dispatch({ type: GET_PAYMENT_REQUEST_SUCCESS, data: res.data.data, isFetching: false })
            })
            .catch(error => {
                let errorMsg = 'Sorry! something went wrong, please try again.'
                const errorsList = error.response.data.errors
                if (errorsList.message !== 'Validation Errors') {
                    errorMsg = errorsList.message
                } else {
                    for (const key in errorsList.detail) {
                        if (Object.prototype.hasOwnProperty.call(errorsList.detail, key)) {
                            if (typeof errorsList.detail === 'undefined') {
                                errorMsg = errorsList.message
                            } else {
                                errorMsg = errorsList.detail[key]
                            }
                        }
                    }
                }
                return dispatch({ type: GET_PAYMENT_REQUEST_FAIL, hasError:true, errorMessage:errorMsg, isFetching: false })
            })
    }
}