import React from 'react'
import RoutesComponent from '../../routes/Routes'

function App() {
	
	return (
		<div className="App">
			<RoutesComponent />
		</div>
	)
}

export default App
