import React, { Component } from 'react'
import './carousel.css'

class CarouselItems extends Component {
  
  componentDidMount = () => {
  }

  render() {
    return (
      <div className="carousel hvh" >
        <div id="carouselExampleIndicators" className="carousel slide h-100" data-bs-ride="carousel">
          <div className="carousel-inner h-100">
          <section className="hero position-relative sidebar">
    
        
    <div className="container">
        <div className="row justify-content-md-end">
       
            <div className="col-sm-6 me-4">
            <div className="p-3 topBox text-white">
              <h1 >Wailuku Town Parking Permits </h1>
              <p>
                  <ul>
                      <li>
                        Welcome to the Wailuku Town Parking Permit Portal! Designed exclusively for Wailuku Town, this program offers an alternative monthly parking solution.
                      </li>
                      <li>
                        Artwork for Wailuku: Lori to send
                      </li>
                  </ul>
              </p>
            </div>
                

            </div>
        </div>
    </div>
</section>
          </div>
        </div>
      </div>
    )
  }
}

export default CarouselItems