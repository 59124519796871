import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { EMPTY_ARRAY, EMPTY_OBJECT, EXPIRY_MONTHS, EXPIRY_YEARS } from '../../../constants/globalConstants'
// import { prorateList } from '../../.././redux/actions/paymentActions'
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask'
import { Circles } from 'react-loader-spinner'
import { isLoggedIn, phoneMasker, restrictInputValues, validateCardNumber } from '../../.././utils/helper'
import moment from 'moment'
import { getCardDataList } from '../../.././redux/actions/cardDetailsAction'
const cardnumbermask = createDefaultMaskGenerator('9999 9999 9999 9999')
//const expirymask = createDefaultMaskGenerator('99/99')

function Pass(props) {
    const { setPage, dispatch, formData, setFormData, formError, resValue, guidValue, purchaseSummary } = props
    const [card, setCard] = useState()
    // const [expiryMonth, setExpiryMonth] = useState('')
    // const [expiryYear, setExpiryYear] = useState('')
    // const [phone, setPhone] = useState('')
    // const [couponError, setCouponError] = useState('')
    // const [coupon, setCoupon] = useState('')
    //const [expiry, setExpiry] = useState()
    // const [couponRemoveButton, setCouponRemoveButton] = useState()
    // const [isCouponApplied, setIsCouponApplied] = useState(false)
    // const [isLoading, setIsLoading] = useState(false)
    const [isCardFormVisible, setCardFormVisible] = useState(false)
    const [cardList, setCardList] = useState(EMPTY_ARRAY)
    const [passDetail, setPassDetail] = useState(EMPTY_OBJECT)
    useEffect(() => {
        // setPhone('')

        // const facility = JSON.parse(localStorage.getItem('facility'))
        const passDetails = JSON.parse(localStorage.getItem('passDetails'))
        setPassDetail(passDetails)
        if (isLoggedIn()) {
            setCardFormVisible(false)
            dispatch(getCardDataList()).then((res) => {
                if (res?.data) {
                    setCardList(res?.data?.payments)
                    // setIsLoading(false)
                }
            }).catch(() => {
                // setIsLoading(false)
            })
        } else {
            setCardFormVisible(true)
        }
    }, [EMPTY_ARRAY])

    const handleCardChange = (value) => {
        setCard(value?.trim())
        formData['card_pan'] = value?.trim()
        setFormData({ ...formData })
    }
    // const handleExpiryChange = (value) => {
    //     setExpiry(value?.trim())
    //     formData['expiration_date'] = value?.trim()
    //     setFormData({ ...formData })
    // }
    // const handlePhoneChange = (value) => {
    // 	setPhone(value.trim())
    // 	localStorage.setItem('phone', value.trim())
    // }
    // const handleInputChange = (e) => {
    // 	const {name, value} = e.target

    // 	if(name == 'card_date_expiry_month'){
    // 		setExpiryMonth(value)
    // 	}
    // 	if(name == 'card_date_expiry_year'){
    // 		setExpiryYear(value)
    // 	}

    // 	localStorage.setItem([name], value.trim())

    // }
    const handleCardSelectChange = (e) => {
        const { value } = e.target
        const selectedCard = cardList?.filter((card) => card?.token == value)
        // setFormData({ ...formData, ['card_pan']: selectedCard[0]?.token })
        setFormData({ ...formData, ['payment_profile_id']: selectedCard[0]?.token })
    }

    const showCardForm = () => {
        setCardFormVisible(true)
        setFormData({ ...formData, ['card_pan']: '' })
    }

    const hideCardForm = () => {
        setCardFormVisible(false)
        setFormData({ ...formData, ['card_pan']: '' })
    }

    useEffect(() => {
        setPage('payment')
        delete formData.name
        delete formData.card_pan
        delete formData.card_date_expiry_month
        delete formData.card_date_expiry_year
        delete formData.card_card_security_cvx_2
        delete formData.zip_code
        setFormData({ ...formData })
    }, [EMPTY_ARRAY])

    const handleInputFieldsData = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleConsentChange = (e) => {
        const { name, checked } = e.target
        setFormData({ ...formData, [name]: checked })
    }
    // const handleCouponRemove = () => {
    //     setCouponError('')
    //     setCoupon('')
    //     setCouponRemoveButton(false)
    //     const facility = JSON.parse(localStorage.getItem('facility'))
    //     const permits = JSON.parse(localStorage.getItem('permits'))

    //     const payLoadData = {
    //         facility_id: facility?.id,
    //         driving_license: formData?.license_number,
    //         permit_rate_id: permits?.id
    //     }
    //     dispatch(prorateList(payLoadData)).then((res) => {
    //         setCouponError('')
    //         setIsCouponApplied(false)
    //         setProrateResponse(res?.data)
    //         formData['permit_rate'] = res?.data?.total_amount
    //         formData['coupon_applied'] = false
    //         formData['promocode'] = ''
    //         setFormData({ ...formData })
    //     })
    // }
    // const handleCouponCode = () => {
    //     if (coupon?.trim()) {
    //         setCouponRemoveButton(true)
    //         setCouponError('')
    //         setIsLoading(true)
    //         setIsCouponApplied(true)
    //         setCoupon(coupon)
    //         const facility = JSON.parse(localStorage.getItem('facility'))
    //         const permits = JSON.parse(localStorage.getItem('permits'))
    //         const proRate = {
    //             facility_id: facility?.id,
    //             driving_license: formData?.license_number,
    //             permit_rate_id: permits?.id,
    //             promocode: coupon,
    //             client_id: CLIENT_SECRET,
    //             email: formData?.email
    //         }
    //         dispatch(prorateList(proRate)).then((res) => {
    //             if (res?.data) {
    //                 setIsLoading(false)
    //                 setIsCouponApplied(true)
    //                 formData['permit_rate'] = res?.data?.total_amount
    //                 formData['coupon_applied'] = true
    //                 setFormData({ ...formData })
    //                 setProrateResponse(res?.data)
    //             } else {
    //                 setIsLoading(false)
    //                 setCouponError(res?.errors?.message)
    //                 const facility = JSON.parse(localStorage.getItem('facility'))
    //                 const permits = JSON.parse(localStorage.getItem('permits'))

    //                 const payLoadData = {
    //                     facility_id: facility?.id,
    //                     driving_license: formData?.license_number,
    //                     permit_rate_id: permits?.id
    //                 }
    //                 dispatch(prorateList(payLoadData)).then((res) => {
    //                     setIsCouponApplied(false)
    //                     formData['permit_rate'] = res?.data?.total_amount
    //                     formData['coupon_applied'] = false
    //                     setFormData({ ...formData })
    //                     setProrateResponse(res?.data)
    //                 })

    //             }

    //         }).catch((error) => {
    //             setCouponError(error?.errors?.message)
    //             setCoupon('')
    //             setCouponRemoveButton(false)
    //             const facility = JSON.parse(localStorage.getItem('facility'))
    //             const permits = JSON.parse(localStorage.getItem('permits'))

    //             const payLoadData = {
    //                 facility_id: facility?.id,
    //                 driving_license: formData?.license_number,
    //                 permit_rate_id: permits?.id
    //             }
    //             dispatch(prorateList(payLoadData)).then((res) => {
    //                 setIsCouponApplied(false)
    //                 formData['permit_rate'] = res?.data?.total_amount
    //                 formData['coupon_applied'] = false
    //                 setFormData({ ...formData })
    //                 setProrateResponse(res?.data)
    //             })
    //             setIsLoading(false)
    //             setIsCouponApplied(false)
    //             formData['coupon_applied'] = false

    //         })
    //     } else {
    //         setCouponError('Please enter a valid promocode')
    //         setCoupon('')
    //         setCouponRemoveButton(false)
    //         const facility = JSON.parse(localStorage.getItem('facility'))
    //         const permits = JSON.parse(localStorage.getItem('permits'))

    //         const payLoadData = {
    //             facility_id: facility?.id,
    //             driving_license: formData?.license_number,
    //             permit_rate_id: permits?.id
    //         }
    //         dispatch(prorateList(payLoadData)).then((res) => {
    //             setIsCouponApplied(false)
    //             formData['permit_rate'] = res?.data?.total_amount
    //             formData['coupon_applied'] = false
    //             setFormData({ ...formData })
    //             setProrateResponse(res?.data)
    //         })
    //         setIsLoading(false)
    //         setIsCouponApplied(false)
    //         formData['coupon_applied'] = false

    //     }

    // }
    return (
        <div>
            <h4 className="mb-4 text-center">Confirm Your Details</h4>
            <div className="card">
                <div className='card-header text-center text-bg-dark'>User Information</div>
                <div className='card-body'>
                    <div className="row g-0">
                        <div className="col-sm-6">
                            <p className="mb-1"><b>Name :</b>  {formData?.firstname} {formData?.lastname} </p>
                        </div>
                        <div className="col-sm-6">
                            <p className="mb-1"><b>Email :</b>  {formData?.email}</p>
                        </div>
                        <div className="col-sm-6">
                            <p className="mb-0"><b>Phone Number :</b> {phoneMasker(formData?.phone)}</p>
                        </div>
                        <div className="col-sm-6">
                            <p className="m-0 align-items-end ms-auto"><b>License Plate Number :</b> {formData?.vehicleList?.[0]?.license_plate}</p>
                        </div>
                        <div className="col-sm-6">
                            <p className="m-0 align-items-end ms-auto"><b>Make/Model :</b> {`${formData?.vehicleList?.[0]?.make}/${formData?.vehicleList?.[0]?.model}`}</p>
                        </div>
                        <div className="col-sm-6">
                            <p className="m-0 align-items-end ms-auto"><b>Color :</b> {`${formData?.vehicleList?.[0]?.color}`}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="border mb-3 p-3">
                {/* <div className="block">
                    <div className="row">
                        <div className="col-sm-6">

                            <label htmlFor="promocode" className="form-label">Promo Code</label>

                            <div className="input-group position-relative">
                                <input
                                    autoComplete="off"
                                    className="form-control "
                                    placeholder="Enter Promo Code"
                                    name="promocode"
                                    value={coupon}
                                    onChange={handleInputFieldsData}
                                    type="text"
                                    required=""
                                />
                                <button type="button" className="btn btn-primary d-flex" onClick={(e) => handleCouponCode(e)}>Apply  {isLoading ? <span className=' ms-2'><Circles
                                    height="20"
                                    width="20"
                                    color="#ffffff"
                                    ariaLabel="circles-loading"
                                    visible={true}
                                /></span> : null}
                                </button>
                                {couponRemoveButton && <span className="btn floatBtnCross" id="basic-addon1" onClick={handleCouponRemove}><i className='fa fa-times text-danger'></i></span>}
                            </div>
                        </div>
                        <div className='errorBlock d-inline-block'>{couponError == 'loading' ? <span className=' ms-2'><Circles
                            height="20"
                            width="20"
                            color="var(--bs-primary)"
                            ariaLabel="circles-loading"
                            visible={true}
                        /></span> : <small className='text-danger'>{couponError}</small>}
                        </div>
                    </div>
                </div> */}
                <div className="card ">
                    <div className="card-header">
                        <b> Purchase Summary</b>
                    </div>
                    {((purchaseSummary === {}) || (passDetail === {})) ? <span className='m-5 d-flex justify-content-center'><Circles
                        height="40"
                        width="40"
                        color="var(--bs-primary)"
                        ariaLabel="circles-loading"
                        visible={true}
                    /></span> :
                        <div className="card-body">
                            <div className="row align-items-center g-5">
                                <div className="col-12 col-sm-6">
                                    <table className="table table-sm table-borderless">
                                        <tbody><tr>
                                            <th>
                                                Price
                                            </th>
                                            <td>:</td>
                                            <td className='text-end'>
                                                <span>{passDetail?.price ? `$${passDetail?.price}` : '$0.00'}</span>
                                            </td>
                                        </tr>
                                            {(purchaseSummary?.tax_rate) && <tr>
                                                <th>
                                                    Processing Fee
                                                </th>
                                                <td>:</td>
                                                <td className='text-end'>
                                                    <span>{(purchaseSummary?.tax_rate) ? `+ $${parseFloat(purchaseSummary?.tax_rate)?.toFixed(2)}` : '+ $0.00'}</span>
                                                </td>
                                            </tr>}
                                            <tr>
                                                <th>
                                                    Start Date
                                                </th>
                                                <td>:</td>
                                                <td className='text-end'>
                                                    <span>{passDetail?.booking_start_date ? `${moment(passDetail?.booking_start_date).format('MM/DD/YYYY')}` : ''}</span>
                                                </td>
                                            </tr><tr>
                                                <th>
                                                    End Date
                                                </th>
                                                <td>:</td>
                                                <td className='text-end'>
                                                    <span>{passDetail?.booking_end_date ? `${moment(passDetail?.booking_end_date).format('MM/DD/YYYY')}` : ''}</span>
                                                </td>
                                            </tr>
                                        </tbody></table>
                                </div>

                                <div className="col text-end">
                                    <h3 className="fw-bold m-0">Total : {(purchaseSummary?.amount) ? `$${parseFloat(purchaseSummary?.amount)?.toFixed(2)}` : ''}</h3>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="mt-4" id="accountExpand">
                    <b className="mt-0" style={{ fontSize: 'larger' }}>Payment Details</b>
                    <hr className="my-1" />
                    {isLoggedIn() &&
                        <div className='row '>
                            {cardList?.length > 0 && <div className="col-sm-6">
                                <div className="mb-3">
                                    <select name="vehicle" id="vehicle" disabled={isCardFormVisible} className="form-select" onChange={handleCardSelectChange} >
                                        <option>Select Card</option>
                                        {cardList?.map((card, index) => {
                                            return (
                                                <option value={card?.token} selected={card?.token == formData?.payment_profile_id} key={index}>{`XXXX XXXX XXXX ${card?.card_last_four.slice(0, 4)} (${card?.expiry.slice(0, 2)}/${card?.expiry.slice(2, 4)})`}</option>
                                            )
                                        })}
                                    </select>
                                    <span className="form-text text-danger h-40">{formError == 'pass' && (!formData?.token && !formData?.payment_profile_id) ? 'Please select or add new card' : ''}</span>
                                </div>
                            </div>}

                            {cardList?.length > 0 && !isCardFormVisible ?
                                <div className='col-sm-6 mb-3'>{cardList?.length > 0 &&
                                    <span className='badge badge-primary text-dark fw-bold me-2'>OR</span>}
                                    <button className='btn btn-primary' type="button" onClick={showCardForm}><i className='fa fa-plus'></i> Add New Card</button></div> :
                                cardList?.length > 0 && <div className='col-sm-6 mb-3'><span className='badge badge-primary text-dark fw-bold me-2'>OR</span>
                                    <button className='btn btn-danger' type="button" onClick={hideCardForm}>Select Existing Card</button>
                                </div>}

                        </div>}

                    {(cardList?.length < 1 || isCardFormVisible) && <div>
                        <div className="row">
                            <div className="col-sm-6 ">
                                <div className="mb-3">
                                    <label htmlFor="Name on Card" className="form-label">Name on Card <span className='text-danger'> *</span></label>
                                    <input
                                        onChange={handleInputFieldsData}
                                        autoComplete="off"
                                        name='name'
                                        className="form-control "
                                        maxLength={20}
                                        minLength={1}
                                        placeholder="Name on Card"
                                        type="text"
                                        required="" />
                                    <span className="form-text text-danger h-40">{formError == 'pass' && !formData?.name ? 'This field is required' : ''}</span>
                                </div>
                            </div>

                            <div className="col-sm-6 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="Card Number" className="form-label">Card Number <span className='text-danger'> *</span>
                                    </label>
                                    <MaskedInput
                                        maskGenerator={cardnumbermask}
                                        value={card}
                                        type="text"
                                        name="card_pan"
                                        id="card_pan"
                                        required={true}
                                        pattern="[0-9 ]{18,19}"
                                        maxLength='19'
                                        minLength='18'
                                        title="Card no. should be 15-16 digit numeric"
                                        className="form-control"
                                        onChange={handleCardChange}
                                        placeholder="Card Number"
                                    />
                                    <span className="form-text text-danger h-40">{formError == 'pass' ? !formData?.card_pan ? 'This field is required' : validateCardNumber(formData?.card_pan) ? '' : '' : ''}</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 col-6 mb-3">
                                <label htmlFor="Expiry Month" className="form-label">Expiry Month<span className='text-danger'> *</span></label>
                                <select
                                    name='card_date_expiry_month'
                                    id="card_date_expiry_month"
                                    required=""
                                    className="form-select"
                                    onChange={handleInputFieldsData}
                                >
                                    <option value="">Expiry Month</option>
                                    {EXPIRY_MONTHS.map((item, key) => <option key={key} value={item}>{item}</option>)}
                                </select>
                                <span className="form-text text-danger h-40">{formError == 'pass' && !formData?.card_date_expiry_month ? 'This field is required' : ''}</span>

                            </div>

                            <div className="col-sm-4 col-6 mb-3">
                                <label htmlFor="Expiry Year" className="form-label">Expiry Year<span className='text-danger'> *</span></label>

                                <select
                                    name='card_date_expiry_year'
                                    id='card_date_expiry_year'
                                    required=""
                                    className="form-select"
                                    onChange={handleInputFieldsData}
                                >
                                    <option value="">Expiry Year</option>
                                    {EXPIRY_YEARS.map((item, key) => <option key={key} value={item.val}>{item.label}</option>)}
                                </select>
                                <span className="form-text text-danger h-40">{formError == 'pass' && !formData?.card_date_expiry_year ? 'This field is required' : ''}</span>

                            </div>

                            <div className="col-sm-4">
                                <div className="mb-3">
                                    <label htmlFor="" className="form-label">CVV <span className='text-danger'> *</span></label>
                                    <input
                                        onChange={handleInputFieldsData}
                                        autoComplete="off"
                                        className="form-control"
                                        id="standardCardCvv"
                                        maxLength="4"
                                        name="card_card_security_cvx_2"
                                        placeholder="CVV / Security Code"
                                        onKeyPress={(e) => restrictInputValues(e)}
                                        type="password"
                                        required=""

                                    />
                                    <span className="form-text text-danger h-40">{formError == 'pass' && !formData?.card_card_security_cvx_2 ? 'This field is required' : ''}</span>
                                </div>
                            </div></div>
                        <div className="row">

                            <div className="col-sm-4">
                                <div className="mb-3">
                                    <label htmlFor="" className="form-label">Zip Code <span className='text-danger'> *</span></label>
                                    <input
                                        onChange={handleInputFieldsData}
                                        className="form-control ng-untouched ng-pristine ng-invalid"
                                        maxLength="6"
                                        minLength="5"
                                        name="zip_code"
                                        pattern="[0-9]{5,6}"
                                        placeholder="Billing Address Zip Code"
                                        type="text"
                                        required=""
                                    />
                                    <span className="form-text text-danger h-40">{formError == 'pass' && !formData?.zip_code ? 'This field is required' : ''}</span>
                                </div>
                            </div>
                        </div>

                        {/* <input type='hidden' name='XXX_IPG_XXX' id='XXX_IPG_XXX' value='confirm' />
                        <input type='hidden' name='XXX_IPGTRXNO_XXX' id='XXX_IPGTRXNO_XXX' value={guidValue} />
                        <input type='hidden' name='XXX_IPGSESSION_XXX' id='XXX_IPGSESSION_XXX' value={resValue}></input> */}

                        <input type='hidden' name='XXX_IPG_XXX' id='XXX_IPG_XXX' value='confirm' />
                        <input type='hidden' name='XXX_IPGTRXNO_XXX' id='XXX_IPGTRXNO_XXX' value={guidValue} />
                        <input type='hidden' name='XXX_IPGSESSION_XXX' id='XXX_IPGSESSION_XXX' value={resValue}></input>
                    </div>}
                </div>
                <div className=' my-2' >
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="userConsent" name="userConsent" onClick={(e) => handleConsentChange(e)} checked={formData?.userConsent} />
                        <small>By clicking this button, you are providing your consent for auto renewal of the permit.</small>
                    </div>
                </div>
            </div>
        </div>
    )
}

Pass.propTypes = {
    dispatch: PropTypes.func,
    data: PropTypes.object,
    events: PropTypes.object,
    loader: PropTypes.bool,
    getCard: PropTypes.func,
    passData: EMPTY_ARRAY,
    setPage: PropTypes.func,
    idProof: PropTypes.array,
    rateList: PropTypes.object,
    purchaseSummary: PropTypes.object,
    paymentLoader: PropTypes.bool,
    formData: PropTypes.object,
    setFormData: PropTypes.func,
    formError: PropTypes.string,
    guidValue: PropTypes.string,
    resValue: PropTypes.string,
}

Pass.defaulProps = {
    dispatch: noop,
    data: EMPTY_OBJECT,
    events: EMPTY_OBJECT,
    loader: PropTypes.bool,
    getCard: PropTypes.func,
    passData: EMPTY_ARRAY,
    setPage: noop,
    idProof: EMPTY_ARRAY,
    rateList: EMPTY_OBJECT,
    paymentLoader: false,
    formData: EMPTY_OBJECT,
    setFormData: noop,
    formError: undefined,
    guidValue: '',
    resValue: ''

}

function mapStateToProps({ paymentReducer }) {
    return {
        data: paymentReducer,
        paymentLoader: paymentReducer?.proRateLoader,
        // rateList: basicInfoReducer.rateList.data[0],
        purchaseSummary: paymentReducer?.paymentSession?.data,
    }
}
export default connect(mapStateToProps)(Pass)