import React, { useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { Circles } from 'react-loader-spinner'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Card, Form, Image, InputGroup } from 'react-bootstrap'
// import emailImage from '../../assets/img/email.png'
import keyImage from '../../assets/img/key.png'
import { UpdateUserpassword } from '../../redux/actions/login'
import { getQuerySearchParams } from '../../utils/helper'
import './ForgotPassword.scss'
import PasswordSuccess from './PasswordSuccess'


function ForgotPassword(props) {

    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm()

    const dispatch = useDispatch()
    const { token_id } = useParams()

    let isCreate = getQuerySearchParams('is_create')
    const { loader } = props

    const [passwordShown, setPasswordShown] = useState(false)
    const [cpasswordShown, setCPasswordShown] = useState(false)
    const [isPasswordSuccess, setIsPasswordSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const togglePasswordVisiblity = (e, key) => {
        if (key === 'password') { setPasswordShown(passwordShown ? false : true) }
        if (key === 'password_confirmation') { setCPasswordShown(cpasswordShown ? false : true) }
    }

    const handleReset = (data) => {
        let formData = {
            password: data?.password,
            password_confirmation: data?.password_confirmation,
            token: token_id
        }

        dispatch(UpdateUserpassword(formData)).then((res) => {
            if (res?.status == '201') {
                setIsPasswordSuccess(true)
                setErrorMessage('')
                reset(formValues => ({
                    ...formValues,
                    'password': '',
                    'password_confirmation': ''
                }))
            } else {
                setIsPasswordSuccess(true)
                setErrorMessage(res?.errors?.message)
            }
        })
    }
    return (
        isPasswordSuccess ? <PasswordSuccess isCreate={isCreate} errorMessage={errorMessage} /> : <div className='container py-3 usersActive min-height-600'>
            <div className="row g-0 justify-content-center mt-5">
                <div className='col-sm-6'>
                    <div className='p-3 bg-white mt-3 border shadow-sm '>
                        <div className='border-bottom'>
                            <h3>{`${(isCreate == '1') ? 'Create' : 'Reset'} Password`}</h3>
                        </div>
                        <div className="alert alert-primary my-2">
                            {'Password should 8 to 15 characters, at least one uppercase letter, one lowercase letter, one number and one special character'}
                        </div>
                        <Form className='mt-5 forgot-pass' onSubmit={handleSubmit(handleReset)}>
                            <div>
                                <Card className='border-0 '>
                                    {/* <div className='mb-2'>
                                        <InputGroup className='border rounded'>
                                            <InputGroup.Text id="inputLoginImage iconWidth"><Image src={emailImage} width={23} height={23} /></InputGroup.Text>
                                            <Form.Control
                                                type="email"
                                                name='email'
                                                disabled={(isCreate == '1') ? true : false}
                                                aria-describedby="basic-addon1"
                                                className='form-control inputLogin'
                                                id='email'
                                                maxLength='40'
                                                minLength='1'
                                                placeholder="Enter Email Address"
                                            {...register('email', {
                                                required: { value: true, message: 'This field is required' },
                                                pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Please enter a valid email address' },
                                            })}
                                            />
                                        </InputGroup>
                                        {(errors?.email) && <span className='text-danger small'><small>{errors?.email?.message}</small></span>}
                                    </div> */}
                                    <div className='mb-2'>
                                        <InputGroup className='border rounded myiconset'>
                                            <InputGroup.Text id="inputLoginImage iconWidth"><Image width={23} height={23} src={keyImage} /></InputGroup.Text>
                                            <Form.Control
                                                type={passwordShown ? 'text' : 'password'}
                                                name='password'
                                                className="form-control passwordInput inputLogin"
                                                id='password'
                                                placeholder="Enter Password"
                                                aria-describedby="basic-addon1"
                                                {...register('password', {
                                                    required: { value: true, message: 'This field is required' },
                                                    pattern: { value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,15}$/, message: 'Password must be between 8 and 15 characters long and include at least one uppercase letter, one lowercase letter, one digit (0-9), and one special character (!, @, $, %, ^, &, *, #,?,-).' }
                                                })}
                                            />
                                            <i className={passwordShown ? 'fas fa-eye-slash loginPassIcon' : 'fas fa-eye loginPassIcon'} onClick={(e) => togglePasswordVisiblity(e, 'password')}></i>
                                        </InputGroup>
                                        {(errors?.password) && <span className='text-danger small'><small>{errors?.password?.message}</small></span>}
                                    </div>
                                    <div>
                                        <InputGroup className='border rounded myiconset'>
                                            <InputGroup.Text id="inputLoginImage iconWidth"><Image width={23} height={23} src={keyImage} /></InputGroup.Text>
                                            <Form.Control
                                                type={cpasswordShown ? 'text' : 'password'}
                                                name='password_confirmation'
                                                className="form-control passwordInput inputLogin"
                                                id="password_confirmation"
                                                placeholder="Confirm Password"
                                                aria-describedby="basic-addon1"
                                                {...register('password_confirmation', {
                                                    required: { value: true, message: 'This field is required' },
                                                    pattern: { value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,15}$/, message: 'Password must be between 8 and 15 characters long and include at least one uppercase letter, one lowercase letter, one digit (0-9), and one special character (!, @, $, %, ^, &, *, #,?,-).' },
                                                    validate: (val) => {
                                                        if (watch('password') != val) {
                                                            return 'Your passwords do no match'
                                                        }
                                                    },
                                                })}
                                            />
                                            <i className={cpasswordShown ? 'fas fa-eye-slash loginPassIcon' : 'fas fa-eye loginPassIcon'} onClick={(e) => togglePasswordVisiblity(e, 'password_confirmation')}></i>
                                        </InputGroup>
                                        {(errors?.password_confirmation) && <span className='text-danger small'><small>{errors?.password_confirmation?.message}</small></span>}
                                    </div>
                                </Card>
                            </div>
                            <div className="mt-3">
                                <button type="submit" name="submit" disabled={loader} className="btn w-100 my-4 text-white bg-primary">
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <span>{`${(isCreate == '1') ? 'Create' : 'Reset'} Password`} </span>  <span className='ms-2'>
                                            {(loader) ? <Circles
                                                height="20"
                                                width="20"
                                                style={{ display: 'inline-block' }}
                                                color="#ffffff"
                                                ariaLabel="circles-loading"
                                                visible={true}
                                            /> : null}
                                        </span>
                                    </div>
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}
ForgotPassword.propTypes = {
    dispatch: PropTypes.func,
    notify: PropTypes.func,
    loader: PropTypes.func,
}

ForgotPassword.defaulProps = {
    dispatch: noop,
    notify: noop,
    loader: noop,
}

function mapStateToProps({ resetPasswordReducer }) {
    return {
        loader: resetPasswordReducer?.loader
    }
}
export default connect(mapStateToProps)(ForgotPassword)