import React from 'react'
import PropTypes from 'prop-types'

function PassSteps(props) {

    const { page } = props
    return (
        <div className="row stpes-Design">
            <div className={page == 'home' ? 'col active' : 'col'}>
                <div className="box">
                    <span>1</span>
                </div>
            </div>
            <div className={page == 'payment' ? 'col active' : 'col'}>
                <div className="box"><span>2</span> </div>
            </div>
            <div className={page == 'confirmation' ? 'col active' : 'col'}>
                <div className="box"><span>3</span> </div>
            </div>
        </div>

    )
}
PassSteps.propTypes = {
    page: PropTypes.string,
    paymentStatus: PropTypes.bool
}

PassSteps.defaulProps = {
    page: undefined,
    paymentStatus: false
}
export default PassSteps
