import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import { getTicketDetails } from '../../../redux/actions/eTicketRecipt'
// downloadTicket
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { formattedExpiry, getLocation, loggedInUserData, phoneMasker, priceGreaterOrNot } from '../../../utils/helper'
import { QRCodeSVG } from 'qrcode.react'
// import { useParams } from 'react-router-dom'
// import Footer from '../../components/global/Footer'
// import MiniHeader from '../../components/global/MiniHeader'
import { errorMessage } from '../../../constants/errorMessage'
import { getConfigDetails } from '../../../services/config'
import { endParking } from '../../../redux/actions/transientAction'
import { Circles } from 'react-loader-spinner'
import { EMPTY_OBJECT } from '../../../constants/globalConstants'
// import DownloadApp from '../../components/global/DownloadApp'
// import thankyou from '../../../assets/img/thank-you-icon.svg'
import direction from '../../../assets/img/traffic-sign.png'
import PaymentBreakUpDetails from '../../../components/global/PaymentBreakUpDetails'

function Ticket(props) {
	const { dispatch, ticketDetailsFromProps, loader, isFetching, notify, ticketId } = props
	const [ticketDetails, setTicketDetails] = useState(EMPTY_OBJECT)
	const [error, setError] = useState('')
	const [isDisplayDetails, setIsDisplayDetails] = useState(false)
	// const [listData, setListData] = useState(EMPTY_OBJECT)
	// const [remainingAmount, setRemainingAmount] = useState(null)
	const [amount, setAmount] = useState(null)

	// const { ticketId } = useParams()
	const partner = 'townsend'
	useEffect(() => {
		// if (partner == 'parkingpayments' || partner == 'townsend' || partner == 'demoparkengage') {
		// const UserPartner_id = JSON.parse((localStorage.getItem('partner_id')))
		if (ticketDetailsFromProps && ticketDetailsFromProps?.ticket_number != '') {
			setTicketDetails(ticketDetailsFromProps)
			setAmount(ticketDetailsFromProps?.payable_amount ? ticketDetailsFromProps?.payable_amount : '0.00')
			// setRemainingAmount((Number(ticketDetailsFromProps?.payable_amount) - Number(ticketDetailsFromProps?.grand_total)).toFixed(2))
		} else {
			if (ticketId) {
				const payload = {
					'ticket': ticketId,
					'phone': '',
					'cardLast4': '',
					'cardExpiry': '',
					'license_plate': '',
					'partner_id': ticketDetailsFromProps?.partner_id,
				}
				dispatch(getTicketDetails(payload)).then((res) => {
					if (res?.status === 201 || res?.status === 200) {
						if (res?.data && res?.data != null) {
							// notify('success', 'T successfully')
							setTicketDetails(res?.data)
							setAmount(res?.data?.payable_amount ? res?.data?.payable_amount : '0.00')
						} else {
							notify('error', res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
						}
					} else {
						setError(res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
						notify('error', res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
					}
				}).catch(() => {
					notify('error', errorMessage?.somethingWentWrong)
				})
			} else {
				setTicketDetails('')
			}
		}
		// }

	}, [ticketDetailsFromProps])


	const handleEndParking = () => {
		dispatch(endParking(ticketDetails?.ticket_number)).then((res) => {
			if (res?.status === 201 || res?.status === 200) {
				if (res?.data && res?.data != null) {
					notify('success', 'Parking ended successfully')
					setTicketDetails(res?.data)
				} else {
					notify('error', res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
				}
			} else {
				setError(res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
				notify('error', res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
			}
		}).catch(() => {
			notify('error', errorMessage?.somethingWentWrong)
		})
	}

	// const handleDownloadTicket = () => {
	// 	if(!loaderDownload){dispatch(downloadTicket(ticketId, ticketDetailsFromProps?.partner_id)).then((res) => {
	// 		if(res?.status && res?.status == 500){
	// 			notify('error', 'Something went wrong, Please try again!')
	// 		}
	// 		notify('Success', 'E-Ticket Downloaded')
	// 	}).catch(() => {
	// 		notify('error', 'Something went wrong, Please try again!')
	// 	})}
	// }

	// const handleDetailsDisplay = () => {
	// 	setIsDisplayDetails(true)
	// }

	return (
		<div className={ticketId ? 'main' : ''}>
			{/* {ticketId && <MiniHeader title={`E-RECEIPT - ${ticketId}`} handleDownload={handleDownloadTicket} download />} */}
			{loader ? <span className='ms-2 d-flex justify-content-center'><Circles
				height="50"
				width="50"
				color="var(--bs-primary)"
				wrapperStyle={{ margin: '20.5rem' }}
				ariaLabel="circles-loading"
				visible={true}
			/></span> : (ticketDetails?.ticket_number && ticketDetails?.ticket_number != '') ? <div className={`${ticketId ? 'm-2 ' : ''}`}>

				<div>
					{/* <div className="card-header headerBg row p-1 m-0"> */}
					{/* <div className='col-12 d-flex- justify-content-center align-items-center text-center'>
							<img className='img-50 img-height-50 p-2' src={ticketDetails?.facility?.logo} alt={ticketDetails?.facility?.full_name}/> */}
					{/* <p className="pull-left my-2 add-member-plan text-primary fw-bold small mb-0 pb-0">{ticketDetails?.facility?.full_name}</p>
							<p className="pull-left my-2 add-member-plan small mt-0 pt-0">{ticketDetails?.facility?.entrance_location}</p> */}
					{/* </div> */}
					{/* <div>
						<a href={getLocation(ticketDetails?.facility)} target="_blank" rel="noreferrer"><img src={direction} alt="Direction" className='direction'/></a>
						</div> */}
					{/* <div className='col-4 d-flex align-items-center justify-content-center small'>
						{ticketDetails?.facility?.garage_code && <p className="my-2 header-s rounded-pill bg-dark ps-3 pe-3 text-white d-flex pt-1 pb-1 small h5 me-0 pe-0">CODE: {ticketDetails?.facility?.garage_code}</p>}
						</div> */}

					{/* </div> */}
					<div className="clearfix">


						{/* THANK YOU */}

						{/* <img src={thankyou} className='imgh-20 thankyou-img-height-80 mt-2' alt='Thank You'/>
								<h5 className='thank-you-text mt-3 fw-bold mb-3 ticket-font'>Thank you!</h5>
								<p className='ms-2 me-2 text-black mt-2 pb-0 mb-0 ticket-font'>For parking with {ticketDetails?.facility?.full_name}</p> */}
						<h3 className=' text-center mb-4 fw-bold ticket-font'>Thank You for Parking!</h3>

						<div className=' p-2 mb-3 border border-primary'>
							<div className='row  '>
								{ticketDetails?.ticket_number && (
									<div className='col-3 responsive-canvas'>
										<QRCodeSVG
											value={`${getConfigDetails()?.adminUrl}/admin/tickets?ticket_number=${ticketDetails?.ticket_number}`}
											width="100%"
											height="100%"
										// size={180}
										/>
									</div>)}
								<div className='col-9  '>


									{ticketDetails?.ticket_number && <span className='text-start text-primary small pb-0 mb-0'>Ticket ID: {ticketDetails?.ticket_number}</span>}
									<a href={getLocation(ticketDetails?.facility)} target="_blank" rel="noreferrer" className='float-end me-1'><img src={direction} alt="Direction" className='direction' /></a>
									<h6 className='text-start text-primary mt-2 mb-0 fw-bold small'>{ticketDetails?.facility?.full_name}</h6>
									<p className='text-start text-black pb-0 mb-0 small'>Entrance: {ticketDetails?.facility?.geolocations?.city ? `${ticketDetails?.facility?.geolocations?.city}` : ''} </p>
								</div>
								{/* <div className='col-4 d-flex align-items-center justify-content-end'>
									</div> */}

							</div>
						</div>


						<h6 className="text-primary fw-semibold p-2 bg-primary bg-opacity-10">Ticket Details</h6>



						<div className='d-block'>
							<table className='table table-sm'>
								{ticketDetails?.user?.name && <tr>
									<td>Name</td>
									<td className='text-end'>
										{ticketDetails?.user?.name ? ticketDetails?.user?.name : '-'}

									</td>
								</tr>}

								{ticketDetails?.user?.email && <tr>
									<td>Email Id</td>
									<td className='text-end'>
										{ticketDetails?.user?.email ? ticketDetails?.user?.email : '-'}
									</td>
								</tr>}
								{ticketDetails?.user?.phone_number && <tr>
									<td>Phone No</td>
									<td className='text-end'>
										{ticketDetails?.user?.phone_number ? phoneMasker(ticketDetails?.user?.phone_number) : '-'}
									</td>
								</tr>}

								{ticketDetails?.license_plate && <tr>
									<td>License Plate</td>
									<td className='text-end'>
										{ticketDetails?.license_plate ? ticketDetails?.license_plate : '-'}
									</td>
								</tr>}
								{/* {ticketDetails?.ticket_number && <tr>
									<td>Ticket ID</td>
									<td className='text-end'>
									{ticketDetails?.ticket_number ? `${ticketDetails?.ticket_number}` : '-'}
									</td>
								</tr>} */}
								{/* {ticketDetails?.checkin_gate && <tr>
									<td>Check-In Gate</td>
									<td className='text-end'>
									{ticketDetails?.checkin_gate ? `${ticketDetails?.checkin_gate}` : '-'}
									</td>
								</tr>} */}
								{/* {ticketDetails?.checkout_gate && <tr>
									<td>Check-Out Gate</td>
									<td className='text-end'>
									{ticketDetails?.checkout_gate ? `${ticketDetails?.checkout_gate}` : '-'}
									</td>
								</tr>} */}
								{ticketDetails?.check_in_datetime && <tr>
									<td>Check-In Time</td>
									<td className='text-end'>
										{ticketDetails?.check_in_datetime ? moment(ticketDetails?.check_in_datetime).format('MM/DD/YYYY h:mmA') : '-'}
									</td>
								</tr>}
								{(ticketDetails?.checkout_datetime && ticketDetails?.is_checkout == '1') && <tr>
									<td>Check-Out Time</td>
									<td className='text-end'>
										{ticketDetails?.checkout_datetime ? moment(ticketDetails?.checkout_datetime).format('MM/DD/YYYY h:mmA') : '-'}
									</td>
								</tr>}
								{ticketDetails?.card_type && <tr>
									<td>Card Type</td>
									<td className='text-end'>
										{ticketDetails?.card_type ? ticketDetails?.card_type : '-'}
									</td>
								</tr>}
								{ticketDetails?.card_last_four && <tr>
									<td>Payment Method</td>
									<td className='text-end'>
										{ticketDetails?.card_last_four ? `Ending with ${ticketDetails?.card_last_four}` : '-'}
									</td>
								</tr>}
								{ticketDetails?.expiry && <tr>
									<td>Card Expiry</td>
									<td className='text-end'>
										{ticketDetails?.expiry ? formattedExpiry(ticketDetails?.expiry) : '-'}
									</td>
								</tr>}
							</table>
							{amount != null && <>
								{priceGreaterOrNot(ticketDetails) && <p className='text-primary fw-semibold p-2 bg-primary bg-opacity-10'>Price Breakup</p>}
								<PaymentBreakUpDetails
									className="my-2 m-2 d-flex justify-content-between"
									paymentData={ticketDetails}
									sectionKey={(ticketDetails?.payable_amount == '0.00') ? false : 'paymantResponse'}
									isDisplayDetails={true}
									setIsDisplayDetails={setIsDisplayDetails}
									ticketId={ticketId}
									isExtendDetails
								/>
								<div className='row g-0 border-bottom border-top text-primary '>
									<div className='col-4 p-0 m-0 pt-2 pb-2'>
										<p className='fw-bold p-0 m-0 ticket-font'>Amount To Pay</p>
									</div>
									<div className='col-8 p-0 m-0 pt-2 pb-2 ps-2 text-end '>
										<p className='fw-bold p-0 m-0 ticket-font'>${amount ? Number(amount).toFixed(2) : '-'}
											{/* {amount && <i className='fa fa-info-circle text-primary ms-1 cursor-pointer' onClick={() => handleDetailsDisplay()}></i>} */}
										</p>
									</div>
								</div>
							</>}
							{/* {ticketDetails?.grand_total && <div className='col-12 m-auto border-bottom row d-flex align-items-center'>
											<div className='col-5 small p-0 m-0 pt-2 pb-2'>
												<p className='fw-bold p-0 m-0 ticket-font'>Total Amount</p>
											</div>
											<div className='col-7 small p-0 m-0 pt-2 pb-2 ps-2 '>
												<p className=' p-0 m-0 ticket-font'>{ticketDetails?.grand_total ? `$${ticketDetails?.grand_total}` : '-'} {ticketDetails?.grand_total && <i className='fa fa-info-circle text-primary ms-1 cursor-pointer' onClick={() => handleDetailsDisplay()}></i>}</p>
											</div>
										</div>} */}
							{/* {ticketDetails?.payable_amount != null && <div className='col-12 m-auto border-bottom row d-flex align-items-center'>
											<div className='col-5 small p-0 m-0 pt-2 pb-2'>
												<p className='fw-bold p-0 m-0 ticket-font'>Amount To Pay</p>
											</div>
											<div className='col-7 small p-0 m-0 pt-2 pb-2 ps-2 '>
												<p className=' p-0 m-0 ticket-font'>${ticketDetails?.payable_amount ? Number(ticketDetails?.payable_amount).toFixed(2) : '-'} {ticketDetails?.payable_amount && <i className='fa fa-info-circle text-primary ms-1 cursor-pointer' onClick={() => handleDetailsDisplay()}></i>}</p>
											</div>
										</div>} */}

							{(ticketDetails?.is_checkout == 0) && <div className='col-12 m-auto border border-primary rounded mt-2 bg-primary row d-flex align-items-center'>

								<a
									href={`${getConfigDetails()?.checkInUrl}/${partner}/${ticketDetails?.encrypt_ticket_number}/${loggedInUserData()?.access_token ? loggedInUserData()?.access_token : ''}`}
									type='button'
									className='text-decoration-none border-primary rounded'
								><div className='p-2 text-center bg-primary text-white text-decoration-none'>
										<p className='p-0 m-0 ticket-font fw-bold text-center'>Click to Check-Out</p>
									</div></a>
							</div>}

							{isDisplayDetails && <PaymentBreakUpDetails
								className="my-2 m-2 d-flex justify-content-between"
								paymentData={ticketDetails}
								sectionKey={(ticketDetails?.payable_amount == '0.00') ? false : 'paymantResponse'}
								isDisplayDetails={isDisplayDetails}
								setIsDisplayDetails={setIsDisplayDetails}
								ticketId={ticketId}
								isExtendDetails
							/>}

						</div>

						{/* <div className='headerBg mt-0 m-2 p-3 mb-5 border'>
								<div className='row m-0 p-2 bg-white d-flex align-items-center'>
									<div className='col-sm-4 col-12'>
										<div className='responsive-canvas pt-4 pb-4'>
											{ticketDetails?.ticket_number && (
												<QRCodeSVG
												value={`${getConfigDetails()?.adminUrl}/admin/tickets?ticket_number=${ticketDetails?.ticket_number}`}
												width="100%"
												height="100%" */}
						{/* // size={180} */}
						{/* />
											)}
										</div>
									</div>
									<div className='col-sm-8 col-sm-8 p-0 m-0'>
										{ticketDetails?.user?.phone_number && <div className='col-12 m-auto border row d-flex align-items-center'>
											<div className='col-6 small p-2'>
												<p className='fw-bold p-0 m-0 ticket-font'>Phone Number</p>
											</div>
											<div className='col-6 small p-2'>
												<p className='p-0 m-0 ticket-font'>{ticketDetails?.user?.phone_number ? phoneMasker(ticketDetails?.user?.phone_number) : '-'}</p>
											</div>
										</div>}
										{ticketDetails?.ticket_number && <div className='col-12 m-auto border row d-flex align-items-center'>
											<div className='col-6 small p-2'>
												<p className='fw-bold p-0 m-0 ticket-font'>Ticket ID</p>
											</div>
											<div className='col-6 small p-2'>
												<p className='p-0 m-0 ticket-font'>{ticketDetails?.ticket_number ? `${ticketDetails?.ticket_number}` : '-'}</p>
											</div>
										</div>}
										{ticketDetails?.license_plate && <div className='col-12 m-auto border row d-flex align-items-center'>
											<div className='col-6 small p-2'>
												<p className='fw-bold p-0 m-0 ticket-font'>License Plate</p>
											</div>
											<div className='col-6 small p-2'>
												<p className='p-0 m-0 ticket-font'>{ticketDetails?.license_plate ? ticketDetails?.license_plate : '-'}</p>
											</div>
										</div>}
										{ticketDetails?.check_in_datetime && <div className='col-12 m-auto border row d-flex align-items-center'>
											<div className='col-6 small p-2'>
												<p className='fw-bold p-0 m-0 ticket-font'>Check-In Date & Time</p>
											</div>
											<div className='col-6 small p-2'> */}
						{/* <p >{ticketDetails?.check_in_datetime ? moment(ticketDetails?.check_in_datetime).format('Do MMM YYYY h:mmA') : '-'}</p> */}
						{/* <p className=' p-0 m-0  ticket-font'>{ticketDetails?.check_in_datetime ? moment(ticketDetails?.check_in_datetime).format('MM/DD/YYYY h:mmA') : '-'}</p>
											</div>
										</div>}
										{ticketDetails?.checkout_datetime && <div className='col-12 m-auto border row d-flex align-items-center'>
											<div className='col-6 small p-2'>
												<p className='fw-bold p-0 m-0 ticket-font'>Check-Out Date & Time</p>
											</div>
											<div className='col-6 small p-2'> */}
						{/* <p >{ticketDetails?.check_in_datetime ? moment(ticketDetails?.check_in_datetime).format('Do MMM YYYY h:mmA') : '-'}</p> */}
						{/* <p className=' p-0 m-0  ticket-font'>{ticketDetails?.checkout_datetime ? moment(ticketDetails?.checkout_datetime).format('MM/DD/YYYY h:mmA') : '-'}</p>
											</div>
										</div>} */}
						{/* {ticketDetails?.card_last_four && <div className='col-12 m-auto border row d-flex align-items-center'>
											<div className='col-6 small p-2'>
												<p className='fw-bold p-0 m-0 ticket-font'>Payment Method</p>
											</div>
											<div className='col-6 small p-2'>
												<p className=' p-0 m-0  ticket-font'>{ticketDetails?.card_last_four ? <img src={getCardIcon(ticketDetails?.card_type)} width="32" className='me-2 '/> : ''}{ticketDetails?.card_last_four ? `${ticketDetails?.card_last_four}` : '-'}</p>
											</div>
										</div>}
										{ticketDetails?.grand_total && <div className='col-12 m-auto border row d-flex align-items-center'>
											<div className='col-6 small p-2'>
												<p className='fw-bold p-0 m-0 ticket-font'>Amount Paid</p>
											</div>
											<div className='col-6 small p-2'>
												<p className=' p-0 m-0 ticket-font'>{ticketDetails?.grand_total ? `$${ticketDetails?.grand_total}` : '-'} </p> */}
						{/* {ticketDetails?.grand_total && <i className='fa fa-info-circle text-primary ms-1 cursor-pointer' onClick={() => handleDetailsDisplay()}></i>} */}
						{/* </div>
										</div>}
										
									</div>
								</div>
							</div> */}

						{(ticketDetails?.facility?.is_gated_facility != '1' && ticketDetails?.is_expired == '0') && <div className='mt-3 mb-5 m-2'>
							{<div className='col-sm-6 small'>
								<a
									href={`${getConfigDetails()?.transientURL}/${partner}/extend/${ticketDetails?.ticket_number}`}
									type='button'
									className='text-decoration-none w-100 border bg-white text-center text-primary border-primary rounded ps-4 pe-4 fw-bold pt-2 pb-2'>
									<i className='fa fa-plus-circle'></i> Extend
								</a>
							</div>}
							{/* Hide This */}
							{ticketDetails?.facility?.is_end_parking == '0000' && <div className='col-sm-6 small mt-3 mb-5'>
								<button
									type='button'
									className='text-decoration-none border w-100 bg-white text-center text-danger border-danger rounded ps-4 pe-4 fw-bold pt-2 pb-2'
									onClick={() => handleEndParking()}>
									<div className='d-flex justify-content-center align-items-center'>
										<span>End Parking</span>
										<span className='ms-2'>
											{isFetching ? <Circles
												height="20"
												width="20"
												style={{ display: 'inline-block' }}
												color="#27A8E1"
												ariaLabel="circles-loading"
												visible={true}
											/> : null}
										</span>
									</div>
								</button>
							</div>}
						</div>}
					</div>
					{/* <div className="card-footer row p-2 m-0 bg-primary">
						<div className='col-7 d-flex align-items-center justify-content-left'>
							<p className=" text-right my-2 text-white fw-bold">Paid Amount</p>
						</div>
						<div className='col-5 d-flex align-items-center justify-content-end'>
							<p className="my-2 ps-3 pe-3 text-white d-flex pt-1 pb-1 small h5">{ti	cketDetails?.grand_total ? `$${ticketDetails?.grand_total}` : 'NA'}</p>
						</div>
						
					</div> */}
				</div>

				{/* <div className='col d-flex align-items-center justify-content-center small mt-3 mb-5'>
					<button type="button" className="btn btn-outline-dark" onClick={() => handleDownloadTicket()}>Download Ticket</button>
				</div> */}
			</div> : error && <p className='text-center mb-0'><strong className='text-muted'>Oops!</strong> {error ? error : 'No Ticket found.'} </p>}
			{/* {ticketId && <Footer />} */}
			{/* <DownloadApp/> */}

		</div>

	)
}


Ticket.propTypes = {
	dispatch: PropTypes.func,
	loader: PropTypes.bool,
	loaderSms: PropTypes.bool,
	loaderDownload: PropTypes.bool,
	isFetching: PropTypes.bool,
	ticketDetailsFromProps: PropTypes.object,
	notify: PropTypes.func,
	ticketId: PropTypes.string,
}

Ticket.defaulProps = {
	dispatch: noop,
	loader: false,
	isFetching: false,
	ticketDetailsFromProps: {},
	notify: noop,
	ticketId: '',
	loaderDownload: false
}

function mapStateToProps({ userDetailsReducer, transientReducer }) {
	return {
		loader: userDetailsReducer?.loader,
		loaderSms: userDetailsReducer?.loaderSms,
		loaderDownload: userDetailsReducer?.loaderDownload,
		isFetching: transientReducer?.isFetching,

	}
}
export default connect(mapStateToProps)(Ticket)

