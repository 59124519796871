import React, { Component } from 'react'
import Aux from './../Wrapper/Auxiliary'
// import { useNavigate } from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator'
import NotificationSystem from 'react-notification-system'
import LoadingComponent from '../../../../src/common/UI/loadingComponent'
import * as actionCreators from '../../../redux/actions'
import eventCallRequest from '../../../../src/utils/HelperEvent'
import { connect } from 'react-redux'
import 'sweetalert/dist/sweetalert.css'
import MaskedInput from 'react-text-mask'
// import { BottomLogo } from '../Constant'
// import logo from '../../assets/img/logo.png';
import logo from '../../../assets/img/logo.png'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import { EMPTY_OBJECT } from '../../../constants/globalConstants'
import Sidebar from '../../../components/global/Sidebar'
// import { getConfigDetails } from '../../../services/config'
import { getPartnerDetails, loggedInUserData } from '../../../utils/helper'
require('./homeComponent.scss')
class HomeComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user_name: '',
            email: '',
            member_id: '',
            is_member: false,
            phone_number: '',
            display_current_date: '',
            garage_id: '',
            isLoaded: false,
            isSubmitted: false,
            isEdited: false,
            isFieldDisabled: false,
            showALert: false,
            latitude: '',
            longitude: ''
        }

        this.fields = [
            { label: 'Your Name', key: 'user_name', placeholder: 'Your Name', type: 'text', validation: 'required' },
            { label: 'Email', key: 'email', placeholder: 'Your Email', type: 'email', validation: 'required|email' },
            { label: 'Phone Number', key: 'phone_number', placeholder: 'Your Phone Number', type: 'tel', validation: 'required|validPhone' },
            { label: 'Member Id', key: 'member_id', placeholder: 'Member ID # (optional)', type: 'text', validation: 'validMemberNo|numeric|max:18,string' },
        ]

        this.validator = new SimpleReactValidator({
            validators: {
                validPhone: {
                    message: 'The phone number must be a valid phone number.',
                    rule: (val, params) => {
                        return ((this.state.phone_number.replace(/\D/g, '') === '0000000000' || this.state.phone_number.replace(/\D/g, '').length < 10) && params.indexOf(val) === -1) ? false : true
                    },
                    required: true
                },
                validMemberNo: {
                    message: 'Invalid member ID entered',
                    rule: (val, params) => {
                        return ((this.state.member_id.match(/^((?=(511))[0-9]{18})$/g)) && params.indexOf(val) === -1) ? true : false
                    },
                },
            }
        })

        this.phoneNumberMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
        this.notificationSystem = React.createRef()
    }

    componentDidMount = () => {
        // localStorage.clear()
        const partnerData = getPartnerDetails()
        this.setState({ garage_id: partnerData.facilities[0].id })

        if(loggedInUserData()?.access_token){
            window.location.replace(`${getPartnerDetails()?.partner_slug}/booking/checkout-passes`)
        }   


        localStorage.setItem('queries', '{}')
        let eventParams = eventCallRequest('Home Screen Loaded')
        this.props.eventRequest(eventParams).then(() => { })
        window.addEventListener('load', () => {
            // localStorage.removeItem('userData')
            localStorage.removeItem('reservationData')
        })

        setTimeout(
            () => {
                let userData = JSON.parse(localStorage.getItem('userData'))
                if (userData) {
                    // let conformedPhoneNumber = conformToMask(
                    //     userData.phone,
                    //     this.phoneNumberMask
                    // )
                    this.setState({
                        user_name: userData.user_name,
                        email: userData.email,
                        garage_id: userData.garage_id,
                        member_id: userData.member_id,
                        is_member: userData.is_member,
                        phone_number: userData.phone ? userData.phone : '',
                        current_date: userData.current_date
                    })
                }
            },
            1000
        )
        
        // const userData = getLoggedUserData()
        // const apiCallLog = {...getConfigDetails()?.CLIENT_SECRET, userData}
        // let partnerDetailsApiCall = eventCallRequest('Partner Details API Call', JSON.stringify(apiCallLog))
        // this.props.eventRequest(partnerDetailsApiCall).then(() => { })

        // this.props.reqGetPartnerDetails(getConfigDetails()?.CLIENT_SECRET).then(() => {
        //     const { partnerDetails, hasError, errorMessage } = this.props
            
        //     const logError = {...errorMessage, userData}
        //     if (hasError) {
        //         let eventParams = eventCallRequest('Partner Details API Failed', JSON.stringify(logError))
        //         this.props.eventRequest(eventParams).then(() => { })
        //     } else {
        //         this.setState({ garage_id: partnerDetails.facilities[0].id }, () => {
        //             const apiSuccessLog = {...partnerDetails, userData}
        //             let eventParams = eventCallRequest('Partner Details API Success', JSON.stringify(apiSuccessLog), partnerDetails.facilities[0].id)
        //             this.props.eventRequest(eventParams).then(() => { })
        //         })
        //     }
        // })
    }

    handleInputChange = (event) => {
        let { name, value } = event.target
        if (name == 'phone_number') {
            this.setState({ [name]: value.replace(/\D/g, '') })
        } else {
            this.setState({ [name]: value })
        }
    }

    reverseObj = (obj) => {
        let newObj = {}
        Object.keys(obj)
            .reverse()
            .forEach((key) => {
                newObj[key] = obj[key]
            })
        return newObj
    }

    handleSubmit = (e) => {
        let userDataLog = {
            user_name: this.state?.user_name,
            email: this.state?.email,
            member_id: this.state?.member_id,
            is_member: this.state?.is_member,
            phone: this.state?.phone_number.replace(/\D/g, ''),
            current_date: this.state?.current_date,
            garage_id: this.state?.garage_id,
        }
        let eventParams = eventCallRequest('Process Button Click', JSON.stringify(userDataLog), this.state.garage_id)
        this.props.eventRequest(eventParams).then(() => { })
        if (this.state.member_id !== '') {
            this.setState({ is_member: true })
        } else {
            this.setState({ is_member: false })
        }
        this.setState({ isSubmitted: true }, () => {
            if (this.validator.allValid()) {
                let data = {
                    user_name: this.state.user_name,
                    email: this.state.email,
                    member_id: this.state.member_id,
                    is_member: this.state.is_member,
                    phone: this.state.phone_number.replace(/\D/g, ''),
                    current_date: this.state.current_date,
                    garage_id: this.state.garage_id,
                }
                if (data.is_member) {
                    let eventParams = eventCallRequest('Check Member Exist API Call', JSON.stringify(data), this.state.garage_id)
                    this.props.eventRequest(eventParams).then(() => { })
                    this.props.checkMemberExistance({ phone: data.phone, member_id: data.member_id }).then(() => {
                        const notification = this.notificationSystem.current
                        const { memberCheck, hasError, errorMessage } = this.props
                        if (hasError) {
                            const apiFailLog = {...errorMessage, data}
                            let eventParams = eventCallRequest('Check Member Exist API Failed', JSON.stringify(apiFailLog), this.state.garage_id)
                            this.props.eventRequest(eventParams).then(() => { })

                            this.setState({ isSubmitted: false }, () => {
                                notification.addNotification({
                                    title: 'Sorry!',
                                    message: errorMessage,
                                    level: 'error'
                                })
                            })
                        } else {
                            const apiPassLog = {...memberCheck, data}
                            let eventParams = eventCallRequest('Check Member Exist API Success', JSON.stringify(apiPassLog), this.state.garage_id)
                            this.props.eventRequest(eventParams).then(() => { })
                            if (!memberCheck.is_member_exists) {
                                localStorage.setItem('userData', JSON.stringify(data))
                                window.location.replace(`${getPartnerDetails()?.partner_slug}/booking/checkout-passes`)
                            }
                        }
                    })
                } else {

                    let eventParams = eventCallRequest('Set Non Member To Storage', JSON.stringify(data), this.state.garage_id)
                    this.props.eventRequest(eventParams).then(() => { })

                    localStorage.setItem('userData', JSON.stringify(data))
                    window.location.replace(`${getPartnerDetails()?.partner_slug}/booking/checkout-passes`)
                }
            } else {
                let validateFields = this.reverseObj(this.validator.fields)
                for (const property in validateFields) {
                    if (!validateFields[property]) {
                        document.getElementById(property).focus()
                    }
                }
                this.validator.showMessages()
                this.forceUpdate()
                this.setState({ isSubmitted: false })
            }
        })
        e.preventDefault()
    }

    render() {
        const { isFetching } = this.props
        const { isSubmitted } = this.state
        return (
            <Aux>
                <NotificationSystem ref={this.notificationSystem} />
                <div className="content container-fluid ps-0 pe-0">
                    <div className="container-fluid min-vh-100">
                        <div className="row align-items-center min-vh-100">
                            <div className="col-12 col-sm order-1 order-sm-0 d-none d-sm-block h-8 ps-0 pe-0">
                                <div className='shadow d-block h-100'>
                                    <Sidebar/>
                                </div>
                            </div>
                            <div className="col-12 col-sm order-0 order-sm-1 form_body">
                                <div className='p-4 px-2'>
                                    <div className="my-2 text-center">
                                        <img alt="" height="55px" src={logo} />
                                        <h4 className="my-4">User Information</h4>
                                        <form style={{ position: 'relative' }} onSubmit={this.handleSubmit}>
                                            {(isFetching || isSubmitted) ? <LoadingComponent /> : ''}
                                            {this.fields.map((field, key) => {
                                                switch (field.key) {
                                                    case 'user_name':
                                                        return (<div key={key} className="form-group mb-2">
                                                            <input type={field.type} disabled={this.state.isFieldDisabled} maxLength="50" name={field.key} id={field.key} value={this.state[field.key]} onChange={this.handleInputChange} placeholder={field.placeholder} className="form-control" />
                                                            {this.validator.message(field.key, this.state[field.key], field.validation)}
                                                        </div>)
                                                    case 'email':
                                                        return (<div key={key} className="form-group mb-2">
                                                            <input type={field.type} disabled={this.state.isFieldDisabled} name={field.key} id={field.key} value={this.state[field.key]} onChange={this.handleInputChange} placeholder={field.placeholder} className="form-control" />
                                                            {this.validator.message(field.key, this.state[field.key], field.validation)}
                                                        </div>)
                                                    case 'phone_number':
                                                        return (<div key={key} className="form-group mb-2">
                                                            <MaskedInput
                                                                className="form-control"
                                                                placeholder={field.placeholder}
                                                                mask={this.phoneNumberMask}
                                                                name={field.key}
                                                                id={field.key}
                                                                guide={false}
                                                                onChange={this.handleInputChange}
                                                                value={this.state[field.key]}
                                                                type="tel"
                                                                maxLength={14}
                                                            />
                                                            {this.validator.message(field.key, this.state[field.key], field.validation)}
                                                        </div>)
                                                    case 'member_id':
                                                        return (<div key={key} className="form-group mb-2">
                                                            <p className="form-text text-start mb-0 border px-3 pt-1 border-bottom-0 text-primary fw-bold">If you are a Parkengage member, please enter your Parkengage member ID # for discounted parking options</p>
                                                            <input type={field.type} name={field.key} maxLength="18" id={field.key} value={this.state[field.key]} onChange={this.handleInputChange} onBlur={this.memberIdValidation} placeholder={field.placeholder} className="form-control" />
                                                            {this.validator.message(field.key, this.state[field.key], field.validation)}
                                                        </div>)
                                                     default:
                                                        return false    
                                                }
                                            })}
                                            <div className="form-group text-center">
                                                <button type="submit" className="btn btn-block w-100 btn-primary " >Proceed</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {/* <BottomLogo
                                    className="bg-opacity-10 pe-4 logo_style"
                                /> */}
                            </div>
                        </div>
                    </div>

                </div>
            </Aux>
        )
    }
}

HomeComponent.propTypes = {
    reqGetPartnerDetails : PropTypes.func,
    eventRequest : PropTypes.func,
    checkMemberId : PropTypes.func,
    checkMemberExistance : PropTypes.func,
    memberCheck : PropTypes.object,
    errorMessage: PropTypes.string,
    isFetching  : PropTypes.bool,
    partnerDetails : PropTypes.object,
    hasError  : PropTypes.bool,
    history  : PropTypes.object

}

HomeComponent.defaultProps = {
    reqGetPartnerDetails  : noop,
    eventRequest : noop,
    checkMemberId : noop,
    checkMemberExistance : noop,
    memberCheck : EMPTY_OBJECT,
    errorMessage : '',
    isFetching  : false,
    partnerDetails  : EMPTY_OBJECT,
    hasError  : false,
    history  : EMPTY_OBJECT,

}

const mapStateToProps = ({ PartnerReducer }) => {
    const { partnerDetails, eventRes, memberCheck, isFetching, hasError, errorMessage } = PartnerReducer
    return {
        partnerDetails,
        memberCheck,
        isFetching,
        hasError,
        errorMessage,
        eventRes
    }
}

const mapDispatchToProps = dispatch => {
    return {
        reqGetPartnerDetails: (data) => dispatch(actionCreators.getPartnerDetails(data)),
        eventRequest: (data) => dispatch(actionCreators.logEvent(data)),
        checkMemberExistance: (data) => dispatch(actionCreators.checkMemberId(data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent)
