import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { EMPTY_ARRAY } from '../../../constants/globalConstants'
import { Circles } from 'react-loader-spinner'
import { renderNoResults } from '../../../components/global/NoDataFound'
import { getPermitRequestList } from '../../../redux/actions/PermitRequestAction'
import { phoneMasker } from '../../../utils/helper'
// import { errorMessages } from '../../../constants/errorMessages'
// import { Link } from 'react-router-dom'

// let moment = require('moment')

function PermitRequest(props) {
    const { dispatch, loader } = props
    const [permitRequestList, setPermitRequestList] = useState(EMPTY_ARRAY)
    // const [userData, setUserData] = useState(EMPTY_OBJECT)
    const [displayVehicle, setDisplayVehicle] = useState(false)


    useEffect(() => {
        // setTimeout(
            dispatch(getPermitRequestList()).then((res) => {
               
                if (res?.data && res?.data.length > 0) {
                    let permitrecord=res?.data?.filter((v)=>v.approved_status != '1')//PIMS-10316 point 13
                   
                    setPermitRequestList(permitrecord)
                } else {
                    setPermitRequestList('')
                }
            }).catch(() => {
                setPermitRequestList('')
            })
            // , 500)

    }, [EMPTY_ARRAY])

    const handleshowVehicle = () => {
        setDisplayVehicle(!displayVehicle)
    }

    return (
        loader ? <span className='ms-2 d-flex justify-content-center'><Circles
            height="50"
            width="50"
            color="var(--bs-primary)"
            ariaLabel="circles-loading"
            visible={true}
        /></span> :
            <div>
                <div className="d-flex justify-content-between mb-4">
                    <h4 className="fw-bold">Permit Request </h4>
                </div>
                {permitRequestList?.length > 0 ? <ul className="permitList">
                    {permitRequestList?.map((item, index) => {
                        if (item == null) return null
                        return (
                            <li className={`listItem card border-opacity-25 ${item?.approved  && item?.approved_status == '1' ? 'active ' :  item?.is_rejected == '1' ? 'inactive ' : 'pendingpermitborder card'}`} key={index}>
                                <div className={item?.approved  && item?.approved_status == '1' ? 'card-header border-0 bg-success bg-opacity-10' : item?.is_rejected == null ?  'card-header border-0 bg-info bg-opacity-10' : 'card-header border-0 bg-danger bg-opacity-10'} >
                                    <div className='d-flex justify-content-end'>
                                        {/* <h5 className='mb-0 fw-bold'>{item?.permit_rate?.name} Plan</h5> */}
                                        <small className={item?.approved  && item?.approved_status == '1' ? 'text-success' :  item?.is_rejected == null ? 'text-info' : 'text-white' }><i className='fa fa-circle '></i> {item?.approved  && item?.approved_status == '1' ? 'Approved' : item?.is_rejected == null ? 'Pending' : 'Rejected'}</small>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='row'>
                                        
                                        <div className='row'>
                                            <div className='col-sm-12 mb-sm-0 mb-4'>
                                                <h5 className='mb-0 fw-bold text-muted '>{item?.facility?.full_name ? `${item?.facility?.full_name} -` : ''} <span className='h-6 small'>{item?.permit_type_name}</span></h5> 
                                            </div>
                                        </div>
                                        <div className='col-sm mb-sm-0 mb-3'>
                                            <p><b className='text-muted small'>Name: </b><span className='text-black small'>{item?.name}</span></p>
                                        </div>
                                        <div className='col-sm col-sm-5 mb-sm-0 mb-3'>
                                            <p><b className='text-muted small'>Email: </b><span className='text-black small'>{item?.email}</span></p>
                                        </div>
                                        <div className='col-sm mb-sm-0 mb-3'>
                                            <p><b className='text-muted small'>Phone: </b><span className='text-black small'>{phoneMasker(item?.phone)}</span></p>
                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4 mb-sm-0 mb-3'>
                                            <p><b className='text-muted small'>Refrence ID: </b><span className='text-black small'>{item?.tracking_code}</span></p>
                                        </div>
                                        {item?.license_number && <div className='col-sm mb-sm-0 mb-3 d-none'>
                                            <span><h5 className='mb-0 fw-bold text-muted small'>Driver License No.:</h5>
                                                <p><b className='text-black small'>{item?.license_number}</b></p></span>

                                            {/* <p className='text-muted mb-0  '>{item?.user?.license_number}<br/>  <b>Driving License No.</b></p> */}
                                        </div>}
                                        {item?.permit_vehicle && 
                                        <div className='col-sm-12 col-12 order-sm-1 order-4 mt-sm-0  '>
                                            <table className='table mt-3' cellSpacing="0px" cellPadding="0">
                                                <tr>
                                                {item?.permit_vehicle[0]?.vehicle?.license_plate_number &&
                                                    <th className='text-muted border ps-2'>License Plate:</th>
                                                }
                                                {item?.permit_vehicle?.length > 0 &&
                                                    <th className='text-muted border ps-2'>Color:</th>
                                                }
                                                {item?.permit_vehicle[0]?.vehicle?.make &&
                                                    <th className='text-muted border ps-2'>Make:</th>
                                                }
                                                {item?.permit_vehicle[0]?.vehicle?.model &&
                                                    <th className='text-muted border ps-2'>Model:</th>
                                                }
                                                </tr>
                                                <tr>
                                                {item?.permit_vehicle?.length > 0 &&
                                                    <td className='small border ps-2'>{item?.permit_vehicle[0]?.vehicle?.license_plate_number}</td>
                                                }
                                                {item?.permit_vehicle?.length > 0 &&
                                                    <td className='small border ps-2'>{item?.permit_vehicle[0]?.vehicle?.color}</td>
                                                }
                                                {item?.permit_vehicle[0]?.vehicle?.make &&
                                                    <td className='small border ps-2'>{item?.permit_vehicle[0]?.vehicle?.make}</td>
                                                }
                                                {item?.permit_vehicle[0]?.vehicle?.model &&
                                                   <td className='small border ps-2'>{item?.permit_vehicle[0]?.vehicle?.model}</td>
                                                }   
                                                </tr>
                                            </table>
                                          {item?.permit_vehicle.length > 1 ?  <span className=' fw-bold text-primary text-decoration-underline cursor' onClick={() => handleshowVehicle()}>{displayVehicle ? 'Hide All' : 'View All'}</span> : ''}

                                        </div>
                                        }
                                    </div>





                                    {displayVehicle && <span><hr />
                                        <b className='text-muted fw-bold'>Vehicles:</b>
                                        <div className='row align-items-center justify-content-between  '>
                                            <div className='col-sm-12 '>
                                                {item?.permit_vehicle && <div className='col-12 col-sm-auto order-sm-1 order-4 mt-sm-0  '>
                                                    <table cellSpacing="0px" cellPadding="0" className='table'>
                                                        <thead>
                                                            <tr>
                                                                <th className='text-muted'>License Plate</th>
                                                                <th className='text-muted'>Color</th>
                                                                <th className='text-muted'>Make</th>
                                                                <th className='text-muted'>Model</th>
                                                            </tr>
                                                        </thead>
                                                        {item?.permit_vehicle?.length > 0 && item?.permit_vehicle?.map((vehicle, index) => {
                                                            return <tr key={index}>
                                                                <td className='text-start ps-2'>{vehicle?.vehicle?.license_plate_number}</td>
                                                                <td className='text-start ps-2'>{vehicle?.vehicle?.color}</td>
                                                                <td className='text-start ps-2'>{vehicle?.vehicle?.make}</td>
                                                                <td className='text-start ps-2'>{vehicle?.vehicle?.model}</td>

                                                            </tr>
                                                        })}
                                                    </table>

                                                </div>
                                                }
                                            </div>
                                        </div></span>}

                                    <hr />
                                    <div className='col-12 row'>
                                        <div className='col-12'>
                                            <span className='fontsize85'>
                                                <b>Uploaded Documents:</b>
                                            </span>
                                        </div>

                                        {item?.permit_doc?.length > 0 ? 
                                            item?.permit_doc?.map((item, index) => {
                                                return(
                                                    <div className='col-6' key={index}>
                                                        <span className='d-block'>{item?.doc_name}</span>
                                                        <a href={`${item[item?.document_key]}`} target='_blank' rel="noreferrer" className='fw-bold'>View</a>
                                                    </div>
                                                )
                                            })
                                        : <div className='col-12'>
                                            <span className='fontsize85'>
                                                N/A
                                            </span>
                                        </div>}
                                        
                                        
                                    </div>
                                    {/* <div className='row align-items-center justify-content-between  '>
                                        <div className='col-sm-4 text-end order-sm-1 order-4 '>
                                            {item?.transaction?.total && <p className='mb-0'><b className='text-muted fw-bold'>Paid: </b> ${item?.transaction?.total}</p>}
                                        </div>
                                        <div className='col-sm-4 '>
                                            <small><b className='text-muted fw-bold'>Valid Till:</b>  <br className='d-block d-sm-none' />{item?.formatted_desired_end_date}</small>
                                        </div>
                                        <div className='col-sm-4 '>
                                            <small><b className='text-muted fw-bold'>Autopay Enabled</b> - <br className='d-block d-sm-none' />{item?.user_consent ? item?.user_consent == 1 ? 'Yes' : 'No' : 'Yes'}</small>
                                        </div>
                                    </div> */}
                                    {/* <div>
                                        <small className='text-danger'>{userData?.user?.license_number != item?.license_number ? errorMessages?.licenseAlertMessage : ''}</small>
                                    </div> */}
                                </div>
                            </li>
                        )
                    })}
                </ul> : renderNoResults()}
            </div>
    )
}

PermitRequest.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
}

PermitRequest.defaulProps = {
    dispatch: noop,
    loader: PropTypes.bool,
}

function mapStateToProps({ permitRequestReducer }) {
    return {
        loader: permitRequestReducer?.loader,
    }
}
export default connect(mapStateToProps)(PermitRequest)