import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import './sidebar.css'
function Header(props) {
    const { loggedInError} = props
  return (
        <section className="hero position-relative sidebar">
    
        
        <div className="carousel ">
            <div className="row justify-content-md-end">
            {loggedInError && <div className="alert alert-danger">
                <strong>Danger!</strong> {loggedInError}.
            </div>}
                <div className="col-sm-6 me-4">
                <div className="p-3 topBox text-white">
                    <h1 >Wailuku Town Parking Permits </h1>
                    <p>
                        <ul>
                            <li>
                                Welcome to the Wailuku Town Parking Permit Portal! Designed exclusively for Wailuku Town, this program offers an alternative monthly parking solution.
                            </li>
                            <li>
                                Artwork for Wailuku: Lori to send 
                            </li>
                        </ul>
                    </p>
                </div>
                    

                </div>
            </div>
        </div>
    </section>
    
  )
}

Header.propTypes = {
	page: PropTypes.string,
	loggedInError: PropTypes.string,
}

Header.defaulProps = {
	page: undefined,
	loggedInError: undefined
}

function mapStateToProps({ login, paymentReducer }) {
	return {
		isAuthenticated: login?.isAuthenticated,
		userData: login?.data,
		permitData: paymentReducer?.data
	}
}
export default connect(mapStateToProps)(Header)
