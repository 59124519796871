export const getConfigDetails = () => {
    //  switch ('staging2') {
   switch ('demo') {
        // switch ('live') {
        case 'staging2': //staging2
            return {
                instance_baseURL: 'https://staging2-api.parkengage.com',
                config_baseURL: 'https://staging2-api.parkengage.com',
                // clientSecret: 'oO3kDjRzeOkU0wrNaSjss5yaa',
                clientSecret: 'ChMEibSZ8JGN8dnlVl8gvvz2S',
                adminBaseUrl: 'https://staging-admin.parkengage.com',
                cmsBaseUrl: 'https://staging.parkengage.com',
            }
        case 'staging': //staging
            return {
                instance_baseURL: 'https://staging-api.parkengage.com',
                config_baseURL: 'https://staging-api.parkengage.com',
                clientSecret: 'ChMEibSZ8JGN8dnlVl8gvvz2S',
                adminBaseUrl: 'https://staging-admin.parkengage.com',
                cmsBaseUrl: 'https://staging.parkengage.com',
            }
        case 'demo': //staging
            return {
                instance_baseURL: 'https://demo-api.parkengage.com',
                config_baseURL: 'https://demo-api.parkengage.com',
                clientSecret: '9f3Blizhmt8qhkw62Y16qTJXa',
                adminBaseUrl: 'https://demo-admin.parkengage.com',
                cmsBaseUrl: 'https://demo.parkengage.com',
            }    
        case 'live': //live
            return {
                instance_baseURL: 'https://api.parkengage.com',
                config_baseURL: 'https://api.parkengage.com',
                clientSecret: 'oO3kDjRzeOkU0wrNaSjss5yaa',
                adminBaseUrl: 'https://partner.parkengage.com',
                cmsBaseUrl: 'https://parkengage.com'
            }
        case 'test': // Test
            return {
                instance_baseURL: 'https://test-api.parkengage.com',
                config_baseURL: 'https://test-api.parkengage.com',
                clientSecret: 'ChMEibSZ8JGN8dnlVl8gvvz2S',
                adminBaseUrl: 'https://test-admin.parkengage.com',
                cmsBaseUrl: 'https://test.parkengage.com',
            }
        default:
            return {
                instance_baseURL: 'https://staging-api.parkengage.com',
                config_baseURL: 'https://staging-api.parkengage.com',
                clientSecret: 'ChMEibSZ8JGN8dnlVl8gvvz2S',
                adminBaseUrl: 'https://staging-admin.parkengage.com',
                cmsBaseUrl: 'https://staging.parkengage.com',
            }    
    }
}
