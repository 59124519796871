import CONSTANTS from '../constants'
import { getRequest, postRequest } from '../../services'
import { APICONFIG } from '../config/apiConfig'

// get faclity List
export const getFaciltyData = (facilityUrl) => (dispatch) => {
  dispatch({ type: CONSTANTS.GET_FACILTY_REQUEST })
  return postRequest(APICONFIG.getFaclityUrl, facilityUrl).then((res) => {
    dispatch({ type: CONSTANTS.GET_FACILTY_SUCCESS, data: res })
    return res
  }).catch((err) => {
    dispatch({ type: CONSTANTS.GET_FACILTY_FAILURE })
    return err
  })
}

export const postCardPaymentDetails = (data) => (dispatch) => {
  dispatch({ type: CONSTANTS.POST_CARD_PAYMENT_REQUEST })
  return postRequest(APICONFIG.postUrlForPayment, data).then((res) => {
    dispatch({ type: CONSTANTS.POST_CARD_PAYMENT_SUCCESS, data: res })
    return res
  }).catch((err) => {
    dispatch({ type: CONSTANTS.POST_CARD_PAYMENT_FAILURE })
    return err
  })
}

// End Current Parking
export const endParking = (ticket_number) => (dispatch) => {
  dispatch({ type: CONSTANTS.END_PARKING_REQUEST })
  return getRequest(`${APICONFIG.endParking}/${ticket_number}`).then((res) => {
    dispatch({ type: CONSTANTS.END_PARKING_SUCCESS, data: res })
    return res
  }).catch((err) => {
    dispatch({ type: CONSTANTS.END_PARKING_FAILURE })
    return err
  })
}

