import React from 'react'
import './footer.scss'
function FooterComponent() {
	return (
		<footer className="bg-dark p-4 text-light footer-static">
			<div className="container">
				<div className="row">
					<div className="col-12 text-center">
						<small className="u-align-center u-small-text u-text u-text-variant u-text-1">
							For assistance with your permit, please contact (808) 451-2792 or email <a href="mailto:parkmaui@diamondparking.com" className='text-white'>parkmaui@diamondparking.com</a>
						</small>
						<p className="u-align-center u-small-text u-text u-text-variant u-text-1 mt-3">Powered By :
							<img className='brand-img2' height='50px' src="https://parkengage.com/static/media/logo.8ed76367.svg" />
						</p>
					</div>
				</div>
			</div>
		</footer>
	)
}
export default FooterComponent
