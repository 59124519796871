// import { APICONFIG } from '../../src/redux/config/apiConfig'
import moment from '../components/global/Time/Moment'
import { getConfigDetails } from '../services/config'
let loggedUserId = localStorage.getItem('userId')
// let loggedUser = localStorage.getItem('userData')
let loggedUserEmail = localStorage.getItem('userEmail')


// export const getLoggedUserData = () => localStorage.getItem('userData')
export const getLoggedUserData = () => {
    const userData = JSON.parse(localStorage.getItem('userData') ? localStorage.getItem('userData') : '{}')
    return userData
  }


const eventCallRequest = (eventName, errorMessage = '', facilityId = '', latitude = '', longitude = '', zipCode = '', userId = (loggedUserId) ? loggedUserId : '', emailId = (loggedUserEmail) ? loggedUserEmail : '', deviceDetails = 'React Web App', partnerKey = getConfigDetails()?.CLIENT_SECRET) => {
    switch (eventName) {
        case 'Home Screen Loaded':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }
            
        case 'Partner Details API Call':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }
        case 'Partner Details API Success':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }
        case 'Partner Details API Failed':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }
        case 'Process Button Click':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }
        case 'Check Member Exist API Failed':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }
        case 'Check Member Exist API Call':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }
        case 'Check Member Exist API Success':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }
        case 'Set Non Member To Storage':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }
        case 'Pass Screen Loaded':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }
        case 'Get Passes API Success':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }
        case 'Get Passes API Failed':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }
        case 'Fetch User Passes API Failed':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }
        case 'Fetch User Passes API Success':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }
        case 'Fetch Booking Rate API Success':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }
        case 'Fetch Booking Rate API Failed':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }
        case 'Fetch Arrival Rate API Failed':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }
        case 'Fetch Arrival Rate API Success':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }
        case 'Payment API Failed':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }
        case 'Payment API Success':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }
        case 'Existing Pass Payment API Failed':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }
        case 'Existing Pass Payment API Success':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }
        case 'Existing Pass Payment API Success and Page Loaded':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }    
        case 'IPG Session API Call':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            } 
        case 'IPG Session API Call Success':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            } 
        case 'IPG Session API Call Failed':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }  
        case 'Before Booking Data Save API Call':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }      
        case 'Before Booking Data Save API Call Failed':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }   
        case 'Before Booking Data Save API Call Success':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }   
        
        case 'After Payment Success, Booking API Call':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }        
        case 'After Payment Success, Booking API Call Failed':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }    
        case 'After Payment Success, Booking API Call Success':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }
        case 'After Payment Failed, Booking API Call':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }        
        case 'After Payment Failed, Booking API Call Failed':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }    
        case 'After Payment Failed, Booking API Call Success':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }                
        case 'Payment Response Page Loaded':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }     
        case 'Paynow Button Clicked':
            return {
                user_id: userId,
                user_data: getLoggedUserData(),
                event_name: eventName,
                latitude: latitude,
                longitude: longitude,
                facility_id: facilityId,
                email_id : emailId,
                app_version: '1.0',
                error_message: errorMessage,
                device: 'web',
                os_version: '1.0',
                device_detail: deviceDetails,
                zip_code: zipCode,
                partner_key : partnerKey,
                updated_at: moment(new Date()),
                created_at: moment(new Date())
            }                
    default:
    }
}

export default eventCallRequest