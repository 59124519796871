import CONSTANTS from '../constants'

const INITIAL_STATE = {
    loader: false,
    hasError: false,
    resetData: {},
    errorMessage: '',
    isModalOpen: false
}

const resetPasswordReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONSTANTS.POST_RESET_PASSWORD_REQUEST:
            return { ...state, loader: true, errorMessage: '' }
        case CONSTANTS.POST_RESET_PASSWORD_SUCCESS:
            return { ...state, loader: false, resetData: action.data, hasError: false, errorMessage: '' }
        case CONSTANTS.POST_RESET_PASSWORD_FAILURE:
            return { ...state, loader: false, errorMessage: 'There is some Error' }
        case CONSTANTS.RESET_PASSWORD_REQUEST:
            return { ...state, loader: true, errorMessage: '' }
        case CONSTANTS.RESET_PASSWORD_SUCCESS:
            return { ...state, loader: false, errorMessage: '' }
        case CONSTANTS.RESET_PASSWORD_FAILURE:
            return { ...state, loader: false, errorMessage: 'There is some Error' }
        default:
            return state
    }
}

export default resetPasswordReducer
