import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { EMPTY_ARRAY, EMPTY_OBJECT, userProfilePayload, validLicenceNumber, validPhone } from '../../../constants/globalConstants'
import VehicleAndPermit from '../vehicleAndPermit/VehicleAndPermit'
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask'
import { fileChangedHandler, isLoggedIn, restrictSpecialChar, validateEmail } from '../../../utils/helper'
import { updateUserProfile, getUserProfile } from '../../../redux/actions/login'
import FileUpload from '../../../components/fileUpload/FileUpload'
import { Circles } from 'react-loader-spinner'
import ResetPassWordModal from '../../../components/global/ResetPassWordModal'
import debounce from 'lodash/debounce'

// import URL from '../../../constants/urls'
const phoneMaskGenerator = createDefaultMaskGenerator('(999) 999-9999')
const zipCodeMaskGenerator = createDefaultMaskGenerator('999999')


function UserProfile(props) {
    const { dispatch, notify } = props
    const navigate = useNavigate()

    const [idProof, setIdProof] = useState(EMPTY_ARRAY)
    const [images, setImages] = useState({})
    const [userData, setUserData] = useState(EMPTY_OBJECT)
    const [edit, setEdit] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(EMPTY_OBJECT)
    const [resident, setResident] = useState('')
    const [userUpdatePayload, setUserUpdatePayload] = useState(userProfilePayload)
    const [disclaimerNote, setDisclaimerNote] = useState('')
    const [isUpdatingProfile, setIsUpdatingProfile] = useState(false)

    // modal state 
    const [isResetModalOpen, setResetModalOpen] = useState(false)
    const [isConsent, setIsConsent] = useState(true)

    // 


    useEffect(() => {

        if (idProof?.front) {
            const front = idProof?.front ? URL.createObjectURL(idProof?.front) : ''
            setImages({ ...images, front: front })
        }

    }, [idProof?.front])
    useEffect(() => {
        if (idProof?.back) {
            const back = idProof?.back ? URL.createObjectURL(idProof?.back) : ''
            setImages({ ...images, back: back })
        }

    }, [idProof?.back])

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate('/')
        }
        dispatch(getUserProfile()).then((res) => {
            if (res) {
                setResident(res?.resident_status)
                const userProfileData = JSON.parse(localStorage.getItem('userData'))
                userProfileData.user = res
                setIsConsent(res?.user_consent == '1' ? true : false)
                if (userProfileData) {
                    localStorage.setItem('userData', JSON.stringify(userProfileData))
                } else {
                    localStorage.setItem('userData', '{}')
                }
                setUserData(res)
                images['front'] = res?.permit?.[0]?.idfront
                images['back'] = res?.permit?.[0]?.idback
                setImages({ ...images })
                if (res?.name !== '') {
                    retrieveData(res)
                }
            }
        })
    }, [EMPTY_OBJECT])

    const retrieveData = (res) => {
        const formdata = res
        userUpdatePayload['name'] = formdata?.name ? formdata?.name?.trim() : ''
        userUpdatePayload['license_number'] = formdata?.license_number ? formdata?.license_number?.trim() : ''
        userUpdatePayload['email'] = formdata?.email ? formdata?.email?.trim() : ''
        userUpdatePayload['phone'] = formdata?.phone_number ? formdata?.phone_number?.trim() : ''
        userUpdatePayload['address'] = formdata?.address ? formdata?.address?.trim() : ''
        userUpdatePayload['address2'] = formdata?.address2 ? formdata?.address2?.trim() : ''
        userUpdatePayload['city'] = formdata?.city ? formdata?.city?.trim() : ''
        userUpdatePayload['state'] = formdata?.state ? formdata?.state?.trim() : ''
        userUpdatePayload['zipcode'] = formdata?.pincode ? formdata?.pincode?.trim() : ''
        setUserUpdatePayload({ ...userUpdatePayload })
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target

        if (name == 'license_number') {
            userUpdatePayload[name] = value?.trim()?.toUpperCase()
            setUserUpdatePayload({ ...userUpdatePayload })

            if (value?.length > 6 && value?.length < 14) {
                if (value?.length != 9) {
                    if (validLicenceNumber.test(value?.trim())) {
                        setResident('Non-Resident')
                    } else {
                        setResident('Invalid License Number')
                    }
                } else {
                    const alpha = value?.slice(0, 1)
                    const number = value?.slice(1, 9)
                    if (/^[a-zA-Z]+$/.test(alpha) && /(\d{8})/g.test(number)) {
                        setResident('Resident')
                    } else {
                        if (validLicenceNumber.test(value?.trim())) {
                            setResident('Non-Resident')
                        } else {
                            setResident('Invalid License Number')
                        }
                    }
                }
            } else {
                setResident('')
            }
            if (userData?.license_number != value) {
                setDisclaimerNote('For New/Existing Permits, the revised rates will be applicable as per your new License number.')
            } else {
                setDisclaimerNote('')
            }
        } else {
            userUpdatePayload[name] = value?.trim()
            setUserUpdatePayload({ ...userUpdatePayload })
        }
    }

    const handleLicenseChange = (e) => {
        const { value } = e.target

        if (userData?.license_number != value) {
            setDisclaimerNote('For New/Existing Permits, the revised rates will be applicable as per your new License number.')
        } else {
            setDisclaimerNote('')
        }
    }

    const handlePhoneChange = (value) => {
        const phone = value?.trim()
        setUserUpdatePayload({ ...userUpdatePayload, ['phone']: phone })
        setErrorMessage('')
    }

    const handleZipCodeChange = (value) => {
        const zipcode = value?.trim()
        setUserUpdatePayload({ ...userUpdatePayload, ['zipcode']: zipcode })
    }

    const handleEditMode = () => {
        setEdit(true)
    }

    const handleUpdateUserProfile = (e) => {
        setError(true)
        setIsUpdatingProfile(true)
        e.preventDefault()
        let formData = new FormData()     //formdata object
        formData.append('license_number', userUpdatePayload?.license_number)
        formData.append('email', userUpdatePayload?.email)
        formData.append('name', userUpdatePayload?.name)
        formData.append('phone', userUpdatePayload?.phone)
        formData.append('address', userUpdatePayload?.address)
        formData.append('address2', userUpdatePayload?.address2 ? userUpdatePayload?.address2 : '')
        formData.append('city', userUpdatePayload?.city)
        formData.append('state', userUpdatePayload?.state)
        formData.append('zipcode', userUpdatePayload?.zipcode)
        formData.append('permit_id', userData?.permit?.[0]?.id)
        if (idProof?.length > 0) {
            idProof?.map((item) => {
                formData.append(item?.type, item?.file)
            })
        }
        if (userUpdatePayload?.name != '' && userUpdatePayload?.email != '' && userUpdatePayload?.phone != '') {
            if (validateEmail(userUpdatePayload?.email)) {
                if (validPhone.test(userUpdatePayload?.phone) && userUpdatePayload?.phone?.length == 10) {
                    dispatch(updateUserProfile(formData)).then((res) => {
                        setIsUpdatingProfile(false)
                        setDisclaimerNote('')
                        if (res?.errors) {
                            setErrorMessage({ message: res?.errors?.message })
                        } else {
                            setErrorMessage({ message: '' })
                            const userProfileData = JSON.parse(localStorage.getItem('userData'))
                            userProfileData.user = res?.data?.user
                            userProfileData.permit = res?.data?.permit
                            if (userProfileData) {
                                if (res?.data?.permit?.idfront || res?.data?.permit?.idback) {
                                    images['front'] = res?.data?.permit?.idfront ? res?.data?.permit?.idfront : ''
                                    images['back'] = res?.data?.permit?.idback ? res?.data?.permit?.idback : ''
                                    setImages({ ...images })
                                }
                                localStorage.setItem('userData', JSON.stringify(userProfileData))
                            } else {
                                localStorage.setItem('userData', '{}')
                            }
                            setEdit(false)
                        }
                    }).catch(() => {
                        setDisclaimerNote('')
                        setIsUpdatingProfile(false)
                        setErrorMessage({ message: 'Something went wrong, Please try again!' })
                    })
                } else {
                    setIsUpdatingProfile(false)
                    setErrorMessage({ message: '' })
                }
            } else {
                setIsUpdatingProfile(false)
                setErrorMessage({ message: '' })
            }

        } else {
            setIsUpdatingProfile(false)
            setErrorMessage({ message: '' })
        }
    }

    // modal functions

    const handleModalOpen = (e) => {
        e.preventDefault()
        setResetModalOpen(true)
    }

    const handleAutoPayChange = () => {

        setIsConsent(!isConsent)
        let formData = new FormData()     //formdata object
        formData.append('license_number', userUpdatePayload?.license_number ? userUpdatePayload?.license_number : '')
        formData.append('email', userUpdatePayload?.email ? userUpdatePayload?.email : '')
        formData.append('name', userUpdatePayload?.name ? userUpdatePayload?.name : '')
        formData.append('phone', userUpdatePayload?.phone ? userUpdatePayload?.phone : '')
        formData.append('address', userUpdatePayload?.address ? userUpdatePayload?.address : '')
        formData.append('address2', userUpdatePayload?.address2 ? userUpdatePayload?.address2 : '')
        formData.append('city', userUpdatePayload?.city ? userUpdatePayload?.city : '')
        formData.append('state', userUpdatePayload?.state ? userUpdatePayload?.state : '')
        formData.append('zipcode', userUpdatePayload?.zipcode ? userUpdatePayload?.zipcode : '')
        formData.append('image_front', idProof?.front ? idProof?.front : '')
        formData.append('image_back', idProof?.back ? idProof?.back : '')
        formData.append('permit_id', userData?.permit?.[0]?.id ? userData?.permit?.[0]?.id : '')
        formData.append('user_consent', !isConsent ? 1 : 0)

        deb(formData)


    }
    const deb = useCallback(
        debounce((payload) => {
            dispatch(updateUserProfile(payload)).then((res) => {
                notify('success', 'Autopay consent is updated successfully')
                setIsUpdatingProfile(false)
                setDisclaimerNote('')
                const userProfileData = JSON.parse(localStorage.getItem('userData'))
                userProfileData.user = res?.data?.user
                userProfileData.permit = res?.data?.permit
                if (userProfileData) {
                    if (res?.data?.permit?.idfront || res?.data?.permit?.idback) {
                        images['front'] = res?.data?.permit?.idfront ? res?.data?.permit?.idfront : ''
                        images['back'] = res?.data?.permit?.idback ? res?.data?.permit?.idback : ''
                        setImages({ ...images })
                    }
                    localStorage.setItem('userData', JSON.stringify(userProfileData))
                } else {
                    localStorage.setItem('userData', '{}')
                }
                setEdit(false)
            }).catch(() => {
                notify('success', 'Something went wrong, Please try again!')
                setDisclaimerNote('')
                setIsUpdatingProfile(false)
                setErrorMessage({ message: 'Something went wrong, Please try again!' })
            })
        }, 300
        ), [])

    return (
        <main>
            <section className="formDesign p-sm-5 mt-5">
                <VehicleAndPermit userData={userData} />
                <div className="container mb-3">
                    <div className="card mb-3">
                        <div className="card-header">
                            <div className="d-flex">
                                <h5 className="mb-0">Personal Information</h5>
                                <button className="btn btn-primary p-0 px-2 ms-auto me-1" type='button' onClick={(e) => handleModalOpen(e)}>Reset Password</button>
                                <button className="btn btn-secondary p-0 px-2" onClick={handleEditMode}> <i className="fa fa-pencil"></i></button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Name <span style={{ color: 'red' }}>*</span></label>
                                        <input className="form-control" maxLength={20} name='name' id='name' type='text' value={userData?.name ? userData?.name : ''} disabled />
                                        <span className="form-text text-danger h-40">{error && !userUpdatePayload?.name ? 'This field is required' : ''}</span>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email <span style={{ color: 'red' }}>*</span></label>
                                        <input className="form-control" maxLength={40} id='email' name='email' type='email' value={userData?.email ? userData?.email : ''} disabled />
                                        <span className="form-text text-danger h-40">{error && !userUpdatePayload?.email ? 'This field is required' : ''}</span>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="mb-3">
                                        <label htmlFor="phone" className="form-label">Phone Number <span style={{ color: 'red' }}>*</span></label>
                                        <MaskedInput
                                            maskGenerator={phoneMaskGenerator}
                                            value={userUpdatePayload?.phone ? userUpdatePayload?.phone : ''}
                                            type="text"
                                            name="phone"
                                            id="phone"
                                            className="form-control"
                                            required
                                            maxLength={14}
                                            minLength={14}
                                            title="Please enter a valid phone number"
                                            onInvalid={e => e.target.setCustomValidity('Please enter phone number')}
                                            onInput={e => e.target.setCustomValidity('')}
                                            placeholder="Phone Number"
                                            onChange={handlePhoneChange}
                                            disabled={!edit}
                                        />
                                        <span className="form-text text-danger h-40">{error && !userUpdatePayload?.phone ? 'This field is required' : ''}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="mb-3">
                                        <label htmlFor="address" className="form-label">Address</label>
                                        <input className="form-control" maxLength={100} id='address' name='address' defaultValue={userData?.address ? userData?.address : ''} onChange={handleInputChange} onBlur={handleInputChange} disabled={!edit} />
                                        {/* <span className="form-text text-danger h-40">{error && !userUpdatePayload?.address ? 'This field is required' : ''}</span> */}
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="mb-3">
                                        <label htmlFor="address2" className="form-label">Address 2</label>
                                        <input className="form-control" maxLength={100} id='address2' name='address2' defaultValue={userData?.address2 ? userData?.address2 : ''} onChange={handleInputChange} onBlur={handleInputChange} disabled={!edit} />
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="mb-3">
                                        <label htmlFor="city" className="form-label">City</label>
                                        <input className="form-control" maxLength={20} name='city' id='city' defaultValue={userData?.city ? userData?.city : ''} onChange={handleInputChange} onBlur={handleInputChange} disabled={!edit} />
                                        {/* <span className="form-text text-danger h-40">{error && !userUpdatePayload?.city ? 'This field is required' : ''}</span> */}
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="mb-3">
                                        <label htmlFor="state" className="form-label">State</label>
                                        <input className="form-control" maxLength={20} name='state' id='state' defaultValue={userData?.state ? userData?.state : ''} onChange={handleInputChange} onBlur={handleInputChange} disabled={!edit} />
                                        {/* <span className="form-text text-danger h-40">{error && !userUpdatePayload?.state ? 'This field is required' : ''}</span> */}
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="mb-3">
                                        <label htmlFor="zipcode" className="form-label">Zip Code</label>
                                        <MaskedInput
                                            maskGenerator={zipCodeMaskGenerator}
                                            value={userData?.pincode ? userData?.pincode : ''}
                                            type="text"
                                            name="zipcode"
                                            id="zipcode"
                                            className="form-control"
                                            required
                                            // pattern="[0-9 \\-\(\)\]{14}"
                                            maxLength={6}
                                            minLength={6}
                                            title="Please enter a valid zipcode"
                                            onInvalid={e => e.target.setCustomValidity('Please enter zipcode')}
                                            onInput={e => e.target.setCustomValidity('')}
                                            placeholder="Pin Code / Security Code"
                                            onChange={handleZipCodeChange}
                                            disabled={!edit}
                                        />
                                        {/* <span className="form-text text-danger h-40">{error && !userUpdatePayload?.zipcode ? 'This field is required' : ''}</span> */}
                                    </div>
                                </div>
                            </div>
                            {/* <hr /> */}
                            <div className="row d-none">
                                <div className="col-sm-3">
                                    <div>
                                        <label htmlFor="license_number" className="form-label">Driver License Number <span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            className="form-control"
                                            name='license_number'
                                            id='license_number'
                                            type='text'
                                            value={userUpdatePayload?.license_number ? userUpdatePayload?.license_number : ''}
                                            minLength={7}
                                            maxLength={13}
                                            // defaultValue={userData?.license_number ? userData?.license_number : ''} 
                                            onChange={handleInputChange}
                                            onKeyDown={restrictSpecialChar}
                                            onBlur={handleLicenseChange}
                                            disabled={!edit} />
                                    </div>
                                    {resident ? <span className={resident == 'Invalid License Number' ? 'd-block form-text text-danger h-40 text-end' : 'd-block form-text mainColor h-40 text-end'}><b>{resident}</b></span> : ''}
                                    <span className="d-block form-text text-danger h-40">{error && !userUpdatePayload?.license_number ? 'This field is required' : ''}</span>
                                    {disclaimerNote && userUpdatePayload?.license_number ? <span className='text-danger'><small>{disclaimerNote}</small></span> : ''}
                                </div>
                                <div className="col text-center ">
                                    <b>Drivers License Proof (Front)</b>
                                    {images?.front ? <div className="p-1 text-center"> <img height="46px" src={images?.front} /></div> : <div className="p-1 text-center">-</div>}
                                </div>
                                <div className="col text-center ">
                                    <b>Drivers License Proof (Back)</b>
                                    {images?.back ? <div className="p-1 text-center"> <img height="46px" src={images?.back} /></div> : <div className="p-1 text-center">-</div>}
                                </div>
                                {edit ?
                                    <div className="row">
                                        <FileUpload
                                            accept='image/*'
                                            onChange={(event) => fileChangedHandler(event, 'front', setIdProof, idProof, 300, 200)}
                                            name='front'
                                            id='front'
                                            required />
                                        <FileUpload
                                            accept='image/*'
                                            onChange={(event) => fileChangedHandler(event, 'back', setIdProof, idProof, 300, 200)}
                                            name='back'
                                            id='back'
                                            required />
                                        <small>
                                            (File type : jpeg, jpg, png)
                                        </small>
                                    </div>
                                    : null}
                            </div>
                            {/* <hr /> */}
                            {errorMessage ? <span className='text-danger'>{errorMessage?.message ? errorMessage?.message : ''}</span> : ''}
                            {edit && <button type="button" className="btn btn-primary d-flex" onClick={(e) => handleUpdateUserProfile(e)} style={{ float: 'right' }}>Update {isUpdatingProfile ? <span className=' ms-2'><Circles
                                height="20"
                                width="20"
                                color="#ffffff"
                                ariaLabel="circles-loading"
                                visible={true}
                            /></span> : null}
                            </button>}
                        </div>
                    </div>
                    <div className="container p-0">
                        <div className="card">
                            <div className="card-header">
                                <div className="d-flex">
                                    <h5 className="mb-0">AutoPay Settings</h5>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row"></div>
                                <div className="form-check form-switch">
                                 
                                        <input type="checkbox" id='autopayconsent' className='form-check-input'  name='autopayconsent' checked={isConsent} onChange={() => handleAutoPayChange()} />
                                        <label htmlFor="autopayconsent" className='form-check-label' > AutoPay Consent </label>
                                   
                                
                                    {/* <i className='ps-2 fa-sm fa fa-info-circle' onClick={() => setDisplayModal(true)}></i> */}
                                </div>
                                <i className='d-block small'>By clicking this button, you are providing your consent for auto renewal.</i>

                            </div>
                        </div>
                    </div>
                    {/* <div className="card">
                        <div className="card-header">
                            <div className="d-flex">
                                <h5 className="mb-0">Password</h5>
                                <button className="btn p-0 px-2 ms-auto"> <i className="fa fa-pencil"></i></button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="mb-3">
                                        <label htmlFor="" className="form-label">Password Old</label>
                                        <div className="form-control">********</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
            <ResetPassWordModal isResetModalOpen={isResetModalOpen} setResetModalOpen={setResetModalOpen} modalHeader='Reset Password' buttonLable='Reset' />
        </main>
    )
}


UserProfile.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    setFront: PropTypes.func,
    setBack: PropTypes.func,
    notify: PropTypes.func,
}

UserProfile.defaulProps = {
    dispatch: noop,
    loader: PropTypes.bool,
    setFront: noop,
    setBack: noop,
    notify: noop,
}

export default connect()(UserProfile)