import * as actionTypes from '../config/ActionTypes'
import CONSTANTS from '../constants'
import { updateObject } from '../utility'

const initialState = {
  isFetching: false,
  hasError: false,
  partnerDetails:{},
  memberCheck:{},
  eventRes:{},
  errorMessage:''
}

const PartnerReducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.GET_PARTNER_DETAILS_REQUEST:
      return updateObject(state, {isFetching:true})
    case actionTypes.GET_PARTNER_DETAILS_REQUEST_SUCCESS:
      return updateObject(state, {partnerDetails:action.data, isFetching:false, errorMessage: '', hasError: false})
    case actionTypes.GET_PARTNER_DETAILS_REQUEST_FAIL:
      return updateObject(state, {isFetching:false, errorMessage: action.errorMessage, hasError: true})
    case actionTypes.GET_MEMBER_CHECK_REQUEST:
      return updateObject(state, {isFetching:true})
    case actionTypes.GET_MEMBER_CHECK_REQUEST_SUCCESS:
      return updateObject(state, {memberCheck:action.data, hasError:false, isFetching:false})
    case actionTypes.GET_MEMBER_CHECK_REQUEST_FAIL:
      return updateObject(state, {hasError:true, errorMessage:action.errorMessage, isFetching:false})
    case actionTypes.POST_EVENT_LOG_REQUEST:
      return updateObject(state, {isFetching:false})
    case actionTypes.POST_EVENT_LOG_REQUEST_SUCCESS:
      return updateObject(state, {eventRes:action.data, hasError:false, isFetching:false})
    case actionTypes.POST_EVENT_LOG_REQUEST_FAIL:
      return updateObject(state, {hasError:true, errorMessage:action.errorMessage, isFetching:false})
    case CONSTANTS.GET_PARTNER_DETAILS_BY_SLUG_REQUEST:
      return updateObject(state, {isFetching:true})
    case CONSTANTS.GET_PARTNER_DETAILS_BY_SLUG_SUCCESS:
      return updateObject(state, {isFetching:false, hasError:false, partnerDetails:action?.data, errorMessage: null})
    case CONSTANTS.GET_PARTNER_DETAILS_BY_SLUG_FAILURE:
      return updateObject(state, {hasError:true, errorMessage:action.errorMessage, isFetching:false, partnerDetails: null})
    default:
      return state
  }
}

export default PartnerReducer