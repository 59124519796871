import React, { Component } from 'react'
import Aux from './../Wrapper/Auxiliary'
import SimpleReactValidator from 'simple-react-validator'
import noop from 'lodash/noop'
import moment from '../../../components/global//Time/Moment'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import 'react-datepicker/dist/react-datepicker.css'
import * as actionCreators from '../../../redux/actions'
import { connect } from 'react-redux'
import eventCallRequest, { getLoggedUserData } from '../../../../src/utils/HelperEvent'
import { conformToMask } from 'react-text-mask'
import { APICONFIG } from '../../../redux/config/apiConfig'
import CryptoJS from 'crypto-js'
import NotificationSystem from 'react-notification-system'
import LoadingComponent from '../../../common/UI/loadingComponent'
import coupon from '../../../assets/img/coupon.png'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { BottomLogo, EXPIRY_MONTHS, EXPIRY_YEARS } from '../Constant'
import { getPaymentInitials } from '../../../redux/actions'
// import queryString from 'query-string'
import { checkValidations, makePayment } from '../../../redux/actions/passesAction'
import { getPartnerDetails, isLoggedIn, loggedInUserData, phoneMasker, restrictInputValues } from '../../../../src/utils/helper'
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getSessionAttribute } from '../../../utils/helpers'
import { EMPTY_OBJECT } from '../../../constants/globalConstants'
import '../../../assets/css/style.css'
// import Sidebar from '../../../components/global/Sidebar'
import CarouselItems from './CarouselItems'
import { getCardDataList } from '../../.././redux/actions/cardDetailsAction'





class PassComponent extends Component {
    encryptKey = APICONFIG.encryptKey
    dateMask = [/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/]
    cardMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
    security_codeMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
    zipmask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
    maskGenerator = createDefaultMaskGenerator('9999 9999 9999 9999')
    phoneMaskGenerator = createDefaultMaskGenerator('(999) 9999-9999')
    constructor(props) {
        super(props)
        this.state = {
            userData: '',
            email_address: '',
            name: '',
            card_number: '',
            cvv: '',
            expiry_date: '',
            zip_code: '',
            pass_id: '',
            pass_rate_id: '',
            price: 0,
            processing_fee: 0,
            latitude: '',
            longitude: '',
            length: APICONFIG.defaultBookingLength,
            isMember: true,
            isPassExits: false,
            is_book_parking: true,
            passWithParking: false,
            existPass: {},
            paymentScreenOpen: false,
            entry_date: moment().format('MM/DD/yyyy'),
            exit_date: moment().format('MM/DD/yyyy'),
            isSubmitted: false,
            paymentSession: {},
            inputType:'password',
            eyeIcon: faEye,
            card_pan:'',
            card_card_security_cvx_2: '',
            card_date_expiry_month: '',
            card_date_expiry_year: '',
            isBooking: false,
            expiryTime: 900000,
            garage_id: '',
            cardList: [],
            isCardFormVisible: false,
            payment_profile_id: ''

        }
        this.formRef = React.createRef()
        // this.paymentWindow = window

        this.validator = new SimpleReactValidator({
            validators: {
                validCard: {
                    message: 'The card number must be between 15 and 16 digits.',
                    rule: (val, params) => {
                        return ((this.state.card_number.replace(/\D/g, '').length < 15) && params.indexOf(val) === -1) ? false : true
                    },
                    required: true
                },
            }
        })
        this.notificationSystem = React.createRef()
        this.dateTimeDisplayFormat = 'MM/DD/yyyy'
        this.dateDisplayFormat = 'MM/DD/yyyy'
        this.sendDateFormat = 'yyyy-MM-dd'
        this.senddateTimeFormat = 'YYYY-MM-DD'
        this.timeSelection = APICONFIG.timeDropDown
        this.phoneNumberMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    }

    carouselOptions = {
        nav: false,
        rewind: false,
        autoplay: false,
        margin: 30,
        loop: false,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 4,
            }
        }
    }

    componentDidMount = () => {

        localStorage.setItem('queries', '{}')
        localStorage.setItem('isBooking', 0)
        localStorage.setItem('passDetails', JSON.stringify({ is_book_parking: true }))
        const userData = localStorage.getItem('userData')
        this.setState({ isBooking: true })
        const { dispatch } = this.props
        let eventParams = eventCallRequest('Pass Screen Loaded', JSON.stringify(userData))


        if (loggedInUserData()?.access_token) {
            const partnerData = getPartnerDetails()
            this.setState({ garage_id: partnerData.facilities[0].id })
            // const apiCallLog = { ...getConfigDetails()?.CLIENT_SECRET, userData }
            // let partnerDetailsApiCall = eventCallRequest('Partner Details API Call', JSON.stringify(apiCallLog))
            // this.props.eventRequest(partnerDetailsApiCall).then(() => { })
            
            // this.props.reqGetPartnerDetails(getConfigDetails()?.CLIENT_SECRET).then(() => {
            //     const { partnerDetails, hasError, errorMessage } = this.props

            //     const logError = { ...errorMessage, userData }
            //     if (hasError) {
            //         let eventParams = eventCallRequest('Partner Details API Failed', JSON.stringify(logError))
            //         this.props.eventRequest(eventParams).then(() => { })
            //     } else {
            //         this.setState({ garage_id: partnerDetails.facilities[0].id }, () => {

            //             const apiSuccessLog = { ...partnerDetails, userData }
            //             let eventParams = eventCallRequest('Partner Details API Success', JSON.stringify(apiSuccessLog), partnerDetails.facilities[0].id)
            //             this.props.eventRequest(eventParams).then(() => { })
            //         })
            //     }
            // })

            this.props.dispatch(getCardDataList()).then((res) => {
                this.setState({ cardList: res.data.payments })
            })
        }

        setTimeout(() => {
            this.setState({ expiryTime: 0 })
        }, 900000)




        this.setState({ paymentScreenOpen: false })

        this.props.eventRequest(eventParams).then(() => { })

        this.setState({ isSubmitted: true }, () => {
            const partnerData = getPartnerDetails()
            const garage = partnerData.facilities[0]
            this.setState({ garage_id: partnerData.facilities[0].id })
            const notification = this.notificationSystem.current

            // const secret = getConfigDetails()?.CLIENT_SECRET
            // const apiCallLog = { userData, secret }

            // let eventParams = eventCallRequest('Partner Details API Call', JSON.stringify(apiCallLog))
            // this.props.eventRequest(eventParams).then(() => { })
            // this.props.reqGetPartnerDetails(getConfigDetails()?.CLIENT_SECRET).then(() => {
            //     const notification = this.notificationSystem.current
            //     const { hasErrorPartner, partnerDetails, hasErrorMessage } = this.props
            //     localStorage.setItem('proFee', partnerDetails.facilities[0].processing_fee)
            //     let apiFailLog = { userData, hasErrorPartner }
            //     apiFailLog = { ...apiFailLog, secret }
            //     if (hasErrorPartner) {
            //         let eventParams = eventCallRequest('Partner Details API Failed', JSON.stringify(apiFailLog))
            //         this.props.eventRequest(eventParams).then(() => { })

            //         notification.addNotification({
            //             title: 'Sorry!',
            //             message: hasErrorMessage,
            //             level: 'error'
            //         })
            //     } else {
                    // let apiSuccessLog = { userData, partnerDetails }
                    // apiSuccessLog = { ...apiSuccessLog, secret }
                    // let eventParams = eventCallRequest('Partner Details API Success', JSON.stringify(apiSuccessLog), partnerDetails.facilities[0].id)
                    // this.props.eventRequest(eventParams).then(() => { })

                    if ((garage.active == 0) || (garage.is_available != 1) || (garage.facility_booking_type == 1)) {
                        notification.addNotification({
                            title: 'Sorry!',
                            message: 'Garage is not available for booking.',
                            level: 'error'
                        })
                        setTimeout(() => {
                            window.location.replace(`${getPartnerDetails()?.partner_slug}/booking`)
                        }, 3000)
                    } else {
                        this.setState({ isSubmitted: false }, () => {
                            let userData = loggedInUserData()?.access_token ? loggedInUserData()?.user : JSON.parse(localStorage.getItem('userData'))
                            if (userData == null) {
                                window.location.replace(`${getPartnerDetails()?.partner_slug}/booking`)
                            } else {
                                this.setState({
                                    userData: userData,
                                    email_address: userData.email,
                                    is_member: userData.is_member,
                                    processing_fee: (garage.processing_fee > 0) ? garage.processing_fee : 0,
                                    entry_date: moment(userData.current_date).format(this.dateDisplayFormat),
                                }, () => {
                                    if (this.state.is_member || loggedInUserData()?.access_token) {
                                        this.setState({ isMember: true }, () => {
                                            let reqParams = {
                                                garage_id: loggedInUserData()?.access_token ? this.state.garage_id : this.state.userData.garage_id,
                                                is_member: (this.state.isMember) ? 1 : 0,
                                                arrival: moment(this.state.userData.current_date).format(this.senddateTimeFormat)
                                            }

                                            this.props.reqGetPasses(reqParams).then(() => {
                                                const { passes, hasError, errorMessage } = this.props
                                                const userData = localStorage.getItem('userData')
                                                if (hasError) {
                                                    const apiFailLog = { userData, errorMessage }
                                                    let eventParams = eventCallRequest('Get Passes API Failed', JSON.stringify(apiFailLog))
                                                    this.props.eventRequest(eventParams).then(() => { })
                                                } else {
                                                    const apiSuccessLog = { userData, passes }
                                                    let eventParams = eventCallRequest('Get Passes API Success', JSON.stringify(apiSuccessLog), loggedInUserData()?.access_token ? this.state.garage_id : this.state.userData.garage_id)
                                                    this.props.eventRequest(eventParams).then(() => { })
                                                }
                                            })
                                             this.props.reqGetUserPasses({ phone: loggedInUserData()?.access_token ? loggedInUserData()?.user?.phone_number : this.state.userData.phone_number }).then(() => {
                                                const { userPasses, hasError, errorMessage } = this.props
                                                const userData = localStorage.getItem('userData')
                                                if (hasError) {
                                                    const apiFailLog = { userData, errorMessage }
                                                    let eventParams = eventCallRequest('Fetch User Passes API Failed', JSON.stringify(apiFailLog))
                                                    this.props.eventRequest(eventParams).then(() => { })
                                                } else {
                                                    const apiSuccessLog = { userData, userPasses }
                                                    let eventParams = eventCallRequest('Fetch User Passes API Success', JSON.stringify(apiSuccessLog), loggedInUserData()?.access_token ? this.state.garage_id : this.state.userData.garage_id)
                                                    this.props.eventRequest(eventParams).then(() => { })
                                                }
                                                this.setState({ isPassExits: userPasses.length > 0 ? true : false })

                                            }).then(() => {
                                                let formData = {
                                                    'use_bonus': 0,
                                                    'is_member': (this.state.isMember) ? 1 : 0,
                                                    'length_of_stay': this.state.length,
                                                    'arrival_time': moment(this.state.userData.current_date).format(this.senddateTimeFormat),
                                                    'garage_id': loggedInUserData()?.access_token ? this.state.garage_id : this.state.userData.garage_id
                                                }
                                                this.props.reqGetPrice(formData).then(() => {
                                                    const { getGaragePriceRes, hasError, errorMessage } = this.props
                                                    let userDetails = loggedInUserData()?.access_token ? loggedInUserData()?.user : JSON.parse(localStorage.getItem('userData'))
                                                    userDetails = { ...errorMessage, userDetails }
                                                    if (hasError) {
                                                        let eventParams = eventCallRequest('Fetch Booking Rate API Failed', JSON.stringify(userDetails))
                                                        this.props.eventRequest(eventParams).then(() => { })
                                                    } else {
                                                        userDetails = { ...getGaragePriceRes, userDetails }
                                                        let eventParams = eventCallRequest('Fetch Booking Rate API Success', JSON.stringify(userDetails), loggedInUserData()?.access_token ? this.state.garage_id : this.state.userData.garage_id)
                                                        this.props.eventRequest(eventParams).then(() => { })
                                                    }
                                                    const requestPayload = {
                                                        'amount': getGaragePriceRes?.price,
                                                        'facility_id': userData?.garage_id ? userData?.garage_id : this.state.garage_id,
                                                        'rate_id': getGaragePriceRes?.id,
                                                        'is_booking_also': 1,
                                                        'arrival': this.state?.entry_date,
                                                        'type': 'booking',
                                                    }
                                                    let userDataLog = localStorage.getItem('userData')
                                                    userDataLog = { ...errorMessage, userDataLog }
                                                    let eventParams = eventCallRequest('IPG Session API Call', JSON.stringify(userDataLog))
                                                    this.props.eventRequest(eventParams).then(() => { })
                                                    dispatch(getPaymentInitials(requestPayload)).then((res) => {
                                                        if (res.data && res.data != null) {
                                                            let eventParams = eventCallRequest('IPG Session API Call Success', JSON.stringify(userDataLog))
                                                            this.props.eventRequest(eventParams).then(() => { })
                                                            this.setState({ paymentSession: res?.data, expiryTime: 900000 })
                                                            localStorage.setItem('paymentSession', JSON.stringify(res?.data))
                                                        } else {
                                                            notification.addNotification({
                                                                title: 'Sorry!',
                                                                message: 'There is some Problem with Payment Gateway',
                                                                level: 'error'
                                                            })
                                                            let eventParams = eventCallRequest('IPG Session API Call Failed', JSON.stringify(userDataLog))
                                                            this.props.eventRequest(eventParams).then(() => { })

                                                        }

                                                    }).catch((err) => {
                                                        notification.addNotification({
                                                            title: 'Sorry!',
                                                            message: err,
                                                            level: 'error'
                                                        })
                                                        let eventParams = eventCallRequest('IPG Session API Call Failed', JSON.stringify(userDataLog))
                                                        this.props.eventRequest(eventParams).then(() => { })

                                                    })
                                                    this.setState({ price: (this.state.isPassExits) ? getGaragePriceRes.price : 0, pass_rate_id: '', pass_id: '', is_book_parking: true, length: APICONFIG.defaultBookingLength })
                                                })
                                            })
                                        })
                                    } else {
                                        this.setState({ isSubmitted: false, isMember: false, isPassExits: false }, () => {
                                            let formData = {
                                                'use_bonus': 0,
                                                'is_member': (this.state.isMember) ? 1 : 0,
                                                'length_of_stay': this.state.length,
                                                'arrival_time': moment(this.state.userData.current_date).format(this.senddateTimeFormat),
                                                'garage_id': loggedInUserData()?.access_token ? this.state.garage_id : this.state.userData.garage_id
                                            }
                                            this.props.reqGetPrice(formData).then(() => {
                                                const { getGaragePriceRes, hasError, errorMessage } = this.props
                                                if (hasError) {
                                                    let eventParams = eventCallRequest('Fetch Arrival Rate API Failed', JSON.stringify(errorMessage))
                                                    this.props.eventRequest(eventParams).then(() => { })
                                                } else {
                                                    let eventParams = eventCallRequest('Fetch Arrival Rate API Success', JSON.stringify(getGaragePriceRes), loggedInUserData()?.access_token ? this.state.garage_id : this.state.userData.garage_id)
                                                    this.props.eventRequest(eventParams).then(() => { })

                                                    const requestPayload = {
                                                        'amount': getGaragePriceRes?.price,
                                                        'facility_id': userData?.garage_id ? userData?.garage_id : this.state.garage_id,
                                                        'rate_id': getGaragePriceRes?.id,
                                                        'is_booking_also': 1,
                                                        'arrival': this.state?.entry_date,
                                                        'type': 'booking',
                                                    }
                                                    let userDataLog = localStorage.getItem('userData')
                                                    userDataLog = { ...errorMessage, userDataLog }
                                                    let setUserLog = eventCallRequest('IPG Session API Call', JSON.stringify(userDataLog))
                                                    this.props.eventRequest(setUserLog).then(() => { })
                                                    dispatch(getPaymentInitials(requestPayload)).then((res) => {
                                                        if (res.data && res.data != null) {
                                                            let setUserLog = eventCallRequest('IPG Session API Call Success', JSON.stringify(userDataLog))
                                                            this.props.eventRequest(setUserLog).then(() => { })
                                                            this.setState({ paymentSession: res?.data, expiryTime: 900000 })
                                                            localStorage.setItem('paymentSession', JSON.stringify(res?.data))

                                                        } else {
                                                            let setUserLog = eventCallRequest('IPG Session API Call Failed', JSON.stringify(userDataLog))
                                                            this.props.eventRequest(setUserLog).then(() => { })
                                                            notification.addNotification({
                                                                title: 'Sorry!',
                                                                message: 'There is some problem with Payment Gateway',
                                                                level: 'error'
                                                            })
                                                        }
                                                    }).catch((err) => {
                                                        let setUserLog = eventCallRequest('IPG Session API Call Failed', JSON.stringify(userDataLog))
                                                        this.props.eventRequest(setUserLog).then(() => { })
                                                        notification.addNotification({
                                                            title: 'Sorry!',
                                                            message: err,
                                                            level: 'error'
                                                        })
                                                    })

                                                }
                                                this.setState({ price: getGaragePriceRes.price, pass_rate_id: '', pass_id: '', is_book_parking: true, length: APICONFIG.defaultBookingLength })
                                            })
                                        })
                                    }
                                })
                            }
                        })
                    }
                // }
            // })
        })
    }


    reverseObj = (obj) => {
        let newObj = {}
        Object.keys(obj)
            .reverse()
            .forEach((key) => {
                newObj[key] = obj[key]
            })
        return newObj
    }

    handleCVVVisibility = (e, type) => {
        if (type === 'text') {
            this.setState({ inputType: 'password', eyeIcon: faEye })

        } else {
            this.setState({ inputType: 'text', eyeIcon: faEyeSlash })
        }
    }

    handleFormSubmit = () => {

        const notification = this.notificationSystem.current

        if (this.state.expiryTime > 0) {

            const { dispatch } = this.props
            this.setState({ paymentScreenOpen: true })
            const user_Data = loggedInUserData()?.access_token ? loggedInUserData()?.user : JSON.parse(localStorage.getItem('userData'))
            let payNowLog = user_Data
            const cardLastFour = localStorage.getItem('card_pan')
            const card_date_expiry_month = localStorage.getItem('card_date_expiry_month')
            const card_date_expiry_year = localStorage.getItem('card_date_expiry_year')
            const card_card_security_cvx_2 = localStorage.getItem('card_card_security_cvx_2')
            const paymentDetails = {
                card: cardLastFour,
                expiry_month: card_date_expiry_month,
                expiry_year: card_date_expiry_year,
                cvv: card_card_security_cvx_2
            }
            payNowLog = { ...user_Data, paymentDetails }
            let setUserLog = eventCallRequest('Paynow Button Clicked', JSON.stringify(payNowLog))
            this.props.eventRequest(setUserLog).then(() => { })


            if (localStorage.getItem('passDetails') === '' && !this.state.isPassExits && this.state.isMember) {
                this.setState({ paymentScreenOpen: false })
                const notification = this.notificationSystem.current
                notification.addNotification({
                    title: 'Sorry!',
                    message: 'Please select a pass or 1 day parking',
                    level: 'error'
                })
            } else {
                this.setState({ paymentScreenOpen: false })

                const user_Data = loggedInUserData()?.access_token ? loggedInUserData()?.user : JSON.parse(localStorage.getItem('userData'))
                const passDetails = localStorage.getItem('passDetails') ? JSON.parse(localStorage.getItem('passDetails')) : ''
                const paaswithBooking = localStorage.getItem('isBooking')
                const paymentSession = JSON.parse(localStorage.getItem('paymentSession'))


                if (this.state.payment_profile_id != '' || (this.state.name != '' && this.state.name != null && this.state.card_pan != '' && this.state.card_pan != null && (this.state.card_pan.length == 15 || this.state.card_pan.length == 16) && this.state.card_card_security_cvx_2 != '' && this.state.card_card_security_cvx_2 != null && (this.state.card_card_security_cvx_2.length == 3 || this.state.card_card_security_cvx_2.length == 4) && this.state.card_date_expiry_month != '' && this.state.card_date_expiry_month != null && this.state.card_date_expiry_year != '' && this.state.card_date_expiry_year != null)) {
                    this.setState({ paymentScreenOpen: true })
                    const currentDate = new Date(moment(new Date()).format('YYYY'), moment(new Date()).format('MM') - 1)
                    const cardExpiry = new Date(moment(card_date_expiry_year, 'YY').format('YYYY'), card_date_expiry_month - 1)
                    if (this.state.payment_profile_id != '' || currentDate <= cardExpiry) {

                        const requestPayload = {
                            name: user_Data?.user_name ? user_Data?.user_name : getLoggedUserData()?.user?.name,
                            email: user_Data?.email,
                            phone: user_Data?.phone_number,
                            arrival: this.state.entry_date,
                            facility_id: user_Data?.garage_id ? user_Data?.garage_id : this.state.garage_id,
                            mer_reference: paymentSession?.reference,
                            is_pass_purchase: this.state.isPassExits ? 0 : passDetails !== '' ? passDetails?.is_book_parking ? 0 : 1 : 0,
                            is_book_parking: this.state.isPassExits ? 1 : passDetails?.is_book_parking ? 1 : 0,
                            is_booking_also: this.state.isPassExits ? 0 : parseInt(paaswithBooking),
                            payment_profile_id: this.state.payment_profile_id ? this.state.payment_profile_id : ''
                        }


                        dispatch(checkValidations(requestPayload)).then((res) => {
                            this.setState({ paymentScreenOpen: true })

                            if (res?.data === 'success') {



                                // const entry_date = localStorage.getItem('entry_date')
                                const paymentDetails = JSON.parse(localStorage.getItem('paymentSession'))
                                const passDetails = localStorage.getItem('passDetails') ? JSON.parse(localStorage.getItem('passDetails')) : ''
                                const paaswithBooking = localStorage.getItem('isBooking')
                                const requestPayloadMakePayment = {
                                    'length': this.state.length,
                                    'facility_id': user_Data?.garage_id ? user_Data?.garage_id : this.state.garage_id,
                                    'member_id': user_Data?.member_id,
                                    'pass_id': this.state.pass_id,
                                    'pass_rate_id': passDetails?.is_book_parking ? '' : passDetails?.pass_rate_id,
                                    'is_pass_purchase': this.state.isPassExits ? 0 : passDetails !== '' ? passDetails?.is_book_parking ? 0 : 1 : 0,
                                    'is_booking_also': this.state.isPassExits ? 0 : parseInt(paaswithBooking),
                                    'is_book_parking': this.state.isPassExits ? 1 : passDetails?.is_book_parking ? 1 : 0,
                                    'description': `Reservation for Parkengage - A beginning at ${moment(this.state.entry_date, this.dateDisplayFormat).format('yyyy-MM-DD')}`,
                                    'total': parseInt(paymentDetails?.amount).toFixed(2),
                                    'session': paymentDetails?.ipgSession,
                                    'email': user_Data?.email,
                                    'phone': user_Data?.phone_number ? user_Data?.phone_number : user_Data?.phone,
                                    'name': user_Data?.user_name ? user_Data?.user_name : getLoggedUserData()?.user?.name,
                                    'user_id': getLoggedUserData()?.access_token ? getLoggedUserData()?.user?.id : '',
                                    'companyName': '',
                                    'is_opt_out': '',
                                    'use_bonus': 0,
                                    'coupon_threshold_price': 0,
                                    'warning_on_reservation': 0,
                                    'warning_on_reservation_msg_email': '',
                                    'is_card_req': 0,
                                    'arrival': moment(this.state.entry_date, this.dateDisplayFormat).format('yyyy-MM-DD'),
                                    'MerchantRef': paymentDetails.reference ? paymentDetails.reference : '',
                                    'TxID': '',
                                    'AuthorisationCode': '',
                                    'CardType': '',
                                    'TokenNo': '',
                                    'DSIndicator': '',
                                    'SCATransRef': ''

                                }

                                let userDataLog = { ...user_Data, passDetails }
                                userDataLog = { ...userDataLog, requestPayload }
                                let setUserLog = eventCallRequest('Before Booking Data Save API Call', JSON.stringify(userDataLog))
                                this.props.eventRequest(setUserLog).then(() => { })
                                

                                dispatch(makePayment(requestPayloadMakePayment)).then((res) => {
                                    this.setState({ paymentScreenOpen: true })

                                    if ((res.data.reservation.id != null) || (res.data.pass.id != null)) {
                                        let responseLog = res.data

                                        let userDataLog = { ...user_Data, passDetails }
                                        userDataLog = { ...userDataLog, requestPayload }
                                        responseLog = { ...userDataLog, responseLog }
                                        let setUserLog = eventCallRequest('Before Booking Data Save API Call Success', JSON.stringify(responseLog))
                                        this.props.eventRequest(setUserLog).then(() => { })
                                        if (this.state.payment_profile_id != '') {
                                            window.location.replace(`${getPartnerDetails()?.partner_slug}/booking/payment-success`)
                                        } else {
                                            const form = this.formRef.current
                                            form.submit()
                                        }

                                    } else {
                                        let responseLog = res
                                        this.setState({ paymentScreenOpen: false })

                                        let userDataLog = { ...user_Data, passDetails }
                                        userDataLog = { ...userDataLog, requestPayload }
                                        responseLog = { ...userDataLog, responseLog }
                                        let setUserLog = eventCallRequest('Before Booking Data Save API Call Failed', JSON.stringify(responseLog))
                                        this.props.eventRequest(setUserLog).then(() => { })
                                        this.setState({ paymentScreenOpen: false })
                                        const notification = this.notificationSystem.current
                                        notification.addNotification({
                                            title: 'Sorry!',
                                            message: 'Something went wrong, please try after sometime.',
                                            level: 'error'
                                        })
                                    }

                                }).catch((err) => {
                                    let responseLog = err
                                    this.setState({ paymentScreenOpen: false })

                                    let userDataLog = { ...user_Data, passDetails }
                                    userDataLog = { ...userDataLog, requestPayload }
                                    responseLog = { ...userDataLog, responseLog }
                                    let setUserLog = eventCallRequest('Before Booking Data Save API Call Failed', JSON.stringify(responseLog))
                                    this.props.eventRequest(setUserLog).then(() => { })
                                    this.setState({ paymentScreenOpen: false })
                                    const notification = this.notificationSystem.current
                                    notification.addNotification({
                                        title: 'Sorry!',
                                        message: 'Something went wrong, please try after sometime.',
                                        level: 'error'
                                    })
                                })
                            } else {
                                const notification = this.notificationSystem.current
                                this.setState({ paymentScreenOpen: false })

                                notification.addNotification({
                                    title: 'Sorry!',
                                    message: res ? res : 'Something went wrong, please try after sometime.',
                                    level: 'error'
                                })
                            }
                        }).catch((err) => {

                            const notification = this.notificationSystem.current
                            this.setState({ paymentScreenOpen: false })

                            notification.addNotification({
                                title: 'Sorry!',
                                message: err,
                                level: 'error'
                            })
                        })

                    } else {
                        this.setState({ paymentScreenOpen: false })
                        notification.addNotification({
                            title: 'Card Expired! ',
                            message: 'Please select correct expiry date or another card.',
                            level: 'error'
                        })
                    }

                } else {
                    document.getElementById('ipg_form').reportValidity()
                    this.setState({ paymentScreenOpen: false })

                    // setError(true)
                    if (this.state.card_pan == '' || this.state.card_pan == null || this.state.card_pan.length < 15 || this.state.card_pan.length > 16) {
                        document.getElementById('card_pan').setCustomValidity('Please enter a valid card.')
                    }
                    else if (this.state.card_card_security_cvx_2 == '' || this.state.card_card_security_cvx_2 == null || this.state.card_card_security_cvx_2.length < 3 || this.state.card_card_security_cvx_2.length > 4) {
                        document.getElementById('card_card_security_cvx_2').setCustomValidity('Please enter a valid CVV number.')
                    }
                    else if (this.state.card_date_expiry_month == '' || this.state.card_date_expiry_month == null) {
                        document.getElementById('card_date_expiry_month').setCustomValidity('Please enter expiry month.')
                    } else if (this.state.card_date_expiry_year == '' || this.state.card_date_expiry_year == null) {
                        document.getElementById('card_date_expiry_year').setCustomValidity('Please enter expiry year.')
                    }
                }


            }
        } else {
            notification.addNotification({
                title: 'Payment Session Expired! ',
                message: 'Please refresh and select a pass or booking again.',
                level: 'error'
            })
        }
    }


    EncryptionHandler = (d) => {
        var nonceString = d.name_on_card + ':' + d.card_number + ':' + d.expiration_date + ':' + d.security_code + ':' + d.zip_code
        var CryptoJSAesJson = {
            stringify: function (cipherParams) {
                var j = {
                    ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
                    iv: '',
                    s: ''
                }
                if (cipherParams.iv)
                    j.iv = cipherParams.iv.toString()
                if (cipherParams.salt)
                    j.s = cipherParams.salt.toString()
                return JSON.stringify(j)
            },
            parse: function (jsonStr) {
                var j = JSON.parse(jsonStr)
                var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) })
                if (j.iv)
                    cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
                if (j.s)
                    cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
                return cipherParams
            }
        }
        var encrypted = CryptoJS.AES.encrypt(JSON.stringify(nonceString), this.encryptKey, { format: CryptoJSAesJson }).toString()
        return encrypted
    }

    componentDidUpdate = () => {
        const userQueries = localStorage.getItem('queries')
        const Query = userQueries !== '' ? JSON.parse(userQueries) : {}
        if (Query?.MerchantRef) {
            // this.setState({paymentScreenOpen:false})           
        }
    }



    handleInputChange = (event) => {
        let { name, value } = event.target

        localStorage.setItem([name], value.trim())


        localStorage.setItem(name, value)
        this.setState({ [name]: value })
    }
    handleCardInputChange = (value) => {
        this.setState({ card_pan: value })
        localStorage.setItem('card_pan', value.substring(12, 16))
    }

    handlePassRateChange = (passId, price, length) => {
        localStorage.setItem('directBooking', false)

        const userData = loggedInUserData()?.access_token ? loggedInUserData()?.user : JSON.parse(localStorage.getItem('userData'))
        const isBooking = localStorage.getItem('isBooking')
        this.setState({ isBooking: false })
        const { dispatch } = this.props
        const requestPayload = {
            'amount': price,
            'facility_id': userData?.garage_id ? userData?.garage_id : this.state.garage_id,
            'arrival': this.state?.entry_date,
            'rate_id': passId,
            'is_booking_also': isBooking == 1 ? 3 : 2,
            'type': 'booking',
        }
        let userDetails = loggedInUserData()?.access_token ? loggedInUserData()?.user : JSON.parse(localStorage.getItem('userData'))
        userDetails = { ...userDetails, requestPayload }
        let setUserLog = eventCallRequest('IPG Session API Call', JSON.stringify(userDetails))
        this.props.eventRequest(setUserLog).then(() => { })
        dispatch(getPaymentInitials(requestPayload)).then((res) => {
            if (res.data && res.data != null) {
                let setUserLog = eventCallRequest('IPG Session API Call Success', JSON.stringify(userDetails))
                this.props.eventRequest(setUserLog).then(() => { })
                this.setState({ paymentSession: res?.data, expiryTime: 900000 })
                localStorage.setItem('paymentSession', JSON.stringify(res?.data))
            } else {
                let setUserLog = eventCallRequest('IPG Session API Call Failed', JSON.stringify(userDetails))
                this.props.eventRequest(setUserLog).then(() => { })
                const notification = this.notificationSystem.current
                notification.addNotification({
                    title: 'Sorry!',
                    message: 'Error in session api',
                    level: 'error'
                })
            }
        }).catch((err) => {

            let setUserLog = eventCallRequest('IPG Session API Call Failed', JSON.stringify(userDetails))
            this.props.eventRequest(setUserLog).then(() => { })
            const notification = this.notificationSystem.current
            notification.addNotification({
                title: 'Sorry!',
                message: err,
                level: 'error'
            })
        })

        this.setState({ pass_rate_id: passId, price: price, length: length, is_book_parking: false })
        const passDetails = { pass_rate_id: passId, price: price, length: length, is_book_parking: false }
        localStorage.setItem('passDetails', JSON.stringify(passDetails))
    }

    handleDayPlanChange = (price) => {
        const { getGaragePriceRes, dispatch } = this.props
        const userData = loggedInUserData()?.access_token ? loggedInUserData()?.user : JSON.parse(localStorage.getItem('userData'))
        this.setState({ isBooking: true })
        const requestPayload = {
            'amount': price,
            'facility_id': userData?.garage_id ? userData?.garage_id : this.state.garage_id,
            'arrival': this.state?.entry_date,
            'rate_id': getGaragePriceRes?.id,
            'is_booking_also': 1,
            'type': 'booking',
        }
        let userDetails = loggedInUserData()?.access_token ? loggedInUserData()?.user : JSON.parse(localStorage.getItem('userData'))
        userDetails = { ...userDetails, requestPayload }
        let setUserLog = eventCallRequest('IPG Session API Call', JSON.stringify(userDetails))
        this.props.eventRequest(setUserLog).then(() => { })

        dispatch(getPaymentInitials(requestPayload)).then((res) => {
            if (res.data && res.data != null) {
                let setUserLog = eventCallRequest('IPG Session API Call Success', JSON.stringify(userDetails))
                this.props.eventRequest(setUserLog).then(() => { })

                this.setState({ paymentSession: res?.data, expiryTime: 900000 })
                localStorage.setItem('paymentSession', JSON.stringify(res?.data))
            } else {
                let setUserLog = eventCallRequest('IPG Session API Call Failed', JSON.stringify(userDetails))
                this.props.eventRequest(setUserLog).then(() => { })

                const notification = this.notificationSystem.current
                notification.addNotification({
                    title: 'Sorry!',
                    message: 'Error in session api',
                    level: 'error'
                })
            }
        }).catch((err) => {

            let setUserLog = eventCallRequest('IPG Session API Call Failed', JSON.stringify(userDetails))
            this.props.eventRequest(setUserLog).then(() => { })

            const notification = this.notificationSystem.current
            notification.addNotification({
                title: 'Sorry!',
                message: err,
                level: 'error'
            })
        })

        this.setState({ price: price, length: APICONFIG.defaultBookingLength, pass_rate_id: '', pass_id: '', is_book_parking: true })
        const planDetails = { price: price, length: APICONFIG.defaultBookingLength, pass_rate_id: '', pass_id: '', is_book_parking: true }
        localStorage.setItem('passDetails', JSON.stringify(planDetails))

    }

    consumeExistingPasses = (e, passId, price) => {
        const { dispatch } = this.props

        const directBooking = {
            pass_id: passId,
            price: price,
            length: APICONFIG.defaultBookingLength
        }
        localStorage.setItem('directBooking', true)
        localStorage.setItem('directBookingData', JSON.stringify(directBooking))
        const user_Data = loggedInUserData()?.access_token ? loggedInUserData()?.user : JSON.parse(localStorage.getItem('userData'))
        const paymentSession = JSON.parse(localStorage.getItem('paymentSession'))


        const requestPayload = {
            name: user_Data?.user_name ? user_Data?.user_name : getLoggedUserData()?.user?.name,
            email: user_Data?.email,
            phone: user_Data?.phone_number,
            arrival: this.state.entry_date,
            mer_reference: paymentSession?.reference,
            facility_id: user_Data?.garage_id ? user_Data?.garage_id : this.state.garage_id,

        }
        dispatch(checkValidations(requestPayload)).then((res) => {
            if (res?.data === 'success') {
                this.setState({ pass_id: passId, price: price, length: APICONFIG.defaultBookingLength }, () => {
                    const notification = this.notificationSystem.current
                    this.setState({ isSubmitted: true }, () => {
                        let formData = {}
                        formData['length'] = this.state.length
                        formData['facility_id'] = user_Data?.garage_id ? user_Data?.garage_id : loggedInUserData()?.access_token ? this.state.garage_id : this.state.userData.garage_id
                        formData['member_id'] = (this.state.userData.is_member) ? this.state.userData.member_id : ''
                        formData['pass_id'] = this.state.pass_id
                        formData['pass_rate_id'] = ''
                        formData['is_pass_purchase'] = 1
                        formData['is_book_parking'] = 1
                        formData['no_of_visitor'] = this.state.userData.no_of_visitors
                        formData['description'] = 'Reservation for Parkengage - A beginning at ' + moment(this.state.entry_date, this.dateDisplayFormat).format('yyyy-MM-DD')
                        formData['total'] = 0
                        formData['nonce'] = ''
                        formData['email'] = this.state.email
                        formData['phone'] = this.state.userData.phone_number
                        formData['name'] = this.state.userData.user_name
                        formData['user_id'] = getLoggedUserData()?.access_token ? getLoggedUserData()?.user?.id : ''
                        formData['companyName'] = ''
                        formData['is_opt_out'] = ''
                        formData['use_bonus'] = 0
                        formData['coupon_threshold_price'] = 0
                        formData['warning_on_reservation'] = 0
                        formData['warning_on_reservation_msg_email'] = ''
                        formData['is_card_req'] = 0
                        formData['arrival'] = moment(this.state.entry_date, this.dateDisplayFormat).format('yyyy-MM-DD') + ' 00:00:00'

                        this.props.reqMakePayment(formData).then(() => {
                            const { paymentRes, hasError, errorMessage } = this.props
                            if (hasError) {
                                let eventParams = eventCallRequest('Existing Pass Payment API Failed', JSON.stringify(errorMessage))
                                this.props.eventRequest(eventParams).then(() => { })

                                this.setState({ isSubmitted: false }, () => {
                                    notification.addNotification({
                                        title: 'Sorry!',
                                        message: errorMessage,
                                        level: 'error'
                                    })
                                })
                            } else {
                                localStorage.setItem('reservationDetails', JSON.stringify(paymentRes))
                                let eventParams = eventCallRequest('Existing Pass Payment API Success', JSON.stringify(paymentRes), loggedInUserData()?.access_token ? this.state.garage_id : this.state.userData.garage_id)
                                this.props.eventRequest(eventParams).then(() => { })
                                if ((paymentRes?.reservation ? Object.keys(paymentRes?.reservation).length > 0 : false) && (paymentRes?.pass ? Object.keys(paymentRes?.pass).length > 0 : false)) {
                                    let confirmationData = {
                                        booking_date: paymentRes.reservation.formatted_start_date,
                                        valid_till: paymentRes.pass.end_date,
                                        phone: paymentRes.reservation.user.phone_number,
                                        email: paymentRes.reservation.user.email,
                                        is_pass: 1,
                                        ticketech_code: paymentRes.reservation.ticketech_code,
                                        pass_code: paymentRes.pass.pass_code
                                    }
                                    localStorage.setItem('reservationData', JSON.stringify(confirmationData))
                                    window.location.replace(`${getPartnerDetails()?.partner_slug}/booking/booking-response`)
                                } else if (paymentRes?.reservation ? Object.keys(paymentRes?.reservation).length > 0 : false) {
                                    let confirmationData = {
                                        booking_date: paymentRes.reservation.formatted_start_date,
                                        phone: paymentRes.reservation.user.phone_number,
                                        email: paymentRes.reservation.user.email,
                                        is_pass: 0,
                                        ticketech_code: paymentRes.reservation.ticketech_code,
                                    }
                                    localStorage.setItem('reservationData', JSON.stringify(confirmationData))
                                    window.location.replace(`${getPartnerDetails()?.partner_slug}/booking/booking-response`)
                                } else if (paymentRes?.pass ? Object.keys(paymentRes?.pass).length > 0 : false) {
                                    let confirmationData = {
                                        valid_till: paymentRes.pass.end_date,
                                        phone: paymentRes.pass.user.phone_number,
                                        email: paymentRes.pass.user.email,
                                        is_pass: 1,
                                        pass_code: paymentRes.pass.pass_code
                                    }
                                    localStorage.setItem('reservationData', JSON.stringify(confirmationData))
                                    window.location.replace(`${getPartnerDetails()?.partner_slug}/booking/booking-response`)
                                }
                            }
                        }).catch((err) => {
                            const notification = this.notificationSystem.current
                            notification.addNotification({
                                title: 'Sorry!',
                                message: err,
                                level: 'error'
                            })
                        })
                    })
                    e.preventDefault()
                })
            } else {
                localStorage.setItem('directBooking', '')
                localStorage.setItem('directBookingData', JSON.stringify({}))
                const notification = this.notificationSystem.current
                notification.addNotification({
                    title: 'Sorry!',
                    message: res ? res : 'Something went wrong, please try after sometime.',
                    level: 'error'
                })
            }
        }).catch((err) => {
            localStorage.setItem('directBooking', '')
            localStorage.setItem('directBookingData', JSON.stringify({}))
            const notification = this.notificationSystem.current
            notification.addNotification({
                title: 'Sorry!',
                message: err,
                level: 'error'
            })
        })

    }

    handleDayBookingWithParking = (e) => {
        localStorage.setItem('directBooking', false)

        if (e.target.checked) {
            // Refresh the planet session if booking is also done with pass purchase
            const userData = loggedInUserData()?.access_token ? loggedInUserData()?.user : JSON.parse(localStorage.getItem('userData'))
            const passDetails = JSON.parse(localStorage.getItem('passDetails'))
            const { dispatch } = this.props
            const requestPayload = {
                'amount': passDetails?.price,
                'facility_id': userData?.garage_id ? userData?.garage_id : this.state.garage_id,
                'arrival': this.state?.entry_date,
                'rate_id': passDetails?.pass_rate_id,
                'is_booking_also': 3,
                'type': 'booking',
            }
            dispatch(getPaymentInitials(requestPayload)).then((res) => {
                if (res.data && res.data != null) {
                    let setUserLog = eventCallRequest('IPG Session API Call Success', JSON.stringify(requestPayload))
                    this.props.eventRequest(setUserLog).then(() => { })

                    this.setState({ paymentSession: res?.data, expiryTime: 900000 })
                    localStorage.setItem('paymentSession', JSON.stringify(res?.data))
                } else {
                    let setUserLog = eventCallRequest('IPG Session API Call Failed', JSON.stringify(requestPayload))
                    this.props.eventRequest(setUserLog).then(() => { })

                    const notification = this.notificationSystem.current
                    notification.addNotification({
                        title: 'Sorry!',
                        message: 'Error in session api',
                        level: 'error'
                    })
                }
                // this.setState({paymentSession: res?.data})
                // localStorage.setItem('paymentSession', JSON.stringify(res?.data))
            }).catch((err) => {
                const notification = this.notificationSystem.current
                notification.addNotification({
                    title: 'Sorry!',
                    message: err,
                    level: 'error'
                })
            })
            // Refresh the planet session if booking is also done with pass purchase
            localStorage.setItem('isBooking', 1)
            const notification = this.notificationSystem.current
            let calLength = APICONFIG.defaultBookingLength
            this.setState({ length: calLength }, () => {
                let formData = {
                    'use_bonus': 0,
                    'is_member': (this.state.isMember) ? 1 : 0,
                    'length_of_stay': this.state.length,
                    'arrival_time': moment(this.state.entry_date, this.dateDisplayFormat).format(this.senddateTimeFormat),
                    'garage_id': loggedInUserData()?.access_token ? this.state.garage_id : this.state.userData.garage_id
                }

                if (this.state.length < APICONFIG.defaultBookingLength) {
                    notification.addNotification({
                        title: 'Sorry ! Request failed.',
                        message: 'Length should not be less than ' + APICONFIG.defaultBookingLength + ' hours!!!',
                        level: 'error'
                    })
                } else {
                    this.props.reqGetPrice(formData).then(() => {
                        const { getGaragePriceRes, hasError, errorMessage } = this.props
                        let userDetails = loggedInUserData()?.access_token ? loggedInUserData()?.user : JSON.parse(localStorage.getItem('userData'))
                        userDetails = { ...errorMessage, userDetails }
                        if (hasError) {
                            let eventParams = eventCallRequest('Fetch Booking Rate API Failed', JSON.stringify(userDetails))
                            this.props.eventRequest(eventParams).then(() => { })
                        } else {
                            userDetails = { ...getGaragePriceRes, userDetails }

                            let eventParams = eventCallRequest('Fetch Booking Rate API Success', JSON.stringify(userDetails), loggedInUserData()?.access_token ? this.state.garage_id : this.state.userData.garage_id)
                            this.props.eventRequest(eventParams).then(() => { })
                        }

                        this.setState({ price: getGaragePriceRes.price, is_book_parking: true, passWithParking: true, length: APICONFIG.defaultBookingLength })
                    })
                }
            })
        } else {
            // Refresh the planet session if booking is also done with pass purchase
            const userData = loggedInUserData()?.access_token ? loggedInUserData()?.user : JSON.parse(localStorage.getItem('userData'))
            const passDetails = JSON.parse(localStorage.getItem('passDetails'))
            const { dispatch } = this.props
            const requestPayload = {
                'amount': passDetails?.price,
                'facility_id': userData?.garage_id ? userData?.garage_id : this.state.garage_id,
                'arrival': this.state?.entry_date,
                'rate_id': passDetails?.pass_rate_id,
                'is_booking_also': 2,
                'type': 'booking',
            }
            dispatch(getPaymentInitials(requestPayload)).then((res) => {
                if (res.data && res.data != null) {
                    let setUserLog = eventCallRequest('IPG Session API Call Success', JSON.stringify(requestPayload))
                    this.props.eventRequest(setUserLog).then(() => { })

                    this.setState({ paymentSession: res?.data, expiryTime: 900000 })
                    localStorage.setItem('paymentSession', JSON.stringify(res?.data))
                } else {
                    let setUserLog = eventCallRequest('IPG Session API Call Failed', JSON.stringify(requestPayload))
                    this.props.eventRequest(setUserLog).then(() => { })

                    const notification = this.notificationSystem.current
                    notification.addNotification({
                        title: 'Sorry!',
                        message: 'Error in session api',
                        level: 'error'
                    })
                }
                // this.setState({paymentSession: res?.data})
                // localStorage.setItem('paymentSession', JSON.stringify(res?.data))
            }).catch((err) => {
                const notification = this.notificationSystem.current
                notification.addNotification({
                    title: 'Sorry!',
                    message: err,
                    level: 'error'
                })
            })
            // Refresh the planet session if booking is also done with pass purchase
            localStorage.setItem('isBooking', 0)
            this.setState({ is_book_parking: false, passWithParking: false, length: APICONFIG.defaultBookingLength })
        }
    }

    showCardForm = () => {
        this.setState({ isCardFormVisible: true, payment_profile_id: '' })
    }

    hideCardForm = () => {
        this.setState({ isCardFormVisible: false, payment_profile_id: '' })
    }


    handleCardSelectChange = (e) => {
        const { value } = e.target
        let selectedCard = this.state.cardList?.filter((card) => card?.token == value)
        this.setState({ payment_profile_id: selectedCard[0].token })
    }

    existingPassDateChangeHandler = (date) => {
        let entryDate = this.state.entry_date
        entryDate = moment(date).format(this.dateDisplayFormat)
        this.setState({ entry_date: entryDate })
        localStorage.setItem('entry_date', entryDate)
    }

    oneDayDateChangeHandler = (date) => {
        localStorage.setItem('directBooking', false)

        let entryDate = this.state.entry_date
        const notification = this.notificationSystem.current
        entryDate = moment(date).format(this.dateDisplayFormat)
        this.setState({ entry_date: entryDate }, () => {
            localStorage.setItem('entry_date', entryDate)
            let calLength = APICONFIG.defaultBookingLength
            this.setState({ length: calLength }, () => {
                let formData = {
                    'use_bonus': 0,
                    'length_of_stay': this.state.length,
                    'is_member': (this.state.isMember) ? 1 : 0,
                    'arrival_time': moment(date).format(this.senddateTimeFormat),
                    'garage_id': loggedInUserData()?.access_token ? this.state.garage_id : this.state.userData.garage_id
                }

                if (this.state.length < APICONFIG.defaultBookingLength) {
                    notification.addNotification({
                        title: 'Sorry ! Request failed.',
                        message: 'Length should not be less than 2 hours!!!',
                        level: 'error'
                    })
                } else {
                    this.props.reqGetPrice(formData).then(() => {
                        const { getGaragePriceRes, hasError, errorMessage } = this.props
                        let userDetails = loggedInUserData()?.access_token ? loggedInUserData()?.user : JSON.parse(localStorage.getItem('userData'))
                        userDetails = { ...errorMessage, userDetails }
                        if (hasError) {
                            let eventParams = eventCallRequest('Fetch Booking Rate API Failed', JSON.stringify(userDetails))
                            this.props.eventRequest(eventParams).then(() => { })
                        } else {
                            userDetails = { ...getGaragePriceRes, userDetails }

                            let eventParams = eventCallRequest('Fetch Booking Rate API Success', JSON.stringify(userDetails), loggedInUserData()?.access_token ? this.state.garage_id : this.state.userData.garage_id)
                            this.props.eventRequest(eventParams).then(() => { })
                        }
                    })
                }
            })
        })
    }

    renderRemainingDays = (no_of_days, consume_days) => {
        var tmp = []
        for (var i = 0; i < no_of_days; i++) {
            tmp.push(i)
        }
        var list = tmp.map(function (i) {
            return (i < consume_days) ? <li key={i} className='bg-gray'>&nbsp;</li> : <li key={i} className='bg-success'>&nbsp;</li>
        })
        return list
    }

    render() {
        const { userData, isSubmitted, cardList, isCardFormVisible } = this.state
        const { passes, isFetching, getGaragePriceRes, userPasses } = this.props
        const partnerDetails = getPartnerDetails()

        const yesterday = moment().subtract(1, 'day')
        const disablePastDt = current => { return current.isAfter(yesterday) }

        return (
            <Aux>
                {/* {this.state.paymentScreenOpen && <LoadingComponent />} */}
                <NotificationSystem ref={this.notificationSystem} />
                <div className="container-fluid">
                    <div className="row no-gutters">
                        <div className="col pe-0 ps-0">
                            <div className="col my-4 text-center">
                                <Link to="/" className='text-decoration-none'>
                                    <h5 className="fw-bold text-primary">Parking at Main Street Parking</h5>
                                </Link>
                            </div>
                            <CarouselItems />
                            {/* <BottomLogo
                                className="my-2 text-center bg-opacity-10 d-none d-sm-block"
                            /> */}
                        </div>
                        <div className="col-sm-12 col-md-7 col-12 bg-white p-0 border-start ">
                            <div className="ContentWrapper ">
                                {userData &&
                                    <div className=" user-data card-header shadow ">
                                        <div className="row aling-items-center">
                                            <div className="col-12 col-sm ">
                                                <h3 className=" mt-0 font-weight-bold text-capitalize textcolor2"><b className="text-dark lead">Hello</b>&nbsp;{getLoggedUserData()?.access_token ? getLoggedUserData()?.user?.name : userData.user_name}!</h3>
                                            </div>
                                        </div>
                                        <div className="border-top mt-2">
                                            <div className="row my-3">
                                                {(userData.member_id) ? <div className="col-6 col-sm-auto">
                                                    <p className="m-0"><b>Member ID</b> : {userData.member_id}</p>
                                                </div> : ''}
                                                <div className="col-6 col-sm-auto text-right">
                                                    <p className="m-0"><b><i className="fa fa-phone"></i></b> : {getLoggedUserData()?.access_token ? phoneMasker(getLoggedUserData()?.user?.phone_number) : conformToMask(userData.phone, this.phoneNumberMask).conformedValue} </p>
                                                </div>
                                                {((partnerDetails.facilities) && (partnerDetails.facilities[0].processing_fee > 0)) && <div className="col-12 col-sm-auto text-right">
                                                    <p className="m-0"><b>Processing Fee</b> : ${partnerDetails.facilities[0].processing_fee} </p>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>}
                                <div style={{ position: 'relative' }} >
                                    <div className="content-body">
                                        {(isSubmitted || isFetching || this.state.paymentScreenOpen) ? <LoadingComponent /> : ''}
                                        {this.state.isPassExits &&
                                            <div className="card shadow coupon-box mb-3">
                                                <div className="card-header">
                                                    <b>Your Pass</b>
                                                </div>
                                                {userPasses.map((userPass, k) => {
                                                    return (<div key={k} className="card-body border-top">
                                                        <table className="table table-coupon table-borderless">
                                                            <tbody>
                                                                <tr>
                                                                    <td className="td-1"><b><img alt="" className="mr-1" src={coupon} width="22" /> {userPass.rate ? userPass.rate.description : ''} &nbsp;|&nbsp; ${userPass.total}</b>
                                                                        <span className="visible-xs">Pass expiring on {moment(userPass.end_date).format('Do MMMM, YYYY')}</span>
                                                                        <ul>
                                                                            {this.renderRemainingDays(userPass.total_days, userPass.consume_days)}
                                                                        </ul>
                                                                        <span className="visible-xs">{userPass.consume_days} out of {userPass.total_days} {(userPass.total_days == 1) ? 'day' : 'days'} used</span>
                                                                    </td>
                                                                    <td className="td-1" rowSpan="2">
                                                                        <i className="datePicker datepic-small">
                                                                            <Datetime inputProps={{ readOnly: true }} isValidDate={disablePastDt} closeOnSelect={true} dateFormat={'MM/DD/yyyy'} timeFormat={false} initialValue={moment(new Date()).format(this.dateDisplayFormat)} value={this.state.entry_date} onChange={this.existingPassDateChangeHandler} />
                                                                            <i className="fa listBlockWrap fa-calendar"></i>
                                                                            <label>Parking Date</label>
                                                                        </i>
                                                                        {(userPass.total_days == userPass.consume_days) ? <button disabled={true} type="submit" className="btn py-1 my-1 btn-y">Book Parking</button> : <button type="submit" onClick={(event) => this.consumeExistingPasses(event, userPass.id, userPass.total)} className="btn py-1 my-1 btn-y">Book Parking</button>}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="td-1">
                                                                        <span className="span-1 hidden-xs">Pass expiring on {moment(userPass.end_date).format('Do MMMM, YYYY')}</span>
                                                                        <span className="span-1 hidden-xs">{userPass.consume_days} out of {userPass.total_days} {(userPass.total_days == 1) ? 'day' : 'days'} used</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>)
                                                })}
                                            </div>}

                                        {this.state.isPassExits && <div className="or-devide">
                                            <p><span>Or Without Using Pass</span></p>
                                        </div>}
                                        <form ref={this.formRef} action={getSessionAttribute('api_url')} target="_top" id='ipg_form' name='ipg_form' method='post'>


                                            <div className="card oneDayParking">
                                                {(this.state.isMember && !this.state.isPassExits) && <div className="card-header">
                                                    <b>Buy Pass or One Day Parking</b>
                                                </div>}
                                                {(this.state.isMember && this.state.isPassExits) && <div className="card-header">
                                                    <b>Buy One Day Parking</b>
                                                </div>}
                                                {(!this.state.isMember) && <div className="card-header">
                                                    <b>Buy One Day Parking</b>
                                                </div>}
                                                {this.state.isMember && <div className="card-body">
                                                    {!this.state.isPassExits && <div className="row justify-content-center radioButtons  bypass-tab">
                                                        {(passes && passes.length > 0) && passes.map((pass) => {
                                                            if (pass.rate_type == 'Pass Rates') {
                                                                return pass.rates.map((p, k) => {
                                                                    let option = 'option' + k
                                                                    return (<div key={k} className="col-12 col-sm-4 mb-2">
                                                                        <input type="radio" required id={option} onChange={() => this.handlePassRateChange(p.id, p.price, p.max_stay)} className="" name="pass" />
                                                                        <label className="btn btn-secondary py-3" htmlFor={option}> <img alt="" className="mr-2" src={coupon} width="22" />{p.description} ${p.price}</label>
                                                                    </div>)
                                                                })
                                                            }
                                                        })}
                                                    </div>}
                                                    <div className={(this.state.isPassExits) ? 'book-park' : 'card-body book-park day1-park existing-pass-daily'}>
                                                        <table className="table radio table-park table-borderless">
                                                            <tbody>
                                                                {(Object.keys(getGaragePriceRes).length > 0) ?
                                                                    (<tr>
                                                                        <td className="td1">
                                                                            <b className="radioLink">
                                                                                {(!this.state.isPassExits) && <input type="radio" style={{marginLeft : '-1.5rem' , opacity : '0.7'}} required onChange={() => this.handleDayPlanChange(getGaragePriceRes.price)} name="pass" checked={this.state.isBooking} />}
                                                                                <label className="radio-label">1 Day Parking</label>
                                                                            </b>
                                                                            <span>Parking Date</span>
                                                                            <span className="datePicker datepic-small">
                                                                                <Datetime inputProps={{ readOnly: true }} isValidDate={disablePastDt} closeOnSelect={true} dateFormat={'MM/DD/yyyy'} timeFormat={false} initialValue={moment(new Date()).format(this.dateDisplayFormat)} value={this.state.entry_date} onChange={this.oneDayDateChangeHandler} />
                                                                                <i className="fa listBlockWrap fa-calendar"></i>
                                                                            </span>
                                                                        </td>
                                                                        <th className="th1">{(getGaragePriceRes.price != 'N/A') ? '$' + getGaragePriceRes.price : getGaragePriceRes.price}</th>
                                                                    </tr>) : ''}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>}
                                                {(!this.state.isMember) && <div className="card-body">
                                                    <div className="book-park">
                                                        <table className="table radio table-park table-borderless">
                                                            <tbody>
                                                                {(Object.keys(getGaragePriceRes).length > 0) ?
                                                                    (<tr>
                                                                        <td className="td1">
                                                                            <b className="radioLink">
                                                                                {/* <input type="radio" onChange={() => this.handleDayPlanChange(getGaragePriceRes.price)} name="pass" /> */}
                                                                                <label className="radio-label">1 Day Parking</label>
                                                                            </b>
                                                                            <span>Parking Date</span>
                                                                            <span className="datePicker datepic-small">
                                                                                <Datetime inputProps={{ readOnly: true }} isValidDate={disablePastDt} closeOnSelect={true} dateFormat={'MM/DD/yyyy'} timeFormat={false} initialValue={moment(new Date()).format(this.dateDisplayFormat)} value={this.state.entry_date} onChange={this.oneDayDateChangeHandler} />
                                                                                <i className="fa listBlockWrap fa-calendar"></i>
                                                                            </span>
                                                                        </td>
                                                                        <th className="th1">{(getGaragePriceRes.price != 'N/A') ? '$' + getGaragePriceRes.price : getGaragePriceRes.price}</th>
                                                                    </tr>) : ''}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>}
                                                <div className="box-2 card-body border-top">
                                                    {(this.state.pass_rate_id) && <div className="row">
                                                        <div className="col-sm-12 col-md-auto mt-2 checkbox">
                                                            <p className="mb-0">
                                                                <input type="checkbox" style={{marginLeft : '-0.3rem' , marginTop : '0.4rem' ,  opacity : '0.7'}} id="parkalso" onChange={(e) => this.handleDayBookingWithParking(e)} name="1day" />
                                                                <label className="radio-label ms-3" htmlFor="parkalso">Book Parking Also</label>
                                                            </p>
                                                        </div>
                                                        <div className="col-sm-12 col-md my-2">
                                                            <p>
                                                                <span>Parking Date</span>
                                                                <span className="datePicker datepic-small">
                                                                    <Datetime inputProps={{ readOnly: true }} isValidDate={disablePastDt} closeOnSelect={true} dateFormat={'MM/DD/yyyy'} timeFormat={false} initialValue={moment(new Date()).format(this.dateDisplayFormat)} value={this.state.entry_date} onChange={this.oneDayDateChangeHandler} />
                                                                    <i className="fa listBlockWrap fa-calendar"></i>
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>}
                                                    {/* CARD DETAILS */}

                                                    <div className="row ">
                                                        <div className=' d-flex '><label className="mb-3"><b>Payment Details</b></label></div>
                                                        {isLoggedIn() &&
                                                            <div className='row '>
                                                                {cardList?.length > 0 && <div className="col-sm-6">
                                                                    <div className="mb-3">
                                                                        <select name="vehicle" id="vehicle" disabled={isCardFormVisible} className="form-select" onChange={this.handleCardSelectChange} >
                                                                            <option>Select Card</option>
                                                                            {cardList?.map((card, index) => {
                                                                                return (
                                                                                    <option value={card?.token} selected={card?.token == this.state.payment_profile_id} key={index}>{`XXXX XXXX XXXX ${card?.card_last_four.slice(0, 4)} (${card?.expiry.slice(0, 2)}/${card?.expiry.slice(2, 4)})`}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                        <span className="form-text text-danger h-40">{(this.state.paymentScreenOpen && this.state.payment_profile_id == '') ? 'Please select or add new card' : ''}</span>
                                                                    </div>
                                                                </div>}

                                                                {cardList?.length > 0 && !isCardFormVisible ?
                                                                    <div className='col-sm-6 mb-3'>{cardList?.length > 0 &&
                                                                        <span className='badge badge-primary text-dark fw-bold me-2'>OR</span>}
                                                                        <button className='btn btn-primary' type="button" onClick={this.showCardForm}><i className='fa fa-plus'></i> Add New Card</button></div> :
                                                                    cardList?.length > 0 && <div className='col-sm-6 mb-3'><span className='badge badge-primary text-dark fw-bold me-2'>OR</span>
                                                                        <button className='btn btn-danger' type="button" onClick={this.hideCardForm}>Select Existing Card</button>
                                                                    </div>}

                                                            </div>}
                                                        {(cardList.length < 1 || isCardFormVisible) && <div className='row'>
                                                            <div className='col-12 col-md-6 col-sm-8'>
                                                                <div className='row'>
                                                                    <div className="col-12 mb-3">
                                                                        <input
                                                                            type="text"
                                                                            name="name"
                                                                            id="name"
                                                                            className="form-control"
                                                                            placeholder="Name on the Card"
                                                                            pattern="^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"
                                                                            required
                                                                            onInvalid={e => e.target.setCustomValidity('Please enter your name')}
                                                                            onInput={e => e.target.setCustomValidity('')}
                                                                            onChange={(e) => this.handleInputChange(e)} />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 mb-3">
                                                                <MaskedInput
                                                                    maskGenerator={this.maskGenerator}
                                                                    value={this.state.card_pan}
                                                                    type="text"
                                                                    name="card_pan"
                                                                    id="card_pan"
                                                                    required={true}
                                                                    pattern="[0-9 ]{18,19}"
                                                                    maxLength='19'
                                                                    minLength='18'
                                                                    title="Card no. should be 15-16 digit numberic"
                                                                    className="form-control"
                                                                    onChange={(e) => this.handleCardInputChange(e)}
                                                                    onInvalid={e => e.target.setCustomValidity('Please enter a valid card number')}
                                                                    onInput={e => e.target.setCustomValidity('')}
                                                                    placeholder="Card Number"
                                                                />

                                                            </div>

                                                            <div className="col-sm-3 mb-3">
                                                                <select
                                                                    name='card_date_expiry_month'
                                                                    id="card_date_expiry_month"
                                                                    required={true}
                                                                    className="form-select"
                                                                    onInvalid={e => e.target.setCustomValidity('Please select expiry month')}
                                                                    onInput={e => e.target.setCustomValidity('')}
                                                                    onChange={(e) => this.handleInputChange(e)}
                                                                >
                                                                    <option value="">Expiry Month</option>
                                                                    {EXPIRY_MONTHS.map((item, key) => <option key={key} value={item}>{item}</option>)}
                                                                </select>
                                                            </div>
                                                            <div className="col-sm-3 mb-3">
                                                                <select
                                                                    name='card_date_expiry_year'
                                                                    id='card_date_expiry_year'
                                                                    required={true}
                                                                    className="form-select"
                                                                    onInvalid={e => e.target.setCustomValidity('Please select expiry year')}
                                                                    onInput={e => e.target.setCustomValidity('')}
                                                                    onChange={(e) => this.handleInputChange(e)}
                                                                >
                                                                    <option value="">Expiry Year</option>
                                                                    {EXPIRY_YEARS.map((item, key) => <option key={key} value={item.val}>{item.label}</option>)}
                                                                </select>
                                                            </div>
                                                            <div className="col-sm-6 ">
                                                                <div className="input-group mb-3">
                                                                    <input
                                                                        type={this.state.inputType}
                                                                        className="form-control"
                                                                        id='card_card_security_cvx_2'
                                                                        required={true}
                                                                        maxLength='4'
                                                                        minLength='3'
                                                                        // size='4' 
                                                                        inputMode='numeric'
                                                                        name='card_card_security_cvx_2'
                                                                        placeholder="CVV"
                                                                        onChange={(e) => this.handleInputChange(e)}
                                                                        onInvalid={e => e.target.setCustomValidity('Please enter CVV code')}
                                                                        onInput={e => e.target.setCustomValidity('')}
                                                                        onKeyPress={(e) => restrictInputValues(e)}
                                                                        autoComplete="new-password" />
                                                                    <span className="cursor input-group-text" id="basic-addon1" onClick={(e) => this.handleCVVVisibility(e, this.state.inputType)}><FontAwesomeIcon icon={this.state.eyeIcon} /></span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        }

                                                        {/* CARD DETAILS */}


                                                    </div>
                                                    <input type='hidden' name='XXX_IPG_XXX' id='XXX_IPG_XXX' value='confirm' />
                                                    <input type='hidden' name='merchant_script_data_1' id='merchant_script_data_1' value={getSessionAttribute('merchant_script_data_1')} />
                                                    <input type='hidden' name='merchant_script_data_2' id='merchant_script_data_2' value={getSessionAttribute('merchant_script_data_2')} />
                                                    <input type='hidden' name='merchant_script_data_3' id='merchant_script_data_3' value={getSessionAttribute('merchant_script_data_3')} />
                                                    <input type='hidden' name='XXX_IPGTRXNO_XXX' id='XXX_IPGTRXNO_XXX' value={getSessionAttribute('guid')} />
                                                    <input type='hidden' name='XXX_IPGSESSION_XXX' id='XXX_IPGSESSION_XXX' value={getSessionAttribute('ipgSession')}></input>

                                                    {(getGaragePriceRes.price === 'N/A') ? <button type="button" disabled={true} className="btn py-1 my-1 btn-primary">Pay Now</button> : <button type="button" className="btn py-1 my-1 btn-primary" onClick={this.handleFormSubmit}>Pay Now</button>}
                                                    {/* </form> */}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <BottomLogo
                            className="my-2 text-center bg-opacity-10 d-block d-sm-none"
                        />
                    </div>
                </div>
            </Aux>
        )
    }
}


PassComponent.propTypes = {
    errorMessage: PropTypes.string,
    hasError: PropTypes.bool,
    eventRequest: PropTypes.func,
    dispatch: PropTypes.func,
    reqGetPartnerDetails: PropTypes.func,
    hasErrorPartner: PropTypes.bool,
    partnerDetails: PropTypes.object,
    hasErrorMessage: PropTypes.bool,
    history: PropTypes.object,
    reqGetPasses: PropTypes.func,
    passes: PropTypes.object,
    reqGetUserPasses: PropTypes.func,
    getGaragePrice: PropTypes.func,
    reqMakePayment: PropTypes.func,
    paymentRes: PropTypes.object,
    userPasses: PropTypes.array,
    getGaragePriceRes: PropTypes.object,
    reqGetPrice: PropTypes.func,
    isFetching: PropTypes.bool,

}

PassComponent.defaultProps = {
    errorMessage: '',
    hasError: false,
    eventRequest: noop,
    dispatch: noop,
    reqGetPartnerDetails: noop,
    hasErrorPartner: false,
    partnerDetails: EMPTY_OBJECT,
    hasErrorMessage: false,
    history: EMPTY_OBJECT,
    reqGetPasses: noop,
    passes: EMPTY_OBJECT,
    reqGetUserPasses: noop,
    getGaragePrice: noop,
    reqMakePayment: noop,
    paymentRes: EMPTY_OBJECT,
    userPasses: noop,
    getGaragePriceRes: EMPTY_OBJECT,
    reqGetPrice: noop,
    isFetching: false,

}

const mapStateToProps = ({ PassesReducer, PartnerReducer }) => {
    const { passes, isFetching, paymentRes, getGaragePriceRes, hasError, errorMessage, userPasses } = PassesReducer
    const { memberCheck, partnerDetails, eventRes } = PartnerReducer
    return {
        passes,
        userPasses,
        isFetching,
        paymentRes,
        getGaragePriceRes,
        hasError,
        hasErrorPartner: PartnerReducer.hasError,
        hasErrorMessage: PartnerReducer.errorMessage,
        errorMessage,
        memberCheck,
        eventRes,
        partnerDetails
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        reqGetPasses: (data) => dispatch(actionCreators.getPasses(data)),
        reqGetUserPasses: (data) => dispatch(actionCreators.getUserPasses(data)),
        reqGetPrice: (data) => dispatch(actionCreators.getGaragePrice(data)),
        reqMakePayment: (data) => dispatch(actionCreators.makePayment(data)),
        checkMemberExistance: (data) => dispatch(actionCreators.checkMemberId(data)),
        eventRequest: (data) => dispatch(actionCreators.logEvent(data)),
        reqGetPartnerDetails: (data) => dispatch(actionCreators.getPartnerDetails(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PassComponent)