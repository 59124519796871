import CONSTANTS from '../constants'
import { isLoggedIn } from '../../utils/helper'
import { EMPTY_OBJECT } from '../../constants/globalConstants'

const INITIAL_STATE = {
	loader: false,
	isUserLogin: isLoggedIn(),
	isAuthenticated: false,
	hasError: false,
	data: EMPTY_OBJECT,
	email: null,
	role: null,
	errorMessage: '',
	id: null,
	orgId: '',
	userDetails: EMPTY_OBJECT,
}

const loginReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {

		//LOGIN HANDLING
		case CONSTANTS.LOGIN_REQUEST:
			return {
				...state,
				loader: true,
				errorMessage: ''
			}
		case CONSTANTS.LOGIN_SUCCESS:
			return {
				...state,
				loader: false,
				data: action.data,
				isAuthenticated: true,
				hasError: false,
				errorMessage: ''
			}
		case CONSTANTS.LOGIN_FAILURE:
			return {
				...state,
				loader: false,
				errorMessage: 'There is some Error'
			}

			//SIGNUP HANDLING
		case CONSTANTS.SINGUP_REQUEST:
			return {
				...state,
				loader: true,
				errorMessage: ''
			}
		case CONSTANTS.SINGUP_SUCCESS:
			return {
				...state,
				loader: false,
				//data: action.data,
				//isAuthenticated: true,
				hasError: false,
				errorMessage: ''
			}
		case CONSTANTS.SINGUP_FAILURE:
			return {
				...state,
				loader: false,
				errorMessage: 'There is some Error'
			}

		//  GET USER PROFILE DETAILS
		case CONSTANTS.GET_USER_PROFILE_REQUEST:
			return {
				...state,
				loader: true,
				errorMessage: ''
			}
		case CONSTANTS.GET_USER_PROFILE_SUCCESS:
			return {
				...state,
				loader: false,
				userDetails: action.data,
				hasError: false,
				errorMessage: ''
			}
		case CONSTANTS.GET_USER_PROFILE_FAILURE:
			return {
				...state,
				loader: false,
				hasError: true,
				errorMessage: 'There is some Error'
			}
		//  GET USER DATA DETAILS
		case CONSTANTS.GET_USER_DATA_REQUEST:
			return {
				...state,
				loader: true,
				errorMessage: ''
			}
		case CONSTANTS.GET_USER_DATA_SUCCESS:
			return {
				...state,
				loader: false,
				userDetails: action.data,
				hasError: false,
				errorMessage: ''
			}
		case CONSTANTS.GET_USER_DATA_FAILURE:
			return {
				...state,
				loader: false,
				hasError: true,
				errorMessage: 'There is some Error'
			}		
		default:
			return state
	}
}

export default loginReducer
