
import React, { useEffect, useState } from 'react'
import Login from '../../../components/global/login'
import BookingHistory from '../bookingHistory/BookingHistory'
import Invoice from '../Invoice/Invoice'
// import LinkAndUnlink from '../linkAndUnlink/LinkAndUnlink'
import { TabContent, TabPane } from 'reactstrap'
import PaymentSetting from '../paymentSetting/PaymentSetting'
import PermitAccount from '../permitAccount/PermitAccount'
import VehicleAndPermit from '../vehicleAndPermit/VehicleAndPermit'
import VehicleDetails from '../vehicleDetails'
import { useNavigate } from 'react-router-dom'
import URL from '../../../constants/urls'
import { EMPTY_ARRAY, EMPTY_OBJECT, tabsNameList } from '../../../constants/globalConstants'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// import Passlist from '../passList/passlist'
import PermitRequest from '../permitRequest/PermitRequest'
import noop from 'lodash/noop'

function VehicleDemo(props) {
  const { isAuthenticated, userData, permitData , notify,partnerDetails} = props

  // FOR THE VEHICLE COUNT IN TH EFRONT UI 

  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [userDetails, setuserDetails] = useState(false)
  const [vehicleCount, setVehicleCount] = useState(false)


  // FOR TABS CHANGE 

  const [activeTab, setActiveTab] = useState(0)


  useEffect(() => {
    const permitDetails = permitData?.data
    const userDetailsPermit = permitDetails?.userDetails
    if (isAuthenticated) {
      setIsLoggedIn(true)
      const getuserDatafromLocal = JSON.parse(localStorage.getItem('userData'))
      const userDetails = getuserDatafromLocal?.user
      if (getuserDatafromLocal?.access_token && getuserDatafromLocal?.access_token != '') {
        setuserDetails(userDetails)
      } else {
        const userDetails = userData?.data?.user
        setuserDetails(userDetails)
      }
    } else if (userDetailsPermit?.access_token && userDetailsPermit?.access_token != '') {
      setIsLoggedIn(true)
      setuserDetails(userDetailsPermit?.user)
    } else {
      const getuserDatafromLocal = JSON.parse(localStorage.getItem('userData'))
      const userDetails = getuserDatafromLocal?.user
      if (getuserDatafromLocal?.access_token && getuserDatafromLocal?.access_token != '') {
        setuserDetails(userDetails)
        setIsLoggedIn(true)
      } else {
        setIsLoggedIn(false)

        navigate(URL.HOME)

      }
    }
  }, [EMPTY_ARRAY, isAuthenticated, permitData])


  // FOR THE TABS CHANGE 

  const handleLinkClick = (e, value) => {
    setActiveTab(value)
  }



  return (
    <div>
      {/* main section ---  */}
      <main>
        <section className="formDesign usersActive p-sm-5 mt-5">
          {/* for the maain header--- */}
          {/* <VehicleAndPermit /> */}
          <VehicleAndPermit vehicleCount={vehicleCount}/>

          <div className="container">
            <div className="row">
              <div className="col-sm-2 mb-3 col-12" style={{ minWidth: '15em' }} role="tablist" aria-orientation="vertical">
                <div className="list-group">

                  {tabsNameList?.map((tabNameItem, tabNameIndex) => {
                    return (
                      <button key={tabNameIndex} className={`list-group-item list-group-item-action ${activeTab === tabNameIndex ? 'active' : ''}`} type="button" onClick={(e) => handleLinkClick(e, tabNameIndex)}><i className={`fa fa-${tabNameItem?.icon} me-3`}></i>{tabNameItem?.tabName}</button>
                      //  <NavLink key={tabNameIndex} className={activeTab === tabNameIndex ? 'active' : null} onClick={(e) => handleLinkClick(e, tabNameIndex)}><i className={`fa fa-${tabNameItem?.icon} me-3`}></i>{tabNameItem?.tabName}</NavLink> 
                    )
                  })}

                </div>
              </div>

              <div className="col-12 mb-3 col-sm">
                <div className="p-4 card">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={0}>
                      {activeTab === 0 ?
                        <PermitAccount notify={notify} partnerDetails={partnerDetails} />
                        : null
                      }
                    </TabPane>
                    <TabPane tabId={1}>
                      {activeTab === 1 ?
                        <PermitRequest notify={notify} partnerDetails={partnerDetails} />
                        : null
                      }
                    </TabPane>
                    <TabPane tabId={2}>
                      {activeTab === 2 ?
                        // <VehicleDetails notify={notify} />
                        <PaymentSetting notify={notify} />
                        : null
                      }
                    </TabPane>
                    <TabPane tabId={3}>
                      {activeTab === 3 ?
                        <VehicleDetails notify={notify} setVehicleCount={setVehicleCount} vehicleCount={vehicleCount}/>  

                        : null
                      }
                    </TabPane>
                    <TabPane tabId={4}>
                      {activeTab === 4 ?
                        <Invoice notify={notify} partnerDetails={partnerDetails} />
                        : null
                      }
                    </TabPane>
                    
                    <TabPane tabId={5}>
                      {activeTab === 5 ?
                        <BookingHistory />
                        : null
                      }
                    </TabPane>

                   
                  </TabContent>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* login */}
      <Login setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} userDetails={userDetails}/>
    </div>
  )
}

VehicleDemo.propTypes = {
  isAuthenticated: PropTypes.bool,
  userData: PropTypes.object,
  permitData: PropTypes.object,
  notify:PropTypes.func,
  partnerDetails: PropTypes.object
}

VehicleDemo.defaulProps = {
  isAuthenticated: false,
  userData: EMPTY_OBJECT,
  permitData: EMPTY_OBJECT,
  partnerDetails: EMPTY_ARRAY,
  notify :noop
}

function mapStateToProps({ login, paymentReducer }) {
  return {
    isAuthenticated: login?.isAuthenticated,
    userData: login?.data,
    permitData: paymentReducer?.data,
  }
}
export default connect(mapStateToProps)(VehicleDemo)