import React, { Component } from 'react'
import Aux from './../Wrapper/Auxiliary'

import CarouselItems from './CarouselItems'
import { Link } from 'react-router-dom'
import { BottomLogo } from '../Constant'
import queryString from 'query-string'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { EMPTY_OBJECT } from '../../../constants/globalConstants'

class paymentFailed extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userData: '',
            ticket: '',
            endDate: '',
            endTime: '',
            phone: '',
            phonePre: '+1',
            email: '',
            pass_code: '',
            ticketech_code: '',
            isPass: false
        }
        this.dateDisplayFormat = 'MM/DD/YYYY'
        this.existingDateFormat = 'll'
        this.timeDisplayFormat = 'h:mm A'
        this.phoneNumberMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    }

    carouselOptions = {
        nav: false,
        rewind: false,
        autoplay: false,
        margin: 30,
        loop: false,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 4,
            }
        }
    }

    

    componentDidMount = () => {
        let queries = queryString.parse(window.location.search)
            localStorage.setItem('queries', JSON.stringify(queries))
            localStorage.setItem('paymentStatus', 'fail')
            window.close()
    }

    render() {
        return (
            <Aux>
                <div className="container-fluid">
                    <div className="row no-gutters">
                        <div className="col-sm-12 col-md-6 col-12">
                            <div className="col my-4 text-center">
                                <Link to="/" className='text-decoration-none'>
                                    <h5 className="fw-bold text-primary">Parking at Main Street Parking</h5>
                                </Link>
                            </div>
                            <CarouselItems />
                            <BottomLogo
                                className="my-2 text-center bg-opacity-10 d-none d-sm-block"
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 col-12 bg-white p-0 border-start ">
                            <div className="ContentWrapper p-5 mt-5 thankyou">
                                <div className="card shadow">
                                    <div className='card-body text-center'>
                                        <h3 className="text-center text-success my-4 font-weight-bold">Loading... Please wait!!!</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <BottomLogo
                            className="my-2 text-center bg-opacity-10 d-block d-sm-none"
                        />
                    </div>
                </div>
            </Aux>
        )
    }
}

paymentFailed.propTypes = {
    errorMessage: PropTypes.string,
    hasError: PropTypes.bool,
    eventRequest: PropTypes.func,
    dispatch: PropTypes.func,
    reqGetPartnerDetails: PropTypes.func, 
    hasErrorPartner: PropTypes.bool, 
    partnerDetails: PropTypes.object, 
    hasErrorMessage: PropTypes.bool,
    history: PropTypes.object, 
    reqGetPasses: PropTypes.func,
    passes: PropTypes.object,
    reqGetUserPasses : PropTypes.func,
    getGaragePrice  : PropTypes.func,
    reqMakePayment : PropTypes.func,
    paymentRes : PropTypes.object,
    userPasses : PropTypes.array,
    getGaragePriceRes  : PropTypes.object,
    reqGetPrice : PropTypes.func,
    isFetching  : PropTypes.bool,
    location: PropTypes.object,
    
}

paymentFailed.defaultProps = {
    errorMessage: '',
    hasError: false,
    eventRequest: noop,
    dispatch: noop,
    reqGetPartnerDetails: noop,
    hasErrorPartner: false, 
    partnerDetails: EMPTY_OBJECT, 
    hasErrorMessage: false,
    history: EMPTY_OBJECT,
    reqGetPasses: noop,
    passes:EMPTY_OBJECT,
    reqGetUserPasses : noop,
    getGaragePrice : noop,
    reqMakePayment : noop,
    paymentRes : EMPTY_OBJECT,
    userPasses : noop,
    getGaragePriceRes : EMPTY_OBJECT,
    reqGetPrice : noop,
    isFetching : false,
    location: EMPTY_OBJECT,
}

const mapStateToProps = ({ PassesReducer, PartnerReducer }) => {
    const { passes, isFetching, paymentRes, getGaragePriceRes, hasError, errorMessage, userPasses } = PassesReducer
    const { memberCheck, partnerDetails, eventRes } = PartnerReducer
    return {
        passes,
        userPasses,
        isFetching,
        paymentRes,
        getGaragePriceRes,
        hasError,
        errorMessage,
        memberCheck,
        eventRes,
        partnerDetails
    }
}
const mapDispatchToProps = dispatch => {
    return {
        dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(paymentFailed)
