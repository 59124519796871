import CONSTANTS from '../constants'
import { EMPTY_ARRAY, } from '../../constants/globalConstants'

const INITIAL_STATE = {
    loader: false,
    hasError: false,
    waitList: EMPTY_ARRAY,
    errorMessage: '',
}

const waitingListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        //RESPONSE HANDLING
        case CONSTANTS.UPDATE_WAITING_LIST_REQUEST:
            return {
                ...state,
                loader: true,
                errorMessage: ''
            }
        case CONSTANTS.UPDATE_WAITING_LIST_SUCCESS:
            return {
                ...state,
                loader: false,
                waitList: action.data,
                hasError: false,
                errorMessage: action.errorMessage
            }
        case CONSTANTS.UPDATE_WAITING_LIST_FAILURE:
            return {
                ...state,
                loader: false,
                errorMessage: 'There is some Error'
            }
       
        default:
            return state
    }
}

export default waitingListReducer
