import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
import URL from '../../constants/urls'
import noop from 'lodash/noop'
import { useNavigate } from 'react-router-dom'
import ModalLogin from './modalLogin'
import { getPartnerDetails, getPartnerLogo, isPartnerTownsend, isPartnerWorldPort } from '../../utils/helper'
import whitepng from '../../assets/img/whitebg.png'
import { getConfigDetails } from '../../services/config'
// import townsend from '../../assets/img/townsend.png'

function NavbarComponent(props) {
	// const { isAuthenticated, userData, permitData, waitListData, passData, paymentData } = props
	const { isAuthenticated, userData, waitListData, paymentData, setIsOpened, isOpened, notify, partnerDetails, pageActive } = props
	const [isLoggedIn, setIsLoggedIn] = useState(false)
	const [userDetails, setuserDetails] = useState(false)
	const [activeTab, setActiveTab] = useState('')
	const [caller, setCaller] = useState()
	// const [isOpened, setIsOpened] = useState(false)
	const [show, setShow] = useState(false)
	const [isTownsend, setIsTownsend] = useState(false)
	const [isWorldPort, setIsWorldPort] = useState(false)

	// for modal open and close 

	// const [isLoginModal, setLoginModal] = useState(false)
	// 
	const { pathname } = useLocation()
	const navigate = useNavigate()
	const partner = window.location.toString().split('/')[3]
	const path = window.location.toString().split('/')?.[3]


	// useEffect(() => {
	// 	if(partner && partner != 'navigating' && isNotUrl(partner)){
	// 		setPartnerName(partner)
	// 	}else if(getPartnerDetails()?.partner_slug){
	// 		setPartnerName(getPartnerDetails()?.partner_slug)
	// 	}
	// },[EMPTY_ARRAY, partner])


	useEffect(() => {
		if (pathname == '/') {
			setActiveTab('')
		} else {
			setActiveTab(pathname)
		}
		if (isPartnerTownsend(partner)) {
			setIsTownsend(true)
		} else {
			setIsTownsend(false)
		}
		if (isPartnerWorldPort(partner)) {
			setIsWorldPort(true)
		} else {
			setIsWorldPort(false)
		}

	}, [pathname, partner])

	useEffect(() => {
		localStorage.setItem('isLoggedIn', false)
		const waitListDetails = waitListData?.userDetails
		// const permitDetails = permitData?.data
		// const userDetailsPass = passData?.data?.userDetails
		// const userDetailsPermit = permitDetails?.userDetails
		const userDetailsPayment = paymentData?.data?.userDetails

		if (isAuthenticated) {
			setIsLoggedIn(true)
			const getuserDatafromLocal = JSON.parse(localStorage.getItem('userData'))
			const userDetails = getuserDatafromLocal?.user
			if (getuserDatafromLocal?.access_token && getuserDatafromLocal?.access_token != '') {
				setuserDetails(userDetails)
			} else {
				const userDetails = userData?.data?.user
				setuserDetails(userDetails)
			}
		}
		else if (userDetailsPayment?.access_token && userDetailsPayment?.access_token != '') {
			setIsLoggedIn(true)
			localStorage.setItem('isLoggedIn', true)
			setuserDetails(userDetailsPayment?.user)
		}
		// else if (userDetailsPermit?.access_token && userDetailsPermit?.access_token != '') {
		// 	setIsLoggedIn(true)
		// 	localStorage.setItem('isLoggedIn', true)
		// 	setuserDetails(userDetailsPermit?.user)
		// } 
		else if (waitListDetails?.access_token && waitListDetails?.access_token != '') {
			setIsLoggedIn(true)
			setuserDetails(waitListDetails?.user)
		}
		//  else if (userDetailsPass?.access_token && userDetailsPass?.access_token != '') {
		// 	setIsLoggedIn(true)
		// 	localStorage.setItem('isLoggedIn', true)
		// 	setuserDetails(userDetailsPass?.user)
		// }
		else {
			const getuserDatafromLocal = JSON.parse(localStorage.getItem('userData'))
			const userDetails = getuserDatafromLocal?.user
			if (getuserDatafromLocal?.access_token && getuserDatafromLocal?.access_token != '') {
				setuserDetails(userDetails)
				setIsLoggedIn(true)
				localStorage.setItem('isLoggedIn', true)
			} else {
				setIsLoggedIn(false)
			}
		}
		// }, [EMPTY_ARRAY, isAuthenticated, permitData, waitListData, passData,paymentData])
	}, [EMPTY_ARRAY, isAuthenticated, waitListData, paymentData, pathname])

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	const handleLogout = () => {
		localStorage.removeItem('userData')
		localStorage.removeItem('isLoggedIn')

		if (getPartnerDetails()) {
			sessionStorage.setItem('partnerSecret', getConfigDetails()?.CLIENT_SECRET)
			sessionStorage.setItem('partnerData', JSON.stringify(getPartnerDetails()))

		}
		localStorage.removeItem('partnerSecret')
		localStorage.removeItem('partnerData')
		setIsLoggedIn(false)
		navigate(`${URL?.INDEX}`)
		navigate(0)

	}
	const handleModalOpen = () => {

		// setLoginModal(true)
		setShow(true)
		setCaller('nav')
	}

	const handleActiveTab = (tab) => {
		setIsOpened(false)
		setActiveTab(tab)
	}
	const handleDropDown = (e) => {
		e.stopPropagation()
		if (isOpened) {
			setIsOpened(false)
		} else {
			setIsOpened(true)
		}
	}
	return (
		<header>
			<nav className="navbar navbar-expand-sm fixed-top navbar-light  shadow bg-white">
				<div className="container">
					<a className='navbar-bannd flex-grow-1' href={`${URL?.INDEX}`} > <img src={partnerDetails ? partnerDetails?.brand_setting ? partnerDetails?.brand_setting?.logourl : partnerDetails?.partner_details ? partnerDetails?.partner_details?.brand_setting?.logourl : getPartnerDetails()?.brand_setting?.logourl ? getPartnerDetails()?.brand_setting?.logourl : isPartnerTownsend() ? whitepng : getPartnerLogo('wailuku') : getPartnerLogo('wailuku')} width="140px" alt={getPartnerDetails()?.name} /></a>

					<button className="navbar-toggler border-0 px-1" type="button" data-bs-toggle="collapse" data-bs-target="#mainNavID" aria-controls="mainNavID" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					{isLoggedIn ?
						<div className="dropdown d-block d-sm-none">
							<button onClick={(e) => handleDropDown(e)} className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className='far fa-user-circle text-primary fa-lg' ></i> </button>

							<ul className={'dropdown-menu dropdown-menu-end '}>
								<li><Link className={activeTab == URL?.USER ? 'dropdown-item activeLink ' : 'dropdown-item '} to={URL?.USER} onClick={() => handleActiveTab(URL?.USER)}>My Account</Link></li>
								<li> <hr className="dropdown-divider" /> </li>
								<li> <Link className={activeTab == URL?.USER_PROFILE ? 'dropdown-item activeLink ' : 'dropdown-item '} to={URL?.USER_PROFILE} onClick={() => handleActiveTab(URL?.USER_PROFILE)}>Profile</Link></li>
								<li> <hr className="dropdown-divider" /></li>
								<li><button type='button' className="dropdown-item " onClick={(e) => handleLogout(e)}>Logout</button></li>
							</ul>
						</div> : <button className="listStyleNone d-block d-sm-none btn px-1" onClick={handleModalOpen}> <i className="fa fa-sign-in text-primary" ></i> </button>}


					{(!isTownsend && !isWorldPort) && <>
						<div className="collapse navbar-collapse" id="mainNavID">
							<ul className="navbar-nav ms-auto">
								{path != '' ? <li className="nav-item" >
									<a href={URL?.INDEX} className={activeTab == `${URL?.INDEX}` ? 'nav-link px-3 mt-2 mt-sm-0 activeLink' : 'nav-link px-3 mt-2 mt-sm-0'} to={`${URL?.INDEX}`} onClick={() => handleActiveTab(`${URL?.INDEX}`)}>Get Permit</a>
								</li> : 
									
									(pageActive != 'home' && pageActive != 'pass' && pageActive != 'signup') ?
									<li className="nav-item" >
									<a href={URL?.INDEX} className={activeTab == `${URL?.INDEX}` ? 'nav-link px-3 mt-2 mt-sm-0 activeLink' : 'nav-link px-3 mt-2 mt-sm-0'} to={`${URL?.INDEX}`} onClick={() => handleActiveTab(`${URL?.INDEX}`)}>Get Permit</a>
								</li> : ''
									
								}
								{isLoggedIn ?
									<li className=" nav-item dropdown  d-none d-sm-block" id="accountmenucontainer">
										<span className='nav-link '>
											<Link className={activeTab == URL?.USER ? 'nav-link fw-bold ms-2 me-2 d-inline' : 'nav-link me-2 ms-2 d-inline'} to={URL?.USER} onClick={() => handleActiveTab(URL?.USER)}>My Account</Link>
											<a className="dropdown-toggle fw-bold text-dark" href="javascript:;" role="button" onClick={(e) => handleDropDown(e)} data-bs-toggle="dropdown" aria-expanded="false"></a>
										</span>

										<ul className={isOpened ? 'dropdown-menu dropdown-menu-end d-block right-0' : 'dropdown-menu dropdown-menu-end right-0'}>
											{/* <li><Link className={activeTab == URL?.USER ? 'nav-link activeLink ms-2' : 'nav-link ms-2'} to={URL?.USER} onClick={() => handleActiveTab(URL?.USER)}>My Account</Link></li> */}
											{/* <li> <hr className="dropdown-divider" /> </li> */}
											<li><Link className={activeTab == URL?.USER_PROFILE ? 'nav-link activeLink ms-2' : 'nav-link ms-2'} to={URL?.USER_PROFILE} onClick={() => handleActiveTab(URL?.USER_PROFILE)}>Profile</Link></li>
											<li> <hr className="dropdown-divider" /></li>
											<li><button type='button' className="dropdown-item ps-4 ps-0" onClick={(e) => handleLogout(e)}>Logout</button></li>
										</ul>
									</li> : <li className=" nav-item d-none d-sm-block"><a className='nav-link' onClick={handleModalOpen}> 
										<i className="showLoginIcon fa fa-sign-in text-primary" ></i>
										<span className='showLoginText cursor btn btn-primary'>Login</span>
									</a></li>}
							</ul>
						</div>
					</>}
				</div>
			</nav>
			<ModalLogin setShow={setShow} show={show} caller={caller} notify={notify} userDetails={userDetails} />

			{/* <Login isLoginModal={isLoginModal} setLoginModal={setLoginModal} caller={caller} /> */}
		</header>
	)
}


NavbarComponent.propTypes = {
	dispatch: PropTypes.func,
	isAuthenticated: PropTypes.bool,
	userData: PropTypes.object,
	// permitData: EMPTY_OBJECT,
	// passData: EMPTY_OBJECT,
	paymentData: PropTypes.object,
	isModalOpen: PropTypes.bool,
	waitListData: PropTypes.object,
	setIsOpened: PropTypes.func,
	isOpened: PropTypes.bool,
	notify: PropTypes.func,
	partnerDetails: PropTypes.object,
	setPageActive: PropTypes.func,
	pageActive: PropTypes.string,
}

NavbarComponent.defaulProps = {
	dispatch: noop,
	isAuthenticated: false,
	userData: EMPTY_OBJECT,
	// permitData: EMPTY_OBJECT,
	// passData: EMPTY_OBJECT,
	paymentData: EMPTY_OBJECT,
	isModalOpen: false,
	waitListData: EMPTY_OBJECT,
	setIsOpened: noop,
	isOpened: false,
	notify: noop,
	partnerDetails: EMPTY_OBJECT,
	setPageActive: noop,
	pageActive: undefined, 
}

function mapStateToProps({ login, paymentReducer, basicInfoReducer, waitingListReducer }) {
	return {
		isAuthenticated: login?.isAuthenticated,
		userData: login?.data,
		// permitData: paymentReducer?.data,
		// passData: paymentReducer?.passData,
		// paymentData: paymentReducer?.data,
		paymentData: paymentReducer?.data,
		isModalOpen: basicInfoReducer?.isModalOpen,
		waitListData: waitingListReducer?.waitList?.data
	}
}
export default connect(mapStateToProps)(NavbarComponent)
