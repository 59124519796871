import CONSTANTS from '../constants'
import { deleteRequest, getRequest, postRequest } from '../../services'
import { APICONFIG } from '../config/apiConfig'

// GET VEHICLE LIST DETAILS
export const getVehicleDataList = () => (dispatch) => {
    dispatch({ type: CONSTANTS.GET_VEHICLE_LIST_REQUEST })
    return getRequest(`${APICONFIG.getVehicleListUrl}/page/1`).then((res) => {
        dispatch({ type: CONSTANTS.GET_VEHICLE_LIST_SUCCESS, data: res.data })
        return res.data
    }).catch((error) => {
        dispatch({ type: CONSTANTS.GET_VEHICLE_LIST_FAILURE })
        return error
    })
}

// Post Vehicle Details
export const postVehicleList = (data) => (dispatch) => {
    dispatch({ type: CONSTANTS.POST_VEHICLE_LIST_REQUEST })
    return postRequest(APICONFIG.postVehicleListUrl, data).then((res) => {
        dispatch({ type: CONSTANTS.POST_VEHICLE_LIST_SUCCESS, data: res.data })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.POST_VEHICLE_LIST_FAILURE })
        return err
    })
}

// Delete Vehicle Details

export const DeleteVehicleList = (id) => (dispatch) => {
    dispatch({ type: CONSTANTS.DELETE_VEHICLE_ITEM_REQUEST })
    return deleteRequest(`${APICONFIG.deleteVehicleItemUrl}/${id}`).then((res) => {
        dispatch({ type: CONSTANTS.DELETE_VEHICLE_ITEM_SUCCESS })
        return res.data
    }).catch((err) => {
        dispatch({ type: CONSTANTS.DELETE_VEHICLE_ITEM_FAILURE })
        return err
    })
}


// GET VEHICLE Model List
export const getVehicleModelList = () => (dispatch) => {
    dispatch({ type: CONSTANTS.GET_VEHICLE_MODEL_REQUEST })
    return getRequest(`${APICONFIG.getVehicleModelUrl}`).then((res) => {
        dispatch({ type: CONSTANTS.GET_VEHICLE_MODEL_SUCCESS, data: res.data })
        return res.data
    }).catch((error) => {
        dispatch({ type: CONSTANTS.GET_VEHICLE_MODEL_FAILURE })
        return error
    })
}
