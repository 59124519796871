import CONSTANTS from '../constants'
import { postRequest } from '../../services'
import { APICONFIG } from '../config/apiConfig'

export const postRestPassword = (payload) => dispatch => {
    dispatch({ type: CONSTANTS.POST_RESET_PASSWORD_REQUEST })
    return postRequest(APICONFIG.postRestPassword, payload).then((res) => {
        dispatch({ type: CONSTANTS.POST_RESET_PASSWORD_SUCCESS, data: res.data })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.POST_RESET_PASSWORD_FAILURE })
        return err
    })
}