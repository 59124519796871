import React, { Component } from 'react'
import PropTypes from 'prop-types'
import aaBlack from '../../../src/assets/img/aa-black.png'
import parkengageLogo from '../../../src/assets/img/parkengage.png'
import bgImage1 from '../../../src/assets/img/banner.jpg'
import bgImage2 from '../../../src/assets/img/A1.jpg'
import bgImage3 from '../../../src/assets/img/A2.jpg'
import bgImage4 from '../../../src/assets/img/A3.jpg'
import bgImage5 from '../../../src/assets/img/A4.jpg'


export const bgImageURL = 'https://www.google.com/maps/place/Grant+Park+Gateway+-+Parking/@33.7344413,-84.3696226,354m/data=!3m1!1e3!4m12!1m6!3m5!1s0x88f5030e85d2a10b:0x1e64e76a77976447!2sGrant+Park+Gateway+-+Parking!8m2!3d33.7343128!4d-84.3685345!3m4!1s0x88f5030e85d2a10b:0x1e64e76a77976447!8m2!3d33.7343128!4d-84.3685345'
export const systemError = 'Something went wrong. Please check your network connection and try again in some time. If your card is already charged, then please contact our support team.'
export class BottomLogo extends Component {
    render() {
        const { className } = this.props
        return (
            <div className={className}>
                <b> Powered By:</b>
                &nbsp;&nbsp;
                <img alt="" height="30px" src={aaBlack} />
                &nbsp;&nbsp;
                <img alt="" height="30px" src={parkengageLogo} />
            </div>
        )
    }
	
}

BottomLogo.propTypes = {
	className : PropTypes.string
}

BottomLogo.defaultProps = {
	clclassName : '',
}





export const CarouselData = [bgImage1, bgImage2, bgImage3, bgImage4, bgImage5]

export const EXPIRY_MONTHS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
export const EXPIRY_YEARS = [
	
	{
		val: '23',
		label: '2023'
	},
	{
		val: '24',
		label: '2024'
	},
	{
		val: '25',
		label: '2025'
	},
	{
		val: '26',
		label: '2026'
	},
	{
		val: '27',
		label: '2027'
	},
	{
		val: '28',
		label: '2028'
	},
	{
		val: '29',
		label: '2029'
	},
	{
		val: '30',
		label: '2030'
	},
	{
		val: '31',
		label: '2031'
	},
	{
		val: '32',
		label: '2032'
	},
	{
		val: '33',
		label: '2033'
	},
	{
		val: '34',
		label: '2034'
	},
	{
		val: '35',
		label: '2035'
	},
	{
		val: '36',
		label: '2036'
	},
	{
		val: '37',
		label: '2037'
	},
	{
		val: '38',
		label: '2038'
	},
	{
		val: '39',
		label: '2039'
	},
	{
		val: '40',
		label: '2040'
	},
	{
		val: '41',
		label: '2041'
	},
	{
		val: '42',
		label: '2042'
	},
	{
		val: '43',
		label: '2043'
	},
	{
		val: '44',
		label: '2044'
	},
	{
		val: '45',
		label: '2045'
	},
	{
		val: '46',
		label: '2046'
	},
	{
		val: '47',
		label: '2047'
	},
	{
		val: '48',
		label: '2048'
	},
	{
		val: '49',
		label: '2049'
	},
	{
		val: '50',
		label: '2050'
	}


]