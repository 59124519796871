import CONSTANTS from '../constants'
import { EMPTY_OBJECT } from '../../constants/globalConstants'

const INITIAL_STATE = {
    loader: false,
    hasError: false,
    cardDetails: EMPTY_OBJECT,
    errorMessage: '',
    current_page: 1,
    per_page: 20,
    total: 20,
}

const cardDetailsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        //RESPONSE HANDLING
        case CONSTANTS.GET_CARD_LIST_REQUEST:
            return {
                ...state,
                loader: true,
                errorMessage: ''
            }
        case CONSTANTS.GET_CARD_LIST_SUCCESS:
            return {
                ...state,
                loader: false,
                cardDetails: action.data,
                hasError: false,
                current_page: action.data.current_page,
                per_page: action.data.per_page,
                total: action.data.total,
                errorMessage: ''
            }
        case CONSTANTS.GET_CARD_LIST_FAILURE:
            return {
                ...state,
                loader: false,
                hasError: true,
                errorMessage: 'There is some Error'
            }
        case CONSTANTS.CREATE_CARD_REQUEST:
            return {
                ...state,
                loader: true,
                errorMessage: ''
            }
        case CONSTANTS.CREATE_CARD_SUCCESS:
            return {
                ...state,
                loader: false,
                cardDetails: action.data,
                hasError: false,
                errorMessage: ''
            }
        case CONSTANTS.CREATE_CARD_FAILURE:
            return {
                ...state,
                loader: false,
                hasError: true,
                errorMessage: 'There is some Error'
            }
        default:
            return state
    }
}

export default cardDetailsReducer
