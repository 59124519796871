import React, { Component } from 'react'
import Aux from './../Wrapper/Auxiliary'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { EMPTY_OBJECT } from '../../../constants/globalConstants'
import CarouselItems from './CarouselItems'
import { Link } from 'react-router-dom'
import { BottomLogo } from '../Constant'
import queryString from 'query-string'
import eventCallRequest from '../../../../src/utils/HelperEvent'
import { connect } from 'react-redux'
import * as actionCreators from '../../../redux/actions'
import LoadingComponent from '../../../common/UI/loadingComponent'
import { getPartnerDetails } from '../../../utils/helper'

class paymentResponse extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userData: '',
            ticket: '',
            endDate: '',
            endTime: '',
            phone: '',
            phonePre: '+1',
            email: '',
            pass_code: '',
            ticketech_code: '',
            isPass: false
        }
        this.dateDisplayFormat = 'MM/DD/YYYY'
        this.existingDateFormat = 'll'
        this.timeDisplayFormat = 'h:mm A'
        this.phoneNumberMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    }

    carouselOptions = {
        nav: false,
        rewind: false,
        autoplay: false,
        margin: 30,
        loop: false,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 4,
            }
        }
    }



    componentDidMount = () => {

        let queries = queryString.parse(window.location.search)
        localStorage.setItem('queries', JSON.stringify(queries))
        let userDataLog = localStorage.getItem('userData')
        userDataLog = { ...queries, userDataLog }
        
        if (queries.AuthorisationCode) {
            localStorage.setItem('paymentStatus', 'success')
        } else {
            localStorage.setItem('paymentStatus', 'fail')
        }
        let eventParams = eventCallRequest('Payment Response Page Loaded', JSON.stringify(userDataLog))
        this.props.eventRequest(eventParams).then(() => {
            if (queries.AuthorisationCode) {
                window.location.replace(`${getPartnerDetails()?.partner_slug}/booking/payment-success`)
            } else {
                window.location.replace(`${getPartnerDetails()?.partner_slug}/booking/payment-failure`)
            }
         }).catch(() => {
            if (queries.AuthorisationCode) {
                window.location.replace(`${getPartnerDetails()?.partner_slug}/booking/payment-success`)
            } else {
                window.location.replace(`${getPartnerDetails()?.partner_slug}/booking/payment-failure`)
            }
         })
    }

    render() {
        return (
            <Aux>
                <div className="container-fluid">
                    <div className="row no-gutters">
                        <div className="col-sm-12 col-md-6 col-12">
                            <div className="col my-4 text-center">
                                <Link to="/" className='text-decoration-none'>
                                    <h5 className="fw-bold text-primary">Parking at Main Street Parking</h5>
                                </Link>
                            </div>
                            <CarouselItems />
                            <BottomLogo
                                className="my-2 text-center bg-opacity-10 d-none d-sm-block"
                            />
                        </div>
                        <LoadingComponent />
                        <BottomLogo
                            className="my-2 text-center bg-opacity-10 d-block d-sm-none"
                        />
                    </div>
                </div>
            </Aux>
        )
    }
}

paymentResponse.propTypes = {
    errorMessage: PropTypes.string,
    hasError: PropTypes.bool,
    eventRequest: PropTypes.func,
    dispatch: PropTypes.func,
    reqGetPartnerDetails: PropTypes.func, 
    hasErrorPartner: PropTypes.bool, 
    partnerDetails: PropTypes.object, 
    hasErrorMessage: PropTypes.bool,
    history: PropTypes.object, 
    reqGetPasses: PropTypes.func,
    passes: PropTypes.object,
    reqGetUserPasses : PropTypes.func,
    getGaragePrice  : PropTypes.func,
    reqMakePayment : PropTypes.func,
    paymentRes : PropTypes.object,
    userPasses : PropTypes.array,
    getGaragePriceRes  : PropTypes.object,
    reqGetPrice : PropTypes.func,
    isFetching  : PropTypes.bool,
    location: PropTypes.object,
    
}

paymentResponse.defaultProps = {
    errorMessage: '',
    hasError: false,
    eventRequest: noop,
    dispatch: noop,
    reqGetPartnerDetails: noop,
    hasErrorPartner: false, 
    partnerDetails: EMPTY_OBJECT, 
    hasErrorMessage: false,
    history: EMPTY_OBJECT,
    reqGetPasses: noop,
    passes:EMPTY_OBJECT,
    reqGetUserPasses : noop,
    getGaragePrice : noop,
    reqMakePayment : noop,
    paymentRes : EMPTY_OBJECT,
    userPasses : noop,
    getGaragePriceRes : EMPTY_OBJECT,
    reqGetPrice : noop,
    isFetching : false,
    location: EMPTY_OBJECT,
}

const mapStateToProps = ({ PassesReducer, PartnerReducer }) => {
    const { passes, isFetching, paymentRes, getGaragePriceRes, hasError, errorMessage, userPasses } = PassesReducer
    const { memberCheck, partnerDetails, eventRes } = PartnerReducer
    return {
        passes,
        userPasses,
        isFetching,
        paymentRes,
        getGaragePriceRes,
        hasError,
        errorMessage,
        memberCheck,
        eventRes,
        partnerDetails
    }
}
const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        eventRequest: (data) => dispatch(actionCreators.logEvent(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(paymentResponse)
