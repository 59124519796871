import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { EMPTY_ARRAY, EMPTY_OBJECT, validLicenceNumber } from '../../../constants/globalConstants'
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask'
import { matchPassword, restrictSpecialChar, validateEmail, validatePassword, validatePhone } from '../../.././utils/helper'
import { getPassListDetail, getVehicleDataList } from '../../../redux/actions/passListAction'
import { getFacilityList } from '../../../redux/actions/BasicInfoAction'
import Login from '../../../components/global/login'
import { Circles } from 'react-loader-spinner'
const phoneMaskGenerator = createDefaultMaskGenerator('(999) 999-9999')
import './PassForm.scss'


function PassForm(props) {
    const { setPage, formData, setFormData, formError, dispatch } = props
    /// console.log('formData permit',props)
    const [isLoginModal, setLoginModal] = useState(false)
    const [caller, setCaller] = useState()
    const [resident, setResident] = useState()
    const [vehicleList, setVehicleList] = useState(EMPTY_ARRAY)
    const [isVehicleTableVisible, setIsVehicleTableVisible] = useState(false)
    const [facilityList, setFacilityList] = useState(EMPTY_ARRAY)
    const [facilityPassList, setFacilityPassList] = useState(EMPTY_ARRAY)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [zipcode, setZipcode] = useState('')

    // show and hide passowrd 
    const [cpasswordShown, setCPasswordShown] = useState(false)
    const [mpasswordShown, setMPasswordShown] = useState(false)

    const [loginData, setLoginData] = useState(EMPTY_OBJECT)

    useEffect(() => {
        dispatch(getFacilityList()).then((res) => {
            setFacilityList(res)
            if (formData?.facility_id) {
                const selFaclity = res?.filter((item) => item?.id == Number(formData?.facility_id))
                if (selFaclity[0] && selFaclity[0]?.full_name != '') {
                    localStorage.setItem('facility', JSON.stringify(selFaclity[0]))
                    dispatch(getPassListDetail(formData?.facility_id)).then((res) => {
                        if (res) {
                            setFacilityPassList(res)
                        }
                    })
                }
            }
        })
    }, [EMPTY_ARRAY])

    useEffect(() => {
        setPage('home')
        const vehicles = formData?.vehicleList
        const getuserDatafromLocal = JSON.parse(localStorage.getItem('userData'))
        setLoginData(getuserDatafromLocal?.user)
        if (getuserDatafromLocal?.access_token && getuserDatafromLocal?.access_token != '') {
            const signupFormData = JSON.parse(localStorage.getItem('userData'))

            if ((vehicles?.length > 0 && vehicles[0]?.license_plate != '') && !formData?.vehicleSelected) {
                setIsVehicleTableVisible(false)
            } else {
                setIsVehicleTableVisible(true)
            }

            dispatch(getVehicleDataList()).then((res) => {
                setVehicleList(res?.data)
                if (res?.data?.length > 0) {
                    setIsVehicleTableVisible(false)
                } else {
                    setIsVehicleTableVisible(true)
                }
            })
            setIsLoggedIn(true)
            if (Object.values(signupFormData?.user)?.length > 0) {
                let userNameData = getuserDatafromLocal?.user?.name.split(' ')
                const loggedInUserData = signupFormData?.user
                formData['firstname'] = loggedInUserData?.name ? userNameData[0] : formData?.first_name
                formData['lastname'] = loggedInUserData?.name ? userNameData[1] : formData?.last_name
                // formData['firstname'] = loggedInUserData?.first_name ? loggedInUserData?.first_name : formData?.first_name
                // formData['lastname'] = loggedInUserData?.last_name ? loggedInUserData?.last_name : formData?.last_name
                formData['license_number'] = loggedInUserData?.license_number ? loggedInUserData?.license_number : formData?.license_number
                formData['email'] = loggedInUserData?.email ? loggedInUserData?.email : formData?.email
                formData['phone'] = loggedInUserData?.phone_number ? loggedInUserData?.phone_number : formData?.phone
                formData['address'] = loggedInUserData?.address ? loggedInUserData?.address : formData?.address
                formData['address2'] = loggedInUserData?.address2 ? loggedInUserData?.address2 : formData?.address2
                formData['city'] = loggedInUserData?.city ? loggedInUserData?.city : formData?.city
                formData['state'] = loggedInUserData?.state ? loggedInUserData?.state : formData?.state
                formData['zipcode'] = loggedInUserData?.zipcode ? loggedInUserData?.zipcode : formData?.zipcode
                setFormData({ ...formData })
            }
        } else {
            setIsLoggedIn(false)
            setFormData({ ...formData })
            setIsVehicleTableVisible(true)
        }
    }, [EMPTY_ARRAY])

    const handleLicenceNumberChange = (e) => {
        const { value } = e.target
        if (value?.length > 6 && value?.length < 14) {
            if (value?.length != 9) {
                if (validLicenceNumber.test(value?.trim())) {
                    const licenseData = {
                        resident: 'Non-Resident',
                        license_number: value?.toUpperCase()
                    }
                    setResident('Non-Resident')
                    setFormData({ ...formData, ...licenseData })
                } else {
                    const licenseData = {
                        resident: '',
                        license_number: value?.toUpperCase()
                    }
                    setFormData({ ...formData, ...licenseData })
                    setResident('Invalid License Number')
                }
            } else {
                const alpha = value?.slice(0, 1)
                const number = value?.slice(1, 9)
                if (/^[a-zA-Z]+$/.test(alpha) && /(\d{8})/g.test(number)) {
                    const licenseData = {
                        resident: 'Resident',
                        license_number: value?.toUpperCase()
                    }
                    setFormData({ ...formData, ...licenseData })
                    setResident('Resident')
                } else {
                    if (validLicenceNumber.test(value?.trim())) {
                        const licenseData = {
                            resident: 'Non-Resident',
                            license_number: value?.toUpperCase()
                        }
                        setFormData({ ...formData, ...licenseData })
                        setResident('Non-Resident')
                    } else {
                        const licenseData = {
                            resident: '',
                            license_number: value?.toUpperCase()
                        }
                        setFormData({ ...formData, ...licenseData })
                        setResident('Invalid License Number')
                    }
                }
            }
        } else {
            const licenseData = {
                resident: '',
                license_number: value?.toUpperCase()
            }
            setFormData({ ...formData, ...licenseData })
            setResident('')
        }
    }

    const handleInputFieldsData = (e, i) => {
        const { name, value } = e.target
        const payloadData = { ...formData }
        if (name === 'license_plate' || name === 'make' || name === 'model' || name === 'color') {
            payloadData.vehicleList[i][name] = value
            setFormData({ ...payloadData })
        } else if (name === 'facility_id') {
            if (value) {
                dispatch(getPassListDetail(value)).then((res) => {
                    if (res) {
                        setFacilityPassList(res)
                    }
                })
            } else {
                setFacilityPassList([])
            }
            setFormData({ ...formData, 'facility_id': value })
        } else if (name === 'pass_id') {
            const setPassDetails = facilityPassList?.filter((item) => item?.id == Number(value))
            if (setPassDetails[0] && setPassDetails[0]?.description != '') {
                localStorage.setItem('passDetails', JSON.stringify(setPassDetails[0]))
            }
            setFormData({ ...formData, 'pass_id': value })
        } if (name === 'zipcode') {
            let str = value
            let pattern = /\d/
            if (value && value != '') {
                str.match(pattern) ? setZipcode('') : setZipcode('The zipcode should contain at least a number.')
            }
            setFormData({ ...formData, 'zipcode': value })

        } else {
            setFormData({ ...formData, [name]: value })
        }
    }

    const handlePhoneChange = (value) => {
        setFormData({ ...formData, ['phone']: value })
    }

    const handleModalOpen = () => {
        setLoginModal(true)
        setCaller('home')
    }

    const handleVehicleChange = (e) => {
        const { value } = e.target
        if (value != '') {
            const selectedVehicle = vehicleList?.filter((vehicle) => vehicle?.license_plate_number == value)
            const payloadData = { ...formData }
            payloadData.vehicleList[0]['license_plate'] = selectedVehicle[0]?.license_plate_number
            payloadData.vehicleList[0]['make'] = selectedVehicle[0]?.make
            payloadData.vehicleList[0]['model'] = selectedVehicle[0]?.model
            payloadData.vehicleList[0]['color'] = selectedVehicle[0]?.color
            payloadData['vehicleSelected'] = true
            setFormData({ ...payloadData })
        } else {
            const payloadData = { ...formData }
            payloadData.vehicleList[0]['license_plate'] = ''
            payloadData.vehicleList[0]['make'] = ''
            payloadData.vehicleList[0]['model'] = ''
            payloadData.vehicleList[0]['color'] = ''
            payloadData['vehicleSelected'] = false

            setFormData({ ...payloadData })
        }

    }

    const showVehicleTable = () => {
        setIsVehicleTableVisible(true)
        const payloadData = { ...formData }
        payloadData.vehicleList[0]['license_plate'] = ''
        payloadData.vehicleList[0]['make'] = ''
        payloadData.vehicleList[0]['model'] = ''
        payloadData.vehicleList[0]['color'] = ''
        payloadData['vehicleSelected'] = false

        setFormData({ ...payloadData })
    }

    const removeVehicleMake = () => {
        setIsVehicleTableVisible(false)
        const payloadData = { ...formData }
        payloadData.vehicleList[0]['license_plate'] = ''
        payloadData.vehicleList[0]['make'] = ''
        payloadData.vehicleList[0]['model'] = ''
        payloadData.vehicleList[0]['color'] = ''
        payloadData['vehicleSelected'] = false
        setFormData({ ...payloadData })
    }


    // show and hide password 
    const togglePasswordVisiblity = (e, key) => {
        if (key === 'password') {
            setMPasswordShown(mpasswordShown ? false : true)
        }
        if (key === 'cpassword') {
            setCPasswordShown(cpasswordShown ? false : true)
        }
    }


    return (
        <div >
            <h4 className="text-center my-4 mb-4">Account Information</h4>
            {!isLoggedIn && <div className="bg-white card p-3 mb-4">
                <p className="m-0">
                    <button type='button' className="btn btn-outline-primary" onClick={handleModalOpen} data-bs-target="#loginModal">LOG
                        IN</button> <strong> OR CREATE ACCOUNT BELOW</strong><br /><small
                            className="text-danger">*You must log in or create a new account to
                        proceed.</small>
                </p>
            </div>}
            <div className="mt-2">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <label className="form-label">First Name <span className='text-danger'> *</span></label>
                            <input
                                className="form-control ng-untouched ng-pristine ng-invalid"
                                id="firstname" name="firstname" placeholder="First Name"
                                maxLength={20}
                                minLength={1}
                                disabled={isLoggedIn}
                                value={formData?.firstname ? formData?.firstname : ''}
                                onChange={(e) => handleInputFieldsData(e)}
                                autoComplete='none'
                                required="" type="text" />
                            <span className="form-text text-danger h-40">{formError == 'home' && !formData?.firstname ? 'This field is required' : ''}</span>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="mb-3">
                            {/* <span className='text-danger'> *</span> */}
                            <label className="form-label">Last Name </label>
                            <input
                                maxLength={20}
                                disabled={(!loginData?.last_name) ? false : true}
                                minLength={1}
                                className="form-control ng-untouched ng-pristine ng-invalid"
                                name="lastname"
                                value={formData?.lastname ? formData?.lastname : ''} placeholder="Last Name"
                                required={!isLoggedIn}
                                type="text"
                                onChange={(e) => handleInputFieldsData(e)}
                                autoComplete='none' />
                            {/* <span className="form-text text-danger h-40">{!isLoggedIn && formError == 'home' && !formData?.lastname ? 'This field is required' : ''}</span> */}
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <label className="form-label">Email <span className='text-danger'> *</span></label><input
                                className="form-control ng-untouched ng-pristine ng-invalid"
                                maxLength={40}
                                disabled={isLoggedIn}
                                minLength={1}
                                name="email" placeholder="Email" required="" type="email"
                                value={formData?.email ? formData?.email : ''}
                                onChange={(e) => handleInputFieldsData(e)}
                                autoComplete='none'
                                pattern="^[^@]+@[^@]+.[a-zA-Z]{2,6}" />
                            <span className="form-text text-danger h-40">{formError == 'home' ? !formData?.email ? 'This field is required' : !validateEmail(formData?.email) ? 'Please enter a valid email address' : '' : ''}</span>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <label className="form-label">Phone <span className='text-danger'> *</span></label>
                            <MaskedInput
                                maskGenerator={phoneMaskGenerator}
                                value={formData?.phone ? formData?.phone : ''}
                                type="text"
                                name="phone"
                                id="phone"
                                className="form-control"
                                disabled={isLoggedIn}
                                required
                                // pattern="[0-9 \\-\(\)\]{14}"
                                maxLength={14}
                                minLength={14}
                                title="Please enter a valid phone number"
                                onInvalid={e => e.target.setCustomValidity('Please enter phone number')}
                                onInput={e => e.target.setCustomValidity('')}
                                placeholder="Phone Number"
                                onChange={handlePhoneChange}
                            />
                            <span className="form-text text-danger h-40">{formError == 'home' ? !formData?.phone ? 'This field is required' : !validatePhone(formData?.phone) ? 'Please enter a valid phone number' : '' : ''}</span>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <label className="form-label">Select Facility <span className='text-danger'> *</span></label>
                            <select name="facility_id" id="facility_id" className="form-select" onChange={(e) => handleInputFieldsData(e)} autoComplete='none' >
                                <option value=''>Select Facility</option>
                                {facilityList?.map((faclityListItem, faclityListIndex) => {
                                    return (
                                        <option value={faclityListItem?.id} selected={formData?.facility_id == faclityListItem?.id} key={faclityListIndex}>{faclityListItem?.full_name}</option>
                                    )
                                })}
                            </select>
                            <span className="form-text text-danger h-40">{formError == 'home' && !formData?.facility_id ? 'This field is required' : ''}</span>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <label className="form-label">Select Pass <span className='text-danger'> *</span></label>
                            <select name="pass_id" id="pass_id" className="form-select" onChange={(e) => handleInputFieldsData(e)} autoComplete='none' >
                                <option value=''>Select Pass</option>
                                {facilityPassList?.map((faclityListItem, faclityListIndex) => {
                                    return (
                                        <option value={faclityListItem?.id} selected={formData?.pass_id == faclityListItem?.id} key={faclityListIndex}>{faclityListItem?.description}</option>
                                    )
                                })}
                            </select>
                            <span className="form-text text-danger h-40">{formError == 'home' && !formData?.pass_id ? 'This field is required' : ''}</span>
                        </div>
                    </div>
                    {/* address */}

                    <div className="col-sm-6">
                        <div className="mb-3">
                            <label className="form-label">Address Line 1 </label><input
                                maxLength={100}
                                className="form-control ng-untouched ng-pristine ng-invalid"
                                name="address" placeholder="Address Line 1"
                                value={formData?.address ? formData?.address : ''}
                                onChange={handleInputFieldsData}
                                type="text" />
                            {/* <span className="form-text text-danger h-40">{formError == 'home' && !formData?.address ? 'This field is required' : ''}</span> */}
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <label className="form-label">Address Line 2 </label><input
                                maxLength={100}
                                className="form-control ng-untouched ng-pristine ng-valid"
                                name="address2" placeholder="Address Line 2 (optional)"
                                value={formData?.address2 ? formData?.address2 : ''}
                                onChange={handleInputFieldsData}
                                type="text" /></div>
                    </div>
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <label className="form-label">City </label><input
                                maxLength={20}
                                className="form-control ng-untouched ng-pristine ng-invalid"
                                name="city" value={formData?.city ? formData?.city : ''} placeholder="City" type="text" onChange={handleInputFieldsData} />
                            {/* <span className="form-text text-danger h-40">{formError == 'home' && !formData?.city ? 'This field is required' : ''}</span> */}
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-6">
                        <div className="mb-3">
                            <label className="form-label">State </label><input
                                maxLength={20}
                                className="form-control ng-untouched ng-pristine ng-invalid"
                                name="state" placeholder="State"
                                value={formData?.state ? formData?.state : ''}
                                onChange={handleInputFieldsData}
                                type="text" />
                            {/* <span className="form-text text-danger h-40">{formError == 'home' && !formData?.state ? 'This field is required' : ''}</span> */}
                        </div>
                    </div>



                    {/* end */}
                    <div className="col-sm-6">
                        <div className="mb-3 position-relative">
                            <label className="form-label d-none">Driver License Number <span className='text-danger'> * </span>
                                <span className="popoverSection position-relative"  >
                                    <span className='popoverContent position-absolute shadow-sm bg-white p-2 border'>Driving License Number must contain first letter character and other numbers.</span>
                                    <i className="fa fa-info-circle " />
                                </span>
                            </label>
                            <input
                                className="form-control ng-untouched ng-pristine ng-invalid"
                                name="license_number"
                                id="license_number"
                                maxLength={13}
                                value={formData?.license_number}
                                minLength={1}
                                placeholder="License Number"
                                required=""
                                onKeyDown={restrictSpecialChar}
                                disabled={(!loginData?.license_number) ? false : true}
                                type="text"
                                onChange={(e) => handleLicenceNumberChange(e, 'change', formData?.facility_id)}
                            />
                            <span className='position-absolute end-0 top-100'>
                                {resident == 'checking...' ? <span><Circles
                                    height="15"
                                    width="15"
                                    color="var(--bs-primary)"
                                    ariaLabel="circles-loading"
                                    visible={true}
                                /></span> : <span className={resident == 'Invalid License Number' ? 'form-text text-danger h-40' : 'form-text mainColor h-40'}><b>{resident}</b></span>}
                            </span>
                            {/* <span className="form-text text-danger h-40">{formError == 'home' ? !formData?.license_number ? 'This field is required' : !validLicenceNumber.test(formData?.license_number?.trim()) ? 'Please enter a valid license number' : '' : ''}</span> */}
                            <span className="form-text text-danger h-40">{formError == 'home' && !formData?.license_number ? 'This field is required' : ''}</span>

                        </div>
                    </div>
                    <div className="col-sm-6 ">
                        <div className="mb-3">
                            <label className="form-label">Zipcode </label><input
                                className="form-control ng-untouched ng-pristine ng-invalid"
                                maxLength="6" minLength="5" name="zipcode"
                                value={formData?.zipcode ? formData?.zipcode : ''}
                                placeholder="Zip Code" type="text" onChange={handleInputFieldsData} />
                            <span className="form-text text-danger h-40">{zipcode}</span>
                            {/* <span className="form-text text-danger h-40">{formError == 'home' && !formData?.zipcode ? 'This field is required' : ''}{zipcode}</span> */}
                        </div>
                    </div>

                    <div className="clearfix">
                        <label className="form-label">Vehicle Information <span className='text-danger'> *</span></label>
                        {isLoggedIn &&
                            <div className='row '>
                                {vehicleList?.length > 0 && <div className="col-sm-6">
                                    <div className="mb-3">
                                        <select name="vehicle" id="vehicle" disabled={isVehicleTableVisible} className="form-select" onChange={handleVehicleChange} >
                                            <option value=''>Select Vehicle</option>
                                            {vehicleList?.map((vehicle, index) => {
                                                return (
                                                    <option value={vehicle?.license_plate_number} selected={vehicle?.license_plate_number == formData?.vehicleList[0]?.license_plate} key={index}>{vehicle?.license_plate_number}</option>
                                                )
                                            })}
                                        </select>
                                        <span className="form-text text-danger h-40">{formError == 'home' && !formData?.vehicleList?.[0]?.license_plate ? 'Please select or add new vehicle' : ''}</span>
                                    </div>
                                </div>}
                                <div className='col-sm-6 mb-3'>{vehicleList?.length > 0 && <span className='badge badge-primary text-dark fw-bold me-2'>OR</span>}  {vehicleList?.length > 0 && <button type='button' className='btn btn-primary' onClick={(e) => showVehicleTable(e)}><i className='fa fa-plus'></i> Add New vehicle</button>}</div>

                            </div>}
                        {(vehicleList?.length < 1 || isVehicleTableVisible) ? formData?.vehicleList?.map((vehicleListItem, vehicleListIndex) => {
                            return (
                                <div className='row g-sm-4 g-1 mb-2' key={vehicleListIndex}>
                                    <div className={(vehicleList?.length > 0 && isLoggedIn) ? 'col-sm-3 col-3 mb-2' : 'col-sm-3 col-3 mb-2'}><input
                                        type="text"
                                        name='license_plate'
                                        className="form-control"
                                        placeholder="License Plate"
                                        maxLength={6}
                                        minLength={2}
                                        onKeyDown={restrictSpecialChar}
                                        value={vehicleListItem?.license_plate}
                                        onChange={(e) => handleInputFieldsData(e, vehicleListIndex)}
                                        aria-describedby="licenseplate" />
                                        <span className="form-text text-danger h-40">{formError == 'home' && !formData?.vehicleList?.[vehicleListIndex]?.license_plate != '' ? 'This field is required' : (formData?.vehicleList?.[vehicleListIndex]?.license_plate?.length < 3 || formData?.vehicleList?.[vehicleListIndex]?.license_plate?.length > 10) ? 'License plate should have 2 to 6 characters' : ''}</span>
                                    </div>
                                    <div className={(vehicleList?.length > 0 && isLoggedIn) ? 'col-sm-3 col-3 mb-2' : 'col-sm-3 col-3 mb-2'}>
                                        <input
                                            type="text"
                                            name='color'
                                            className="form-control"
                                            placeholder="Color"
                                            onChange={(e) => handleInputFieldsData(e, vehicleListIndex)}
                                            maxLength={15}
                                            minLength={4}
                                            onKeyDown={restrictSpecialChar}
                                            value={vehicleListItem?.color}
                                            aria-describedby="color" />
                                        <span className="form-text text-danger h-40">{formError == 'home' && !formData?.vehicleList?.[vehicleListIndex]?.model != '' ? 'This field is required' : ''}</span>
                                    </div>


                                    <div className={(vehicleList?.length > 0 && isLoggedIn) ? 'col-sm-2 col-2 mb-2' : 'col-sm-3 col-3 mb-2'}>
                                        <input
                                            type="text"
                                            name='make'
                                            className="form-control"
                                            placeholder="Make"
                                            onChange={(e) => handleInputFieldsData(e, vehicleListIndex)}
                                            maxLength={15}
                                            minLength={4}
                                            onKeyDown={restrictSpecialChar}
                                            value={vehicleListItem?.make}
                                            aria-describedby="make" />
                                        <span className="form-text text-danger h-40">{formError == 'home' && !formData?.vehicleList?.[vehicleListIndex]?.make != '' ? 'This field is required' : ''}</span>
                                    </div>
                                    <div className={(vehicleList?.length > 0 && isLoggedIn) ? 'col-sm-2 col-2 mb-2' : 'col-sm-3 col-3 mb-2'}>
                                        <input
                                            type="text"
                                            name='model'
                                            className="form-control"
                                            placeholder="Model"
                                            onChange={(e) => handleInputFieldsData(e, vehicleListIndex)}
                                            maxLength={15}
                                            minLength={4}
                                            onKeyDown={restrictSpecialChar}
                                            value={vehicleListItem?.model}
                                            aria-describedby="Model" />
                                        <span className="form-text text-danger h-40">{formError == 'home' && !formData?.vehicleList?.[vehicleListIndex]?.model != '' ? 'This field is required' : ''}</span>
                                    </div>
                                    {(vehicleList?.length > 0 && isLoggedIn) ? <div className='col-sm col-2 mb-2'>
                                        <button type="button" className="btn btn-danger w-100" onClick={() => removeVehicleMake()}><i className='fa fa-minus'></i></button>
                                    </div> : null}
                                </div>
                            )
                        }) : ''}
                    </div>
                    {!isLoggedIn && <div className=" mt-2 ">
                        <p className="fw-bold mt-3 mb-2"  >
                            <span >Create Account Password <i className='text-danger' > * </i>
                                <span className="popoverSection position-relative"  >
                                    <span className='popoverContent position-absolute shadow-sm bg-white p-2 border'>Password must contain 8-15 characters, including 1 uppercase, 1 lowercase, 1 number and 1 special character.</span>
                                    <i className="fa fa-info-circle " />
                                </span>
                            </span>
                        </p>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mb-3">
                                    <input
                                        className="form-control ng-untouched ng-pristine ng-invalid"
                                        name="password"
                                        placeholder="Create Password"
                                        required=""
                                        value={formData?.password}
                                        onChange={(e) => handleInputFieldsData(e)}
                                        autoComplete="off"
                                        // reverse="true"
                                        type={(!mpasswordShown) ? 'password' : 'text'}
                                    // validateequal="cpassword"
                                    />
                                    <i className={(!mpasswordShown) ? 'fas fa-eye-slash miconClass' : 'fas fa-eye miconClass'} onClick={(e) => togglePasswordVisiblity(e, 'password')}></i>
                                    <span className="form-text text-danger h-40">{formError == 'home' ? !formData?.password ? 'This field is required' : !validatePassword(formData?.password) ? 'Password must be between 8 and 15 characters long and include at least one uppercase letter, one lowercase letter, one digit (0-9), and one special character (!, @, $, %, ^, &, *, #,?,-).' : !matchPassword(formData?.password, formData?.cpassword) ? 'Passwords does not match' : '' : ''}</span>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="mb-3">
                                    <input
                                        className="form-control ng-untouched ng-pristine ng-invalid"
                                        value={formData?.cpassword}
                                        name="cpassword"
                                        placeholder="Confirm Password"
                                        required=""
                                        onChange={(e) => handleInputFieldsData(e)}
                                        autoComplete='none'
                                        type={(!cpasswordShown) ? 'password' : 'text'}
                                    // validateequal="password"

                                    />
                                    <i className={(!cpasswordShown) ? 'fas fa-eye-slash ciconClass' : 'fas fa-eye ciconClass'} onClick={(e) => togglePasswordVisiblity(e, 'cpassword')}></i>
                                    <span className="form-text text-danger h-40">{formError == 'home' ? !formData?.cpassword ? 'This field is required' : !validatePassword(formData?.cpassword) ? 'Password must be between 8 and 15 characters long and include at least one uppercase letter, one lowercase letter, one digit (0-9), and one special character (!, @, $, %, ^, &, *, #,?,-).' : !matchPassword(formData?.password, formData?.cpassword) ? 'Passwords does not match' : '' : ''}</span>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            <Login isLoginModal={isLoginModal} setLoginModal={setLoginModal} caller={caller} />
        </div>
    )
}

PassForm.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    setPage: PropTypes.func,
    formData: PropTypes.object,
    setFormData: PropTypes.func,
    formError: PropTypes.string,
    facilityPassList: PropTypes.array,
    facilityList: PropTypes.array,
}

PassForm.defaulProps = {
    dispatch: noop,
    loader: PropTypes.bool,
    setPage: noop,
    formData: EMPTY_OBJECT,
    setFormData: noop,
    formError: '',
}


function mapStateToProps() {
    return {

    }

}
export default connect(mapStateToProps)(PassForm)
