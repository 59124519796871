import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { EMPTY_ARRAY, EMPTY_OBJECT, validLicenceNumber } from '../../constants/globalConstants'
import { fileChangedHandler, formatKeyInDocumentName, getAcceptedDocument, getAcceptedFiles, isLoggedIn, isValuePresentInArray, perkingParkingTime, restrictSpecialChar } from '../../utils/helper'
// getFacilityOpenDays
import { permitRateList, getDocumentTypes } from '../../redux/actions/BasicInfoAction'
import { Circles } from 'react-loader-spinner'
import FileUpload from '../../components/fileUpload/FileUpload'
import { getVehicleDataList, getVehicleModelList } from '../../redux/actions/VehicleListAction'
import './home.css'
import { staticStrings } from '../../constants/staticStrings'
// import moment from 'moment'
// import { nextDayCheck, HorurCheck } from '../../utils/helper'
function Home(props) {
	const {
		setPage,
		setIdProof,
		idProof,
		dispatch,
		formData,
		setFormData,
		formError,
		loader,
		selectedFaclity,
		setSelectedFacilty,
		setDocumentTypes,
		documentTypes,
		partnerDetails,
		notify,
		vehicleListArray,
		setVehicleListArray,
		facilityId,
		setFacilityId,
		setvehicleCount,
		vehicleCount,
		VehiclePrev,
		vehicleCountList,
		setvehicleCountList
	} = props
	const searchParams = new URLSearchParams(location.search)
	const [resident, setResident] = useState()
	const [faclityList, setFaclityList] = useState(EMPTY_ARRAY)
	const [permitPrices, setPermitPrices] = useState(EMPTY_ARRAY)
	const [permitRateError, setPermitRateError] = useState('')
	const [selectedDocumentType, setSelectedDocumentType] = useState(EMPTY_OBJECT)
	// for disbaled functionallty 
	const [loginData, setLoginData] = useState(EMPTY_OBJECT)
	// const navigate = useNavigate()
	const [error, setError] = useState(false)
	// const [showRemoveButton, setRemoveButtonShowKey] = useState('')
	const [permitTypes, setPermitTypes] = useState(EMPTY_ARRAY)

	const [fetchingDocumentTypes, setFetchingDocumentTypes] = useState(false)

	// vehicle
	// const [isVehicleTableVisible, setIsVehicleTableVisible] = useState(false)
	// const [vehicleList, setVehicleList] = useState(EMPTY_ARRAY)
	const [vehicleDetailsList, setVehicleDetailsList] = useState(EMPTY_ARRAY)
	const [vehicleMakeList, setVehicleMakeList] = useState(EMPTY_ARRAY)
	const [vehicleModelList, setvehicleModelList] = useState(EMPTY_ARRAY)
	// const [vehicalId, setVehicalId] = useState('')
	// const [otherMake, setOtherMake] = useState(false)
	// const [otherModel, setOtherModel] = useState(false)

	// const [selectedPermit, setSelectedPermit] = useState(EMPTY_OBJECT)

	useEffect(() => {
		if (!facilityId) {
			localStorage.removeItem('facility')
		}


		if (formData?.license_number && facilityId) {
			retrievePermitData(formData?.license_number, facilityId)
		}
		if (formData?.license_number) {
			const userData = localStorage.getItem('userData') ? localStorage.getItem('userData') : '{}'
			const user = JSON.parse(userData)
			const license_number = user?.user?.license_number
			const handlingLicenseChange = {
				target: {
					value: isLoggedIn() ? license_number : formData?.license_number
				}
			}
			handleLicenceNumberChange(handlingLicenseChange)
		}

		setPage('home')

		setFormData({ ...formData, ['facility_id']: facilityId })
		const vehicles = formData?.vehicleList
		if (isLoggedIn()) {
			const userData = localStorage.getItem('userData')
			const user = JSON.parse(userData)
			// save loginUserData
			setLoginData(user?.user)
			const license_number = user?.user?.license_number

			formData['license_number'] = license_number
			setFormData({ ...formData })

			if ((vehicles?.length > 0 && vehicles[0]?.license_plate != '') && !formData?.vehicleSelected) {

				// setIsVehicleTableVisible(false)
			} else {

				// setIsVehicleTableVisible(true)
			}
			if (VehiclePrev) {
				dispatch(getVehicleDataList()).then((res) => {
					// setVehicleList(res?.data)
					let payloadData = { ...formData }

					// IF URL HAS THE PERMIT ID
					let selectedPermit = []
					if(searchParams?.get('permitType') && searchParams?.get('permitType') != null){
						selectedPermit = permitTypes?.filter(item => 
							item?.permit_rate?.some(rate => rate?.id == searchParams?.get('permitType'))
						)
					}
					payloadData.permit_id = selectedPermit?.[0]?.id
					payloadData.permit_rate_id = selectedPermit?.[0]?.permit_rate?.[0]?.id
					payloadData.permit_type_name = selectedPermit?.[0]?.name ? selectedPermit?.[0]?.name : ''


					let vehicleCopy = []
					res?.data?.map((value) => {
						let copy = { ...value }
						let modelData = vehicleDetailsList?.make?.filter((v) => v.name == copy?.make)
						let modelList = (modelData?.length != 0 ? modelData?.[0]?.mst_model : [])
						let filteredmakemodelList = modelList?.filter((v) => v.name == copy?.model)
						// // setvehicleModelList(modelData[0]?.mst_model)
						let check = modelList?.some((value) => value?.name == 'Others')
						if (!check && modelList != undefined) {
							modelList?.unshift({
								'id': 'Others',
								'make_id': 'Others',
								'name': 'Others'
							})
						}
						copy['license_plate'] = copy?.license_plate_number
						copy['maketext'] = copy?.make ? copy?.make : ''
						copy['make'] = (modelData?.length == 0 && copy?.make != '') ? 'Others' : copy?.make ? copy?.make : ''
						copy['othermake'] = modelData?.length == 0 ? true : false
						copy['othermodel'] = filteredmakemodelList?.length == 0 ? true : false
						copy['modeltext'] = copy?.model ? copy?.model : ''
						copy['model'] = filteredmakemodelList?.length == 0 ? 'Others' : copy.model ? copy?.model : ''
						copy['vehicleList'] = modelList != undefined ? modelList : vehicleModelList
						delete copy['license_plate_number']
						vehicleCopy.push(copy)
					})
					payloadData['vehicleList'] = vehicleCopy
					setvehicleCount(vehicleCopy.length)
					setvehicleCountList(vehicleCopy)
					setFormData(payloadData)
					setVehicleListArray((vehicleCopy && vehicleCopy?.length > 0) ? vehicleCopy : vehicleListArray)
					if (res?.data?.length > 0 && formData?.vehicleList?.[0]?.license_plate == '') {

						// setIsVehicleTableVisible(true)
					} else {

						// setIsVehicleTableVisible(true)
					}
				})
			}
		} else {
			// setIsVehicleTableVisible(true)
		}
	}, [vehicleDetailsList])


	useEffect(() => {
		getVehical()

	}, [EMPTY_ARRAY])

	useEffect(() => {
		if (partnerDetails?.name) {
			// Filtering the Lots and Displaying only Facilities
			const facilityList = partnerDetails?.facilities?.filter((item) => item?.is_lot != 1)
			setFaclityList(facilityList)
			if (facilityList?.length == 1) {
				setFacilityId(facilityList?.[0]?.id)
				// setFormData({ ...formData, 'facility_id': facilityList?.[0]?.id })
				setSelectedFacilty(facilityList?.[0])
				localStorage.setItem('facility', JSON.stringify(facilityList?.[0]))
				retrievePermitData(formData?.license_number, facilityList?.[0]?.id)
			}
			if (facilityId) {
				const selFaclity = facilityList?.filter((item) => item?.id == Number(facilityId))
				if (selFaclity[0] && selFaclity[0]?.full_name != '') {
					setSelectedFacilty(selFaclity[0])
					localStorage.setItem('facility', JSON.stringify(selFaclity[0]))
				}
				retrievePermitData(formData?.license_number, facilityId)
				// getPermitTypes(formData?.facility_id)
			}

			if (searchParams?.get('facility') && searchParams?.get('facility') != null) {
				setFacilityId(searchParams?.get('facility'))
				const selFaclity = facilityList?.filter((item) => item?.id == Number(searchParams?.get('facility')))
				if (selFaclity[0] && selFaclity[0]?.full_name != '') {
					setSelectedFacilty(selFaclity[0])
					localStorage.setItem('facility', JSON.stringify(selFaclity[0]))
				}
				setFormData({ ...formData, 'facility': searchParams?.get('facility') })
				retrievePermitData(formData?.license_number ? formData?.license_number : '', searchParams?.get('facility'))
				// getPermitTypes(formData?.facility_id)
			}
		}

	}, [EMPTY_ARRAY, partnerDetails])

	useEffect(() => {
		let uploadedKeys = idProof.map(doc => doc?.type)
		let notUploadedElements = documentTypes.filter(doc => !uploadedKeys?.includes(doc?.document_key))
		setSelectedDocumentType(notUploadedElements?.[0])
		if (notUploadedElements && notUploadedElements?.length > 0) {
			setFormData({ ...formData, 'document_type': notUploadedElements?.[0]?.document_key })
		} else {
			const lastIndex = documentTypes?.length - 1
			const lastDocumentType = documentTypes?.[lastIndex]
			setFormData({ ...formData, 'document_type': lastDocumentType?.document_key })
		}
	}, [EMPTY_ARRAY, idProof, documentTypes])


	const getVehical = () => {
		dispatch(getVehicleModelList(partnerDetails?.facilities?.[0]?.country_code)).then((res) => {
			let makeList = res.make
			makeList?.unshift({
				'id': 'Others',
				'name': 'Others',
				'mst_model': [{
					'id': 'Others',
					'make_id': 'Others',
					'name': 'Others'
				}]
			})

			setVehicleMakeList(makeList)
			setVehicleDetailsList(res)
			// setValue('country', res.country[0].name)
			// if (BookingEdit) {
			// 	let model = (res?.make?.length > 0) && res?.make?.filter((v) => v?.name == BookingEditData?.vehical_details?.make)
			// 	setvehicleModelList(model[0]?.mst_model)
			// }

		})

	}


	const retrievePermitData = (license_number, facility_id) => {
		// if (facility_id && license_number) {
		if (facility_id) {
			const permitPayload = {
				'facility_id': facility_id,
				// 'driving_license': license_number ? license_number?.trim() : '',
				'driving_license': '',
				'is_permit_type': '1',
			}

			if(partnerDetails?.attendent_type == 'business_account'){
				permitPayload.business_id = partnerDetails?.id
			}

			// if (validLicenceNumber.test(license_number?.trim())) {
			dispatch(permitRateList(permitPayload)).then((res) => {
				const filteredPermitTypes = res?.data?.filter((item) => item?.permit_rate?.length > 0)
				setResident(filteredPermitTypes?.[0]?.name)
				// const permit = filteredPermitTypes?.[0]
				setPermitTypes(filteredPermitTypes)
				// setPermitPrices(permit)

				// When user came back by clicking previous button
				if (formData?.permit_id || (searchParams?.get('permitType') && searchParams?.get('permitType') != null)) {
					let selectedPermit = []
					if(searchParams?.get('permitType') && searchParams?.get('permitType') != null){
						selectedPermit = filteredPermitTypes?.filter(item => 
							item?.permit_rate?.some(rate => rate?.id == searchParams?.get('permitType'))
						)

						const updatedData = { ...formData, 'permit_id': selectedPermit?.[0]?.id, 
							'permit_rate_id': selectedPermit?.[0]?.permit_rate?.[0]?.id, 
							'permit_type_name': selectedPermit?.[0]?.name ? selectedPermit?.[0]?.name : '' 
						}
						setFormData(updatedData)
						if(!formData?.permit_id){
							handlePermitTypechange(selectedPermit , 'permit_id', selectedPermit?.[0]?.id)
						}
					}else{
						selectedPermit = filteredPermitTypes?.filter((item) => item?.id == formData?.permit_id)
					}
					

					setPermitPrices(selectedPermit?.[0])

					// setSelectedPermit(selectedPermit?.[0])
					localStorage.setItem('permits', JSON.stringify(selectedPermit?.[0]))
					const permitDetails = {
						permit_rate: selectedPermit?.[0]?.permit_rate?.[0]?.user_permit_type_mapping?.[0]?.permit_negotiable_price ? selectedPermit?.[0]?.permit_rate?.[0]?.user_permit_type_mapping?.[0]?.permit_negotiable_price : selectedPermit?.[0]?.permit_rate?.[0]?.rate,
						permit_rate_id: selectedPermit?.[0]?.permit_rate?.[0]?.id,
						facility_id: facility_id
					}
					setFormData({ ...formData, ...permitDetails })
				} else {

					const permitDetails = {
						permit_rate: '',
						permit_rate_id: '',
						permit_id: '',
						facility_id: facility_id
					}
					setPermitRateError('')
					localStorage.setItem('permits', '{}')
					setFormData({ ...formData, ...permitDetails })
					// const permitRate = permit?.permit_rate[0]
					// if(filteredPermitTypes?.length == 1){
					// 	handlePermitTypechange([permit] , 'permit_id' , permit?.id)
					// }
					// if (permitRate?.name) {
					// 	localStorage.setItem('permits', JSON.stringify(permit))
					// 	const permitDetails = {
					// 		permit_rate: permitRate?.rate,
					// 		permit_rate_id: permitRate?.id,
					// 		permit_id: filteredPermitTypes?.length == 1 ?  permit?.id : '',
					// 		facility_id: facility_id
					// 	}
					// 	setFormData({ ...formData, ...permitDetails })
					// 	setPermitRateError('')
					// } else {
					// 	if (facilityId) {
					// 		setPermitRateError('')
					// 	} else {
					// 		setPermitRateError('')
					// 	}
					// 	localStorage.setItem('permits', '{}')
					// }
				}



			})


		}

	}


	const handleLicenceNumberChange = (e) => {
		const { value } = e.target
		if (value?.length > 6 && value?.length < 14) {
			if (value?.length != 9) {
				if (validLicenceNumber.test(value?.trim())) {
					const licenseData = {
						resident: 'Non-Resident',
						license_number: value?.toUpperCase()
					}
					setResident('Non-Resident')

					setFormData({ ...formData, ...licenseData })
				} else {
					const licenseData = {
						resident: '',
						license_number: value?.toUpperCase()
					}
					setFormData({ ...formData, ...licenseData })
					setResident('Invalid License Number')
				}
			} else {
				const alpha = value?.slice(0, 1)
				const number = value?.slice(1, 9)
				if (/^[H]+$/.test(alpha) && /(\d{8})/g.test(number)) {
					const licenseData = {
						resident: 'Resident',
						license_number: value?.toUpperCase()
					}
					setFormData({ ...formData, ...licenseData })
					setResident('Resident')
				} else {
					if (validLicenceNumber.test(value?.trim())) {
						const licenseData = {
							resident: 'Non-Resident',
							license_number: value?.toUpperCase()
						}
						setFormData({ ...formData, ...licenseData })
						setResident('Non-Resident')
					} else {
						const licenseData = {
							resident: '',
							license_number: value?.toUpperCase()
						}
						setFormData({ ...formData, ...licenseData })
						setResident('Invalid License Number')
					}
				}
			}
		} else {
			const licenseData = {
				resident: '',
				license_number: value?.toUpperCase()
			}
			setFormData({ ...formData, ...licenseData })
			setResident('')
		}
	}

	const handleInputFieldsData = (e, i) => {

		const { name, value } = e.target
		// const payloadData = { ...formData }
		const vehicleList = vehicleListArray?.slice()
		if (name === 'license_plate' || name == 'modeltext' || name == 'color' || name == 'state_id' || name == 'maketext') {
			vehicleList[i][name] = value?.replace(/[^a-zA-Z0-9]/g, '')
			setVehicleListArray(vehicleList)
			// setFormData({ ...payloadData })
		} else if (name === 'facility_id') {
			if (value == '0' || value == null || value == undefined) {
				setPermitTypes(EMPTY_ARRAY)
				// setSelectedPermit(EMPTY_OBJECT)
				setPermitPrices(EMPTY_ARRAY)
				setDocumentTypes([])
				setIdProof([])
				localStorage.setItem('permits', '{}')
				setFormData({ ...formData, 'permit_id': null, 'permit_rate_id': null, 'permit_type_name': '' })
			} else {
				const selFaclity = faclityList?.filter((item) => item?.id == Number(value))
				setSelectedFacilty(selFaclity[0])
				setFacilityId(value)
				// setFormData({ ...formData, 'facility_id': value })
				if (selFaclity[0]) {
					retrievePermitData(formData?.license_number, value)
					// getPermitTypes(value)
					localStorage.setItem('facility', JSON.stringify(selFaclity[0]))
				} else {
					setDocumentTypes([])
					delete formData.document_type
					setFacilityId('')
					// setFormData({ ...formData, 'facility_id': '' })
					setSelectedDocumentType(null)
					localStorage.setItem('facility', '{}')
					setPermitRateError('')
					setIdProof()
				}
			}

		} else if (name == 'model') {
			vehicleList[i]['othermodel'] = (value == 'Others') ? true : false
			vehicleList[i][name] = value
			setVehicleListArray(vehicleList)
			// setFormData({ ...payloadData })
		} else if (name === 'permit_id') {
			const selPermit = permitTypes?.filter((item) => item?.id == value)
			// setSelectedPermit(selPermit?.[0])
			handlePermitTypechange(selPermit , name , value)
		} else if (name === 'document_type') {
			const userSelectedDocumentType = documentTypes?.filter((item) => item?.document_key == value)
			setSelectedDocumentType(userSelectedDocumentType?.[0])
			setFormData({ ...formData, [name]: value })
		} else if (name == 'make') {
			let modelData = vehicleDetailsList?.make?.filter((v) => v.name == value)
			let modelList = modelData[0].mst_model
			let makeData = vehicleMakeList?.filter((v) => v.name == value)
			// setvehicleModelList(modelData[0]?.mst_model)
			let check = modelList?.some((value) => value?.name == 'Others')
			if (!check) {
				modelList?.unshift({
					'id': 'Others',
					'make_id': 'Others',
					'name': 'Others'
				})
			}
			setvehicleModelList(modelList)
			//   setVehicleMakeID(value)
			vehicleList[i][name] = value
			vehicleList[i]['vehicleList'] = modelList
			// setVehicleList([...vehicleList])
			vehicleList[i]['make_id'] = makeData?.[0]?.id != 'Others' ? makeData?.[0]?.id : 0
			vehicleList[i][name] = value
			vehicleList[i]['maketext'] = value == 'Others' ? '' : value
			vehicleList[i]['othermake'] = (value == 'Others') ? true : false
			vehicleList[i]['othermodel'] = (value == 'Others') ? true : false
			vehicleList[i]['vehicleList'] = modelData[0].mst_model.length > 0 ? modelData[0].mst_model : vehicleModelList

			//vehicleList[i]['vehicleList'] = vehicleModelList
			setVehicleListArray(vehicleList)
			// setFormData({ ...payloadData })

		}
		else {
			setFormData({ ...formData, [name]: value })
		}
	}


	const handlePermitTypechange = (selPermit, name , value) =>{

		setPermitPrices(selPermit?.[0])
			const updatedData = { ...formData, [name]: value, 'permit_rate_id': selPermit?.[0]?.permit_rate?.[0]?.id, 'permit_type_name': selPermit?.[0]?.name ? selPermit?.[0]?.name : '' }
			setFormData(updatedData)
			setDocumentTypes([])
			delete formData.document_type
			setIdProof([])
			localStorage.setItem('permits', JSON.stringify(selPermit?.[0]))
			retrieveDocumentsType(value, updatedData)
	}

	// const handleVehicleChange = (e) => {
	// 	const { value } = e.target
	// 	if (value != '') {
	// 		const selectedVehicle = vehicleList?.filter((vehicle) => vehicle?.license_plate_number == value)
	// 		const payloadData = { ...formData }
	// 		payloadData.vehicleList[0]['license_plate'] = selectedVehicle[0]?.license_plate_number
	// 		payloadData.vehicleList[0]['make'] = selectedVehicle[0]?.make
	// 		payloadData.vehicleList[0]['model'] = selectedVehicle[0]?.model
	// 		payloadData.vehicleList[0]['color'] = selectedVehicle[0]?.color
	// 		payloadData.vehicleList[0]['state_id'] = selectedVehicle[0]?.state_id
	// 		payloadData['vehicleSelected'] = true
	// 		setFormData({ ...payloadData })
	// 	} else {
	// 		const payloadData = { ...formData }
	// 		payloadData.vehicleList[0]['license_plate'] = ''
	// 		payloadData.vehicleList[0]['make'] = ''
	// 		payloadData.vehicleList[0]['model'] = ''
	// 		payloadData.vehicleList[0]['color'] = ''
	// 		payloadData.vehicleList[0]['state_id'] = ''
	// 		payloadData['vehicleSelected'] = false

	// 		setFormData({ ...payloadData })
	// 	}

	// }

	// const showVehicleTable = () => {
	// 	// const showVehicleTable = (e, key) => {
	// 	setRemoveButtonShowKey()
	// 	const payloadData = { ...formData }
	// 	if (isVehicleTableVisible && payloadData?.vehicleList?.length < 5) {
	// 		payloadData?.vehicleList.push({ 'license_plate': '', 'make': '', 'model': '', 'color': '', 'state_id': '' , 'othermake' : false , 'othermodel' : false , 'vehicleList' :  [] })
	// 		setFormData({ ...payloadData })
	// 		// payloadData['vehicleSelected'] = false
	// 	}
	// 	setIsVehicleTableVisible(true)
	// }

	// const showExistingVehicle = () => {
	// 	setIsVehicleTableVisible(false)
	// 	const payloadData = { ...formData }
	// 	payloadData.vehicleList[0]['license_plate'] = ''
	// 	payloadData.vehicleList[0]['make'] = ''
	// 	payloadData.vehicleList[0]['model'] = ''
	// 	payloadData.vehicleList[0]['color'] = ''
	// 	payloadData['vehicleSelected'] = true
	// 	setFormData({ ...payloadData })
	// 	setError('')
	// }

	const addVehicle = () => {
		// const payloadData = { ...formData }
		// // checking the every object item is empty or not 
		// const testToSeeAllErrorsAreEmpty = payloadData?.vehicleList.every((item) => item?.license_plate !== '')
		// if (payloadData?.vehicleList.length <= 5 && testToSeeAllErrorsAreEmpty === true) {
		// 	payloadData?.vehicleList.push({ 'license_plate': '', 'make': '', 'model': '', 'color': '', 'state_id': '', 'othermake': false, 'othermodel': false, 'vehicleList': [] })
		// 	setFormData({ ...payloadData })
		// 	setError('')
		// } else if (testToSeeAllErrorsAreEmpty === false) {
		// 	setError('Please enter the License plate, before adding a new vehicle!')
		// }
		const vehicleList = vehicleListArray.slice()
		// checking the every object item is empty or not 
		const testToSeeAllErrorsAreEmpty = vehicleList.every((item) => item?.license_plate !== '')
		if (vehicleList.length <= 5 && testToSeeAllErrorsAreEmpty === true) {
			vehicleList.push({ 'license_plate': '', 'make': '', 'model': '', 'color': '', 'state_id': '', 'othermake': false, 'othermodel': false, 'vehicleList': [] })
			// setFormData({ ...payloadData })
			setVehicleListArray(vehicleList)
			setError('')
		} else if (testToSeeAllErrorsAreEmpty === false) {
			setError('Please enter the License plate, before adding a new vehicle!')
		}
	}

	const removeVehicleMake = (e, i) => {
		// const payloadData = { ...formData }
		// payloadData?.vehicleList?.splice(i, 1)
		// if (payloadData?.vehicleList?.length === 0) {
		// 	setIsVehicleTableVisible(false)
		// }
		// setFormData({ ...payloadData })
		const vehicleList = vehicleListArray.slice()
		let findVeh = vehicleCountList?.some(val => val?.id == vehicleList[i]?.id)

		if (findVeh) {
			setvehicleCount(prev => prev - 1)
		}
		vehicleList?.splice(i, 1)
		if (vehicleList?.length === 0) {
			// setIsVehicleTableVisible(false)
		}
		setVehicleListArray(vehicleList)
	}


	const handleDeleteDocument = (item) => {
		const filteredDocuments = idProof?.filter((idProofItem) => idProofItem?.type != item?.type)
		setIdProof([...filteredDocuments])
	}

	const retrieveDocumentsType = (facilityId = '', updatedData) => {
		setFetchingDocumentTypes(true)
		delete updatedData.document_type
		dispatch(getDocumentTypes(facilityId)).then((res) => {
			setFetchingDocumentTypes(false)
			if (res && res?.length > 0) {
				setSelectedDocumentType(res?.[0])
				const documentAddedFormData = { ...updatedData, ['document_type']: res?.[0]?.document_key }
				setFormData(documentAddedFormData)

				setDocumentTypes(res)
			} else {
				setDocumentTypes([])
				setSelectedDocumentType(null)
			}
		}).catch(() => {
			setFetchingDocumentTypes(false)
			setDocumentTypes([])
			setSelectedDocumentType(null)
		})
	}




	return (
		<div>
			<h4 className="text-center my-4 fw-bolder">Basic Information</h4>
			<div className="row">
				<div className="col-sm-6">
					<div className="mb-3 position-relative">
						<label className="form-label">Select Parking Location <span className='text-danger'> *</span></label>
						<select
							name="facility_id"
							id="facility_id"
							className="form-select"
							onChange={handleInputFieldsData}
							disabled={searchParams?.get('facility')}
							>
							{/* <option value={faclityList[0]?.id} selected={formData?.facility_id == faclityList[0]?.id}>{faclityList[0]?.full_name}</option> */}
							<option value='0'>Select Parking Location</option>
							{faclityList?.map((faclityListItem, faclityListIndex) => {
								return (
									<option value={faclityListItem?.id} selected={facilityId == faclityListItem?.id} key={faclityListIndex}>{faclityListItem?.full_name}</option>
								)
							})}
						</select>
						<span className='position-absolute end-0 top-100'>
							<span className="form-text text-danger h-40">{permitRateError ? permitRateError : ''}
							</span></span>
						<span className="form-text text-danger h-40">{formError == 'home' && !facilityId ? 'This field is required' : ''}</span>
					</div>


				</div>

				<div className="col-sm-6">
					<div className="mb-3 position-relative">
						<label className="form-label">Select Permit Type<span className='text-danger'> *</span></label>
						<select
							name="permit_id"
							id="permit_id"
							className="form-select"
							onChange={handleInputFieldsData}
							disabled={searchParams?.get('permitType')}
							>
							{/* <option value={faclityList[0]?.id} selected={formData?.permit_id == faclityList[0]?.id}>{faclityList[0]?.full_name}</option> */}
							<option value=''>Select Permit Type</option>
							{permitTypes && permitTypes?.map((permit, permitIndex) => {
								return (
									<option value={permit?.id} selected={formData?.permit_id == permit?.id} key={permitIndex}>{permit?.name}</option>
								)
							})}
						</select>
						<span className='position-absolute end-0 top-100'>
							<span className="form-text text-danger h-40">{permitRateError ? permitRateError : ''}
							</span></span>
						<span className="form-text text-danger h-40">{formError == 'home' && !formData?.permit_id ? 'This field is required' : ''}</span>
					</div>


				</div>

				<div className="col-sm-4 d-none">
					<div className="mb-3 position-relative">
						<label className="form-label">Driver License Number</label>
						<input
							className="form-control ng-untouched ng-pristine ng-invalid"
							name="license_number"
							id="license_number"
							maxLength={13}
							value={formData?.license_number}
							minLength={1}
							placeholder="License Number"
							required=""
							onKeyDown={restrictSpecialChar}
							disabled={(!loginData?.license_number) ? false : true}
							type="text"
							onBlur={() => retrievePermitData(formData?.license_number, facilityId)}
							onChange={(e) => handleLicenceNumberChange(e, 'change', facilityId)}
						/>
						{formData?.license_number ? <span className='position-absolute end-0 top-100'>
							{resident == 'checking...' ? <span><Circles
								height="15"
								width="15"
								color="var(--bs-primary)"
								ariaLabel="circles-loading"
								visible={true}
							/></span> : <span className={resident == 'Invalid License Number' ? 'form-text text-danger h-40' : 'form-text mainColor h-40'}><b>{resident}</b></span>}
							{/* <span className="form-text text-danger h-40">{formError == 'home' && !formData?.license_number ? 'This field is required' : (formError == 'home' && formData?.license_number?.length < 7) ? 'Please enter a valid license number' : ''}</span> */}
						</span> : null}
						{/* <span className="form-text text-danger h-40">{formError == 'home' ? !formData?.license_number ? 'This field is required' : !validLicenceNumber.test(formData?.license_number?.trim()) ? 'Please enter a valid license number' : '' : ''}</span> */}
						{/* <span className="form-text text-danger h-40">{formError == 'home' && !formData?.license_number ? 'This field is required' : ''}</span> */}

					</div>
				</div>
			</div>
			{fetchingDocumentTypes ? <div className={'p-3 m-3 d-flex justify-content-center align-item-center'}>
				<Circles
					height={'40'}
					width={'40'}
					color="var(--bs-primary)"
					ariaLabel="circles-loading"
					wrapperStyle={{}}
					wrapperClass=""
					visible={true}
				/>
			</div> :
				documentTypes && documentTypes.length > 0 ? <div className={'clearfix border bg-light p-2 mb-3'} >

					<div className="block d-flex justify-content-between ">
						<label className="form-label">Upload Documents <span className="text-danger">*</span>
							<span className="small fw-normal d-block">(Please select one document at a time. <b>Each document listed must be uploaded.</b>)</span>
						</label>
					</div>
					<div className="row">
						<div className="col-md-4">
							<ul className=" nav ul-nav">
								{documentTypes?.map((item, index) => {
									return <li key={index}
										className="d-flex justify-content-between align-items-center">
										<label className="fw-normal">
											<input
												className="me-1"
												value={item?.document_key}
												checked={formData?.document_type == item?.document_key}
												name="document_type"
												disabled={isValuePresentInArray(idProof, item?.document_key, 'type')}
												id="document_type"
												onChange={handleInputFieldsData}
												type="radio" /> {item?.document_name} <span className="text-danger">*</span>
										</label>
										{isValuePresentInArray(idProof, item?.document_key, 'type') ? <i className="fa fa-check-circle text-success"></i> : <i className="fa fa-times-circle text-danger"></i>}
									</li>
								})}
							</ul>
						</div>
						<div className="col-md-4 p-2 offset-sm-1">
							<FileUpload
								accept={getAcceptedDocument(selectedDocumentType?.document_type)}
								onChange={(event) => fileChangedHandler(event, formData?.document_type, setIdProof, idProof, 900, 600, notify, selectedDocumentType?.file_size_limit)}
								name={formData?.document_type}
								id={formData?.document_type}
								idProof={idProof}
								formData={formData}
								required
								disabled={documentTypes.length==idProof.length ? true : false}
								// disabled={formData?.document_type && formData?.document_type != '' ? false : 'disabled'}
								// disabled={formData?.document_type && formData?.document_type != '' ? false : 'disabled'}
								

							/>

							<small style={{
								'font-size': '11px',
								'font-weight': 'bold',
								'color': '#777'
							}}>

								{selectedDocumentType?.document_type ? `File type : (${getAcceptedFiles(selectedDocumentType?.document_type)})` : null} <br />
								{selectedDocumentType?.file_size_limit ? ` File Size Limit : ${selectedDocumentType?.file_size_limit}MB` : null}

							</small>
						</div>
					</div>
					<span className="form-text text-danger h-40">{formError == 'home' && idProof?.length != documentTypes?.length ? 'Please upload all the required document' : ''}</span>

					{idProof && idProof?.length > 0 ? <div className='small mt-3'>
						<label className="form-label d-block mt-1 mb-0">Selected Documents</label>
						{idProof?.map((item, index) => {
							return (
								<span className="border rounded border-primary ps-1 pe-1 bg-secondary text-white me-1" key={index}>{formatKeyInDocumentName(item?.type)}
									<i className='cursor-pointer ps-2 pe-1 fa fa-close fa-sm' onClick={() => handleDeleteDocument(item)} />
								</span>
							)
						})}

					</div> : null}

				</div> : null
			}
			<hr />

			<div className="clearfix">
				<div className='d-flex justify-content-between mb-1 mt-1'>
					<label className="form-label">Vehicle Information <span className='text-danger'> *</span> <span className='ps-2 fw-normal'>Vehicles allowed per permit (5 max)</span></label>
					{/* {(isVehicleTableVisible && !isLoggedIn()) ? <button type='button' className='btn btn-primary w-20' disabled={formData?.vehicleList?.length > (4 - vehicleList?.length)} onClick={addVehicle}><i className='fa fa-plus'></i></button> : null} */}
					{<button type='button' className='btn btn-primary w-20' disabled={vehicleListArray?.length >= 5} onClick={addVehicle}><i className='fa fa-plus'></i>Add Vehicle</button>}
				</div>

				{/* {isLoggedIn() &&
					<div className='row '>
						{(vehicleList?.length > 0) && <div className="col-sm-6">
							<div className="mb-3">
								<select name="vehicle" id="vehicle" className="form-select" onChange={handleVehicleChange} >
									<option value=''>Select Vehicle</option>
									{vehicleList?.map((vehicle, index) => {
										return (
											<option value={vehicle?.license_plate_number} selected={vehicle?.license_plate_number == formData?.vehicleList[0]?.license_plate} key={index}>{vehicle?.license_plate_number}</option>
										)
									})}
								</select>
								<span className="form-text text-danger h-40">{formError == 'home' && !formData?.vehicleList?.[0]?.license_plate ? 'Please select or add new vehicle' : ''}</span>
							</div>
						</div>}
						<div className='col-sm-6 mb-3'>
							<button type="button" className='btn btn-primary'
								disabled={formData?.vehicleList?.length > 4}
								onClick={() => showVehicleTable()}>
								<i className='fa fa-plus'></i> Add New vehicle
							</button> */}
				{/* {(isVehicleTableVisible && vehicleList?.length > 0) && 
								<button type="button" className='btn btn-danger' onClick={() => showExistingVehicle()}>
									Select Existing vehicle
								</button>} */}
				{/* </div>





					</div>
					} */}
				{vehicleListArray?.map((vehicleListItem, vehicleListIndex) => {
					if (vehicleCount > vehicleListIndex) {
						return (
							<div className='bg-light border p-2 mb-2 position-relative' key={vehicleListIndex}>
								<div className='row g-2 row-cols-2  row-cols-sm-3 row-cols-md-6' >

									<div className={'col'}>
										<label className='form-label' >Licence Plate <span className="text-danger">*</span></label>
										<input
											type="text"
											name='license_plate'
											className="form-control"
											placeholder="-"
											maxLength={10}
											minLength={2}
											onKeyDown={restrictSpecialChar}
											disabled={true}
											value={vehicleListItem?.license_plate}
											onChange={(e) => handleInputFieldsData(e, vehicleListIndex)}
											aria-describedby="licenseplate" />
										{/* <span className="form-text text-danger h-40">{formError == 'home' && !formData?.vehicleList?.[vehicleListIndex]?.license_plate != '' ? 'This field is required' : ''}</span> */}
										<span className="form-text text-danger h-40">{formError == 'home' && !vehicleListArray?.[vehicleListIndex]?.license_plate != '' ? 'This field is required' : ''}</span>

									</div>
									{<div className={'col'}>
										<label className='form-label' >Make </label>
										<input
											type="text"
											name='maketext'
											label="Make"
											className="form-control"
											placeholder="-"
											required={true}
											disabled={true}
											onChange={(e) => handleInputFieldsData(e, vehicleListIndex)}
											maxLength={15}
											minLength={0}
											onKeyDown={restrictSpecialChar}
											value={vehicleListItem?.maketext}
											aria-describedby="color" />



										{/* <span className="form-text text-danger h-40">{formError == 'home' && !formData?.vehicleList?.[vehicleListIndex]?.make != '' ? 'This field is required' : ''}</span> */}
									</div>}
									{<div className={'col'}>
										<label className='form-label' >Model</label>
										<input
											type="text"
											name='modeltext'
											className="form-control"
											placeholder="-"
											onChange={(e) => handleInputFieldsData(e, vehicleListIndex)}
											maxLength={15}
											minLength={4}
											disabled={true}
											onKeyDown={restrictSpecialChar}
											value={vehicleListItem?.modeltext}
											aria-describedby="model" />
										{/* <span className="form-text text-danger h-40">{formError == 'home' && !formData?.vehicleList?.[vehicleListIndex]?.make != '' ? 'This field is required' : ''}</span> */}
									</div>}
									{<div className={'col'}>
										<label className='form-label' >Color</label>
										<input
											type="text"
											name='color'
											className="form-control"
											placeholder="-"
											onChange={(e) => handleInputFieldsData(e, vehicleListIndex)}
											maxLength={15}
											minLength={4}
											disabled={true}
											onKeyDown={restrictSpecialChar}
											value={vehicleListItem?.color}
											aria-describedby="color" />
										{/* <span className="form-text text-danger h-40">{formError == 'home' && !formData?.vehicleList?.[vehicleListIndex]?.make != '' ? 'This field is required' : ''}</span> */}
									</div>}
									{<div className={'col'}>
										<label className='form-label' >State/Province</label>
										<input
											type="text"
											name='state_id'
											className="form-control"
											placeholder="-"
											onChange={(e) => handleInputFieldsData(e, vehicleListIndex)}
											maxLength={15}
											minLength={4}
											disabled={true}
											onKeyDown={restrictSpecialChar}
											value={vehicleListItem?.state_name}
											aria-describedby="color" />
										{/* <span className="form-text text-danger h-40">{formError == 'home' && !formData?.vehicleList?.[vehicleListIndex]?.make != '' ? 'This field is required' : ''}</span> */}
									</div>}

								</div>
								{(vehicleListArray?.length === vehicleListIndex) ?
									<div className='position-absolute top-0 right-0' >
										{/* <button className='btn btn-primary w-100' disabled={formData?.vehicleList?.length > 2} onClick={addVehicle}><i className='fa fa-plus'></i></button> */}
										{/* {(showRemoveButton) ? <button type="button" className="btn btn-danger btn-sm" onClick={(e) => removeVehicleMake(e, vehicleListIndex)}><i className='fa fa-minus'></i></button> : null} */}
										{!(vehicleCount > vehicleListIndex) ? <a type="button" className=" small p-1 border-none text-danger text-white" onClick={(e) => removeVehicleMake(e, vehicleListIndex)}><i className='fa fa-trash'></i></a> : null}
									</div> : (!(vehicleListArray?.length == 1) && vehicleListIndex !== -1 && !(vehicleCount > vehicleListIndex)) ?

										<div className='position-absolute top-0 right-0' ><a type="button" className=" small p-1 border-none text-danger text-white" onClick={(e) => removeVehicleMake(e, vehicleListIndex)}><i className='fa fa-trash'></i></a>

										</div> : null}
							</div>
						)
					} else {
						return (
							<div className='bg-light border p-2 mb-2 position-relative' key={vehicleListIndex}>
								<div className='row g-2 row-cols-2  row-cols-sm-3 row-cols-md-6' >

									<div className={'col'}>
										<label className='form-label' >Licence Plate <span className="text-danger">*</span></label>
										<input
											type="text"
											name='license_plate'
											className="form-control"
											placeholder="License Plate"
											maxLength={10}
											minLength={2}
											onKeyDown={restrictSpecialChar}
											disabled={vehicleCount > vehicleListIndex}
											value={vehicleListItem?.license_plate}
											onChange={(e) => handleInputFieldsData(e, vehicleListIndex)}
											aria-describedby="licenseplate" />
										{/* <span className="form-text text-danger h-40">{formError == 'home' && !formData?.vehicleList?.[vehicleListIndex]?.license_plate != '' ? 'This field is required' : ''}</span> */}
										<span className="form-text text-danger">{formError == 'home' && !vehicleListArray?.[vehicleListIndex]?.license_plate != '' ? 'This field is required' : (vehicleListArray?.[vehicleListIndex]?.license_plate != '' && vehicleListArray?.[vehicleListIndex]?.license_plate.length < 2) ? 'Minimum 2 characters allowed' : ''}</span>
									</div>
									<div className={'col'}>
										<label className='form-label' > Vehicle Make </label>
										<select autoComplete="off" name="make" placeholder="Make" disabled={vehicleCount > vehicleListIndex} onKeyDown={restrictSpecialChar} className="form-select form-control" value={vehicleListItem?.make} id="make" onChange={(e) => handleInputFieldsData(e, vehicleListIndex)}>
											<option value="">Select Make</option>
											{(vehicleMakeList?.length > 0) && vehicleMakeList?.map((val, index) => {
												return (
													<option value={val?.make} key={index} >{val?.name}</option>
												)
											})}
										</select>

										{/* <span className="form-text text-danger h-40">{formError == 'home' && !formData?.vehicleList?.[vehicleListIndex]?.make != '' ? 'This field is required' : ''}</span> */}
									</div>
									{vehicleListItem?.othermake ? <div className={'col'}>
										<label className='form-label' >Make </label>
										<input
											type="text"
											name='maketext'
											label="Make"
											className="form-control"
											placeholder="Make"
											required={true}
											disabled={vehicleCount > vehicleListIndex}
											onChange={(e) => handleInputFieldsData(e, vehicleListIndex)}
											maxLength={15}
											minLength={0}
											onKeyDown={restrictSpecialChar}
											value={vehicleListItem?.maketext}
											aria-describedby="color" />



										{/* <span className="form-text text-danger h-40">{formError == 'home' && !formData?.vehicleList?.[vehicleListIndex]?.make != '' ? 'This field is required' : ''}</span> */}
									</div> : ''}



									{!vehicleListItem?.othermake ? <div className={'col'}>
										<label className='form-label' >Vehicle Model</label>
										<select className='form-select form-control' value={vehicleListItem?.model} placeholder="Model" name="model" id='state' onKeyDown={restrictSpecialChar} onChange={(e) => handleInputFieldsData(e, vehicleListIndex)} disabled={vehicleCount > vehicleListIndex ? true : vehicleListItem?.make ? false : true}>
											<option value="0">Select an option</option>
											{(vehicleListItem?.vehicleList?.length > 0) && vehicleListItem?.vehicleList?.map((item, itemIndex) => <option key={(itemIndex + 1)} value={item?.name}>{item?.name}</option>)}
										</select>
									</div> : ''}

									{(vehicleListItem?.othermake || vehicleListItem?.othermodel) ? <div className={'col'}>
										<label className='form-label' >Model</label>
										<input
											type="text"
											name='modeltext'
											className="form-control"
											placeholder="Model"
											onChange={(e) => handleInputFieldsData(e, vehicleListIndex)}
											maxLength={15}
											minLength={4}
											disabled={vehicleCount > vehicleListIndex}
											onKeyDown={restrictSpecialChar}
											value={vehicleListItem?.modeltext}
											aria-describedby="model" />
										{/* <span className="form-text text-danger h-40">{formError == 'home' && !formData?.vehicleList?.[vehicleListIndex]?.make != '' ? 'This field is required' : ''}</span> */}
									</div> : ''}

									<div className={'col'}>
										<label className='form-label' >Color </label>
										<select name='color' autoComplete="off" className='form-select form-control' value={vehicleListItem?.color} disabled={vehicleCount > vehicleListIndex} placeholder="Color" id='color' onChange={(e) => handleInputFieldsData(e, vehicleListIndex)} onKeyDown={restrictSpecialChar}>
											<option value="0">Select an option</option>
											{(vehicleDetailsList?.color?.length > 0) && vehicleDetailsList?.color?.map((item, itemIndex) => <option key={(itemIndex + 1)} value={item?.name}>{item?.name}</option>)}
										</select>
									</div>
									<div className={'col'}>
										<label className='form-label' >State/Province</label>
										<select className='form-select form-control' value={vehicleListItem?.state_id} disabled={vehicleCount > vehicleListIndex} placeholder="Model" name="state_id" id='state_id' onKeyDown={restrictSpecialChar} onChange={(e) => handleInputFieldsData(e, vehicleListIndex)}>
											<option value="0">Select an option</option>
											{(vehicleDetailsList?.state?.length > 0) && vehicleDetailsList?.state?.map((item, itemIndex) => <option key={(itemIndex + 1)} value={item?.id}>{item?.state_code}</option>)}
										</select>
									</div>

								</div>
								{(vehicleListArray?.length === vehicleListIndex) ?
									<div className='position-absolute top-0 right-0' >
										{/* <button className='btn btn-primary w-100' disabled={formData?.vehicleList?.length > 2} onClick={addVehicle}><i className='fa fa-plus'></i></button> */}
										{/* {(showRemoveButton) ? <button type="button" className="btn btn-danger btn-sm" onClick={(e) => removeVehicleMake(e, vehicleListIndex)}><i className='fa fa-minus'></i></button> : null} */}
										{!(vehicleCount > vehicleListIndex) ? <a type="button" className=" small p-1 border-none text-danger text-white" onClick={(e) => removeVehicleMake(e, vehicleListIndex)}><i className='fa fa-trash'></i></a> : null}
									</div> : (!(vehicleListArray?.length == 1) && vehicleListIndex !== -1 && !(vehicleCount > vehicleListIndex)) ?

										<div className='position-absolute top-0 right-0' ><a type="button" className=" small p-1 border-none text-danger text-white" onClick={(e) => removeVehicleMake(e, vehicleListIndex)}><i className='fa fa-trash'></i></a>

										</div> : null}
							</div>
						)
					}
				})}

				{/* <p className='text-danger'>{vehicleCount > 0 ? 'To remove an existing vehicle please navigate to your account and delete the required vehicle!' : ''}</p> */}
				<p className='text-danger'>{error}</p>
			</div>

			{/* Hide the facility details for diamond */}
			{selectedFaclity?.full_name &&
				<div className="bg-white border p-3  mt-1 mb-3 rate-block">

					<div className="row align-items-center ">
						<div className='col-12 col-sm mb-sm-0 mb-3'>
							<h4 className="text-primary">{selectedFaclity?.full_name}</h4>
							<p className="m-0"><b>Entrance Address: </b>{`${selectedFaclity?.geolocations?.address_1}${selectedFaclity?.geolocations?.address_2 ? `, ${selectedFaclity?.geolocations?.address_2}` : ''}${selectedFaclity?.geolocations?.city ? `, ${selectedFaclity?.geolocations?.city}` : ''}${selectedFaclity?.geolocations?.state ? `, ${selectedFaclity?.geolocations?.state}` : ''}${selectedFaclity?.geolocations?.zip_code ? `, ${selectedFaclity?.geolocations?.zip_code}` : ''}`}</p>
							{permitPrices?.permit_rate?.[0]?.permit_rate_criteria_mapping.length > 0 ? perkingParkingTime(permitPrices?.permit_rate ?.[0] , true) : null}
							{/* {permitPrices?.permit_rate?.length > 0 && permitPrices?.permit_rate?.[0]?.entry_time_begin ? <p className='small m-0'><b>Entry Timings:</b> <span>{`${moment(permitPrices?.permit_rate?.[0]?.entry_time_begin, 'hh:mm:ss').format('hh:mm A')} - ${moment(permitPrices?.permit_rate?.[0]?.entry_time_end, 'hh:mm:ss').format('hh:mm A')}`}</span></p> : null}
							{permitPrices?.permit_rate?.length > 0 && permitPrices?.permit_rate?.[0]?.exit_time_begin ? <p className='small m-0'><b>Exit Timings:</b> <span>{`${moment(permitPrices?.permit_rate?.[0]?.entry_time_begin, 'hh:mm:ss').format('hh:mm A')} - ${moment(permitPrices?.permit_rate?.[0]?.entry_time_end, 'hh:mm:ss').format('hh:mm A')}`}</span></p> : null}
							{permitPrices?.permit_rate?.length > 0 && permitPrices?.permit_rate?.[0]?.sunday ? <p className='small m-0'><b>Entry Allowed on:</b> <span>{getFacilityOpenDays(permitPrices?.permit_rate?.[0])}</span></p> : null} */}
							{partnerDetails?.attendent_type == 'business_account' ? <p className="m-0"><span className='fw-bold'>Business:</span> {partnerDetails?.name}</p> : null}
						</div>
						{loader ? null : selectedFaclity?.is_permit_purchase_enabled == '1' ? permitPrices?.permit_rate?.length > 0 ?
							<div className='col-sm-4 col-12'>

								{/* {permitPrices?.permit_rate?.map((permitPricesItem, permitPricesIndex) => { */}
								{/* // return ( */}
								{Number(permitPrices?.permit_rate?.[0]?.rate) > 0 ? <div className="text-success p-2">
									<input type="radio" className="btn-check" name="permit_rate" id={'btnradio1'} autoComplete="off" checked />

									{permitPrices?.permit_rate?.[0] ? <label className=" position-relative  text-end d-flex gap-3 align-items-center " htmlFor={'btnradio1'}>
										
									<p className="mb-0 text-primary">{permitPrices?.permit_rate?.[0]?.name} </p><h2 className="fw-bold mb-0 mainColor">

											{/* If business URL then Display the Rate as per Capping. */}
											{partnerDetails?.attendent_type == 'business_account' && permitPrices?.permit_rate?.[0]?.user_permit_type_mapping?.[0]?.permit_negotiable_price ? `$${permitPrices?.permit_rate?.[0]?.user_permit_type_mapping?.[0]?.permit_negotiable_price}` : 
											permitPrices?.permit_rate?.[0]?.rate ? `$${permitPrices?.permit_rate?.[0]?.rate}` : ''}
										</h2>
										
										{/* <p className="mb-0 mainColor">{staticStrings?.rates24}</p> */}
										{/* <h3 className="fw-bold mb-0 mainColor">{staticStrings?.rates24}</h3> */}

									</label> : 'N/A'}

								</div> :
									<div className="text-success p-2">
										<h3 className="fw-bold mb-0 mainColor">{staticStrings?.rates24}</h3>
									</div>}
								{/* // ) */}
								{/* // })} */}

							</div> : formData?.license_number &&
							<div className='col-sm-4 col-12'>

								<div className="text-success p-2">
									<h3 className="fw-bold mb-0 mainColor">{staticStrings?.rates24}</h3>
								</div>
							</div>
							: null}

					</div>
				</div>
			}


		</div>
	)
}

Home.propTypes = {
	dispatch: PropTypes.func,
	data: PropTypes.object,
	events: PropTypes.object,
	loader: PropTypes.bool,
	getCard: PropTypes.func,
	passData: PropTypes.array,
	setPage: PropTypes.func,
	setIdProof: PropTypes.func,
	front: PropTypes.string,
	back: PropTypes.string,
	idProof: PropTypes.array,
	formData: PropTypes.object,
	setFormData: PropTypes.func,
	formError: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.bool
	]),
	selectedFaclity: PropTypes.object,
	setSelectedFacilty: PropTypes.func,
	setDocumentTypes: PropTypes.func,
	documentTypes: PropTypes.object,
	partnerDetails: PropTypes.object,
	notify: PropTypes.func,
	vehicleListArray: PropTypes.array,
	setVehicleListArray: PropTypes.array,
	setFacilityId: PropTypes.array,
	facilityId: PropTypes.array,
	setvehicleCount: PropTypes.number,
	vehicleCount: PropTypes.number,
	VehiclePrev: PropTypes.bool,
	vehicleCountList: PropTypes.array,
	setvehicleCountList: PropTypes.array

}

Home.defaulProps = {
	dispatch: noop,
	data: EMPTY_OBJECT,
	events: EMPTY_OBJECT,
	loader: PropTypes.bool,
	getCard: PropTypes.func,
	passData: EMPTY_ARRAY,
	setPage: noop,
	setIdProof: noop,
	front: undefined,
	back: undefined,
	idProof: EMPTY_ARRAY,
	formData: EMPTY_OBJECT,
	setFormData: noop,
	formError: undefined,
	selectedFaclity: EMPTY_OBJECT,
	setSelectedFacilty: noop,
	setDocumentTypes: noop,
	documentTypes: EMPTY_OBJECT,
	partnerDetails: EMPTY_OBJECT,
	notify: noop,
	vehicleListArray: EMPTY_ARRAY,
	setVehicleListArray: noop,
	setFacilityId: noop,
	facilityId: noop,
	setvehicleCount: noop,
	vehicleCount: PropTypes.number,
	VehiclePrev: PropTypes.bool,
	vehicleCountList: EMPTY_ARRAY,
	setvehicleCountList: noop

}

function mapStateToProps({ basicInfoReducer }) {
	return {
		loader: basicInfoReducer?.loader,
	}
}
export default connect(mapStateToProps)(Home)