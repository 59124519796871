import * as actionTypes from '../config/ActionTypes'
import { updateObject } from '../utility'

const initialState = {
  isFetching: false,
  hasError: false,
  passes:[],
  userPasses:[],
  paymentRes:{},
  getGaragePriceRes:{},
  getValidations:{},
  errorMessage:''
}

const PassesReducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.GET_PASSES_REQUEST:
      return updateObject(state, {isFetching:true})
    case actionTypes.GET_PASSES_REQUEST_SUCCESS:
      return updateObject(state, {passes:action.data, isFetching:false})
    case actionTypes.GET_PASSES_REQUEST_FAIL:
      return updateObject(state, {isFetching:false})
    case actionTypes.GET_USER_PASSES_REQUEST:
      return updateObject(state, {isFetching:true})
    case actionTypes.GET_USER_PASSES_REQUEST_SUCCESS:
      return updateObject(state, {userPasses:action.data, isFetching:false})
    case actionTypes.GET_USER_PASSES_REQUEST_FAIL:
      return updateObject(state, {isFetching:false})
    case actionTypes.GET_PAYMENT_REQUEST:
      return updateObject(state, {isFetching:true})
    case actionTypes.GET_PAYMENT_REQUEST_SUCCESS:
      return updateObject(state, {paymentRes:action.data, hasError:false, isFetching:false})
    case actionTypes.GET_PAYMENT_REQUEST_FAIL:
      return updateObject(state, {paymentRes:action.data, hasError:true, errorMessage:action.errorMessage, isFetching:false})
    case actionTypes.GET_FACILITY_PRICE_REQUEST:
      return updateObject(state, {isFetching:true})
    case actionTypes.GET_FACILITY_PRICE_REQUEST_SUCCESS:
      return updateObject(state, {getGaragePriceRes:action.data, hasError:false, isFetching:false})
    case actionTypes.GET_FACILITY_PRICE_REQUEST_FAIL:
      return updateObject(state, {getGaragePriceRes:action.data, hasError:true, errorMessage:action.errorMessage, isFetching:false})
    case actionTypes.CHECK_VALIDATION_REQUEST:
      return updateObject(state, {isFetching:true})
    case actionTypes.CHECK_VALIDATION_SUCCESS:
      return updateObject(state, {getValidations:action.data, hasError:false, isFetching:false})
    case actionTypes.CHECK_VALIDATION_FAILURE:
      return updateObject(state, {getValidations:action.data, hasError:true, errorMessage:action.errorMessage, isFetching:false})
    default:
    return state
  }
}

export default PassesReducer