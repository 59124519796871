import axios from 'axios'
import { getConfigDetails } from './Config'
const instance = axios.create({
    baseURL: getConfigDetails().instance_baseURL,

})

export const config = {
    baseURL: getConfigDetails().config_baseURL,

}

export default instance