import CONSTANTS from '../constants'
import { EMPTY_ARRAY } from '../../constants/globalConstants'

const INITIAL_STATE = {
    loader: false,
    hasError: false,
    tableData: EMPTY_ARRAY,
    errorMessage: '',
    current_page: 1,
    per_page: 20,
    total: 20,
}

const bookingHistoryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        //RESPONSE HANDLING
        case CONSTANTS.GET_BOOKING_HISTORY_REQUEST:
            return {
                ...state,
                loader: true,
                errorMessage: ''
            }
        case CONSTANTS.GET_BOOKING_HISTORY_SUCCESS:
            return {
                ...state,
                loader: false,
                tableData: action.data,
                hasError: false,
                current_page: action.data.current_page,
				per_page: action.data.per_page,
				total: action.data.total,
                errorMessage: ''
            }
        case CONSTANTS.GET_BOOKING_HISTORY_FAILURE:
            return {
                ...state,
                loader: false,
                errorMessage: 'There is some Error'
            }
        default:
            return state
    }
}

export default bookingHistoryReducer
