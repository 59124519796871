import CONSTANTS from '../constants'
import { downloadFile, postRequest } from '../../services'
import { APICONFIG } from '../config/apiConfig'

// Calls the API to fetch Ticket Details
export const getTicketList = (payload) => (dispatch) => {
    dispatch({ type: CONSTANTS.GET_TICKET_LIST_REQUEST })
    return postRequest(APICONFIG.getUserDetailUrl, payload).then((res) => {
        dispatch({ type: CONSTANTS.GET_TICKET_LIST_SUCCESS, data: res })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.GET_TICKET_LIST_FAILURE, errorMessage: err?.errors?.message })
        return err
    })
}

export const downloadTicket = (payload, UserPartner_id) => (dispatch) => {
    dispatch({ type: CONSTANTS.DOWNLOAD_TICKET_REQUEST })
    return downloadFile(`${APICONFIG.getTicketDownloadUrl}/${payload}/${UserPartner_id}`, payload, `${payload ? payload : 'ticket'}.pdf`).then((res) => {
        dispatch({ type: CONSTANTS.DOWNLOAD_TICKET_SUCCESS, data: res })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.DOWNLOAD_TICKET_FAILURE })
        return err
    })
}


export const getTicketDetails = (payload) => (dispatch) => {
    dispatch({ type: CONSTANTS.GET_TICKET_DETAILS_REQUEST })
    return postRequest(APICONFIG.getSearchTicketUrl, payload).then((res) => {
        dispatch({ type: CONSTANTS.GET_TICKET_DETAILS_SUCCESS, data: res })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.GET_TICKET_DETAILS_FAILURE, errorMessage: err?.errors?.message })
        return err
    })
}
