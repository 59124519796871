import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import moment from 'moment'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
// import { phoneMasker } from '../../utils/helper'
import queryString from 'query-string'
import { saveBookingAfterPayment } from '../../redux/actions/paymentActions'
import { useNavigate } from 'react-router-dom'
import URL from '../../constants/urls'
import { Circles } from 'react-loader-spinner'
import { systemError } from '../../Global/Constant'


function PaymentFails(props) {
	const { dispatch} = props
	const [isLoading, setIsLoading] = useState(true)
	const [paymentResponse, setPaymentResponse] = useState(EMPTY_OBJECT)
	const [paymentSession, setPaymentSession] = useState(EMPTY_OBJECT)
	const [queries, setQueries] = useState(EMPTY_OBJECT)
	const navigate = useNavigate()
	const partner = window.location.toString().split('/')[3]

	useEffect(() => {
		
		setPaymentResponse(EMPTY_OBJECT)

		//setPage('thankyou')
		window.scrollTo(0, 0)
		let queries = queryString.parse(location.search)
		setQueries(queries)
		const beforePaymentPayload = JSON.parse(localStorage.getItem('beforePaymentPayloadValue'))
		const paymentSession = JSON.parse(localStorage.getItem('paymentSession'))
		setPaymentSession(paymentSession)
           const payloadafterPayment = {
			'facility_id': beforePaymentPayload?.facility_id,
			'license_number': beforePaymentPayload?.license_number,
			'first_name': beforePaymentPayload?.firstname,
			'last_name': beforePaymentPayload?.lastname,
			'password': beforePaymentPayload?.password ? beforePaymentPayload?.password : '',
			'confirm_password': beforePaymentPayload?.cpassword ? beforePaymentPayload?.cpassword : '',
			'email': beforePaymentPayload?.email,
			'phone': beforePaymentPayload?.phone,
			'city': beforePaymentPayload?.city,
			'state': beforePaymentPayload?.state,
			'zipcode': beforePaymentPayload?.zipcode,
			'desired_start_date': moment(beforePaymentPayload?.desired_start_date).format('YYYY/MM/DD'),
			'vehicleList': beforePaymentPayload?.vehicleList,
			'is_admin': '0',
			'user_id': '',
			'address1': beforePaymentPayload?.address1,
			'address2': beforePaymentPayload?.address2 ? beforePaymentPayload?.address2 : '',
			'permit_rate': beforePaymentPayload?.permit_rate,
			'permit_rate_id': beforePaymentPayload?.permit_rate_id,
			'payment_status': 'fail',
			'MerchantRef': queries?.MerchantRef,
			// TxID : queries?.TxID,
			// CardType: queries?.CardType,
			// total : paymentSession?.amount,
			// TokenNo:queries?.TokenNo,
			// AuthorisationCode: queries?.AuthorisationCode,

		}
		// if (queries?.AuthorisationCode !== null && queries?.AuthorisationCode !== '') {
			// setPaymentStatus(true)
			setIsLoading(true)
			dispatch(saveBookingAfterPayment(payloadafterPayment)).then((res) => {
				if (res?.data?.message && res?.data?.message == 'Booking already done by the user.') {
					navigate(`/${partner}${URL?.BASE_HOME}`)
				}
				setPaymentResponse(res)
				setIsLoading(false)
				if (res?.data && res?.data?.ticketech_code != '') {
					// setSystemError(false)
					// setPaymentStatus(false)
				} else {
					setIsLoading(false)
				}
				//localStorage.clear()
			}).catch((err) => {
				if (err.ticketech_code != '') {
					//localStorage.clear()
				} else {
					setIsLoading(false)
				}
			})
		// }
		// else {
		// 	setIsLoading(false)
		// }
	}, EMPTY_ARRAY)

	const handleReBooking = () => {
		//window.location.reload(true)
		navigate(URL?.HOME)
	}

	return (
		isLoading ? <span className='ms-2 d-flex justify-content-center'><Circles
			height="500"
			width="50"
			color="var(--bs-primary)"
			ariaLabel="circles-loading"
			visible={true}
		/></span> :
			<div>
				<div className="bg-white p-4 mb-3"></div>
				<div className="flex justify-content-center">
					<div className="col-sm-10">
						<div className="card my-card text-center">
							<h1 className="text-center text-danger fw-bold p-3">Payment Failed!</h1>
							<h5 className="text-center text-danger fw-bold p-3">{(paymentResponse?.data == null || paymentResponse?.data == undefined || !paymentResponse?.data) ? paymentResponse?.errors?.message ? paymentResponse?.errors?.message : systemError : systemError}</h5>
							{paymentSession ? <div className="text-center">
								<p><b>Please note the following for your reference:</b></p>
								<p><b>Amount:</b> ${paymentSession?.amount}</p>
								<p><b>Reference ID:</b> {paymentSession?.reference}</p>
								<p><b>Transaction ID:</b> {queries?.TxID}</p>
							</div> : null}
							<div className="bg-success text-dark bg-opacity-25 p-3">

								<button type="button" className="btn btn-primary " onClick={(e) => handleReBooking(e)}>Book Another Permit</button>
							</div>
						</div>
					</div>
				</div>

			</div>
	)

}

PaymentFails.propTypes = {
	dispatch: PropTypes.func,
	data: PropTypes.object,
	events: PropTypes.object,
	loader: PropTypes.bool,
	getCard: PropTypes.func,
	passData: EMPTY_ARRAY,
	setPage: PropTypes.func,
	response: PropTypes.object
}

PaymentFails.defaulProps = {
	dispatch: noop,
	data: EMPTY_OBJECT,
	events: EMPTY_OBJECT,
	loader: PropTypes.bool,
	getCard: PropTypes.func,
	passData: EMPTY_ARRAY,
	setPage: noop,
	response: EMPTY_OBJECT
}

function mapStateToProps({ paymentReducer }) {
	return {
		data: paymentReducer,
	}
}
export default connect(mapStateToProps)(PaymentFails)
