import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask'
import { EncryptionHandler, getPartnerDetails } from '../../utils/helper'
import { QRCodeSVG } from 'qrcode.react'
// import { getConfigDetails } from '../../services/config'

import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import { Circles } from 'react-loader-spinner'
// import setHours from 'date-fns/setHours'
// import setMinutes from 'date-fns/setMinutes'
// import { errorMessage } from '../../constants/errorMessage'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
import {
  getPriceDetailsByTime,
  applyPromoCodeReservation,
  saveReservationPayment,
  downloadTicket,
} from '../../redux/actions/widgetReservationAction'

//import moment from 'moment'
//import moment from '../../Global/Time/Moment'
import moment from 'moment-timezone'
import DatePicker from 'react-datepicker'
import { useParams ,useLocation} from 'react-router-dom'
function responsiveReservation(props) {
  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    reset,
    clearErrors,
  } = useForm()
//   const { dispatch, loader, notify } = props
const { dispatch,  notify } = props
  // const [facilityTimeZone, setFacilityTimeZone] = useState()
  const cardnumbermask = createDefaultMaskGenerator('99999999999999999999')
  const expirymask = createDefaultMaskGenerator('99/99')
  const phoneMask = createDefaultMaskGenerator('(999) 999-9999')
  const [page, setPage] = useState(1)
  // const [partners] = useState('worldport-la')
  const [isValidPartner, setIsValidPartner] = useState(false)
  const [partnerdetails, setPartnerDetails] = useState(EMPTY_OBJECT)
  const [facility, setFacility] = useState(EMPTY_ARRAY)
 
  const [facilityId, setFacilityId] = useState()
  const [priceDetails, setPriceDetails] = useState(EMPTY_OBJECT)
  const [priceDetailsResponce, setPriceDetailsResponce] =
    useState(EMPTY_OBJECT)
  const [promoCodeApply, setPromoCodeApply] = useState(false)
  const [discount, setDiscount] = useState()
  //payment
  const [bookingStatus, setBookingStatus] = useState(false)
  const [totalHoursLength, setTotalHoursLength] = useState(0)
  const [ticketDetails, setTicketDetails] = useState(EMPTY_OBJECT)
  const [isLoading, setIsLoading] = useState(true)
  const [facilityName, setFacilityName] = useState(true)
  const [promocode, setPromocode] = useState(true)
  const [webSourceBooking, setWebSourceBooking] = useState('parkEngage') 
  const [mapURL, setMapURL] = useState('') 
  const [loginData, setLoginData] = useState(EMPTY_OBJECT)
  const [isLoggedIn, setIsLoggedIn] = useState(false)  
  const [mobile, setMobile] = useState('')
  const [ticketId,setTicketId]=useState('') 
  const [isLicensePlate,setIsLicensePlate]=useState(false) 
  const [loaderTicket,setLoaderTicket]=useState(false)
  const [entryDateTime, setEntryDateTime] = useState(new Date())
 const [exitDateTime, setExitDateTime] = useState(new Date()) 

//   const [dateDisplayFormat]=useState('MMMM DD, yyyy')
//   const [dateTimeFormat]=useState('MMMM DD, yyyy h:mm a')
//   const [existingDateFormat]=useState('ll')
//   const [timeDisplayFormat]=useState('hh:mm:a') 

//   console.log('dateDisplayFormat',dateDisplayFormat)
//   console.log('dateTimeFormat',dateTimeFormat)
//   console.log('existingDateFormat',existingDateFormat)
//   console.log('timeDisplayFormat',timeDisplayFormat) 
  //  console.log('setFacilityTimeZone',facilityTimeZone)







  const handleDownloadTicket = () => {
    if(!loaderTicket){
        setLoaderTicket(true)    
        dispatch(downloadTicket(ticketId)).then((res) => {
            setLoaderTicket(false)
            if (res?.status && res?.status == 500) {
                notify('error', 'Something went wrong, Please try again!')
            }
            notify('Success', 'E-Ticket Downloaded')
        }).catch(() => {
            setLoaderTicket(false)
            notify('error', 'Something went wrong, Please try again!')
        })
    }
   
    
}

  const resetFormValue = () => {
    reset()
    setTotalHoursLength(0)
    setPromoCodeApply(false)
    setDiscount(0)
    setPromoCodeApply(false)
    setPromocode()
    setPriceDetailsResponce(EMPTY_OBJECT)
    setPriceDetails(EMPTY_OBJECT)
    setValue('entry_time', new Date())
    setValue('exit_time', new Date())
    setValue('garage', facilityId)
    
  }

  const applyPromocode = () => {
    let promoForm = getValues()
    if (promoForm.promo_code) {
      let promoCodePayload = {
        is_guest: false,
        tax_amount: priceDetails.tax_fee,
        client_id: partnerdetails?.clients?.secret,
        email: promoForm.email, //'dhirendra002@yopmail.com',
        promocode: promoForm.promo_code, //'TESTWORLDPORT',
        amount: priceDetails.payable_amount,
      }
      dispatch(applyPromoCodeReservation(promoCodePayload))
        .then((response) => {
          if (response.status == 200 && response.data) {
            setPromocode(promoCodePayload.promocode)
            setPromoCodeApply(true)
            setDiscount(response.data.discount_in_dollar)
            notify(
              'success',
              response.data
                ? response.data.message
                : 'Promocode successfully applied'
            )
          } else {
            notify(
              'error',
              response.errors
                ? response.errors.message
                : 'Please Enter Valid Promo Code'
            )
          }
        })
        .catch((err) => {
          notify('error', err)
        })
    } else {
      notify('error', 'Please Enter Promo Code')
    }
  }

  const resetPriceValue = () => {
    setPromoCodeApply(false)
    setDiscount(0)
  }

 

  const diff_hours = (dt2, dt1) => {
    // console.log('dt1 ',dt1)
    // console.log('dt2',dt2)
    const start_date = new Date(dt1)
    const end_date = new Date(dt2)
    // console.log('dt start_date',start_date)
    // console.log('dt end_date',end_date)
    var diff = (end_date.getTime() - start_date.getTime()) / 1000
    diff /= (60 * 60)
    return parseFloat(Math.abs(Math.round(diff))).toFixed(2)
  }

  const dateTimeFormatefunction = (date) => { 
    let dateformate= date.toLocaleDateString('en-CA')+' '+ String(date.getHours()).padStart(2, '0')+':'+String(date.getMinutes()).padStart(2, '0')+':'+String(date.getSeconds()).padStart(2, '0')
    return dateformate
    
  }

  const getPriceDetaitlsToggle = (endDate,startDate) => {
    setIsLoading(true)
    let user_id = partnerdetails.brand_setting.user_id 
    //  let dt1 = getValues().entry_time
    //  let dt2 =  getValues().exit_time
    let dt1 =startDate?startDate: entryDateTime
    let dt2 = endDate?endDate: exitDateTime
    let hours = diff_hours(dt2, dt1)
    setTotalHoursLength(hours)
    setTimeout(()=>{
   // let entryTime = moment(getValues().entry_time).format('YYYY-MM-DD HH:mm:ss')
    let entryTime =  dateTimeFormatefunction(new Date(dt1))
    let slug = `arrival_time=${entryTime}&length_of_stay=${hours}&use_bonus=0&user_id=${user_id}`
    dispatch(getPriceDetailsByTime(facilityId, slug)).then((res) => {
      setPriceDetailsResponce(res?.data)
      let payableAmount =
        Number(res?.data?.price) +
        Number(res?.data?.tax_fee) +
        Number(res?.data?.facility?.processing_fee)
      let priceRes = {
        parking_amount: parseFloat(res?.data?.price).toFixed(2),
        tax_fee: parseFloat(res?.data?.tax_fee).toFixed(2),
        processing_fee: parseFloat(res?.data?.facility?.processing_fee).toFixed(
          2
        ),
        payable_amount: parseFloat(payableAmount).toFixed(2),
      }

      setPriceDetails(priceRes)
      setIsLoading(false)
    })
    },0)
    
    
  
  }

  const handleEntryTime = (event) => {
    resetPriceValue()
    setEntryDateTime(event)
    setExitDateTime(event)
    setValue('entry_time', event)
    clearErrors('entry_time')
    getPriceDetaitlsToggle('',getValues().entry_time)
   
    
  }

  const handleExitTime = (event) => {
    resetPriceValue()
    setExitDateTime(event)
    setValue('exit_time', event)
    clearErrors('exit_time')
    getPriceDetaitlsToggle(getValues().exit_time,'')
  }
  const handelGarage=(event)=>{
    clearErrors('garage')
    setValue('garage', event.target.value)
    if(event.target.value){
    let selectedFcility=partnerdetails?.facilities.filter((v)=>v.id==event.target.value)
    setFacilityId(selectedFcility[0]?.id?selectedFcility[0]?.id:partnerdetails?.facilities[0]?.id)
    setFacilityName(selectedFcility[0]?.full_name?selectedFcility[0]?.full_name:partnerdetails?.facilities[0]?.full_name)
    getPriceDetaitlsToggle('','')
    }
    
  }
 const {partner} =useParams()
 const location = useLocation()
  const bookingWeb = new URLSearchParams(location.search).get('web')
   console.log('login data',loginData,isLoggedIn)


  useEffect(() => { 
    setValue('entry_time', new Date())
    setValue('exit_time', new Date())
    loggedInUser()
    if (partner) {
            console.log('isValidPartner >>', isValidPartner)
            const partnerData = getPartnerDetails()
            setPartnerDetails(partnerData)
            setFacility(partnerData?.facilities)
            setFacilityId(partnerData?.facilities[0]?.id)
            setFacilityName(partnerData?.facilities[0]?.full_name)            
            setIsValidPartner(true)
            setWebSourceBooking(bookingWeb)
            // setFacilityTimeZone(partnerData?.facilities[0]?.timezone)
            const d2 = moment(new Date()).tz(`${partnerData?.facilities[0]?.timezone}`).format('YYYY-MM-DD hh:mm:a')
           
            //  let date2 = new Date(d2)
            //  console.log('time useEffect date2',date2) 
            // console.log('current time zone wise >>?timemm',date2.toLocaleDateString('en-CA'))
            setEntryDateTime(new Date(d2))
            setExitDateTime(new Date(d2))

   
    }
  }, [EMPTY_ARRAY])

// console.log('time coming>>FacilityTimeZone',facilityTimeZone)
// console.log('time coming>>entryDateTime',entryDateTime)
// console.log('time coming>>exitDateTime',exitDateTime)
// console.log('time coming>>new Date()',new Date(exitDateTime))

const formatAMPM=(date)=> {
    var hours = date.getHours()
    var minutes = date.getMinutes()
    var ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes
    var strTime = hours + ':' + minutes + ' ' + ampm
    return strTime
  }

const loggedInUser= ()=>{
    const getuserDatafromLocal = JSON.parse(localStorage.getItem('userData'))
    setLoginData(getuserDatafromLocal?.user)
    if (getuserDatafromLocal?.access_token && getuserDatafromLocal?.access_token != '') {
        const signupFormData = JSON.parse(localStorage.getItem('userData'))
        console.log('signupFormData',signupFormData)
        setIsLoggedIn(true)
        if (Object.values(signupFormData?.user)?.length > 0) {
            const loggedInUserData = signupFormData?.user
            setValue('name',loggedInUserData?.name ? loggedInUserData?.name  : '')
            setValue('email',loggedInUserData?.email ? loggedInUserData?.email  : '')
            setValue('phone',loggedInUserData?.phone_number ? loggedInUserData?.phone_number  : '')
            setValue('license_plate',loggedInUserData?.license_number ? loggedInUserData?.license_number  : '')
            setMobile(loggedInUserData?.phone_number ? loggedInUserData?.phone_number  : '')

        }
    } else {
        setIsLoggedIn(false)
    }
}
 

  const newBooking = () => {
        resetFormValue()
        setPage(1)
        setBookingStatus(false)
        setTicketDetails(EMPTY_OBJECT) 
        setValue('entry_time', new Date())
        setValue('exit_time', new Date())
        setIsLoading(false)
       // login details
       loggedInUser()
  }

  const nextPage = () => {
    let formValue = getValues()
   
    if (
      page == 1 &&
      formValue?.garage &&
      formValue?.entry_time &&
      formValue?.exit_time
    ) {
      if(exitDateTime>=entryDateTime){
        setPage(page + 1)
      }else{
        notify('error', 'Please enter valid start and end date!')
      }
      
    }
    if (page == 2 && !('name' in errors) && !('email' in errors) && !('phone' in errors)) {
      setPage(page + 1)
    }

    //setPage(page + 1)
  }
  const prePage = () => {
    setPage(page - 1)
  }

  const onSubmit = (data) => {
    
    if (
      data?.garage &&
      data?.entry_time &&
      data?.exit_time &&
      data?.name &&
      data?.email &&
      data?.phone &&
      data?.card_name &&
      data?.card_number &&
      data?.card_expiry &&
      data?.ccv
    ) {

      setIsLoading(true) 
      let cardData = {
        name_on_card: data?.card_name ? data?.card_name : '',
        card_number: data?.card_number
          ? data?.card_number?.replace(/\D/g, '')
          : '',
        expiration_date: data?.card_expiry
          ? data?.card_expiry?.replace(/\D/g, '')
          : '',
        security_code: data?.ccv ? data?.ccv : '',
        zip_code: '201301',
      }

      const nonce = EncryptionHandler(cardData)
      let garageDetails = partnerdetails?.facilities?.filter(
        (v) => (v.id = data?.garage)
      )
      const description =
        'Reservation for ' +
        garageDetails[0]?.full_name +
        ' Garage begining at ' +
        dateTimeFormatefunction(entryDateTime) +
        ' lasting for ' +
        parseFloat(totalHoursLength).toFixed(2) +
        ''

        const payloadRequest={
          'warning_on_reservation' : '0',
          'is_opt_out' : '',
          'length' : parseFloat(totalHoursLength).toFixed(2),
          'processing_fee' :parseFloat(priceDetailsResponce?.facility?.processing_fee).toFixed(2) ,
          'user_concent' : false,
          'tax_fee' :parseFloat(priceDetailsResponce?.tax_fee).toFixed(2), 
          'vehicle_id' : '',
          'total' : parseFloat(parseFloat(priceDetails.payable_amount)-parseFloat(discount)).toFixed(2),
          'arrival' : dateTimeFormatefunction(entryDateTime) ,
          'is_card_req' : '1',
          'facility_id' : data?.garage,
          'warning_on_reservation_msg_email' : '',
          'use_bonus' : '0',
          'description' : description,
          'warning_on_reservation_msg' : '',
          'companyName' : '',
          'user_id' : partnerdetails.brand_setting.user_id, 
          'payment_profile_id' : '',
          'coupon_threshold_price' : '0',
          'email':data?.email,
          'phone':data?.phone,
          'nonce':nonce,
          'payment_type':'credit_card',
          'savePaymentMethod':true,
          'promocode' : promocode,
          'is_promocode_redeemed' : promoCodeApply,
          'redeemed_amount' : parseFloat(discount).toFixed(2),
          'booking_source':webSourceBooking,
          'license_plate':data?.license_plate,          
          'is_license_plate':isLicensePlate ? 1 : 0,
        }
        if(exitDateTime>=entryDateTime){
            
          dispatch(saveReservationPayment(payloadRequest)).then((response) => {
            const responseData = response?.data
            
            const url ='https://www.google.com/maps/place/'+responseData?.reservation?.facility?.geolocations?.latitude+','+responseData?.reservation?.facility?.geolocations?.longitude
            if (responseData) {
              setTicketDetails(responseData)
              setBookingStatus(true)
              setMapURL(url)
              setPage(4)
              resetFormValue()
              notify('success', 'Booking Successful!')
              setIsLoading(false)
              setTicketId(responseData?.reservation?.id)
    
            } else {
              resetFormValue()
              setPage(4)
              setBookingStatus(false)
              const errors = response?.errors
              setTicketDetails(
                errors ? errors?.message : 'Something went wrong, Please try again!'
              )
              notify('error',  errors ? errors?.message : 'Something went wrong, Please try again!')
             setIsLoading(false)
             
            }
            
          })
        }else{
          notify('error', 'Please enter valid start and end date!')
        }
     
      
    } else {
        
      notify('error', 'Please fill the search details')
      setIsLoading(false)
    }
    
  }

  
  return (
    <div>
      
        <div className="container py-5">
        {page != 4 && <h2 className="fw-light mb-4 mt-4 text-primary">
        Book Parking Right here for your vehicle
      </h2>}
      <form onSubmit={handleSubmit(onSubmit)}>
      {page != 4 &&<div className="row g-5">
        <div className="col-8">
          <div className="accordion" id="accordionPanelsStayOpenExample">
            {/* step 1 */}
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className={page==1?'accordion-button':'accordion-button collapsed'}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded={page==1?true:false}
                  aria-controls="collapseOne"
                >
                  <h5 className="m-0">Select Facility/Garage  </h5>
                </button>
              </h2>
              <div
                id="collapseOne"
                className={page==1?'accordion-collapse collapse show':'accordion-collapse collapse'}
                data-bs-parent="#accordionPanelsStayOpenExample" 
              >
                <div className="accordion-body">
                  
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="mb-3">
                          <label className="form-label">Facility/Garage <span className='text-danger'>*</span></label>
                          <Controller
                          //  onChange={(event) => handelGarage(event)}
                            name="garage"
                            type="select"
                            id="garage"
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: 'This field is required',
                              },
                            }}
                            render={({ field }) => (
                              <select
                                autoComplete="off"
                                className="form-select"
                                {...field}
                                id="garage"
                                selected={facilityId}
                                onChange={(event) => handelGarage(event)}
                                 
                              >
                                <option value="">Select Facility/Garage</option>
                                {/* {facility.length > 1 && (
                                  <option value="">
                                    Select Facility/Garage
                                  </option>
                                )} */}
                                {facility?.map((v, i) => {
                                  return (
                                    <option key={i} value={v.id}>
                                      {v.full_name}
                                    </option>
                                  )
                                })}
                              </select>
                            )}
                          />
                          {errors?.garage && (
                            <span className="text-danger small">
                              {errors?.garage?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="mb-3">
                          <label className="form-label">
                            Start Date / Time <span className='text-danger'>*</span>
                          </label>
                          <Controller
                            name="entry_time"
                            type="text"
                            id="entry_time"
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: 'This field is required',
                              },
                            }}
                            render={({ field }) => (
                              <DatePicker
                                {...field}
                                selected={entryDateTime}
                                onChange={(date) => handleEntryTime(date)}
                                className="form-control "
                                autoComplete="off"
                                id="entry_time"
                                name="entry_time"
                                showTimeSelect
                                dateFormat="MMMM d, yyyy hh:mm aa"
                                minDate={entryDateTime}
                               // minTime={moment(entryDateTime).format('YYYY-MM-DD')>moment(new Date()).format('YYYY-MM-DD') ?setHours(setMinutes(entryDateTime,0), 0):setHours(setMinutes(entryDateTime.getMinutes(), moment(new Date()).format('ss')), entryDateTime.getHours())}
                               // maxTime={setHours(setMinutes(entryDateTime, 30), 23)}
                              />
                            )}
                          />
                          {errors?.entry_time && (
                            <span className="text-danger small">
                              {errors?.entry_time?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="mb-3">
                          <label className="form-label">End Date / Time <span className='text-danger'>*</span></label>
                          <Controller
                            name="exit_time"
                            type="text"
                            id="exit_time"
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: 'This field is required',
                              },
                            }}
                            render={({ field }) => (
                              <DatePicker
                                {...field}
                                selected={exitDateTime}
                                onChange={(date) => handleExitTime(date)}
                                //onChange={(event) => field.onChange(handleCardNumberDD(event))}
                                className="form-control"
                                autoComplete="off"
                                id="exit_time"
                                name="exit_time"
                                showTimeSelect
                                dateFormat="MMMM d, yyyy hh:mm aa"
                                minDate={entryDateTime}
                               // minTime={moment(exitDateTime).format('YYYY-MM-DD')>moment(entryDateTime).format('YYYY-MM-DD') ?setHours(setMinutes(exitDateTime, 0), 0):setHours(setMinutes(entryDateTime.getMinutes(), moment(entryDateTime).format('ss')), entryDateTime.getHours())}
                               // maxTime={setHours(setMinutes(entryDateTime, 30), 23)}
                              />
                            )}
                          />
                          {errors?.exit_time && (
                            <span className="text-danger small">
                              {errors?.exit_time?.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  
                  <div className="mb-3 text-end">
                    <button
                      className="btn bg-primary px-4  text-white"
                      onClick={nextPage}
                      disabled={isLoading}
                    >
                     <span className="ms-2">Next</span> 
                     
                    </button>
                 
            
                  </div>
                </div>
              </div>
            </div>
            {/* step 2 */}
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className={page==2?'accordion-button':'accordion-button collapsed'}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded={page==2?true:false}
                  aria-controls="collapseTwo"
                >
                  <h5 className="m-0">Licese Plate and Name</h5>
                </button>
              </h2>
              <div
                id="collapseTwo"
                className={page==2?'accordion-collapse collapse show':'accordion-collapse collapse'}
                data-bs-parent="#accordionPanelsStayOpenExample"
              >
                <div className="accordion-body">
                  <div className="row ">
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label className="form-label">Name <span className='text-danger'>*</span></label>
                        <Controller
                          name="name"
                          type="text"
                          id="name"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: 'This field is required',
                            },
                          }}
                          render={({ field }) => (
                            <MaskedInput
                              autoComplete="off"
                              className="form-control"
                              {...field}
                              id="name"
                              placeholder="Name"
                            />
                          )}
                        />
                        {errors?.name && (
                          <span className="text-danger small">
                            {errors?.name?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label className="form-label">Email Address <span className='text-danger'>*</span></label>
                        <Controller
                          name="email"
                          type="text"
                          id="email"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: 'This field is required',
                            },
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: 'invalid email address',
                            },
                          }}
                          render={({ field }) => (
                            <MaskedInput
                              autoComplete="off"
                              className="form-control"
                              {...field}
                              id="email"
                              placeholder="email"
                              //onChange={(event) => field.onChange(handleInputChange(event))}
                            />
                          )}
                        />
                        {errors?.email && (
                          <span className="text-danger small">
                            {errors?.email?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label className="form-label">Mobile Number <span className='text-danger'>*</span></label>
                        {/* <input type="number" className="form-control" /> */}
                        <Controller
                          name="phone"
                          type="text"
                          id="phone"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: 'This field is required',
                            },
                            minLength: {
                              value: 10,
                              message: 'Minimum 10 characters allowed',
                            },
                            maxLength: {
                              value: 10,
                              message: 'Maximum 10 characters allowed',
                            },
                          }}
                          render={({ field }) => (
                            //console.log(field, 'field')
                             <MaskedInput
                              maskGenerator={phoneMask}
                              autoComplete="off"
                              maxLength="14"
                              {...field}
                            
                            value={mobile? mobile: ''}
                              title="Phone number should be 10 digit numberic"
                              className="form-control"
                              onInvalid={(e) =>
                                e.target.setCustomValidity(
                                  'Please enter a valid phone number'
                                )
                              }
                              onInput={(e) => e.target.setCustomValidity('')}
                              placeholder="Phone Number"
                              // onChange={(event) => field.onChange(handlePhoneNumber(event))}
                            />
                          )}
                        />

                        {errors?.phone && (
                          <span className="text-danger small">
                            {errors?.phone?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mb-3">
                        <label className="form-label">License Plate</label>
                        <Controller
                          name="license_plate"
                          type="text"
                          id="license_plate"
                          control={control}
                          rules={{
                            required: {
                              value: false,
                              message: 'This field is required',
                            },
                            minLength: {
                              value: 2,
                              message: 'Minimum 2 characters allowed',
                            },
                            maxLength: {
                              value: 6,
                              message: 'Maximum 6 characters allowed',
                            },
                          }}
                          render={({ field }) => (
                            <MaskedInput
                              autoComplete="off"
                              {...field}
                              id="license_plate"
                              title="Phone number should be 10 digit numberic"
                              className="form-control"
                              placeholder="License Plate"
                              // onChange={(event) => field.onChange(handlePhoneNumber(event))}
                            />
                          )}
                        />
                        {errors?.license_plate && (
                          <span className="text-danger small">
                            {errors?.license_plate?.message}
                          </span>
                        )}
                      </div>
                      <div className=' my-2' >
                        <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="isLicensePlate" name="isLicensePlate" onClick={() => setIsLicensePlate(!isLicensePlate)} checked={isLicensePlate} />
                        
                        <small>Set as default</small>
                    </div>
                </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="row">
                      <div className="col-6">
                        <button
                          className="btn btn-secondary  w-100 text-white"
                          onClick={prePage}
                        >
                          Previous
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          className="btn bg-primary  w-100 text-white"
                          onClick={nextPage}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* step 3 */}
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className={page==3?'accordion-button':'accordion-button collapsed'}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded={page==3?true:false}
                  aria-controls="collapseThree"
                >
                  <h5 className="m-0">Enter Payment Details</h5>
                </button>
              </h2>
              <div
                id="collapseThree"
                className={page==3?'accordion-collapse collapse show':'accordion-collapse collapse'}
                data-bs-parent="#accordionPanelsStayOpenExample"
              >
                <div className="accordion-body">
                  <div className="mb-3">
                    <label className="form-label">Apply Promo Code</label>
                    <div className="input-group ">
                      <Controller
                        name="promo_code"
                        type="text"
                        id="promo_code"
                        control={control}
                        rules={{
                          required: {
                            value: false,
                            message: 'This field is required',
                          }
                          
                        }}
                        render={({ field }) => (
                          <MaskedInput
                            autoComplete="off"
                            {...field}
                            id="promo_code"
                            title="Enter Coupon"
                            className="form-control"
                            placeholder="Enter Coupon"
                            // onChange={(event) => field.onChange(handlePhoneNumber(event))}
                          />
                        )}
                      />
                      <button
                        className="btn bg-primary text-white"
                        type="button"
                        onClick={applyPromocode}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="mb-3">
                      <label className="form-label">Name on Card <span className='text-danger'>*</span></label>
                      {/* <input type="text" className="form-control" /> */}
                      <Controller
                        name="card_name"
                        type="text"
                        id="card_name"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'This field is required',
                          },
                        }}
                        render={({ field }) => (
                          <MaskedInput
                            autoComplete="off"
                            className="form-control"
                            {...field}
                            id="card_name"
                            placeholder="card name"
                          />
                        )}
                      />
                      {errors?.card_name && (
                        <span className="text-danger small">
                          {errors?.card_name?.message}
                        </span>
                      )}
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Card Number <span className='text-danger'>*</span></label>
                      <div className="d-flex  align-items-center">
                        {/* <input type="text" className="w-100 p-1 border-0" />{' '} */}
                        <Controller
                          name="card_number"
                          type="text"
                          id="card_number"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: 'Card Number is required',
                            },
                            maxLength: {
                              value: 16,
                              message: 'Maximum 16 characters allowed',
                            },
                          }}
                          render={({ field }) => (
                            <MaskedInput
                              maskGenerator={cardnumbermask}
                              autoComplete="off"
                              pattern="[0-9 ]{16}"
                              maxLength="16"
                              {...field}
                              id="card_number"
                              title="Card no. should be 15-16 digit numberic"
                              className="form-control"
                              onInvalid={(e) =>
                                e.target.setCustomValidity(
                                  'Please enter a valid card number'
                                )
                              }
                              onInput={(e) => e.target.setCustomValidity('')}
                              placeholder="Card no"
                              // onChange={(event) => field.onChange(handleCardNumber(event))}
                            />
                          )}
                        />

                        <i className=" fa text-primary fa-credit-card fa-2x"></i>
                      </div>
                      {errors?.card_number && (
                        <span className="text-danger small">
                          {errors?.card_number?.message}
                        </span>
                      )}
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label">Expiry Date <span className='text-danger'>*</span></label>
                          {/* <input type="text" className="form-control" /> */}
                          <Controller
                            name="card_expiry"
                            type="text"
                            id="card_expiry"
                            control={control}
                            // rules={{ required: (cardNumber.length === 4 && cardExpiry.length !== 4) ? true : false, maxLength: 5 }}
                            rules={{
                              required: {
                                value: true,
                                message: 'Card Expiry is required',
                              },
                              minLength: {
                                value: 4,
                                message: 'Minimum 4 characters allowed',
                              },
                              maxLength: {
                                value: 4,
                                message: 'Maximum 4 characters allowed',
                              },
                            }}
                            render={({ field }) => (
                              <MaskedInput
                                maskGenerator={expirymask}
                                autoComplete="off"
                                pattern="(?:0?[1-9]|1[0-2])/[0-9 ]{2}"
                                maxLength="5"
                                {...field}
                                id="card_expiry"
                                className="form-control"
                                onInvalid={(e) =>
                                  e.target.setCustomValidity(
                                    'Please enter mm/yy format card expiry date'
                                  )
                                }
                                onInput={(e) => e.target.setCustomValidity('')}
                                placeholder="mm/yy"
                                // onChange={(event) => field.onChange(handleCardExpiry(event))}
                              />
                            )}
                          />
                          {errors?.card_expiry && (
                            <span className="text-danger small">
                              {errors?.card_expiry?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label">CVV <span className='text-danger'>*</span></label>
                          <Controller
                            name="ccv"
                            type="text"
                            id="ccv"
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: 'This field is required',
                              },
                              minLength: {
                                value: 3,
                                message: 'Minimum 3 characters allowed',
                              },
                              maxLength: {
                                value: 4,
                                message: 'Maximum 4 characters allowed',
                              },
                            }}
                            render={({ field }) => (
                              <MaskedInput
                                autoComplete="off"
                                type='password'
                               // pattern="([1-9]|1[0-2])/[0-9 ]{2}"
                                maxLength="4"
                                minLength="3"
                                className="form-control"
                                {...field}
                                id="ccv"
                                placeholder="ccv"
                              />
                            )}
                          />
                          {errors?.ccv && (
                            <span className="text-danger small">
                              {errors?.ccv?.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="row">
                        <div className="col-6">
                          <button
                            className="btn btn-secondary  w-100 text-white"
                            onClick={prePage}
                            disabled={isLoading}
                          >
                            Previous
                          </button>
                        </div>
                        <div className="col-6">
                     
                          <div className="col">
                            <button
                              type="submit"
                              name="submit"
                              className="btn bg-primary  w-100 text-white"
                              onClick={handleSubmit}
                              disabled={isLoading}
                            >
                              <div className="d-flex justify-content-center align-items-center">
                                <span>Confirm & Pay</span>
                                <span className="ms-2">
                                  {isLoading ? (
                                    <Circles
                                      height="20"
                                      width="20"
                                      style={{ display: 'inline-block' }}
                                      color="#ffffff"
                                      ariaLabel="circles-loading"
                                      visible={true}
                                    />
                                  ) : null}
                                </span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end */}
          </div>
        </div>
        {/* cart details */}
        <div className="col-sm-4">
          <div className="card  shadow-sm border-0">
            <div className="card-body">
            <div className="mb-3">
               <b className='text-secondory'>Reservation Details</b>
              </div>
              <div className="mb-3">
               <h4>{facilityName}</h4>
              </div>
            </div>
            <div className="mb-3 border border-end-0 border-start-0 p-2 bg-light">
              <div className="row">
                <div className="col text-center">
                  <small className="mb-2 d-block">Entry Date / Time</small>
                  <h6>
                  {entryDateTime ? entryDateTime.toLocaleDateString('en-CA') :'-'}
                    <br />
                    {entryDateTime ? formatAMPM(entryDateTime) :'-'}
                  </h6>
                </div>
                <div className="col-auto">
                  <div
                    className="bg-primary"
                    style={{ height: '100%', width: '2px' }}
                  ></div>
                </div>
                <div className="col-auto border-right border-priamry"></div>
                <div className="col text-center">
                  <small className="mb-2 d-block">Exit Date / Time</small>
                  <h6>
                  {exitDateTime ? exitDateTime.toLocaleDateString('en-CA') :'-'}
                    <br />
                    {exitDateTime ? formatAMPM(exitDateTime) :'-'}
                  </h6>
                </div>
              </div>
            </div>
            <div className="card-body pt-0 border-bottom">
              
              <div className="d-flex justify-content-between mb-1 ">
                <p className="m-0">Name</p> <p className="m-0">{getValues()?.name ? getValues()?.name:'-'}</p>
              </div>
              <div className="d-flex justify-content-between mb-1 ">
                <p className="m-0">Email ID</p>{' '}
                <p className="m-0">{getValues()?.email ? getValues()?.email:'-'}</p>
              </div>
              <div className="d-flex justify-content-between mb-1 ">
                <p className="m-0">Mobile No</p>{' '}
                <p className="m-0">{getValues()?.email ? getValues()?.phone:'-'}</p>
              </div>
              <div className="d-flex justify-content-between mb-1 ">
                <p className="m-0">License Plate</p>{' '}
                <p className="m-0">{getValues()?.license_plate ? getValues()?.license_plate:'-'}</p>
              </div>
            </div>

            <div className="card-body">
              <div className="d-flex justify-content-between mb-1 ">
                <p className="m-0">Parking Amount</p>{' '}
                <p className="m-0">
                  <b>
                    $
                    {priceDetails?.parking_amount
                      ? priceDetails?.parking_amount
                      : '0.00'}
                  </b>
                </p>
              </div>
              {priceDetails?.tax_fee>0 &&  <div className="d-flex justify-content-between mb-1 ">
                <p className="m-0"> Tax</p>{' '}
                <p className="m-0">
                  <b>
                    ${priceDetails?.tax_fee ? priceDetails?.tax_fee : '0.00'}
                  </b>
                </p>
              </div>}
              {priceDetails?.processing_fee>0 && <div className="d-flex justify-content-between mb-1 ">
                <p className="m-0"> Processing Fees</p>{' '}
                <p className="m-0">
                  <b>
                    $
                    {priceDetails?.processing_fee
                      ? priceDetails?.processing_fee
                      : '0.00'}
                  </b>
                </p>
              </div>}
              {promoCodeApply && (
                <div className="d-flex justify-content-between mb-1 ">
                  <p className="m-0"> Promo Code</p>{' '}
                  <p className="m-0">
                    <b>- ${parseFloat(discount).toFixed(2)}</b>
                  </p>
                </div>
              )}

              <div className="d-flex border-top justify-content-between mb-1 pt-2 ">
                <h4 className="font-weight-bold text-primary m-0 ">
                  Payable Amount{' '}
                </h4>
                {promoCodeApply && priceDetails?.payable_amount > discount ? (
                  <h4 className="font-weight-bold text-primary ">
                    $
                    {priceDetails?.payable_amount
                      ? Number(priceDetails?.payable_amount) - Number(discount)
                      : '0.00'}{' '}
                  </h4>
                ) : (
                  <h4 className="font-weight-bold text-primary ">
                    $
                    {priceDetails?.payable_amount
                      ? priceDetails?.payable_amount
                      : '0.00'}{' '}
                  </h4>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>}

      {page == 4 && (
        <div className="card shadow border-0  mt-5 p-5">
          {bookingStatus && ticketDetails?.ref_id ? (
            <div className="row justify-content-center">
              <div className="col-sm-4">
                <h1 className="text-center text-success mb-2">
                  <i className="fa-regular fa-circle-check fa-3x"></i>
                </h1>
                <h4 className="text-center">Thank You for Parking</h4>
                <p className="text-center">
                  Your e-receipt details has been sent to the registered mobile
                  number
                </p>
              </div>
              <div className="col-sm-4">
                <div className="border  p-3 border-dark">
                  <div className="row  ">
                  
                    <div className="col-auto">
                    <QRCodeSVG
                        value={ticketDetails?.reservation?.ticketech_code}
                    
                        //value={`${getConfigDetails()?.adminUrl}/admin/tickets?ticket_number=${ticketDetails?.reservation?.ticketech_code}`}
                        width="100%"
                        height="100%"
                    // size={180}
                    />
                      {/* <img
                        style={{ height: '100px' }}
                        src="https://cdn-icons-png.flaticon.com/512/241/241528.png"
                        alt
                      /> */}
                    </div>
                    <div className="col">
                      <p className="mb-1">Ticket Id: {ticketDetails?.reservation?.ticketech_code}</p>
                      <p className="mb-0">
                        <b>{ticketDetails?.reservation?.facility?.full_name} Garage</b>
                      </p>
                      <p className="mb-0">Enterance: {ticketDetails?.reservation?.facility?.geolocations?.city}</p>
                     
                      <a target='_blank' href={mapURL} className="btn btn-link p-0" rel="noreferrer">
                        Get Directions{' '}
                        <i className="fa-solid fa-diamond-turn-right"></i>
                      </a>
                    </div>
                  </div>
                </div>

              
                             <a  className="btn bg-primary  w-100 text-white"
                              onClick={handleDownloadTicket}
                              disabled={loaderTicket}
                            >
                              <div className="d-flex justify-content-center align-items-center">
                              Download Ticket
                              &nbsp; 
                                 <i className="fa fa-download"></i>
                                <span className="ms-2">
                                  {loaderTicket ? (
                                    <Circles
                                      height="20"
                                      width="20"
                                      style={{ display: 'inline-block' }}
                                      color="#ffffff"
                                      ariaLabel="circles-loading"
                                      visible={true}
                                    />
                                  ) : null}
                                </span>
                              </div>
                            </a>
              </div>
            </div>
          ) : (
            <div className="d-block mt-4">
           <h1 className="text-center text-danger mb-2">
              <i className="fa fa-exclamation-triangle fa-4x"></i>
            </h1>
            <h4 className="text-center"> Booking Error</h4>
            <p className="text-center">
              {ticketDetails}
            </p>
           </div>
          )}

          <div className="mb-3">
            <div className="row">
              <div className="d-flex p-2">
                <button
                  className="btn btn-success   w-100 text-white"
                  onClick={newBooking}
                >
                  New Booking
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      </form>
    </div>
    </div>
   
  )
}

responsiveReservation.propTypes = {
  dispatch: PropTypes.func,
  loader: PropTypes.bool,
  loaderSms: PropTypes.bool,
  loaderDownload: PropTypes.bool,
  notify: PropTypes.func,
}

responsiveReservation.defaulProps = {
  dispatch: noop,
  loader: false,
  notify: noop,
}

function mapStateToProps({ userDetailsReducer }) {
  return {
    loader: userDetailsReducer?.loader,
    loaderSms: userDetailsReducer?.loaderSms,
    loaderDownload: userDetailsReducer?.loaderDownload,
  }
}
export default connect(mapStateToProps)(responsiveReservation)
