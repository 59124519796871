import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import './Confermation.scss'

function ConfermationModal(props) {
    const { setConfermationModal, confermationModal, confermationHeading, confermationMessage, buttonLable, handleDelete } = props
    const toggle = () => setConfermationModal(!confermationModal)

    return (
        <div>
            <Modal isOpen={confermationModal} toggle={toggle} {...props}>
                <ModalHeader toggle={toggle}>{confermationHeading}</ModalHeader>
                <ModalBody className='modalBody-alignment'>{confermationMessage}</ModalBody>
                <ModalFooter>
                    <Button className='modalButton' color={buttonLable === 'Delete' ? 'danger' : 'primary'} onClick={handleDelete}>{buttonLable}</Button>{' '}
                    <Button className='modalButton' color="secondary" onClick={toggle}> Cancel </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

ConfermationModal.propTypes = {
    dispatch: PropTypes.func,
    setConfermationModal: PropTypes.func,
    confermationModal: PropTypes.bool,
    confermationHeading: PropTypes.string,
    confermationMessage: PropTypes.string,
    buttonLable: PropTypes.string,
    handleDelete: PropTypes.func
}

ConfermationModal.defaulProps = {
    dispatch: noop,
    setConfermationModal: PropTypes.func,
    confermationModal: PropTypes.bool,
    confermationHeading: PropTypes.string,
    confermationMessage: PropTypes.string,
    buttonLable: PropTypes.string,
    handleDelete: PropTypes.func
}

function mapStateToProps() {
    return {}
}
export default connect(mapStateToProps)(ConfermationModal)