import {
    GET_PARTNER_DETAILS_REQUEST,
    GET_PARTNER_DETAILS_REQUEST_SUCCESS,
    GET_PARTNER_DETAILS_REQUEST_FAIL,
    GET_MEMBER_CHECK_REQUEST,
    GET_MEMBER_CHECK_REQUEST_SUCCESS,
    GET_MEMBER_CHECK_REQUEST_FAIL,
    POST_EVENT_LOG_REQUEST,
    POST_EVENT_LOG_REQUEST_SUCCESS,
    POST_EVENT_LOG_REQUEST_FAIL
} from '../config/ActionTypes'
import axios from '../../redux/config/axios'
import { APICONFIG } from '../../redux/config/apiConfig'
import { getConfigDetails } from '../../services/config'
import CONSTANTS from '../constants'
import { getRequest } from '../../services'

const token = localStorage.getItem('access_token')
const config = {
    headers: { 
        Authorization: `Bearer ${token}`,
        'X-ClientSecret':getConfigDetails()?.CLIENT_SECRET
    }
}

export const getPartnerDetails = (requestData) => {
    return dispatch => {
        dispatch({ type: GET_PARTNER_DETAILS_REQUEST })
        let endpoint = `${APICONFIG.getPartnerDetailsReqUrl}/${requestData}`
        return axios.get(endpoint, config)
            .then(res => {
                return dispatch({ type: GET_PARTNER_DETAILS_REQUEST_SUCCESS, data: res.data.data, isFetching: false })
            })
            .catch(err => {
                return dispatch({ type: GET_PARTNER_DETAILS_REQUEST_FAIL, isFetching: false, errorMessage: err })
            })
    }
}

export const checkMemberId = (requestData) => {
    return dispatch => {
        dispatch({ type: GET_MEMBER_CHECK_REQUEST })
        let endpoint = `${APICONFIG.verifyMemberReqUrl}`
        
        return axios.post(endpoint, requestData, config)
            .then(res => {
                return dispatch({ type: GET_MEMBER_CHECK_REQUEST_SUCCESS, data:res.data.data, isFetching: false })
            })
            .catch(error => {
                let errorMsg = 'Sorry! something went wrong, please try again.'
                const errorsList = error.response.data.errors
                if (errorsList.message !== 'Validation Errors') {
                    errorMsg = errorsList.message
                } else {
                    for (const key in errorsList.detail) {
                        if (Object.prototype.hasOwnProperty.call(errorsList.detail, key)) {
                            if (typeof errorsList.detail === 'undefined') {
                                errorMsg = errorsList.message
                            } else {
                                errorMsg = errorsList.detail[key]
                            }
                        }
                    }
                }
                return dispatch({ type: GET_MEMBER_CHECK_REQUEST_FAIL, hasError:true, errorMessage:errorMsg, isFetching: false })
            })
        }
}

export const logEvent = (requestData) => {
    return dispatch => {
        dispatch({ type: POST_EVENT_LOG_REQUEST })
        let endpoint = `${APICONFIG.eventLogUrl}`
        
        return axios.post(endpoint, requestData, config)
            .then(res => {
                return dispatch({ type: POST_EVENT_LOG_REQUEST_SUCCESS, data:res.data.data, isFetching: false })
            })
            .catch(error => {
                let errorMsg = 'Sorry! something went wrong, please try again.'
                const errorsList = error.response.data.errors
                if (errorsList.message !== 'Validation Errors') {
                    errorMsg = errorsList.message
                } else {
                    for (const key in errorsList.detail) {
                        if (Object.prototype.hasOwnProperty.call(errorsList.detail, key)) {
                            if (typeof errorsList.detail === 'undefined') {
                                errorMsg = errorsList.message
                            } else {
                                errorMsg = errorsList.detail[key]
                            }
                        }
                    }
                }
                return dispatch({ type: POST_EVENT_LOG_REQUEST_FAIL, hasError:true, errorMessage:errorMsg, isFetching: false })
            })
        }
}

// GET PARTNER BY SLUG
export const getPartnerDetailsBySLug = (slug) => (dispatch) => {
    dispatch({ type: CONSTANTS.GET_PARTNER_DETAILS_BY_SLUG_REQUEST })
    return getRequest(`${APICONFIG.getPartnerBySlug}/${slug}`).then((res) => {
        dispatch({ type: CONSTANTS.GET_PARTNER_DETAILS_BY_SLUG_SUCCESS, data: res })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.GET_PARTNER_DETAILS_BY_SLUG_FAILURE, errorMessage: err })
        return err
    })
}