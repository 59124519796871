import CONSTANTS from '../constants'
import { postRequest } from '../../services'
import { APICONFIG } from '../config/apiConfig'

//Update waiting list
export const updateWaitingList = (data) => (dispatch) => {
	dispatch({ type: CONSTANTS.UPDATE_WAITING_LIST_REQUEST })
	return postRequest(APICONFIG.getWaitingListUrl, data).then((res) => {
		dispatch({ type: CONSTANTS.UPDATE_WAITING_LIST_SUCCESS, data: res })
		return res
	}).catch((err) => {
		dispatch({ type: CONSTANTS.UPDATE_WAITING_LIST_FAILURE, errorMessage: '' })
		return err
	})
}