import React, { useEffect, useState } from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import FooterComponent from '../components/global/footer'
import NavbarComponent from '../components/global/navbar'
import URL from '../constants/urls'
import About from '../pages/about/About'
import Contact from '../pages/contact'
import Main from '../pages/main'
import Pass from '../pages/pass'
import CreatePass from '../pages/passPurchase/main/CreatePass'
import SignUp from '../pages/signup'
import ThankYou from '../pages/thankyou'
import PaymentFails from '../pages/thankyou/PaymentFails'
import UserProfile from '../pages/user/profile/UserProfile'
import VehicleDemo from '../pages/user/vehicleDemo/VehicleDemo'
import Confirmation from '../pages/passPurchase/confirmation/Confirmation'
import Navigating from '../pages/user/MasqueradeUserLogin'
import HomeComponent from '../pages/bookingPortal/home/homeComponent'
import PaymentResponse from '../pages/bookingPortal/pass/paymentResponse'
import PassComponent from '../pages/bookingPortal/pass/passComponent'
import PaymentFailed from '../pages/bookingPortal/pass/paymentFailed'
import FailedComponent from '../pages/bookingPortal/pass/failedComponent'
import DirectBookingResponse from '../pages/bookingPortal/pass/directBookingResponse'
import ConfirmationComponent from '../pages/bookingPortal/pass/confirmationComponent'
import Ticket from '../pages/user/eTicketRecipt/Ticket'
import { toast, ToastContainer } from 'react-toastify'
import { getLoggedUserData } from '../utils/HelperEvent'
import { getPartnerLogo, isNotaValidSlug, isPartnerTownsend } from '../utils/helper'
import Login from '../pages/home/Login'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../constants/globalConstants'
import usePathDetector from '../Hooks/pathChangeDetector'
import SendEmailResetPassword from '../components/global/SendEmailResetPassword'
import ResponsiveReservation from '../pages/responsiveReservation/responsiveReservation'
import { getPartnerDetails } from '../redux/actions'
import { getPartnerDetailsBySLug } from '../redux/actions/partnerAction'
import ForgotPassword from '../components/global/ForgotPassword'
import { getConfigDetails } from '../services/config'
import DraggableButton from '../components/global/DraggableButton'



function RoutesComponent(props) {
	const {dispatch, isFetching} = props
	const slug = window.location.toString().split('/')[3] ? window.location.toString().split('/')[3] : 'wailuku'
	let path = slug?.split('?')?.[0]
	const [isOpened, setIsOpened] = useState(false)
	const [isNavigating, setIsNavigating] = useState(false)
	const [partnerDetails, setPartnerDetails] = useState(EMPTY_OBJECT)
	const [faviconPath, setFaviconPath] = useState('')
	const [pageActive, setPageActive] = useState('')
	// const [title, setTitle] = useState('')
	const isMasquerade = localStorage.getItem('masquerade')

	window.addEventListener('click', function () {
		setIsOpened(false)
	})

	const isPathChanged = usePathDetector()


	useEffect(() => {
		let isNotValidSlug = isNotaValidSlug(path)
		if(isNotValidSlug){
			path = 'wailuku'
		}
		dispatch(getPartnerDetailsBySLug(path)).then((res) => {
			if (res?.data?.email) {
				const partnerData = res?.data
				setPartnerDetails(partnerData)
				localStorage.removeItem('partnerData')
				localStorage.setItem('partnerData', JSON.stringify(res?.data))
				let color = partnerData?.brand_setting ? partnerData?.brand_setting?.color : partnerData?.partner_details?.brand_setting?.color
				let rgb = partnerData?.brand_setting ? JSON.parse(partnerData?.brand_setting?.rgb_color) : JSON.parse(partnerData?.partner_details?.brand_setting?.rgb_color)
				const newFaviconPath = partnerData?.brand_setting ? partnerData?.brand_setting?.logourl : partnerData?.partner_details?.brand_setting ? partnerData?.partner_details?.brand_setting?.logourl : getPartnerLogo(path)
				setFaviconPath(newFaviconPath)
				if (color) {
					document.documentElement.style.setProperty('--bs-primary', color)
					document.documentElement.style.setProperty('base-color', color)
					document.documentElement.style.setProperty('primary', color)
					document.documentElement.style.setProperty('textcolor', color)
					document.documentElement.style.setProperty('--bs-btn-bg', color)
					document.documentElement.style.setProperty('--bs-primary-rgb', `${rgb?.r},${rgb?.g},${rgb?.b}`)
					document.documentElement.style.setProperty('--bs-list-group-active-border-color', color)
					document.documentElement.style.setProperty('--bs-list-group-active-color', color)
					document.documentElement.style.setProperty('--bs-list-group-active-bg', color)
				}
			} else {
				// setIsValidPartner(true)
				localStorage.removeItem('partnerData')
			}

		}).catch(() => {
			console.log('error')

		})


	}, [EMPTY_ARRAY])

	useEffect(() => {
		const updateFavicon = () => {
			const favicon = document.querySelector('link[rel="icon"]')
			favicon.href = faviconPath
		}

		// const updateTitle = () => {
		//   document.title = title
		// }

		updateFavicon()
		// updateTitle()
	}, [faviconPath])

	useEffect(() => {
		if (path != 'navigating') {
			const newFaviconPath = partnerDetails?.brand_setting ? partnerDetails?.brand_setting?.logourl : partnerDetails?.partner_details?.brand_setting ? partnerDetails?.partner_details?.brand_setting?.logourl : getPartnerLogo(path)

			setFaviconPath(newFaviconPath)
			// const newTitle = partnerDetails?.company_name ? partnerDetails?.company_name : getPartnerTagline(path)
			// setTitle(newTitle)
		}

	}, [path, partnerDetails])

	useEffect(() => {
		if (path == 'navigating') {
			setIsNavigating(false)
		} else {
			setIsNavigating(true)
		}
		const partnerData = getPartnerDetails()
		const color = partnerData?.brand_setting?.color
		if (partnerData?.brand_setting && partnerData?.brand_setting?.color) {
			document.documentElement.style.setProperty('--bs-primary', color)
			document.documentElement.style.setProperty('base-color', color)
			document.documentElement.style.setProperty('primary', color)
			document.documentElement.style.setProperty('textcolor', color)
			document.documentElement.style.setProperty('--bs-btn-bg', color)
			document.documentElement.style.setProperty('--bs-primary-rgb', '34, 32, 93')
			document.documentElement.style.setProperty('--bs-list-group-active-border-color', color)
			document.documentElement.style.setProperty('--bs-list-group-active-color', color)
			document.documentElement.style.setProperty('--bs-list-group-active-bg', color)
		}
	}, [EMPTY_ARRAY, isPathChanged])

	const notify = (type, message) => {
		if (type === 'error') {
			toast.error(message, {
				position: toast.POSITION.TOP_RIGHT
			})
			toast.clearWaitingQueue()
		} else if (type === 'success') {
			toast.success(message, {
				position: toast.POSITION.TOP_RIGHT
			})
			toast.clearWaitingQueue()
		}
	}


	const handleStopMasquerading = () => {
		localStorage.removeItem('masquerading')
		window.location.href = `${getConfigDetails().adminUrl}/admin/user-management`
		// window.location.href = 'https://localhost:8888/admin/user-management'
	}


	return (
		<BrowserRouter forceRefresh className="mt-3">
			{isFetching ? null : <NavbarComponent setPageActive={setPageActive} isOpened={isOpened} pageActive={pageActive} setIsOpened={setIsOpened} notify={notify} partnerDetails={partnerDetails} />}
			{isFetching ? null : <Routes>
				<Route
					path={URL.HOME}
					element={
						isPartnerTownsend(path) ? <Login notify={notify} /> : <Main setPageActive={setPageActive} pageActive={pageActive} partnerDetails={partnerDetails} notify={notify} />
					}
				/>
				<Route
					path={URL.INDEX}
					element={
						isPartnerTownsend(path) ? <Login notify={notify} /> : <Main setPageActive={setPageActive} pageActive={pageActive} partnerDetails={partnerDetails} notify={notify}/>
					}
				/>
				<Route
					path={URL.SEND_EMAIL_FORGOT_PASSWORD}
					element={
						<SendEmailResetPassword notify={notify} />
					}
				/>

				<Route
					path={URL.FORFOT_PASSWORD}
					element={
						<ForgotPassword notify={notify} />
					}
				/>

				<Route
					path={URL.THANKYOU}
					element={
						<ThankYou />
					}
				/>
				<Route
					path={URL.ABOUT}
					element={
						<About />
					}
				/>
				<Route
					path={URL.SIGNUP}
					element={
						<SignUp />
					}
				/>
				<Route
					path={URL.PASS}
					element={
						<Pass />
					}
				/>
				<Route
					path={URL.PASSCREATE}
					element={
						<CreatePass partnerDetails={partnerDetails} />
					}
				/>
				<Route
					path={URL.CONTACT}
					element={
						<Contact />
					}
				/>
				<Route
					path={URL.USER}
					element={
						<VehicleDemo notify={notify} partnerDetails={partnerDetails} />
					}
				/>
				<Route
					path={URL.USER_PROFILE}
					element={
						<UserProfile notify={notify} />
					}
				/>
				<Route
					path={URL.PAYMENT_SUCCESS}
					element={
						<ThankYou />
					}
				/>

				<Route
					path={URL.PAYMENT_FAIL}
					element={
						<PaymentFails />
					}
				/>
				<Route
					path={URL.PASS_PAYMENT_SUCCESS}
					element={
						<Confirmation />
					}
				/>
				<Route
					path={URL.PASS_PAYMENT_FAIL}
					element={
						<Confirmation />
					}
				/>
				<Route
					path={URL.NAVIGATING}
					element={
						<Navigating />
					}
				/>
				<Route
					path={URL.NAVIGATING_RECEIPT}
					element={
						<Navigating />
					}
				/>
				<Route
					path={URL.NAVIGATING_PARTNER}
					element={
						<Navigating />
					}
				/>
				<Route
					path={URL.NAVIGATING_MASQUERADE_PARTNER}
					element={
						<Navigating />
					}
				/>
				<Route
					path={URL.BOOKTICKET}
					element={
						getLoggedUserData()?.access_token ? <PassComponent /> : <HomeComponent />
					}
				/>
				<Route
					path={URL.CHECKOUT_PASSES}
					element={
						<PassComponent />
					}
				/>
				<Route
					path={URL.AAA_PAYMENT_RESPONSE}
					element={
						<PaymentResponse />
					}
				/>
				<Route
					path={URL.AAA_FAILED_COMPONENT}
					element={
						<PaymentFailed />
					}
				/>
				<Route
					path={URL.AAA_PAYMENT_SUCCESS}
					element={
						<ConfirmationComponent />
					}
				/>
				<Route
					path={URL.AAA_PAYMENT_FAILED}
					element={
						<FailedComponent />
					}
				/>
				<Route
					path={URL.AAA_BOOKING_RESPONSE}
					element={
						<DirectBookingResponse />
					}
				/>

				<Route
					path={URL.TICKET}
					element={
						<Ticket notify={notify} />
					}
				/>
				<Route
					path={URL.RESPONSIVE_RESERVATION}
					element={
						<ResponsiveReservation notify={notify} partnerDetails={partnerDetails} />
					}
				/>

				<Route path="*" element={
					<Main partnerDetails={partnerDetails} />
				}>

				</Route>
			</Routes>}
			{isFetching ? null : (!isPartnerTownsend(path) && isNavigating) && <FooterComponent />}
			<ToastContainer limit={1} />
			{(isMasquerade == '1') && <DraggableButton
				handleStopMasquerade={handleStopMasquerading}
			/>}
		</BrowserRouter>
	)
}

RoutesComponent.propTypes = {
	dispatch: PropTypes.func,
	isFetching: PropTypes.bool,
}

RoutesComponent.defaulProps = {
	dispatch: noop,
	isFetching: false

}

function mapStateToProps({ PartnerReducer }) {
	return {

		isFetching: PartnerReducer?.isFetching,
	}
}
export default connect(mapStateToProps)(RoutesComponent)
