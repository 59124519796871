import CONSTANTS from '../constants'
import { deleteRequest, downloadFile, getRequest, postRequest } from '../../services'
import { APICONFIG } from '../config/apiConfig'
import { addEventLog } from './EventsLog'

// // Calls the API to get Facility Initials
// export const getPermitDataList = () => (dispatch) => {
//     dispatch({ type: CONSTANTS.GET_PERMIT_LIST_REQUEST })
//     return getRequest(APICONFIG.getPermitDataUrl).then((res) => {
//         dispatch({ type: CONSTANTS.GET_PERMIT_LIST_SUCCESS, data: res.data })
//         return res.data
//     }).catch(() => {
//         dispatch({ type: CONSTANTS.GET_PERMIT_LIST_FAILURE })
//     })
// }

export const getPermitDataList = () => (dispatch) => {
    dispatch(addEventLog('', CONSTANTS.GET_PERMIT_LIST_REQUEST))
    dispatch({ type: CONSTANTS.GET_PERMIT_LIST_REQUEST })
    return getRequest(APICONFIG.getPermitDataUrl).then((res) => {
        let resObject = res 
        dispatch(addEventLog(resObject, CONSTANTS.GET_PERMIT_LIST_SUCCESS))
        dispatch({ type: CONSTANTS.GET_PERMIT_LIST_SUCCESS, data: res?.data })
        return res
    }).catch((err) => {
        let errObject = err 
        dispatch(addEventLog(errObject, CONSTANTS.GET_PERMIT_LIST_FAILURE))
        dispatch({ type: CONSTANTS.GET_PERMIT_LIST_FAILURE })
        return err
    })
}

// GET VEHICLE LIST DETAILS
export const getVehicleDataList = () => (dispatch) => {
    dispatch({ type: CONSTANTS.GET_VEHICLE_LIST_REQUEST })
    return getRequest(`${APICONFIG.getVehicleListUrl}/page/1`).then((res) => {
        dispatch({ type: CONSTANTS.GET_VEHICLE_LIST_SUCCESS, data: res.data })
        return res.data
    }).catch(() => {
        dispatch({ type: CONSTANTS.GET_VEHICLE_LIST_FAILURE })
    })
}

// Post Vehicle Details
export const postVehicleList = (data) => (dispatch) => {
    dispatch({ type: CONSTANTS.POST_VEHICLE_LIST_REQUEST })
    return postRequest(APICONFIG.postVehicleListUrl, data).then((res) => {
        dispatch({ type: CONSTANTS.POST_VEHICLE_LIST_SUCCESS, data: res.data })
        return res.data
    }).catch(() => {
        dispatch({ type: CONSTANTS.POST_VEHICLE_LIST_FAILURE })
    })
}

// Delete Vehicle Details

export const DeleteVehicleList = (id) => (dispatch) => {
    dispatch({ type: CONSTANTS.DELETE_VEHICLE_ITEM_REQUEST })
    return deleteRequest(`${APICONFIG.deleteVehicleItemUrl}/${id}`).then((res) => {
        dispatch({ type: CONSTANTS.DELETE_VEHICLE_ITEM_SUCCESS })
        return res.data
    }).catch(() => {
        dispatch({ type: CONSTANTS.DELETE_VEHICLE_ITEM_FAILURE })
    })
}

// GET PERMIT QR
export const getPermitQR = (permitId, permitCode) => (dispatch) => {
    dispatch({ type: CONSTANTS.GET_PERMIT_QR_REQUEST })
    return downloadFile(`${APICONFIG.getPermitQr}/${permitId}`, '', `${permitCode}.pdf`).then((res) => {
        // console.log(res)
        dispatch({ type: CONSTANTS.DOWNLOAD_TICKET_SUCCESS, data: res })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.GET_PERMIT_QR_FAILURE })
        return err
    })
}

export const PermitRenewData = (data) => (dispatch) => {
    dispatch(addEventLog(data, CONSTANTS.PERMIT_RENEW_REQUEST))
    dispatch({ type: CONSTANTS.PERMIT_RENEW_REQUEST })
    return postRequest(APICONFIG.permitrenewapi, data).then((res) => {
        let resObject = {...data, ...res} 
        dispatch(addEventLog(resObject, CONSTANTS.PERMIT_RENEW_SUCCESS))
        dispatch({ type: CONSTANTS.PERMIT_RENEW_SUCCESS, data: res.data })
        return res.data
    }).catch((err) => {
        let errObject = {...data, ...err} 
        dispatch(addEventLog(errObject, CONSTANTS.PERMIT_RENEW_FAILURE))
        dispatch({ type: CONSTANTS.PERMIT_RENEW_FAILURE })
        return err
    })
}